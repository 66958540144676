import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Spinner,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import { WelcomeInfoThumbnail } from "components/WelcomeInfoPanel";
import { WithRoles } from "components/WithRoles";
import {
  GetInformationPostsDocument,
  RolesEnum,
} from "graphql/__generated__/graphql";
import { orderBy } from "lodash-es";
import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import {
  RiArrowLeftSFill,
  RiArrowLeftSLine,
  RiArrowRightSFill,
  RiArrowRightSLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useQuery } from "urql";

export const InfoPannelRow = () => {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1300px)");
  const [{ data, fetching }] = useQuery({ query: GetInformationPostsDocument });
  const { t } = useTranslation();

  const posts = data?.posts || [];
  const orderedPosts = orderBy(posts, "priority");
  const navigate = useNavigate();

  return (
    <Accordion allowToggle defaultIndex={[0]}>
      <AccordionItem borderTop={"0px"}>
        <Stack gap={3}>
          <AccordionButton>
            <Flex gap={5} alignItems={"center"}>
              <Heading>{t("info_title", "Actualités")}</Heading>
              <AccordionIcon fontSize={"xl"} />
            </Flex>
          </AccordionButton>
        </Stack>
        <AccordionPanel>
          {fetching ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Stack width={"100%"}>
              <CarouselProvider
                visibleSlides={isLargerThan1080 ? 2 : 1}
                totalSlides={orderedPosts.length}
                step={1}
                naturalSlideWidth={50}
                naturalSlideHeight={100}
                isIntrinsicHeight={true}
              >
                <Stack gap={3}>
                  <Flex
                    width={"100%"}
                    justifyContent={"space-between"}
                    direction={"column"}
                    alignItems={"end"}
                  >
                    <Flex gap={5}>
                      <WithRoles roles={[RolesEnum.ProviderReadOnly]}>
                        <Button
                          leftIcon={<FaPlus />}
                          onClick={() => {
                            navigate("./posts/create");
                          }}
                        >
                          {t("info_create_post", "Créer un post")}
                        </Button>
                      </WithRoles>
                      <ButtonFirst>
                        <Icon
                          as={RiArrowLeftSFill}
                          bg={"brand.blue.background"}
                          color={"brand.blue.main"}
                          rounded={"2xl"}
                          p={1}
                          fontSize={"3xl"}
                        />
                      </ButtonFirst>
                      <ButtonBack>
                        <Icon
                          as={RiArrowLeftSLine}
                          bg={"brand.blue.background"}
                          color={"brand.blue.main"}
                          rounded={"2xl"}
                          p={1}
                          fontSize={"3xl"}
                        />
                      </ButtonBack>
                      <ButtonNext>
                        <Icon
                          as={RiArrowRightSLine}
                          bg={"brand.blue.background"}
                          color={"brand.blue.main"}
                          rounded={"2xl"}
                          p={1}
                          fontSize={"3xl"}
                        />
                      </ButtonNext>
                      <ButtonLast>
                        <Icon
                          as={RiArrowRightSFill}
                          bg={"brand.blue.background"}
                          color={"brand.blue.main"}
                          rounded={"2xl"}
                          p={1}
                          fontSize={"3xl"}
                        />
                      </ButtonLast>
                      <DotGroup />
                    </Flex>
                  </Flex>
                  <Slider>
                    <Flex width={"100%"}>
                      {posts.map((post, index) => (
                        <Slide key={index} index={index}>
                          <WelcomeInfoThumbnail post={post} />
                        </Slide>
                      ))}
                    </Flex>
                  </Slider>
                </Stack>
              </CarouselProvider>
            </Stack>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
