import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  useNumberInput,
  type UseNumberInputProps,
} from "@chakra-ui/react";
export const NumberInputCustom = (
  props: UseNumberInputProps & { sign?: string },
) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      ...props,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  return (
    <HStack>
      <Button
        variant={"unstyled"}
        backgroundColor="brand.blue.background"
        borderRadius={"5px"}
        size="md"
        m={0}
        {...dec}
      >
        -
      </Button>
      <InputGroup size="md" w={"120px"}>
        <Input {...input} />
        {props.sign && <InputRightAddon>{props.sign}</InputRightAddon>}
      </InputGroup>

      <Button
        variant={"unstyled"}
        backgroundColor="brand.blue.background"
        borderRadius={"5px"}
        size="md"
        m={0}
        {...inc}
      >
        +
      </Button>
    </HStack>
  );
};
