import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { type User } from "@sentry/react";
import { BetaFeature } from "components/BetaFeature";
import { NavBarLink } from "components/NavBarLink";
import { ProviderRoleBadge } from "components/RolesBadges";
import { WithRoles } from "components/WithRoles";
import { RolesEnum } from "graphql/__generated__/graphql";
import { useLogout } from "hooks";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FiLogOut } from "react-icons/fi";
import { MdAdminPanelSettings } from "react-icons/md";
import {
  RiAlertLine,
  RiBuilding4Line,
  RiCodeSSlashFill,
  RiFileList2Line,
  RiQuestionnaireFill,
} from "react-icons/ri";
import { VscTriangleDown } from "react-icons/vsc";

export const AvatarButton: React.FC<{
  me: User;
  fetching: boolean;
}> = ({ me, fetching }) => {
  const userLastNameFirstName = `${me?.firstName} ${me?.lastName}`;

  const { logout } = useLogout();

  const { i18n, t } = useTranslation();
  const nextLang = i18n.language === "fr" ? "en" : "fr";
  const initialFocusRef = useRef(null);

  const toggleLanguage = () => {
    i18n.changeLanguage(nextLang);
    localStorage.setItem("i18nextLng", nextLang); // Save to Local Storage
  };

  return (
    <Popover placement={"bottom-end"} initialFocusRef={initialFocusRef}>
      {fetching ? (
        <Spinner aria-label="Loading" />
      ) : (
        <PopoverTrigger>
          <Avatar
            name={userLastNameFirstName}
            boxSize={10}
            bg="brand.blue.background"
            color="brand.blue.dark"
            borderRadius="50%"
            cursor={"pointer"}
            aria-label="User"
            as="button"
          >
            <Icon
              position="absolute"
              bottom={-1}
              left={6}
              as={VscTriangleDown}
              color="neutral.grey.400"
              fontSize="md"
              role="button"
            />
          </Avatar>
        </PopoverTrigger>
      )}
      <PopoverContent p={3}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Heading>
            {me.firstName} {me.lastName} <ProviderRoleBadge roles={me.roles} />
          </Heading>
          <Text fontSize={"sm"} my={2}>
            {me.fonction} @{me.partnerName}
          </Text>
          <BetaFeature>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="toggle-language" mb="0">
                {i18n.language === "fr" ? "🇫🇷" : "🇬🇧"}
              </FormLabel>
              <Switch id="toggle-language" onChange={toggleLanguage} />
            </FormControl>
          </BetaFeature>
        </PopoverHeader>
        <Divider />
        <Stack flex="1" alignItems={"start"} p={4}>
          <BetaFeature>
            <WithRoles roles={[RolesEnum.ProviderExecutive]}>
              <NavBarLink
                to={"/dashboard/ma-societe/nda"}
                icon={RiBuilding4Line}
              >
                {t("navbar-my-company", "Ma société")}
              </NavBarLink>
            </WithRoles>
            <NavBarLink to={"/onboarding/mes-alertes"} icon={RiAlertLine}>
              {t("navbar-onboarding", "Onboarding")}
            </NavBarLink>
          </BetaFeature>

          <NavBarLink
            to={"/dashboard/ma-societe/mes-expertises"}
            icon={RiCodeSSlashFill}
          >
            {t("navbar-my-expertises", "Mes expertises")}
          </NavBarLink>

          <NavBarLink to={"/dashboard/documents"} icon={RiFileList2Line}>
            {t("navbar-my-bills", "Marchés Inop’s actifs")}
          </NavBarLink>

          <NavBarLink
            href={import.meta.env.VITE_APP_INTERCOM_FAQ_URL}
            icon={RiQuestionnaireFill}
          >
            {t("navbar-faq", "FAQ")}
          </NavBarLink>

          <Divider />
          <WithRoles roles={[RolesEnum.InternalOperational]}>
            <Text size="sm">{t("navbar-admin", "Administrateur")}</Text>
            <NavBarLink icon={MdAdminPanelSettings} to="/admin">
              {t("navbar-admin-dashboard", "Admin")}
            </NavBarLink>
            <Divider />
          </WithRoles>
          <Flex py={3}>
            <Button variant={"link"} onClick={logout} leftIcon={<FiLogOut />}>
              {t("navbar-logout", "Déconnexion")}
            </Button>
          </Flex>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
