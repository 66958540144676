import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const ChannelInfoPage = () => {
  const navigate = useNavigate();
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 10, md: 20 }}>
          <Heading fontSize={"3xl"}>Information pour le channel</Heading>
          <Text color={"neutral.grey.500"}>
            Afin de mieux vous connaître et de vous propser les appels d'offre
            qui vous correspondent le mieux, nous avons besoin que vous nous
            communiquiez des informations sur votre structure.
          </Text>
          <Text color={"neutral.grey.500"}>- Team Channel, INOPS.</Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              size={"lg"}
              fontWeight={"md"}
              px={12}
              onClick={() => {
                navigate("/onboarding/mes-alertes");
              }}
            >
              On y va!
            </Button>
            <Button
              variant={"link"}
              onClick={() => {
                navigate("/dashboard/main");
              }}
            >
              Plus tard
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            height={"300px"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              alt={""}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={
                "https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?w=2000&t=st=1650987198~exp=1650987798~hmac=1b9b3583a40c873dcfb1c9dcfa30b472c161f8b21078e8ecf5924ad65acd973d"
              }
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
};
