import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Select,
  Switch,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "components/Datepicker";
import { Dropzone } from "components/Dropzone";
import { isBefore, isEqual } from "date-fns";
import {
  GenerateDownloadUrlDocument,
  ProjectTypeEnum,
  RiskLevelEnum,
  SetCachedPhaseDocument,
  type Maybe,
  type Phase,
} from "graphql/__generated__/graphql";
import yup from "libs/yup";
import { useState, type FC } from "react";
import { Controller, useForm, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IPartnerContactProject } from "types";
import { useMutation } from "urql";
import { toBase64 } from "utils";

const isProofRequired = (
  proofPath?: Maybe<string>,
  adjustedDeliveryDate?: Maybe<Date>,
  originalAdjustedDeliveryDate?: Maybe<Date>,
  riskLevel?: Maybe<RiskLevelEnum>,
) => {
  if (proofPath) {
    return false;
  }

  if (riskLevel === RiskLevelEnum.Cancel) {
    return true;
  }

  if (originalAdjustedDeliveryDate && adjustedDeliveryDate) {
    if (!isEqual(originalAdjustedDeliveryDate, adjustedDeliveryDate)) {
      return true;
    }
    return false;
  }

  if (adjustedDeliveryDate) {
    return true;
  }

  return false;
};

const schema = yup
  .object()
  .shape({
    completion: yup
      .number()
      .typeError("Doit être un nombre")
      .integer()
      .min(0)
      .max(100)
      .when("$isForfait", ([isForfait], schema) =>
        isForfait ? schema.required() : schema,
      ),

    timeDone: yup
      .number()
      .typeError("Doit être un nombre")
      .min(0)
      .when("$isAT", ([isAT], schema) =>
        isAT
          ? schema
              .test(
                "is a slice of 0.5",
                "Vous devez renseigner un nombre de jour par tranche de 0.5 jours",
                (number) => number !== undefined && (number / 0.5) % 1 === 0,
              )
              .required()
          : schema,
      ),
    proof: yup
      .mixed()
      .when(
        [
          "proofPath",
          "adjustedDeliveryDate",
          "originalAdjustedDeliveryDate",
          "riskLevel",
        ],
        {
          is: (
            proofPath: Maybe<string>,
            adjustedDeliveryDate: Maybe<Date>,
            originalAdjustedDeliveryDate: Maybe<Date>,
            riskLevel: Maybe<RiskLevelEnum>,
          ) =>
            isProofRequired(
              proofPath,
              adjustedDeliveryDate,
              originalAdjustedDeliveryDate,
              riskLevel,
            ),
          then(schema) {
            return schema.required(
              "Merci de nous fournir un justificatif client attestant le décalage projet à la date ajustée ou la demande d'annulation.",
            );
          },
        },
      ),
    riskLevel: yup
      .string()
      .oneOf(
        Array.from(Object.values(RiskLevelEnum)),
        "Vous devez renseigner une valeur",
      ),
    comment: yup
      .string()
      .max(500)
      .when("riskLevel", {
        is: (riskLevel: Phase["riskLevel"]) =>
          riskLevel === RiskLevelEnum.High ||
          riskLevel === RiskLevelEnum.Medium ||
          riskLevel === RiskLevelEnum.Cancel,
        then(schema) {
          return schema.required(
            "Merci de renseigner un commentaire à propos de ce risque",
          );
        },
      }),
  })
  .required();

export type FormPhase = IPartnerContactProject["phases"][number] & {
  project: IPartnerContactProject;
};

type Inputs = FormPhase & {
  proof: File | undefined;
  proofTime: File | undefined;
  originalAdjustedDeliveryDate?: Maybe<Date>;
};

export const Form: FC<{
  phase: FormPhase;
  onSubmit: (
    values: FormPhase,
    file?: File,
    fileTime?: File,
    isDirty?: boolean,
    isSpread?: boolean,
  ) => Promise<void>;
}> = ({ phase, onSubmit: onParentSubmit }) => {
  const {
    name,
    commentRejection,
    completion,
    riskLevel,
    comment,
    adjustedDeliveryDate,
    originalFields,
    contractualDeliveryDate,
    proofPath,
    proofTimePath,
    id,
    timeDone,
    timeSold,
    project: { type },
  } = phase;

  const isAT = type === ProjectTypeEnum.At || type === ProjectTypeEnum.Atf;
  const isForfait = type === ProjectTypeEnum.Forfait;

  const location = useLocation();
  const from = location.state?.from || null;

  const originalAdjustedDeliveryDate = originalFields?.adjustedDeliveryDate;
  const toast = useToast();
  const [{ fetching: fetchingSavePhase }, savePhase] = useMutation(
    SetCachedPhaseDocument,
  );

  const [, generateDownloadUrl] = useMutation(GenerateDownloadUrlDocument);

  // Show or hide the UI
  const [showAdjustedDeliveryDate, setShowAdjustedDeliveryDate] = useState(
    Boolean(adjustedDeliveryDate) && !isAT,
  );
  const [showDropZone, setShowDropzone] = useState(false);
  const { t } = useTranslation();

  const [isSpread, setIsSpread] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty },
    control,
  } = useForm<Inputs>({
    defaultValues: {
      completion: isForfait ? completion : undefined,
      riskLevel,
      timeDone: isAT ? timeDone : undefined,
      comment: comment || "",
      proof: undefined,
      proofTime: undefined,
      proofPath,
      proofTimePath,
      originalAdjustedDeliveryDate,
      adjustedDeliveryDate,
    },
    mode: "onBlur",
    context: { isAT, isForfait },
    // @ts-expect-error
    resolver: yupResolver(schema),
  });

  const riskLevelWatch = watch("riskLevel");
  const timeDoneWatch = watch("timeDone");
  const formAdjustedDeliveryDate = watch("adjustedDeliveryDate");
  const navigate = useNavigate();

  const isProofRequiredBool = isProofRequired(
    proofPath,
    formAdjustedDeliveryDate,
    originalAdjustedDeliveryDate,
    riskLevelWatch,
  );

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    const {
      adjustedDeliveryDate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      originalAdjustedDeliveryDate,
      proof,
      proofTime,
      ...phase
    } = values;

    const adjustedDeliveryDateConditional =
      showAdjustedDeliveryDate && adjustedDeliveryDate
        ? adjustedDeliveryDate
        : undefined;

    await onParentSubmit(
      {
        ...phase,
        adjustedDeliveryDate: adjustedDeliveryDateConditional,
        commentRejection,
      },
      proof,
      proofTime,
      isDirty,
      isSpread,
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, console.log)}>
      <ModalHeader color="brand.blue.main" fontWeight="bold" fontSize="md">
        {name}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Grid templateColumns="repeat(12, 1fr)" gap={5}>
          <GridItem colSpan={showAdjustedDeliveryDate ? 6 : 12}>
            <FormControl size="sm" isInvalid={!!errors.contractualDeliveryDate}>
              <Flex justify="space-between">
                <FormLabel>
                  {t(
                    "phase_edit_modal_contractual_delivery_date",
                    "Échéance contractuelle",
                  )}
                </FormLabel>
                <Button
                  data-test="show-adjusted-delivery-date-box"
                  hidden={showAdjustedDeliveryDate || isAT}
                  variant={"link"}
                  onClick={() => {
                    setShowAdjustedDeliveryDate(true);
                    setValue("adjustedDeliveryDate", contractualDeliveryDate);
                  }}
                >
                  {t("phase_edit_modal_edit_button", "Modifier")}
                </Button>
              </Flex>
              <DatePicker
                disabled
                selected={contractualDeliveryDate}
                onChange={console.log}
              />
            </FormControl>
          </GridItem>
          <GridItem hidden={!showAdjustedDeliveryDate} colSpan={6}>
            <FormControl isInvalid={!!errors.adjustedDeliveryDate}>
              <Flex justify="space-between">
                <FormLabel htmlFor="adjustedDeliveryDate">
                  {t(
                    "phase_edit_modal_replanified_delivery_date",
                    "Échéance replanifiée",
                  )}
                </FormLabel>
              </Flex>
              <Controller
                render={({ field: { value, ...rest } }) => (
                  <DatePicker
                    data-test="adjusted-delivery-date-input"
                    selected={value}
                    onSelect={(e) => {
                      if (e && isBefore(e, new Date())) {
                        toast({
                          title: t(
                            "phase_edit_modal_warning_title",
                            "Attention",
                          ),
                          description: t(
                            "phase_edit_modal_warning_description_date",
                            "La date saisie est antérieure ou égale à la date du jour.",
                          ),
                          status: "warning",
                          duration: 9000,
                          isClosable: true,
                        });
                      }
                    }}
                    {...rest}
                  />
                )}
                name="adjustedDeliveryDate"
                control={control}
              />
              <FormErrorMessage>
                {errors.adjustedDeliveryDate?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={12}>
            <FormControl
              size="sm"
              isInvalid={!!errors.proof}
              isRequired={isProofRequiredBool}
              hidden={
                !showAdjustedDeliveryDate &&
                riskLevelWatch !== RiskLevelEnum.Cancel
              }
            >
              <FormLabel htmlFor="proof">Justificatif</FormLabel>

              {proofPath && !showDropZone ? (
                <Flex justify="space-between" align="center">
                  <List spacing={3}>
                    <ListItem>
                      <Link
                        variant={"blue"}
                        onClick={async () => {
                          const { data } = await generateDownloadUrl({
                            fileName: proofPath,
                          });

                          if (!data?.generateDownloadUrl) {
                            return null;
                          }

                          window.open(data.generateDownloadUrl, "_target");
                        }}
                      >
                        {proofPath ? proofPath.slice(26) : ""}
                      </Link>
                    </ListItem>
                  </List>
                  <Button
                    variant={"link"}
                    onClick={() => {
                      setShowDropzone(true);
                    }}
                  >
                    {t("phase_edit_modal_delete_button", "Supprimer")}
                  </Button>
                </Flex>
              ) : (
                <>
                  <Controller
                    render={({ field }) => (
                      <Dropzone
                        {...field}
                        Custom={
                          commentRejection === null ? (
                            <Button
                              variant={"link"}
                              ml={4}
                              onClick={async () => {
                                const e = field.value;
                                if (!e) {
                                  return;
                                }

                                const b64 = await toBase64(e);
                                navigate(`check`, {
                                  state: {
                                    b64,
                                    from,
                                    fileType: e.type,
                                    isCancelationPhase:
                                      riskLevelWatch === RiskLevelEnum.Cancel,
                                  },
                                });
                              }}
                            >
                              {t(
                                "phase_edit_modal_check_button",
                                "Vérifier la PJ",
                              )}
                            </Button>
                          ) : (
                            <></>
                          )
                        }
                        onChange={async (e) => {
                          field.onChange(e);

                          const b64 = await toBase64(e);

                          navigate(`check`, {
                            state: {
                              b64,
                              from,
                              fileType: e.type,
                              isCancelationPhase:
                                riskLevelWatch === RiskLevelEnum.Cancel,
                            },
                          });

                          await savePhase({
                            phase: {
                              id,
                              commentRejection: null,
                            },
                          });
                        }}
                      />
                    )}
                    control={control}
                    name="proof"
                  />

                  <FormControl display="flex" alignItems="center" mt={4}>
                    <FormLabel
                      htmlFor="propagation-justif"
                      m={0}
                      mr={2}
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      {t(
                        "phase_edit_modal_propagation_justif_label",
                        "Propager ce justificatif aux autres phases du projet ?",
                      )}
                    </FormLabel>
                    <Switch
                      id="propagation-justif"
                      onChange={(e) => {
                        setIsSpread(e.target.checked);
                      }}
                      checked={isSpread}
                    />
                  </FormControl>
                </>
              )}
              <FormErrorMessage>{errors.proof?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          {isForfait && (
            <GridItem colSpan={4}>
              <FormControl size="sm" isInvalid={!!errors.completion} isRequired>
                <FormLabel htmlFor="completion">
                  {t("phase_edit_modal_progress_label", "Avancement")}
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test="completion-input"
                    pr={5}
                    placeholder="100"
                    {...register("completion")}
                    type="number"
                  />
                  <InputRightElement>
                    <Box fontWeight="bold">%</Box>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {errors.completion?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
          {isAT && (
            <GridItem colSpan={12}>
              <Flex>
                <FormControl size="sm" mr={5}>
                  <FormLabel htmlFor="timeSold">
                    {t("phase_edit_modal_sold_days_label", "Jours vendus")}
                  </FormLabel>

                  <Input
                    data-test="timeSold-input"
                    placeholder="100"
                    isDisabled={true}
                    value={timeSold}
                    type="number"
                  />
                </FormControl>
                <FormControl
                  size="sm"
                  isInvalid={!!errors.timeDone}
                  isRequired
                  ml={5}
                >
                  <FormLabel htmlFor="timeDone">
                    {t(
                      "phase_edit_modal_consumed_days_label",
                      "Jours consommés",
                    )}
                  </FormLabel>

                  <Input
                    data-test="timeDone-input"
                    placeholder={String(timeSold || 5)}
                    {...register("timeDone")}
                    type="number"
                    step={0.5}
                  />

                  <FormErrorMessage>
                    {errors.timeDone?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              {timeDoneWatch > timeSold && (
                <Alert status={"warning"} mt={4}>
                  <AlertDescription>
                    {t(
                      "phase_edit_modal_warning_description_days",
                      "Le nombre de jours consommés est supérieur au nombre de jours vendus.",
                    )}
                  </AlertDescription>
                </Alert>
              )}
            </GridItem>
          )}
          <GridItem colSpan={8}>
            <FormControl isInvalid={!!errors.riskLevel} isRequired>
              <FormLabel htmlFor="riskLevel">
                {t("phase_edit_modal_risk_level_label", "Risques")}
              </FormLabel>
              <Select
                data-test="risk-level-select"
                placeholder="À renseigner"
                {...register("riskLevel")}
                variant="filled"
                fontSize="sm"
              >
                <option value={RiskLevelEnum.Low}>
                  {t("phase_edit_modal_risk_level_low", "Tout va bien")}
                </option>
                <option value={RiskLevelEnum.Medium}>
                  {t(
                    "phase_edit_modal_risk_level_medium",
                    "Problème géré en autonomie",
                  )}
                </option>
                <option value={RiskLevelEnum.High}>
                  {t(
                    "phase_edit_modal_risk_level_high",
                    "Intervention d'Inop's nécessaire",
                  )}
                </option>
                <option value={RiskLevelEnum.Cancel}>
                  {t(
                    "phase_edit_modal_risk_level_cancel",
                    "Demande d’annulation de la phase",
                  )}
                </option>
              </Select>
              <FormErrorMessage>{errors.riskLevel?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={12}>
            <FormControl
              size="sm"
              isInvalid={!!errors.comment}
              isRequired={
                riskLevelWatch === RiskLevelEnum.High ||
                riskLevelWatch === RiskLevelEnum.Medium ||
                riskLevelWatch === RiskLevelEnum.Cancel
              }
            >
              <FormLabel htmlFor="comment">Commentaire</FormLabel>
              <Textarea
                p={4}
                fontSize="sm"
                height={40}
                placeholder={t(
                  "phase_edit_modal_comment_placeholder",
                  "Informations complémentaires sur l'avancement du projet",
                )}
                {...register("comment")}
                variant="filled"
              />
              <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          {isAT && (
            <GridItem colSpan={12}>
              <FormControl size="sm" isInvalid={!!errors.proofTime}>
                <FormLabel htmlFor="proofTime">
                  {t(
                    "phase_edit_modal_activity_report",
                    "Compte rendu d'activité",
                  )}
                </FormLabel>

                {proofTimePath && !showDropZone ? (
                  <Flex justify="space-between" align="center">
                    <List spacing={3}>
                      <ListItem>
                        <Link
                          variant={"blue"}
                          onClick={async () => {
                            const { data } = await generateDownloadUrl({
                              fileName: proofTimePath,
                            });

                            if (!data?.generateDownloadUrl) {
                              return null;
                            }

                            window.open(data.generateDownloadUrl, "_target");
                          }}
                        >
                          {proofTimePath ? proofTimePath.slice(26) : ""}
                        </Link>
                      </ListItem>
                    </List>
                    <Button
                      variant={"link"}
                      onClick={() => {
                        setShowDropzone(true);
                      }}
                    >
                      {t("phase_edit_modal_delete_button", "Supprimer")}
                    </Button>
                  </Flex>
                ) : (
                  <>
                    <Controller
                      render={({ field }) => (
                        <Dropzone {...field} onChange={field.onChange} />
                      )}
                      control={control}
                      name="proofTime"
                    />
                  </>
                )}
                <FormErrorMessage>{errors.proofTime?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button
          type={"submit"}
          size={"md"}
          isLoading={isSubmitting || fetchingSavePhase}
          data-test="register-phase-edit-button"
        >
          {t("phase_edit_modal_save_button", "Enregistrer")}
        </Button>
      </ModalFooter>
    </form>
  );
};
