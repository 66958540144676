import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import {
  OrderStatusEnum,
  type ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IPartnerContactProject } from "types";
import { Order } from "./Order";

export const ProjectOrderView: FC<{
  projectOrders: IPartnerContactProject["clientOrders"];
  type?: ProjectTypeEnum;
  isPvNotManagedByPartner?: IPartnerContactProject["isPvNotManagedByPartner"];
}> = ({ projectOrders, type, isPvNotManagedByPartner }) => {
  const [revealed, setRevealed] = useState<string[]>([]);

  const { t } = useTranslation();

  if (!projectOrders) return null;

  const orders = projectOrders.sort((a, b) =>
    (a.reference || "") > (b.reference || "") ? 1 : -1,
  );
  const isAllRowsExpanded = revealed.length === orders.length;

  const onToggle = (id?: string) => {
    if (id) {
      if (revealed.includes(id)) setRevealed(revealed.filter((r) => r !== id));
      else setRevealed([...revealed, id]);
    } else {
      // toggle all
      setRevealed(isAllRowsExpanded ? [] : orders.map((o) => o.id));
    }
  };

  const activeOrders = orders.filter(
    (o) =>
      o.status === OrderStatusEnum.Active ||
      o.status === OrderStatusEnum.ActiveBilling ||
      o.status === OrderStatusEnum.Billable ||
      o.status === OrderStatusEnum.BillableYooz ||
      o.status === OrderStatusEnum.PvSent ||
      o.status === OrderStatusEnum.PvValidated ||
      o.status === OrderStatusEnum.BilledPartial ||
      o.status === OrderStatusEnum.BilledPartialInops ||
      o.status === OrderStatusEnum.Litigation,
  );

  const waitingOrders = orders.filter(
    (o) =>
      o.status === OrderStatusEnum.Unknown ||
      o.status === OrderStatusEnum.Signing ||
      o.status === OrderStatusEnum.NotAssigned ||
      o.status === OrderStatusEnum.BdcProviderWaiting ||
      o.status === OrderStatusEnum.BdcMultiProviderWaiting,
  );

  const doneOrders = orders.filter(
    (o) =>
      o.status === OrderStatusEnum.BilledInops ||
      o.status === OrderStatusEnum.Billed ||
      o.status === OrderStatusEnum.Canceled,
  );

  useEffect(() => {
    setRevealed(activeOrders.map((o) => o.id));
  }, []);

  if (orders.length === 0)
    return <Text>{t("project_details_no_orders", "Aucune commande")}</Text>;

  return (
    <Box>
      <Button
        variant="ghost"
        borderRadius="md"
        color="brand.blue.dark"
        _hover={{
          bg: "white",
        }}
        bg="white"
        mb={1}
        leftIcon={<Icon as={isAllRowsExpanded ? FiChevronUp : FiChevronDown} />}
        onClick={() => {
          onToggle();
        }}
      >
        {isAllRowsExpanded ? "Tout plier" : "Tout déplier"}
      </Button>
      {activeOrders.length > 0 && (
        <Flex my={"16px"} direction={"column"} gap="16px">
          <Text color="grey.500" fontWeight={600} fontSize={"16px"}>
            {t("project_details_active_orders", "Commandes actives")}
          </Text>
          {activeOrders.map((o) => (
            <Order
              order={o}
              key={o.id}
              providerOrders={o.providerOrders}
              type={type}
              isPvNotManagedByPartner={isPvNotManagedByPartner}
              revealed={revealed}
              onToggle={onToggle}
            />
          ))}
        </Flex>
      )}
      {waitingOrders.length > 0 && (
        <Flex my={"16px"} direction={"column"} gap="16px">
          <Text color="grey.500" fontWeight={600} fontSize={"16px"}>
            {t("project_details_orders_to_come", "Commandes à venir")}
          </Text>
          {waitingOrders.map((o) => (
            <Order
              order={o}
              key={o.id}
              providerOrders={o.providerOrders}
              type={type}
              isPvNotManagedByPartner={isPvNotManagedByPartner}
              revealed={revealed}
              onToggle={onToggle}
            />
          ))}
        </Flex>
      )}
      {doneOrders.length > 0 && (
        <Flex my={"16px"} direction={"column"} gap="16px">
          <Text color="grey.500" fontWeight={600} fontSize={"16px"}>
            {t("project_details_orders_done", "Commandes terminées")}
          </Text>
          {doneOrders.map((o) => (
            <Order
              order={o}
              key={o.id}
              providerOrders={o.providerOrders}
              type={type}
              isPvNotManagedByPartner={isPvNotManagedByPartner}
              revealed={revealed}
              onToggle={onToggle}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};
