import {
  Center,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import {
  GetPhaseWithAttachmentsDocument,
  SetCachedPhaseDocument,
} from "graphql/__generated__/graphql";
import { PdfView } from "modules/order/PdfView";
import { CancelationTaskModalForm } from "modules/task/TaskModal/CancelationTaskModalForm";
import { TaskModalForm } from "modules/task/TaskModal/TaskModalForm";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";

export const CheckAttachmentModal = () => {
  const navigate = useNavigate();
  const { id } = useParams() as {
    id: string;
  };

  const location = useLocation();
  const from = location.state?.from || null;

  const isCancelationPhase = location.state?.isCancelationPhase;
  const defaultB64 = location.state?.b64;
  const [{ fetching: isLoading, data: dataPhase, error }] = useQuery({
    query: GetPhaseWithAttachmentsDocument,
    variables: {
      id,
    },
  });

  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const [, savePhase] = useMutation(SetCachedPhaseDocument);

  const phase = dataPhase?.phase;
  const initialB64 = phase?.lastAttachment?.b64;

  const onUpdate = async (commentRejection: string) => {
    setSubmitLoading(true);

    await savePhase({
      phase: {
        id,
        commentRejection,
      },
    });

    setSubmitLoading(false);

    navigate("..", { state: { from } });
  };

  if (error) {
    navigate("..", { state: { from } });
  }

  return (
    <Modal
      isOpen={true}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      size="6xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent p={8}>
        {!defaultB64 || isLoading ? (
          <Center h="500">
            <Spinner />
          </Center>
        ) : (
          <ModalBody overflow="auto" height="calc(100vh - 300px)">
            <Grid templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
              <GridItem colSpan={6} pr={20}>
                <Heading size={"xl"} mb={8}>
                  {phase?.name}
                </Heading>

                {isCancelationPhase ? (
                  <CancelationTaskModalForm
                    onSubmit={onUpdate}
                    isLoadingSubmit={isSubmitLoading}
                  />
                ) : (
                  <TaskModalForm
                    onSubmit={onUpdate}
                    isLoadingSubmit={isSubmitLoading}
                  />
                )}
              </GridItem>
              <GridItem colSpan={6}>
                <PdfView base64={defaultB64 || initialB64} />
              </GridItem>
            </Grid>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
