import { forwardRef, Input } from "@chakra-ui/react";
import { fr } from "date-fns/locale";
import React from "react";
import ReactDatePicker, {
  type ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

registerLocale("fr", fr);

export const DatePicker = forwardRef(
  (
    props: ReactDatePickerProps<any> & {
      inputSize?: "sm" | "md" | "lg";
      inputStyle?: React.CSSProperties;
    },
    ref,
  ) => (
    <ReactDatePicker
      ref={ref}
      locale="fr"
      dateFormat="dd/MM/yyyy"
      placeholderText="JJ/MM/YYYY"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      customInput={
        <Input
          isDisabled={props.disabled}
          data-test="datepicker"
          size={props.inputSize}
          style={props.inputStyle}
        />
      }
      {...props}
    />
  ),
);
