import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  Progress,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { type Row } from "@tanstack/react-table";
import { format } from "date-fns";
import {
  CaseStatusEnum,
  DeleteCachedCaseDocument,
  OrderStatusEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useTrackEvent } from "libs/tracking";
import { sumBy } from "lodash-es";
import { statusInvoiceDico, statusInvoiceToColor } from "modules/invoice/utils";
import { useRef, useState } from "react";
import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiOutlinePlus,
} from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { type IPartnerContactProject } from "types";
import { useMutation } from "urql";
import { toEuro, toKEuro } from "utils/format";
import { nanoidShort, statusOrderDico, statusTrueColorDico } from "../utils";
import { STATUS_CASE_COLOR, STATUS_CASE_DICO } from "./OrdersTable.config";
import { useTranslation } from "react-i18next";

export const OrdersRowSubComponent = ({
  row: {
    original: { clientOrders, isPartialInvoice, type },
  },
}: {
  row: Row<
    IPartnerContactProject & {
      clientOrders: IPartnerContactProject["clientOrders"];
    }
  >;
  hiddenColumns?: string[];
}) => {
  if (!clientOrders) {
    return <div>Aucune commande...</div>;
  }

  return (
    <OrderRows
      clientOrders={clientOrders}
      isPartialInvoice={isPartialInvoice}
      type={type}
    />
  );
};

const OrderRows: React.FC<{
  clientOrders: IPartnerContactProject["clientOrders"];
  isPartialInvoice: IPartnerContactProject["isPartialInvoice"];
  type: IPartnerContactProject["type"];
}> = ({ clientOrders, isPartialInvoice, type: projectType }) => {
  const navigate = useNavigate();

  const isAT =
    projectType === ProjectTypeEnum.At || projectType === ProjectTypeEnum.Atf;

  const navigateToOrderModal = (orderId: string, caseId: string) => {
    navigate(`orders/${orderId}/${caseId}`);
  };
  const { trackEvent } = useTrackEvent();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const [{ fetching: fetchingDeleteCase }, deleteCase] = useMutation(
    DeleteCachedCaseDocument,
  );

  const [currentCaseToDelete, setCurrentCaseToDelete] = useState<{
    caseId: string;
    providerOrderId: string;
  } | null>(null);

  const openIndex = clientOrders.reduce<number[]>((acc, o, i) => {
    if (o.status !== OrderStatusEnum.BilledInops) {
      acc.push(i);
    }

    return acc;
  }, []);

  return (
    <Accordion
      allowMultiple
      reduceMotion
      defaultIndex={openIndex}
      my={10}
      mt={0}
      p={4}
      border="1px solid #e2e8f0"
      borderTop="none"
      borderBottomRadius={"10px"}
    >
      <Flex direction={"column"}>
        {clientOrders.map((order) => {
          if (!order) {
            return null;
          }
          const firstProviderOrder = order.providerOrders.filter(
            (p) => p.status !== OrderStatusEnum.Canceled,
          )[0];

          const caseIdOS = nanoidShort();

          return (
            <AccordionItem key={order.id}>
              {({ isExpanded }) => (
                <Flex direction={"column"}>
                  <AccordionButton
                    p={0}
                    borderRadius={"10px"}
                    cursor={
                      order.providerOrders.length > 0 ? "pointer" : "initial"
                    }
                    userSelect={"text"}
                    as="div"
                  >
                    <Flex
                      px={4}
                      py={4}
                      align="center"
                      justify={"space-between"}
                      w={"100%"}
                    >
                      <Flex gap={5} align="center">
                        {order.providerOrders.length > 0 ? (
                          isExpanded ? (
                            <Icon as={AiFillCaretDown} fontSize="12px" />
                          ) : (
                            <Icon as={AiFillCaretRight} fontSize="12px" />
                          )
                        ) : (
                          <Box w={"12px"} />
                        )}

                        <Text
                          noOfLines={1}
                          fontWeight="semibold"
                          fontSize={"sm"}
                        >
                          <Box
                            as="span"
                            color={statusTrueColorDico[order.status]}
                          >
                            {statusOrderDico[order.status]}
                          </Box>{" "}
                          : BDC client n°
                          {order.reference}
                        </Text>
                      </Flex>
                      <Spacer />

                      {order.status === OrderStatusEnum.Billable &&
                        isPartialInvoice === false && (
                          <Button
                            size="sm"
                            colorScheme={"blue"}
                            rightIcon={<AiOutlinePlus />}
                            variant={"link"}
                            data-test="new-invoice-full-button"
                            onClick={async (e) => {
                              e.stopPropagation();
                              await trackEvent("Submit", "open-order-modal", {
                                firstProviderOrder: firstProviderOrder.id,
                                caseIdOS,
                              });
                              navigateToOrderModal(
                                firstProviderOrder.id,
                                caseIdOS,
                              );
                            }}
                          >
                            {t("orders_table_new_invoice", "Nouvelle facture")}
                          </Button>
                        )}
                    </Flex>
                  </AccordionButton>

                  {order.providerOrders.length > 0 && (
                    <AccordionPanel p={0}>
                      <Accordion
                        allowMultiple
                        reduceMotion
                        defaultIndex={Array.from(
                          { length: order.providerOrders.length },
                          (_, i) => i,
                        )}
                      >
                        <Flex direction={"column"}>
                          {order.providerOrders?.map((o) => {
                            const totalBilledAT = sumBy(
                              o.phases,
                              (p) => p.timeBilled,
                            );
                            const totalSoldAT = sumBy(
                              o.phases,
                              (p) => p.timeSold,
                            );
                            const totalBilledForfait = sumBy(
                              o.phases,
                              (p) => p.costBilled,
                            );
                            const totalSoldForfait = sumBy(
                              o.phases,
                              (p) => p.cost,
                            );

                            const caseId = nanoidShort();
                            const invoicesFiltered = o.invoices.filter(
                              (i) => !i.caseId,
                            );
                            const hasInvoices =
                              invoicesFiltered.length > 0 || o.cases.length > 0;

                            return (
                              <AccordionItem key={o.id}>
                                {({ isExpanded }) => (
                                  <>
                                    <AccordionButton
                                      borderRadius={"10px"}
                                      p={0}
                                      userSelect={"text"}
                                      as="div"
                                      w={"100%"}
                                      cursor={
                                        hasInvoices ? "pointer" : "initial"
                                      }
                                    >
                                      <Flex
                                        alignItems={"center"}
                                        py={4}
                                        px={4}
                                        gap={5}
                                        w={"100%"}
                                        pl="50px"
                                      >
                                        {hasInvoices ? (
                                          isExpanded ? (
                                            <Icon
                                              as={AiFillCaretDown}
                                              fontSize="12px"
                                            />
                                          ) : (
                                            <Icon
                                              as={AiFillCaretRight}
                                              fontSize="12px"
                                            />
                                          )
                                        ) : (
                                          <Box w={"12px"} />
                                        )}

                                        <Tooltip
                                          label={
                                            o.phases.length > 0 && (
                                              <Stack gap={4}>
                                                <Text color="white">
                                                  {o.name}
                                                </Text>
                                                <Stack gap={2}>
                                                  {o.phases.map((p) => (
                                                    <Text
                                                      color="white"
                                                      key={p.id}
                                                    >
                                                      {p.name}{" "}
                                                      {isAT
                                                        ? `(${p.timeBilled}j
                                                      facturés)`
                                                        : ""}
                                                    </Text>
                                                  ))}
                                                </Stack>
                                              </Stack>
                                            )
                                          }
                                        >
                                          <Text
                                            noOfLines={1}
                                            w={"400px"}
                                            textAlign={"left"}
                                            fontSize={"sm"}
                                          >
                                            <Box
                                              as="span"
                                              color={
                                                statusTrueColorDico[o.status]
                                              }
                                            >
                                              {statusOrderDico[o.status]}
                                            </Box>{" "}
                                            : BDC Partenaire {o.name}
                                          </Text>
                                        </Tooltip>

                                        <Spacer />
                                        <Box w="250px" textAlign={"left"}>
                                          {projectType ===
                                            ProjectTypeEnum.Forfait &&
                                          totalSoldForfait > 0
                                            ? o.status !==
                                                OrderStatusEnum.Canceled && (
                                                <Flex
                                                  align={"center"}
                                                  gap={2}
                                                  fontSize={"sm"}
                                                >
                                                  <Text noOfLines={1}>
                                                    {toKEuro(
                                                      totalBilledForfait,
                                                    )}
                                                  </Text>
                                                  /
                                                  <Text noOfLines={1}>
                                                    {toKEuro(totalSoldForfait)}
                                                  </Text>
                                                  <Progress
                                                    colorScheme="gray"
                                                    ml={6}
                                                    width={"72px"}
                                                    value={
                                                      (totalBilledForfait /
                                                        totalSoldForfait) *
                                                      100
                                                    }
                                                    rounded={"md"}
                                                  />
                                                </Flex>
                                              )
                                            : null}
                                          {isAT && totalSoldAT > 0
                                            ? o.status !==
                                                OrderStatusEnum.Canceled && (
                                                <Flex
                                                  fontSize={"sm"}
                                                  align={"center"}
                                                  gap={2}
                                                >
                                                  <Text noOfLines={1}>
                                                    {totalBilledAT}j
                                                  </Text>{" "}
                                                  /
                                                  <Text noOfLines={1}>
                                                    {totalSoldAT}j
                                                  </Text>
                                                  <Progress
                                                    ml={6}
                                                    colorScheme="gray"
                                                    width={"72px"}
                                                    value={
                                                      (totalBilledAT /
                                                        totalSoldAT) *
                                                      100
                                                    }
                                                    rounded={"md"}
                                                  />
                                                </Flex>
                                              )
                                            : null}
                                        </Box>

                                        <Spacer />
                                        <Box w="200px" textAlign={"right"}>
                                          {o.status ===
                                            OrderStatusEnum.Billable && (
                                            <Button
                                              size="sm"
                                              colorScheme={"blue"}
                                              rightIcon={<AiOutlinePlus />}
                                              variant={"link"}
                                              data-test="new-invoice-button"
                                              onClick={async (e) => {
                                                e.stopPropagation();
                                                await trackEvent(
                                                  "Submit",
                                                  "open-order-modal",
                                                  {
                                                    order: o.id,
                                                    caseId,
                                                  },
                                                );
                                                navigateToOrderModal(
                                                  o.id,
                                                  caseId,
                                                );
                                              }}
                                            >
                                              {t(
                                                "orders_table_new_invoice",
                                                "Nouvelle facture",
                                              )}
                                            </Button>
                                          )}
                                        </Box>
                                      </Flex>
                                    </AccordionButton>

                                    {hasInvoices && (
                                      <AccordionPanel p={0}>
                                        {o.cases.map((c) => (
                                          <Flex
                                            key={c.id}
                                            justify="space-between"
                                            align={"center"}
                                            py={4}
                                            pl="80px"
                                            pr={"20px"}
                                            bg={
                                              c.status ===
                                              CaseStatusEnum.Litigation
                                                ? "red.50"
                                                : c.status ===
                                                    CaseStatusEnum.Waiting
                                                  ? "cyan.50"
                                                  : "white"
                                            }
                                            borderRadius={"10px"}
                                            _hover={{
                                              bg:
                                                c.status ===
                                                CaseStatusEnum.Litigation
                                                  ? "red.100"
                                                  : c.status ===
                                                      CaseStatusEnum.Waiting
                                                    ? "cyan.100"
                                                    : "grey.50",
                                            }}
                                            fontSize={"sm"}
                                          >
                                            <Icon
                                              as={BsDot}
                                              fontSize={"24px"}
                                              mr={2}
                                            />
                                            {c.bill ? (
                                              <Tooltip
                                                isDisabled={!isAT}
                                                label={`${sumBy(
                                                  c.bill.repartition,
                                                  (c) => c.time,
                                                )}j facturés`}
                                              >
                                                <Text noOfLines={1} w="336px">
                                                  {c.bill.name ||
                                                    "Facture à soumettre"}
                                                </Text>
                                              </Tooltip>
                                            ) : (
                                              <Flex>
                                                <Text mx={4}>
                                                  {t(
                                                    "orders_table_bill_generated",
                                                    "Facture générée par votre conseiller",
                                                  )}
                                                </Text>
                                              </Flex>
                                            )}
                                            <Box w={"150px"}>
                                              <Tag
                                                ml={2}
                                                colorScheme={
                                                  STATUS_CASE_COLOR[c.status]
                                                }
                                              >
                                                {STATUS_CASE_DICO[c.status]}
                                              </Tag>
                                            </Box>
                                            <Box w="200px">
                                              {c.bill && (
                                                <Text noOfLines={1} mx={4}>
                                                  {toEuro(c.bill.cost)}
                                                </Text>
                                              )}
                                            </Box>

                                            <Spacer />
                                            <Flex align={"center"}>
                                              {(c.status ===
                                                CaseStatusEnum.Waiting ||
                                                c.status ===
                                                  CaseStatusEnum.Pending ||
                                                c.status ===
                                                  CaseStatusEnum.Litigation) && (
                                                <Icon
                                                  as={FaPen}
                                                  color="brand.blue.main"
                                                  fontSize={"20px"}
                                                  cursor={"pointer"}
                                                  data-test="edit-invoice-icon"
                                                  title="Modifier la facture"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigateToOrderModal(
                                                      o.id,
                                                      c.id,
                                                    );
                                                  }}
                                                />
                                              )}

                                              {c.status ===
                                                CaseStatusEnum.Pending && (
                                                <Icon
                                                  as={FaTrash}
                                                  ml={8}
                                                  fontSize={"20px"}
                                                  color="brand.red.main"
                                                  cursor={"pointer"}
                                                  data-test="delete-invoice-icon"
                                                  title="Supprimer la facture"
                                                  onClick={() => {
                                                    setCurrentCaseToDelete({
                                                      caseId: c.id,
                                                      providerOrderId: o.id,
                                                    });
                                                    onOpen();
                                                  }}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        ))}
                                        {invoicesFiltered.map((invoice) => (
                                          <Flex
                                            key={invoice.name}
                                            justify={"space-between"}
                                            align="center"
                                            py={4}
                                            pl="80px"
                                            pr={"40px"}
                                            _hover={{
                                              bg: "grey.50",
                                            }}
                                            borderRadius={"10px"}
                                            fontSize={"sm"}
                                          >
                                            <Icon
                                              as={BsDot}
                                              fontSize={"24px"}
                                              mr={2}
                                            />
                                            <Tooltip
                                              isDisabled={!isAT}
                                              label={`${invoice.quantity}j facturés`}
                                              key={invoice.id}
                                            >
                                              <Text noOfLines={1} w={"336px"}>
                                                {invoice.name}
                                              </Text>
                                            </Tooltip>

                                            <Box w={"150px"}>
                                              {!!invoice.status && (
                                                <Tag
                                                  colorScheme={
                                                    statusInvoiceToColor[
                                                      invoice.status
                                                    ]
                                                  }
                                                >
                                                  {
                                                    statusInvoiceDico[
                                                      invoice.status
                                                    ]
                                                  }
                                                </Tag>
                                              )}
                                            </Box>
                                            <Box w="200px">
                                              <Text noOfLines={1} mx={4}>
                                                {toEuro(invoice.montant)}
                                              </Text>
                                            </Box>

                                            <Text
                                              noOfLines={1}
                                              color="neutral.grey.400"
                                              ml={8}
                                            >
                                              Éch :{" "}
                                              {invoice.echeanceDate
                                                ? format(
                                                    new Date(
                                                      invoice.echeanceDate,
                                                    ),
                                                    "dd/MM/yy",
                                                  )
                                                : "-"}
                                            </Text>
                                            <Spacer />
                                          </Flex>
                                        ))}
                                      </AccordionPanel>
                                    )}
                                  </>
                                )}
                              </AccordionItem>
                            );
                          })}
                        </Flex>
                      </Accordion>
                    </AccordionPanel>
                  )}
                </Flex>
              )}
            </AccordionItem>
          );
        })}
        {currentCaseToDelete && (
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {t(
                    "orders_table_delete_billing_folder",
                    "Supprimer le dossier de facturation",
                  )}
                </AlertDialogHeader>

                <AlertDialogBody>
                  {t(
                    "orders_table_delete_billing_folder_warning",
                    "Êtes-vous sr de vouloir supprimer ce dossier de facturation ? Vous ne pourrez pas revenir en arrière.",
                  )}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button variant={"link"} ref={cancelRef} onClick={onClose}>
                    {t("orders_table_cancel", "Annuler")}
                  </Button>
                  <Button
                    isLoading={fetchingDeleteCase}
                    onClick={async () => {
                      await deleteCase({
                        id: currentCaseToDelete.caseId,
                        providerOrderId: currentCaseToDelete.providerOrderId,
                      });
                      onClose();
                    }}
                    ml={3}
                  >
                    {t("orders_table_delete", "Supprimer")}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
      </Flex>
    </Accordion>
  );
};
