/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthState {
  isLoggedIn: boolean | null;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

export const useAuthStore = create(
  persist<AuthState>(
    (set) => ({
      isLoggedIn: false,
      setIsLoggedIn: (isLoggedIn) => set(() => ({ isLoggedIn })),
    }),
    {
      name: "auth-storage", // name of the item in the storage (must be unique)
    },
  ),
);
