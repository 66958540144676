import { Flex, Heading } from "@chakra-ui/react";
import { RoleProjectsTable } from "modules/role";
import React from "react";

import { useTranslation } from "react-i18next";

const RoleProjects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={10}>
      <Heading fontSize="2xl">
        {t("role_projects_heading", "Affectations des projets")}
      </Heading>
      <RoleProjectsTable />
    </Flex>
  );
};

export default RoleProjects;
