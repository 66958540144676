import { Button, Flex, Text } from "@chakra-ui/react";
import { Inops } from "assets";
import { LoginForm } from "modules/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("amplify-signin-with-hostedUI")) {
      navigate("/dashboard/main");
    }
  }, []);

  return (
    <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
      <Flex
        bg="white"
        p={8}
        justify="center"
        align="center"
        flexDirection="column"
        borderRadius={16}
        h="100%"
        gap={3}
      >
        <Inops width="100%" height={74} />
        <LoginForm />
        <Button
          variant={"link"}
          data-test="forgot-password-button"
          onClick={() => {
            navigate("/reset-password");
          }}
        >
          {t("forgot_password", "Mot de passe oublié")}
        </Button>
        <Flex fontSize="sm" direction="column">
          <Text>
            {t("no_credentials", "Vous n'avez pas encore d'identifiants ?")}
          </Text>
          <Button
            variant={"link"}
            data-test="create-account-button"
            onClick={() => {
              navigate("/signup");
            }}
          >
            {t("create_account", "Créer un compte")}
          </Button>
        </Flex>
        <Flex fontSize="sm" direction="column">
          <Text>
            {" "}
            {t(
              "no_verification_code",
              "Vous n'avez pas reçu de code de vérification ?",
            )}
          </Text>
          <Button
            data-test="resend-code-button"
            variant={"link"}
            onClick={() => {
              navigate("/resend-code");
            }}
          >
            {t("resend_code", "Renvoyer un code")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
