import { Button, Flex, Text } from "@chakra-ui/react";
import { Inops } from "assets";
import { SignupForm } from "modules/auth";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
      <Flex
        bg="white"
        p={8}
        justify="center"
        align="center"
        flexDirection="column"
        borderRadius={16}
        h="100%"
        gap={5}
      >
        <Inops width="100%" height={74} />
        <SignupForm />
        <Flex fontSize="sm" direction="column">
          <Text>
            {t("already_have_credentials", "Vous avez déjà des identifiants ?")}
          </Text>
          <Button
            variant={"link"}
            display="block"
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("login", "Se connecter")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignUpPage;
