import React, { type FC } from "react";
import { useMe } from "hooks";
import { intersection } from "lodash-es";

export const WithRoles: FC<{ roles: string[]; children?: React.ReactNode }> = ({
  roles,
  children,
}) => {
  const { isLoading, error, me } = useMe();
  if (isLoading || error || !me) return null;
  if (!intersection(roles, me?.roles)?.length) return null;

  return <>{children}</>;
};

export const WithoutRoles: FC<{
  roles: string[];
  children?: React.ReactNode;
}> = ({ roles, children }) => {
  const { isLoading, error, me } = useMe();
  if (isLoading || error || !me) return null;
  if (intersection(roles, me?.roles)?.length) return null;

  return <>{children}</>;
};
