import {
  chakra,
  Flex,
  type FlexProps,
  RadioGroup,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
  type UseRadioProps,
} from "@chakra-ui/react";
import { type Control, useController } from "react-hook-form";

const RadioCustom: React.FC<UseRadioProps & { label: string }> = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const labelProps = getLabelProps();

  const styles = useMultiStyleConfig("Radio", { ...props });

  const { value } = props;

  const rootStyles = {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top",
    ...styles.container,
  };

  const checkboxStyles = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    ...styles.control,
  };

  const labelStyles = {
    userSelect: "none",
    marginStart: "0.5rem",
    ...styles.label,
  };

  return (
    <chakra.label className="chakra-radio" __css={rootStyles}>
      <input {...input} />
      <chakra.span
        className="chakra-radio__control"
        {...checkbox}
        __css={checkboxStyles}
      />
      {value && (
        <chakra.span
          my={1}
          mx={3}
          fontSize="sm"
          className="chakra-radio__label"
          {...labelProps}
          __css={labelStyles}
        >
          {props.label}
        </chakra.span>
      )}
    </chakra.label>
  );
};

export const RadioGroupCustom: React.FC<{
  control: Control<any, object>;
  name: string;
  options: Array<{ value: string; label: string }>;
  direction?: FlexProps["direction"];
}> = ({ control, name, options, direction = "column" }) => {
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    value,
  });

  const group = getRootProps();
  return (
    <RadioGroup>
      <Flex direction={direction} {...group} ml={1}>
        {options.map(({ value, label }) => {
          const radio = getRadioProps({ value });

          return <RadioCustom key={value} {...radio} label={label} />;
        })}
      </Flex>
    </RadioGroup>
  );
};
