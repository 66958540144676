import { Center, Flex, Spinner, useToast } from "@chakra-ui/react";
import { Inops } from "assets";
import { verifyEmail } from "libs/auth";
import querystring from "query-string";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { search } = useLocation();
  const { email, code } = querystring.parse(search);
  const { t } = useTranslation();

  useEffect(() => {
    const verify = async () => {
      try {
        if (!email || !code) {
          return null;
        }

        await verifyEmail(email as string, code as string);
        toast({
          title: t("account_activated", "Votre compte est activé"),
          description: t(
            "account_activated_description",
            "Vous pouvez maintenant vous connecter avec vos identifiants.",
          ),
          status: "success",
        });
        navigate("/login");
      } catch (e) {
        toast({
          title: t("error_occurred", "Oups, une erreur est survenue..."),
          status: "error",
          duration: 9000,
        });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    verify();
  }, [email, code, toast, navigate, t]);

  return (
    <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
      <Flex
        bg="white"
        p={8}
        justify="center"
        align="center"
        flexDirection="column"
        borderRadius={16}
        h="100%"
      >
        <Inops width={300} height={100} />
        <Center>
          <Spinner mt={5} />
        </Center>
      </Flex>
    </Flex>
  );
};

export default VerifyEmailPage;
