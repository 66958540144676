import { isBefore } from "date-fns";
import {
  GetPublishingPeriodStartDateDocument,
  PhaseStatusEnum,
  ProjectTypeEnum,
  RolesEnum,
  type GetContactProjectsQuery,
} from "graphql/__generated__/graphql";
import { isEqual } from "lodash-es";
import { type IPartnerContactProject } from "types";
import { useQuery } from "urql";
import { useMe } from "./useMe";

export const useProjectsWithActivePhases = (
  projects: IPartnerContactProject[],
) => {
  const [{ data: lastDateQuery }] = useQuery({
    query: GetPublishingPeriodStartDateDocument,
  });
  const { me } = useMe();
  const isOnlyADV = isEqual(me?.roles, [RolesEnum.ProviderSales]);

  const publishingPeriodStartDate = lastDateQuery?.startDate;

  const activeProjects = projects.reduce(
    (acc: GetContactProjectsQuery["projects"], project) => {
      if (project.isProgressManaged === false) {
        return acc;
      }

      const filterPhases = project.phases.filter((phase) => {
        // accepts finished phases from AT projects where not all days are done
        const timeSold = phase.originalFields?.timeSold ?? 0;
        const timeDone = phase.originalFields?.timeDone ?? 0;

        if (
          phase.status === PhaseStatusEnum.Finished &&
          timeDone < timeSold &&
          (project.type === ProjectTypeEnum.At ||
            project.type === ProjectTypeEnum.Atf)
        ) {
          return true;
        }
        // filter out non active phases or already published phases
        if (
          phase.status !== PhaseStatusEnum.Active ||
          (phase.publishedAt &&
            publishingPeriodStartDate &&
            !isBefore(phase.publishedAt, publishingPeriodStartDate))
        ) {
          return false;
        }
        // add phase to list
        return true;
      });

      if (filterPhases.length) {
        acc.push({ ...project, phases: filterPhases });
      }

      return acc;
    },
    [],
  );

  const phases = activeProjects?.flatMap((p) =>
    p.phases.map((phase) => ({ ...phase, projectType: p.type })),
  );

  const checkedPhases = phases
    ?.filter((p) => p.isValidated)
    .map(({ id, isValidated, projectType }) => ({
      id,
      isValidated,
      projectType,
    }));

  const phasesCount = me?.hasSubmit || isOnlyADV ? 0 : phases?.length || 0;
  const checkedPhasesCount = checkedPhases?.length || 0;

  const remainingPhasesCount =
    me?.hasSubmit || isOnlyADV ? 0 : phasesCount - checkedPhasesCount;

  const allPhasesChecked = remainingPhasesCount === 0 && checkedPhasesCount > 0;

  return {
    activeProjects,
    phasesCount,
    checkedPhases,
    remainingPhasesCount,
    allPhasesChecked,
  };
};
