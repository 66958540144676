import { Center, Flex, Grid, Spinner, Text } from "@chakra-ui/react";
import { type GetContactTasksQuery } from "graphql/__generated__/graphql";
import { useTaskFilter } from "hooks";
import { groupBy, map } from "lodash-es";
import { ProjectRow } from "modules/project/ProjectRow.component";
import { ProjectFilters } from "modules/project/ProjectsTableWithFilters/ProjectFilters";
import React from "react";
import { TAB_NAME } from "stores";
import { Task } from "./Task.component";
import { useTranslation } from "react-i18next";

export const TaskTab: React.FC<{
  tasks: GetContactTasksQuery["tasks"];
  isLoading: boolean;
}> = ({ tasks, isLoading }) => {
  const { filteredTasks, setFilters, filters, clear } = useTaskFilter(
    tasks || [],
    TAB_NAME.tasks,
  );
  const { t } = useTranslation();

  if (!tasks || !tasks.length) {
    return (
      <Text mt={5}>
        {t("no_tasks_in_progress", "Vous n'avez pas de tâches en cours.")}
      </Text>
    );
  }

  const tasksByProject = groupBy(filteredTasks, (p) => p.phase?.project?.id);

  if (isLoading) {
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );
  }
  return (
    <Flex direction="column">
      <ProjectFilters
        status={[{ label: t("in_progress", "En cours"), value: "En cours" }]}
        setFilters={setFilters}
        filters={filters}
        clearFilters={clear}
      />
      {map(tasksByProject, (tasks) => {
        const project = tasks[0].phase?.project;

        return (
          project && (
            <div key={project?.id}>
              <ProjectRow {...project} />

              <Grid mt={6} templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
                {tasks.map((task) => (
                  <Task task={task} key={task.id} />
                ))}
              </Grid>
            </div>
          )
        );
      })}
    </Flex>
  );
};
