import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import {
  GetCaseQuery,
  GetProviderOrderQuery,
} from "graphql/__generated__/graphql";
import React from "react";
import { useNavigate } from "react-router-dom";
import { OrderCraModalForm } from "./form";

export const OrderCraModal: React.FC<{
  order: GetProviderOrderQuery["order"];
  caseResult: GetCaseQuery["case"];
}> = ({ order, caseResult }) => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(`..`);
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="full"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalBody>
          <OrderCraModalForm
            order={order}
            onClose={onClose}
            caseResult={caseResult}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
