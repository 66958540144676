import * as Sentry from "@sentry/react";

import { authExchange } from "@urql/exchange-auth";
import { cacheExchange } from "@urql/exchange-graphcache";
import { jwtDecode } from "jwt-decode";

import {
  ERRORS_MESSAGE,
  ErrorMessages,
  HANDLED_ERRORS,
} from "constants/errorMessages";

import schema from "graphql/__generated__/schema.json";

import { fetchAuthSession } from "aws-amplify/auth";

import {
  GraphCacheKeysConfig,
  GraphCacheOptimisticUpdaters,
  GraphCacheResolvers,
  GraphCacheUpdaters,
} from "graphql/__generated__/graphql";
import { find } from "lodash-es";
import { toast } from "theme/toast";
import {
  createClient,
  errorExchange,
  fetchExchange,
  gql,
  mapExchange,
} from "urql";
import customScalarsExchange from "urql-custom-scalars-exchange";
import { signOut } from "./auth";

const scalarsExchange = customScalarsExchange({
  // @ts-ignore
  schema,
  scalars: {
    Json(value) {
      return JSON.parse(value);
    },
    Date(value) {
      return new Date(value);
    },
    DateTime(value) {
      return new Date(value);
    },
  },
});

type GraphCache = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
};

export const makeUrqlClient = () =>
  createClient({
    url: import.meta.env.VITE_APP_GRAPHQL_URL + "/graphql",
    exchanges: [
      // @ts-ignore
      scalarsExchange,
      errorExchange({
        onError: ({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach((e) => {
              Sentry.captureMessage(e.message);

              const code = e.extensions.code as ErrorMessages;

              if (!HANDLED_ERRORS.includes(code)) {
                toast({
                  status: "error",
                  title: "Erreur",
                  position: "bottom",
                  duration: 10000,
                  description:
                    ERRORS_MESSAGE[code] || "Une erreur est survenue.",
                });
              }
            });

            if (networkError) {
              Sentry.captureException(networkError);
            }
          }
        },
      }),
      cacheExchange<GraphCache>({
        schema,
        // optimistic: {},
        updates: {
          Mutation: {
            publishCachedPhases(_result, _variables, cache) {
              cache.invalidate("Query", "getReports");
              cache.invalidate("Query", "getMe");
            },
            publishCachedOrder(_result, _variables, cache) {
              cache.invalidate("Query", "getProviderOrder", {
                id: _variables.providerOrderId,
              });
            },
            deleteCachedPhases(_result, _variables, cache) {
              cache.invalidate("Query", "getContactProjects");
            },
            setProjectsContact(_result, _variables, cache) {
              cache.invalidate("Query", "getContactProjects");
            },
            setCachedCRA(_result, _variables, cache) {
              cache.invalidate("Query", "getProviderOrder", {
                id: _variables.cra.providerOrderId,
              });
            },
            setCachedPV(_result, _variables, cache) {
              cache.invalidate("Query", "getProviderOrder", {
                id: _variables.pv.providerOrderId,
              });
            },
            setCachedBill(_result, _variables, cache) {
              cache.invalidate("Query", "getProviderOrder", {
                id: _variables.bill.providerOrderId,
              });
            },
            updatePhaseProof(_result, _variables, cache) {
              cache.invalidate("Query", "getContactTasks");
            },
            setInformationPost(result, _variables, cache) {
              const PostList = gql`
                {
                  getInformationPosts {
                    id
                    active
                    title
                    description
                    startDate
                    endDate
                    link
                    buttonLabel
                    roles
                  }
                }
              `;

              cache.updateQuery({ query: PostList }, (data) => {
                const post = find(data.getInformationPosts, {
                  id: _variables.post.id,
                });

                if (!post) {
                  data.getInformationPosts.push(result.setInformationPost);
                }

                return data;
              });
            },
            deleteInformationPost(result, variables, cache) {
              const posts = cache.resolve("Query", "getInformationPosts");
              const { id } = variables;

              if (Array.isArray(posts) && result.deleteInformationPost) {
                const deletePosts = posts.filter(
                  (val) => val !== `InformationPost:${id}`,
                );

                cache.link("Query", "getInformationPosts", deletePosts);
              }
            },
          },
        },
        keys: {
          UserCampaign: (r) => `${r.id}-${r.openingDate}`,
          Repartition: () => null,
        },
        resolvers: {
          Query: {
            getPhase: (parent, { id }) => ({
              __typename: "Phase",
              id,
            }),
            getContactTask: (parent, { id }) => ({ __typename: "Task", id }),
            getContact: (parent, { id }) => ({ __typename: "Contact", id }),
            getProject: (parent, { id }) => ({
              __typename: "Project",
              id,
            }),
            getInformationPostById: (parent, { id }) => ({
              __typename: "InformationPost",
              id,
            }),
          },
        },
      }),
      mapExchange({
        async onError(error, _operation) {
          const isAuthError = error.graphQLErrors.some(
            (e) =>
              e.extensions?.code === ErrorMessages.NOT_AUTHORIZED ||
              e.extensions?.code === ErrorMessages.SF_USER_LEFT ||
              e.extensions?.code === ErrorMessages.SF_NO_ROLES ||
              e.extensions?.code === ErrorMessages.NO_TOKEN ||
              e.extensions?.code === ErrorMessages.SF_USER_NOT_FOUND,
          );

          if (isAuthError) {
            await signOut();
          }
        },
      }),
      authExchange(async (utils) => {
        // called on initial launch,
        // fetch the auth state from storage (local storage, async storage etc)
        let token: string | null | undefined = null;

        try {
          const session = await fetchAuthSession();
          token = session.tokens?.accessToken?.toString();
        } catch (e) {
          console.log(e);
        }
        const switchTo = localStorage.getItem("X-Switch");

        return {
          addAuthToOperation(operation) {
            if (token) {
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
                ...(switchTo && { "X-Switch": switchTo }),
              });
            }
            return operation;
          },
          willAuthError(_operation) {
            // e.g. check for expiration, existence of auth etc
            if (!token) {
              return true;
            }

            // Check if token is expired
            const decoded = jwtDecode(token);

            const currentTime = Math.floor(Date.now() / 1000);

            if (decoded.exp && decoded.exp < currentTime) {
              return true;
            }

            return false;
          },
          didAuthError(error, _operation) {
            // check if the error was an auth error
            // this can be implemented in various ways, e.g. 401 or a special error code

            return error.graphQLErrors.some(
              (e) =>
                e.extensions?.code === ErrorMessages.NOT_AUTHORIZED ||
                e.extensions?.code === ErrorMessages.SF_USER_LEFT ||
                e.extensions?.code === ErrorMessages.SF_NO_ROLES ||
                e.extensions?.code === ErrorMessages.SF_USER_NOT_FOUND,
            );
          },
          async refreshAuth() {
            // called when auth error has occurred
            // we should refresh the token with a GraphQL mutation or a fetch call,
            // depending on what the API supports
            await fetchAuthSession({ forceRefresh: true });
          },
        };
      }),
      fetchExchange,
    ],
  });
