import { useNavigate } from "react-router-dom";
import { TAB_NAME, rootPaths, useUIStore } from "stores";

export const useNavigateToBillable = () => {
  const [setFilters, clear] = useUIStore((s) => [s.setProjectFilters, s.clear]);
  const navigate = useNavigate();

  const navigateTobillable = (
    orderRef?: string | null,
    from?: string | null,
  ) => {
    const isConsolidated = from?.includes("consolidated-view");

    const destination = isConsolidated
      ? rootPaths["consolidated-bills"]
      : rootPaths["billable-projects"];

    const tab = isConsolidated
      ? TAB_NAME.consolidatedBills
      : TAB_NAME.billableProjects;

    const changeFilter = setFilters(tab);
    clear();
    if (orderRef) changeFilter({ searchTerm: orderRef });
    navigate(destination);
  };

  return { navigateTobillable };
};
