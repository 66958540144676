import {
  Box,
  Button,
  Flex,
  GridItem,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { LinkTo } from "components/LinkTo";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type ITask } from "types/task";
import { dicoButton, dicoLabel } from "./TaskDico.constants";
import { mapTaskEnumToLabel } from "./TaskModal/TaskModal.constants";

export const Task: React.FC<{ task: ITask }> = ({ task }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const phase = task.phase;
  // @ts-expect-error
  const rejectionLabel = mapTaskEnumToLabel[task.rejection]?.large;

  return (
    <GridItem
      colSpan={4}
      p={4}
      shadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      borderRadius="lg"
      alignContent={"space-between"}
    >
      <Flex direction={"column"} justify={"space-between"} height="100%">
        <Flex justify={"space-between"} align="center">
          <Tag size="lg" colorScheme={"red-light"}>
            <TagLabel>{dicoLabel[task.taskType]}</TagLabel>
          </Tag>
          <Text fontSize={"xs"} fontWeight="bold">
            Il y a{" "}
            {task.lastModifiedDate &&
              formatDistance(task.lastModifiedDate, new Date(), { locale: fr })}
            .
          </Text>
        </Flex>

        <Flex mt={5} mb={2}>
          <LinkTo
            label={phase?.name}
            to={`/phases/${phase?.id}`}
            state={{ from: location.pathname }}
            fontSize="sm"
            fontWeight={"bold"}
          />
        </Flex>
        <Box mt={5} fontSize="sm">
          {rejectionLabel}
        </Box>
        <Flex justify={"center"} mt={5}>
          <Button
            onClick={() => {
              navigate(`${task.id}/adjusted-delivery-date`);
            }}
          >
            {dicoButton[task.taskType]}
          </Button>
        </Flex>
      </Flex>
    </GridItem>
  );
};
