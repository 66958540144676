import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { DatePicker } from "components/Datepicker";
import { add } from "date-fns";
import {
  GetInformationPostByIdDocument,
  GetInformationPostsDocument,
  RolesEnum,
  SetInformationPostDocument,
  type InformationPostInput,
} from "graphql/__generated__/graphql";
import yup from "libs/yup";
import { uniqueId } from "lodash-es";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  startDate: yup.date(),
  endDate: yup
    .date()
    .min(
      yup.ref("startDate"),
      "La date de fin ne doit pas être avant la date de début.",
    ),
  link: yup.string(),
  youtubeLink: yup.string(),
  buttonLabel: yup.string(),
  roles: yup
    .array()
    .of(yup.mixed<RolesEnum>().oneOf(Object.values(RolesEnum)).required()),
  priority: yup.number().required(),
});

export const CreateInformationPostModal: React.FC = () => {
  const [{ fetching }, setInformationPost] = useMutation(
    SetInformationPostDocument,
  );
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();

  const [{ data }] = useQuery({
    query: GetInformationPostByIdDocument,
    variables: { id: id ?? "" },
    pause: !id,
  });

  const [{ data: posts }] = useQuery({
    query: GetInformationPostsDocument,
  });

  const post = data?.post;
  const priorityPostOptions = Array.from(
    { length: (posts?.posts.length || 0) + (id ? 0 : 1) },
    (_, i) => i + 1,
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm<InformationPostInput>({
    mode: "onBlur",
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: {
      title: post?.title || "",
      description: post?.description || "",
      startDate: post?.startDate || new Date(),
      endDate: post?.endDate || add(new Date(), { days: 7 }),
      link: post?.link || "",
      youtubeLink: post?.youtubeLink || "",
      buttonLabel: post?.buttonLabel || "",
      priority: post?.priority || 1,
      roles: post?.roles || [
        RolesEnum.ProviderReadOnly,
        RolesEnum.ProviderExecutive,
        RolesEnum.ProviderOperational,
        RolesEnum.ProviderSales,
      ],
    },
  });

  const onClose = () => {
    navigate("/dashboard/main");
  };
  const { t } = useTranslation();

  const onSubmit = async (values: InformationPostInput) => {
    await schema.validate(values);

    const { error } = await setInformationPost({
      post: { ...values, id: post ? post.id : uniqueId() },
    });

    if (!error) {
      toast({
        title: `Le post ${values.title} a été ${post ? "édité" : "créé"}`,
        status: "success",
      });

      reset();

      onClose();
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"2xl"} color={"brand.blue.dark"}>
          {post ? "Éditer le post" : "Nouveau post"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction={"column"} gap={3} py={5}>
              <FormControl isInvalid={!!errors.title}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t("post-title", "Titre de votre post")}
                  </FormLabel>
                  <Input
                    placeholder={t("post-title", "Titre de votre post")}
                    variant={"filled"}
                    {...register("title")}
                  />
                  <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <Flex direction={"column"}>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder={t("post-content", "Votre post ici...")}
                    {...register("description")}
                  ></Textarea>
                  <FormErrorMessage>
                    {errors.description?.message}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.buttonLabel}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t(
                      "createinformationpost-cta-label",
                      "Libellé du Call To Action",
                    )}
                  </FormLabel>

                  <Input
                    placeholder={t(
                      "cta-label",
                      "Ex: Aller à l'édition de phases",
                    )}
                    {...register("buttonLabel")}
                  />
                  <FormErrorMessage>
                    {errors.buttonLabel?.message}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.link}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t("createinformationpost-link", "Lien de navigation")}
                  </FormLabel>

                  <Input
                    placeholder={`Ex: "/dashboard/actions"`}
                    {...register("link")}
                  />
                  <FormErrorMessage>{errors.link?.message}</FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.youtubeLink}>
                <Flex direction={"column"}>
                  <FormLabel>Lien youtube</FormLabel>

                  <Input
                    placeholder={`Ex: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"`}
                    {...register("youtubeLink")}
                  />
                  <FormErrorMessage>
                    {errors.youtubeLink?.message}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.startDate}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t("createinformationpost-start-date", "Date de début")}
                  </FormLabel>
                  <Controller
                    name={"startDate"}
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <DatePicker
                        selected={value}
                        preventOpenOnFocus
                        {...rest}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.startDate?.message?.toString()}
                  </FormErrorMessage>
                </Flex>
              </FormControl>

              <FormControl isInvalid={!!errors.endDate}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t("createinformationpost-end-date", "Date de fin")}
                  </FormLabel>
                  <Controller
                    name={"endDate"}
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <DatePicker
                        selected={value}
                        preventOpenOnFocus
                        {...rest}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.endDate?.message?.toString()}
                  </FormErrorMessage>
                </Flex>
              </FormControl>
              <FormControl size={"sm"}>
                <Flex alignItems={"baseline"}>
                  <FormLabel>
                    {t("createinformationpost-priority", "Priorité")}
                  </FormLabel>
                  <Select {...register("priority")}>
                    {priorityPostOptions.map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </FormControl>
              <FormControl isInvalid={!!errors.roles}>
                <Flex direction={"column"}>
                  <FormLabel>
                    {t("createinformationpost-roles", "Rôles")}
                  </FormLabel>
                  <Controller
                    name="roles"
                    control={control}
                    render={({ field: { ...rest } }) => (
                      <CheckboxGroup
                        // colorScheme="blue"
                        defaultValue={[
                          RolesEnum.ProviderReadOnly,
                          RolesEnum.ProviderExecutive,
                          RolesEnum.ProviderOperational,
                          RolesEnum.ProviderSales,
                        ]}
                        {...rest}
                      >
                        <Stack spacing={2} direction={"column"}>
                          <Checkbox value={RolesEnum.ProviderOperational}>
                            <Text>Responsable de projet partenaire</Text>
                          </Checkbox>
                          <Checkbox value={RolesEnum.ProviderExecutive}>
                            <Text>Responsable société partenaire</Text>
                          </Checkbox>
                          <Checkbox value={RolesEnum.ProviderSales}>
                            <Text>ADV partenaire</Text>
                          </Checkbox>
                          <Checkbox
                            value={RolesEnum.ProviderReadOnly}
                            isDisabled
                          >
                            <Text>Super Admin</Text>
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    )}
                  />
                </Flex>
              </FormControl>

              <Flex justifyContent={"end"}>
                <Button type={"submit"} isLoading={fetching}>
                  {post ? t("edit-post", "Éditer") : t("create-post", "Créer")}{" "}
                  le post
                </Button>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
