import {
  Button,
  Flex,
  Icon,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { type FileSingle } from "graphql/__generated__/graphql";
import { useDownloadContentVersion } from "hooks/useDownloadContentVersion";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegFileAlt } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";

const PhaseAttachment:
  | React.FC<FileSingle & { phaseName?: string; phaseId?: string }>
  | undefined
  | null = ({ name, versionData, id, createdDate, phaseId, phaseName }) => {
  const { downloadDocument, isLoading } = useDownloadContentVersion({
    versionData,
    name,
  });

  const location = useLocation();
  const from = location.state?.from || null;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      key={id}
      bg="blue.50"
      p="12px 24px"
      borderRadius={"10px"}
      fontSize={"14px"}
      fontWeight={500}
      justify={"space-between"}
      align={"center"}
    >
      <Flex gap="8px" align="center" isTruncated w="300px">
        <Icon as={FaRegFileAlt} fontSize={"24px"} />

        <Text isTruncated title={name ?? ""} color="blue.950" fontWeight={500}>
          {name}
        </Text>
      </Flex>

      {phaseName ? (
        <Text color="grey.600" isTruncated w="450px">
          {t("linked_to", "Lié à ")}
          <Link
            isTruncated
            title={phaseName}
            onClick={(e) => {
              e.stopPropagation();

              navigate(`/phases/${phaseId}`, {
                state: { from },
              });
            }}
          >
            {phaseName}
          </Link>
        </Text>
      ) : (
        <Text color="grey.600" fontWeight={500}>
          {t("created_date", "Déposé le {{date}}", {
            date: createdDate && format(createdDate, "dd/MM/yyyy"),
          })}
        </Text>
      )}

      <Button
        isLoading={isLoading}
        variant={"link"}
        onClick={downloadDocument}
        rightIcon={<FiEye />}
      >
        {t("cta_view", "Voir")}
      </Button>
    </Flex>
  );
};

export const PhaseAttachments: React.FC<{
  attachments: Array<
    (FileSingle & { phaseName?: string; phaseId?: string }) | null | undefined
  >;
  fetching: boolean;
}> = ({ attachments, fetching }) => {
  const { t } = useTranslation();

  if (fetching) {
    return <Spinner size={"sm"} />;
  }

  if (!attachments.length) {
    return (
      <Text fontSize={"14px"} color="grey.600">
        {t("no_attachments", "Aucune pièce jointe")}
      </Text>
    );
  }

  return (
    <Stack gap={"16px"}>
      {attachments.map((attachment) =>
        attachment ? (
          <PhaseAttachment key={attachment.id} {...attachment} />
        ) : null,
      )}
    </Stack>
  );
};
