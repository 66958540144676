import SimpleTable from "components/SimpleTable";
import { COLUMNS_PHASE } from "modules/phase";
import { type Row } from "@tanstack/react-table";
import { type IPartnerContactProject } from "types";
import { isOpportunity } from "utils/sf";
import { ProjectTypeEnum } from "graphql/__generated__/graphql";

export const ProjectsRowSubComponent = ({
  row: {
    original: { phases, id, type, isPartialInvoice },
  },
  hiddenColumns,
}: {
  row: Row<
    IPartnerContactProject & {
      clientOrders: IPartnerContactProject["clientOrders"];
    }
  >;
  hiddenColumns?: string[];
}) => {
  let finalHiddenColumns = hiddenColumns ?? [];
  const phasesWithProjectType = phases.map((phase) => ({
    ...phase,
    projectType: type,
    isPartialInvoice,
  }));
  if (isOpportunity(id)) {
    finalHiddenColumns = [
      ...finalHiddenColumns,
      "adjustedDeliveryDate",
      "clientOrders",
      "status",
      "actions",
      "actions-adv",
      "selection",
      "comment",
    ];
  }

  if (type === ProjectTypeEnum.At || type === ProjectTypeEnum.Atf) {
    finalHiddenColumns = [
      ...finalHiddenColumns,
      "originalFields",
      "completion",
      "adjustedDeliveryDate",
    ];
  }

  if (type === ProjectTypeEnum.Forfait) {
    finalHiddenColumns = [
      ...finalHiddenColumns,
      "timeDone",
      "timeSold",
      "resource",
    ];
  }

  if (!phases) {
    return <div>Aucune phases...</div>;
  }

  return (
    <SimpleTable
      data={phasesWithProjectType}
      columns={COLUMNS_PHASE}
      hiddenColumns={finalHiddenColumns}
    />
  );
};
