import { Text } from "@chakra-ui/react";
import { ProjectsTableWithFilters } from "modules/project/ProjectsTableWithFilters/ProjectsTableWithFilters";
import { type ProjectDataProps } from "pages/Home/HomePanel";
import { TAB_NAME } from "stores";
import { hiddenColumns, WAITING_STATUS } from "../utils";
import { useTranslation } from "react-i18next";

export const FinishedProjectTab: React.FC<ProjectDataProps> = ({
  projectsData,
  isLoading,
}) => {
  const finishedProjects =
    projectsData.filter((p) => WAITING_STATUS.includes(p.status)) || [];
  const { t } = useTranslation();

  if (!isLoading && !finishedProjects.length)
    return (
      <Text mt={5}>
        {t(
          "no_project_in_consolidation",
          "Vous n’avez pas de projet en cours de contractualisation.",
        )}
      </Text>
    );

  return (
    <ProjectsTableWithFilters
      tab={TAB_NAME.finishedProjects}
      isLoading={isLoading}
      projects={finishedProjects}
      hiddenColumnsProjects={["count"]}
      hiddenColumns={[
        ...hiddenColumns,
        "timeDone",
        "originalFields",
        "completion",
        "actions-adv",
      ]}
    />
  );
};
