export enum ItemTypeEnum {
  Project = "Project",
  Phase = "Phase",
  Task = "Task",
  Invoice = "Invoice",
  Order = "Order",
}

export const ITEM_TYPE_DICO: Record<ItemTypeEnum, string> = {
  [ItemTypeEnum.Project]: "Projets",
  [ItemTypeEnum.Phase]: "Phases",
  [ItemTypeEnum.Task]: "Taches",
  [ItemTypeEnum.Invoice]: "Factures",
  [ItemTypeEnum.Order]: "Commandes",
};
