export const SF_PREFIX = {
  Contract: "800",
  Opportunity: "006",
  INO_Phase_Contrat__c: "a0i",
  INO_Phase_Proposee__c: "a0g",
};

export const isProject = (id: string) => id.startsWith(SF_PREFIX.Contract);

export const isOpportunity = (id: string) =>
  id.startsWith(SF_PREFIX.Opportunity);

export const isPhase = (id: string) =>
  id.startsWith(SF_PREFIX.INO_Phase_Contrat__c);

export const isPhaseProposed = (id: string) =>
  id.startsWith(SF_PREFIX.INO_Phase_Proposee__c);
