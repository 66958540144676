import { Button, Flex } from "@chakra-ui/react";
import { Inops } from "assets";
import { ResendCodeForm } from "modules/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ResendCodePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
      <Flex
        bg="white"
        p={8}
        justify="center"
        align="center"
        flexDirection="column"
        borderRadius={16}
        h="100%"
      >
        <Inops width="100%" height={74} />
        <ResendCodeForm />
        <Flex mt={5} fontSize="sm">
          <Button
            variant={"link"}
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("back_to_login", "Revenir à la page d'authentification")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResendCodePage;
