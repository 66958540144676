import { CaseStatusEnum, OrderStatusEnum } from "graphql/__generated__/graphql";
import { uniqBy } from "lodash-es";
import { type IPartnerContactProject } from "types";

export const filterPhases = (
  phases: IPartnerContactProject["clientOrders"][number]["phases"],
) => phases.filter(({ completion }) => completion === 100);

export const useBillableOrders = (projects: IPartnerContactProject[]) => {
  const orders = uniqBy(
    projects.flatMap(({ clientOrders, account, name }) =>
      clientOrders
        // .filter((c) => {
        //   if (!isPartialInvoice) {
        //     return true;
        //   }
        //   const cases = c.providerOrders.flatMap((p) => p.cases);
        //   const waitingCases = cases.filter(
        //     (c) => c.status === CaseStatusEnum.Waiting,
        //   );

        //   if (waitingCases.length > 0) {
        //     return true;
        //   }

        //   return false;
        // })
        .map((o) => ({
          id: o.id,
          status: o.status,
          account,
          phases: filterPhases(o.phases),
          name,
        })),
    ),
    (p) => p.id,
  );

  const providerOrders = projects.flatMap((o) =>
    o.clientOrders.flatMap((o) => o.providerOrders),
  );
  const cases = providerOrders.flatMap((o) => o.cases);
  const casesUniq = uniqBy(cases, (c) => c.id);

  const litigiousCases = casesUniq.filter(
    (c) => c.status === CaseStatusEnum.Litigation,
  );

  const billableOrders = orders.filter(
    (billableOrder) => billableOrder.status === OrderStatusEnum.Billable,
  );

  return {
    orders,
    billableOrders,
    litigiousCases,
  };
};
