import { format } from "date-fns";
import { BooleanEnum } from "graphql/__generated__/graphql";
import { Text } from "@chakra-ui/react";
import { fr } from "date-fns/locale";

export const toEuro = (value?: number | null) =>
  value &&
  new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(value);

export const toKEuro = (value?: number | null) =>
  value &&
  new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    notation: "compact",
    unitDisplay: "narrow",
  }).format(value);

export const formatDate = (date?: Date | null) =>
  date ? format(date, "dd/MM/yyyy") : "-";

export const formatDateText = (date?: Date | null) =>
  date ? format(date, "dd MMMM yyyy", { locale: fr }) : "-";

export const toDecimal = (num: number) => Math.round(num * 100) / 100;

function customRound(num1: number, num2: number, factor: number) {
  const int1 = Math.round(num1 * factor);
  const int2 = Math.round(num2 * factor);

  return (int1 * int2) / (factor * factor);
}

export const toDecimalMultiplication = (num1: number, num2: number) =>
  Math.round(customRound(num1, num2, 100) * 100) / 100;

export const booleanToYesNo = (value?: BooleanEnum | null) =>
  value === BooleanEnum.True ? (
    <Text fontSize="sm" fontWeight="normal">
      Oui
    </Text>
  ) : (
    <Text fontSize="sm" fontWeight="normal">
      Non
    </Text>
  );
