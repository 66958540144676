import {
  type RolesEnum,
  type UserCampaign,
} from "graphql/__generated__/graphql";
import { compact, uniq } from "lodash-es";
import { useState } from "react";
import { matchTerm } from "utils";

export type UserCampaignFilterType = {
  partner: string;
  role: RolesEnum | "ALL";
  campaign: string;
  searchTerm: string;
};

export type IUserCampaignFilters = {
  setFilters: (filters: Partial<UserCampaignFilterType>) => void;
  roles?: Array<{ value: RolesEnum | "ALL"; label: string }>;
  partners?: Array<{ value: string; label: string }>;
  campaign?: Array<{ value: string; label: string }>;
  filters: UserCampaignFilterType;
  placeholder?: string;
};

export const useUserCampaignFilter = (unfilteredUsers: UserCampaign[]) => {
  const [filterState, setFilterState] = useState<UserCampaignFilterType>({
    searchTerm: "",
    role: "ALL",
    partner: "ALL",
    campaign: "ALL",
  });

  let filteredUsers = matchTerm(unfilteredUsers, filterState.searchTerm ?? "");

  const campaignOptionsArray = uniq(
    compact(filteredUsers.map((u) => u.openingDate)),
  );
  const campaignOptions = campaignOptionsArray.map((camp) => ({
    value: camp,
    label: camp,
  }));

  const roleOptionsArray = uniq(
    filteredUsers.flatMap((u) => u.roles as Array<RolesEnum | "ALL">),
  );
  const roleOptions = roleOptionsArray.map((role) => ({
    value: role,
    label: role,
  }));

  const parntersOptionsArray = uniq(
    compact(filteredUsers.map((u) => u.companyName) || ""),
  );
  const partnerOption = parntersOptionsArray.map((partner) => ({
    value: partner,
    label: partner,
  }));

  const { role, partner, campaign } = filterState;

  if (role !== "ALL") {
    filteredUsers = filteredUsers.filter(({ roles }) =>
      compact(roles).includes(role),
    );
  }
  if (partner !== "ALL") {
    filteredUsers = filteredUsers.filter(
      ({ companyName }) => partner === companyName,
    );
  }
  if (filterState.campaign !== "ALL") {
    filteredUsers = filteredUsers.filter(
      ({ openingDate }) => campaign === openingDate,
    );
  }
  return {
    filteredUsers,
    partnerOption,
    roleOptions,
    campaignOptions,
    setFilters: (filter: Partial<UserCampaignFilterType>) => {
      setFilterState({ ...filterState, ...filter });
    },
    filters: filterState,
  };
};
