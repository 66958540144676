import { format, isDate } from "date-fns";
import deepdash from "deepdash-es";
import { ProjectTypeEnum } from "graphql/__generated__/graphql";
import lodash, { compact, keyBy, uniq } from "lodash-es";
import { useUIStore, type TAB_NAME } from "stores";
import { matchTerm } from "utils";

export type FilterType = {
  status: string;
  searchTerm: string;
};

interface FilterConfig {
  applyTypeFilter?: boolean;
}

const _ = deepdash(lodash);

export const useProjectFilter = <
  T extends { status?: string | null; type?: ProjectTypeEnum; id: string },
>(
  unfilteredProjects: T[],
  filterKey: TAB_NAME,
  dictionary: Record<string, string>,
  config: FilterConfig = {},
) => {
  const [allFilters, setFilters, clear, type] = useUIStore((s) => [
    s.projectFilters,
    s.setProjectFilters,
    s.clear,
    s.projectType,
  ]);

  const filters = allFilters[filterKey] || {};

  const { searchTerm = "", status = "ALL" } = filters;

  const unfilteredProjectByIds = keyBy(unfilteredProjects, (p) => p.id);

  const unfilteredProjectWithDate = _.mapValuesDeep(
    unfilteredProjects,
    (value) => {
      if (isDate(value)) {
        return format(value, "dd/MM/yy");
      }
      return value;
    },
    { leavesOnly: true },
  );

  const filteredProjectsIds = matchTerm(
    unfilteredProjectWithDate,
    searchTerm,
    // @ts-expect-error
  ).map((p) => p.id);

  let filteredProjects = filteredProjectsIds.map(
    (id) => unfilteredProjectByIds[id],
  );

  const statusOptionsArray =
    compact(uniq(filteredProjects.map((p) => p.status))) || [];

  if (!statusOptionsArray.includes(status) && status !== "ALL") {
    statusOptionsArray.push(status);
  }

  const statusOptions = statusOptionsArray.map((status) => ({
    value: status,
    label: dictionary ? dictionary[status] : status,
  }));

  if (status !== "ALL") {
    filteredProjects = filteredProjects.filter((p) => p.status === status);
  }

  // Apply type filter only if specified in the config
  if (config.applyTypeFilter && type) {
    filteredProjects = filteredProjects.filter(
      (p) => p.type && type.includes(p.type),
    );
  }

  return {
    filteredProjects,
    statusOptions,
    setFilters: setFilters(filterKey),
    filters,
    clear,
  };
};
