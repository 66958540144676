import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput } from "components";
import { SignupDocument } from "graphql/__generated__/graphql";
import { resendCode } from "libs/auth";
import { useTrackEvent } from "libs/tracking";
import yup from "libs/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { type SignUpInput } from "types";
import { useMutation } from "urql";

const schema = yup
  .object()
  .shape({
    email: yup.string().trim().email().required(),
    password: yup
      .string()
      .trim()
      .required()
      .min(8)
      .max(99)
      .matches(/[a-z]/, "Au moins un caractère minuscule")
      .matches(/[A-Z]/, "Au moins un caractère majuscule")
      .matches(/[0-9]/, "Au moins un chiffre")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\':;|_~`]/,
        "Au moins un caractère spécial (@,!,#, etc).",
      ),

    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Doit être identique"),
  })
  .required();

export const SignupForm = () => {
  const {
    handleSubmit,
    register,
    control,
    trigger,
    formState: { errors },
  } = useForm<SignUpInput>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [{ fetching }, signup] = useMutation(SignupDocument);
  const { t } = useTranslation();

  const toast = useToast();
  const { trackEvent } = useTrackEvent();

  const navigate = useNavigate();

  const onSubmit = async (values: SignUpInput) => {
    await schema.validate(values);
    await trackEvent("Auth", "sign-up", { email: values.email });

    const { error } = await signup({
      input: { email: values.email, password: values.password },
    });

    if (!error) {
      toast({
        title: t("auth_signup_account_created", "Votre compte a été créé"),
        description: (
          <div>
            {t(
              "auth_signup_confirmation_email_sent",
              "Un email de confirmation vous a été envoyé ! Merci de cliquer sur le lien présent dans celui-ci pour le valider. Si vous n'avez rien reçu :",
            )}{" "}
            <Button variant={"link"} onClick={() => resendCode(values.email)}>
              {t("auth_signup_send_code", "Renvoyer un code")}
            </Button>
          </div>
        ),
        status: "success",
        duration: null,
        isClosable: true,
      });
      navigate("/login");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        size="sm"
        isInvalid={!!errors.email}
        isRequired
        mb={7}
        mt={10}
      >
        <FormLabel htmlFor="email">
          {t("auth_signup_email", "Votre email")}
        </FormLabel>
        <Input
          placeholder="john@doe.com"
          {...register("email")}
          data-test="input-email"
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.password}
        isRequired
        mb={7}
        onChange={async () => await trigger("confirmPassword")}
      >
        <FormLabel htmlFor="password">
          {t("auth_signup_password", "Votre mot de passe")}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <PasswordInput
              variant="filled"
              data-test="input-password"
              {...field}
            />
          )}
          name="password"
          control={control}
          defaultValue=""
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.confirmPassword} isRequired mb={7}>
        <FormLabel htmlFor="confirmPassword">
          {t("auth_signup_confirm_password", "Confirmer votre mot de passe")}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <PasswordInput
              variant="filled"
              data-test="input-confirmPassword"
              {...field}
            />
          )}
          name="confirmPassword"
          control={control}
          defaultValue=""
        />
        <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
      </FormControl>
      <Flex justify="center">
        <Button isLoading={fetching} type={"submit"}>
          {t("auth_signup_create_account", "Créer un compte")}
        </Button>
      </Flex>
    </form>
  );
};
