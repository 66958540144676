import {
  Box,
  Checkbox,
  CheckboxGroup,
  Grid,
  GridItem,
  Heading,
  Select,
  Stack,
} from "@chakra-ui/react";
import React from "react";

export const InterventionsTab = () => (
  <Grid templateColumns={"repeat(3,1fr)"} gap={6} my={10}>
    <GridItem>
      <Heading size={"sm"}>Mode d'intervention</Heading>
      <CheckboxGroup>
        <Stack gap={2} my={10}>
          <Checkbox>AT</Checkbox>
          <Checkbox>Forfait</Checkbox>
          <Checkbox>CDS</Checkbox>
        </Stack>
        <Box my={10} />
      </CheckboxGroup>
    </GridItem>
    <GridItem>
      <Heading size={"sm"}>Zone d'intervention</Heading>
      <Select my={10} placeholder={"- Selectionner"} />
    </GridItem>
  </Grid>
);
