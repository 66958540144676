import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  GetContactProjectsDocument,
  ProjectStatusEnum,
} from "graphql/__generated__/graphql";
import { indexOf, orderBy } from "lodash-es";
import {
  STATUS_DICO,
  STATUS_TRUE_COLOR_HIGH,
} from "modules/phase/PhasesTable/PhasesTable.config";
import {
  PROJECT_STATUS_DICO,
  PROJECT_STATUS_TRUE_COLOR,
} from "modules/project/utils";
import { FaCaretDown } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { TAB_NAME, useUIStore } from "stores";
import { IPartnerContactProject } from "types";
import { useQuery } from "urql";

const statusOrder: ProjectStatusEnum[] = [
  ProjectStatusEnum.Active,
  ProjectStatusEnum.Validated,
  ProjectStatusEnum.Pending,
  ProjectStatusEnum.Waiting,
  ProjectStatusEnum.Finished,
  ProjectStatusEnum.Executed,
  ProjectStatusEnum.Canceled,
  ProjectStatusEnum.Unknown,
];

export const NavigationBar: React.FC<{
  projectId: string;
  phaseId?: string;
}> = ({ projectId, phaseId }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from || null;

  const [{ data }] = useQuery({
    query: GetContactProjectsDocument,
  });

  const projects = data?.projects ?? [];

  const project = projects.find((p) => p.id === projectId);
  const phases = project?.phases || [];
  const phase = phases.find((p) => p.id === phaseId);

  if (!project) {
    navigate("..");
    return;
  }

  const projectsAccount =
    projects.filter((p) => p.account?.id === project.account?.id) || [];

  const projectsSortByStatus = orderBy(
    projectsAccount,
    [(item) => indexOf(statusOrder, item.status), "reference"],
    ["asc", "desc"],
  );

  const [setFilters] = useUIStore((s) => [s.setProjectFilters]);
  const onChangeFilters = setFilters(TAB_NAME.activeProjects);

  const onClose = () => {
    if (from) {
      navigate(from);
    } else {
      navigate("../");
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={<RiArrowGoBackLine />}
        mr={4}
        onClick={onClose}
      >
        Retour
      </Button>
      <Text
        isTruncated
        maxW={"300px"}
        color="grey.500"
        _hover={{ color: "grey.400" }}
        fontSize={"20px"}
        mr={2}
        onClick={() => {
          navigate("/dashboard/active-projects");
          onChangeFilters({ searchTerm: project.account?.name ?? "" });
        }}
        cursor={"pointer"}
        fontWeight={600}
        title={project.account?.name ?? ""}
      >
        {project.account?.name}
      </Text>
      /
      <Menu autoSelect={false}>
        <MenuButton
          title={project.name}
          sx={{ "&:hover .chakra-text": { color: "grey.700" } }}
        >
          <Flex maxW={"300px"} ml={2} align={"center"}>
            <Text isTruncated fontSize={"20px"} fontWeight={600}>
              {project.name}
            </Text>
            <Icon as={FaCaretDown} fontSize={"20px"} />
          </Flex>
        </MenuButton>
        <MenuList
          padding={"8px"}
          maxW={"650px"}
          maxH={"500px"}
          overflowY={"auto"}
        >
          {projectsSortByStatus.map((p) =>
            p.id === projectId ? (
              <MenuItemProject key={p.id} p={p} isSelected />
            ) : (
              <MenuItemProject key={p.id} p={p} />
            ),
          )}
        </MenuList>
      </Menu>
      {phaseId && phase && (
        <>
          /
          <Menu autoSelect={false}>
            <MenuButton>
              <Flex maxW={"300px"} ml={2} align={"center"}>
                <Text
                  isTruncated
                  fontSize={"20px"}
                  fontWeight={600}
                  title={phase.name}
                >
                  {phase.name}
                </Text>
                <Icon as={FaCaretDown} fontSize={"20px"} />
              </Flex>
            </MenuButton>
            <MenuList color="blue.600">
              {phases.map((p) =>
                p.id === phaseId ? (
                  <MenuItemPhase key={p.id} p={p} isSelected />
                ) : (
                  <MenuItemPhase key={p.id} p={p} />
                ),
              )}
            </MenuList>
          </Menu>
        </>
      )}
    </>
  );
};

const MenuItemProject: React.FC<{
  p: IPartnerContactProject;
  isSelected?: boolean;
}> = ({ p, isSelected }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from || null;

  return (
    <MenuItem
      borderRadius={"10px"}
      {...(isSelected && { bg: "blue.50" })}
      title={p.name}
      key={p.id}
      fontSize={"14px"}
      fontWeight={600}
      onClick={() =>
        navigate(`/projects/${p.id}`, {
          state: { from },
        })
      }
    >
      <Tooltip
        mr={"20px"}
        placement="left"
        label={
          <Flex align={"center"}>
            <span>
              <Box
                w={"10px"}
                height={"10px"}
                borderRadius={"50%"}
                bg={PROJECT_STATUS_TRUE_COLOR[p.status]}
                mr={"8px"}
              />
            </span>
            <Text color="white">{PROJECT_STATUS_DICO[p.status]}</Text>
          </Flex>
        }
      >
        <Flex align={"center"} w="100%" isTruncated>
          <span>
            <Box
              w={"10px"}
              height={"10px"}
              borderRadius={"50%"}
              bg={PROJECT_STATUS_TRUE_COLOR[p.status]}
              mr={4}
            />
          </span>
          <Text isTruncated>
            {p.reference} : {p.name}
          </Text>
          {isSelected && (
            <Icon ml={4} as={MdDone} fontSize={"20px"} color="blue.600" />
          )}
        </Flex>
      </Tooltip>
    </MenuItem>
  );
};

const MenuItemPhase: React.FC<{
  p: IPartnerContactProject["phases"][number];
  isSelected?: boolean;
}> = ({ p, isSelected }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from || null;

  return (
    <MenuItem
      borderRadius={"10px"}
      {...(isSelected && { bg: "blue.50" })}
      title={p.name}
      key={p.id}
      fontSize={"14px"}
      fontWeight={600}
      onClick={() =>
        navigate(`/phases/${p.id}`, {
          state: { from },
        })
      }
    >
      <Tooltip
        mr={"20px"}
        placement="left"
        label={
          <Flex align={"center"}>
            <span>
              <Box
                w={"10px"}
                height={"10px"}
                borderRadius={"50%"}
                bg={STATUS_TRUE_COLOR_HIGH[p.status]}
                mr={"8px"}
              />
            </span>
            <Text color="white">{STATUS_DICO[p.status]}</Text>
          </Flex>
        }
      >
        <Flex align={"center"} w="100%" isTruncated>
          <span>
            <Box
              w={"10px"}
              height={"10px"}
              borderRadius={"50%"}
              bg={STATUS_TRUE_COLOR_HIGH[p.status]}
              mr={4}
            />
          </span>
          <Text isTruncated>{p.name}</Text>
          {isSelected && (
            <Icon ml={4} as={MdDone} fontSize={"20px"} color="blue.600" />
          )}
        </Flex>
      </Tooltip>
    </MenuItem>
  );
};
