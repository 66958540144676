import { useToast } from "@chakra-ui/react";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import {
  GetDownloadContentDocumentDocument,
  type GetDownloadContentDocumentQuery,
} from "graphql/__generated__/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClient } from "urql";

export const useDownloadContentDocument = ({
  linkedEntityId,
  typeDocument,
}: {
  linkedEntityId: string;
  typeDocument?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const urqlClient = useClient();
  const toast = useToast();
  const { t } = useTranslation();

  const downloadDocument = async () => {
    try {
      setIsLoading(true);
      const data = await urqlClient
        .query<GetDownloadContentDocumentQuery>(
          GetDownloadContentDocumentDocument,
          { linkedEntityId, typeDocument },
          {
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      if (!data.data) return;

      const { file } = data.data;

      if (!file) {
        toast({
          title: t(
            "sorry-no-file-associated",
            "Désolé il n'y a pas encore de fichier associé.",
          ),
          duration: 9000,
        });

        return;
      }

      const { b64, name } = file;

      if (!b64 || !name) {
        return;
      }

      const buffer = Buffer.from(b64, "base64");

      const blob = new Blob([buffer]);
      saveAs(blob, name);
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadDocument, isLoading };
};
