import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#FFEDD5"
      d="M4 11a5 5 0 0 1 5-5h22a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5z"
    />
    <path fill="#F43F5E" d="M4 11a5 5 0 0 1 5-5h22a5 5 0 0 1 5 5v5H4z" />
    <path
      stroke="#4C0519"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.083}
      d="M27 3v6M13 3v6"
    />
  </svg>
);
const Memo = memo(SvgCalendar);
export default Memo;
