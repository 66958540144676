import * as React from "react";
import type { SVGProps } from "react";
import { memo } from "react";
const SvgEuro = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.083}>
      <path
        fill="#FDE047"
        stroke="#FDE047"
        d="M6.417 14.367a6.667 6.667 0 0 1 7.966-7.95 6.667 6.667 0 0 1 11.234 0 6.667 6.667 0 0 1 7.966 7.967 6.667 6.667 0 0 1 0 11.233 6.666 6.666 0 0 1-7.95 7.967 6.665 6.665 0 0 1-11.25 0 6.666 6.666 0 0 1-7.966-7.95 6.666 6.666 0 0 1 0-11.267"
      />
      <path
        stroke="#A16207"
        d="M11.667 20H20M25 15.667a6.666 6.666 0 1 0 0 8.667"
      />
    </g>
  </svg>
);
const Memo = memo(SvgEuro);
export default Memo;
