import {
  type DocumentToCheckStatusEnum,
  type InvoiceTypeEnum,
} from "graphql/__generated__/graphql";

export const statusInvoiceDico: Record<InvoiceTypeEnum, string> = {
  WAITING_VALIDATION: "Attente de validation",
  WAITING_PAYMENT: "Attente de paiement",
  LITIGE: "En litige",
  PAID: "Paiement effectué",
  UNKNOWN: "Inconnu",
  VALIDATED: "Validée",
  CANCELED: "Annulé",
};

export const statusInvoiceToColor: Record<InvoiceTypeEnum, string> = {
  WAITING_VALIDATION: "blue-light",
  WAITING_PAYMENT: "green-light",
  LITIGE: "red-light",
  PAID: "green-light",
  UNKNOWN: "grey-light",
  CANCELED: "grey-light",
  VALIDATED: "orange-light",
};

export const statusBillDico: Record<DocumentToCheckStatusEnum, string> = {
  NEW: "Nouveau",
  REJECTED: "Rejeté",
  SEND_BACK: "Renvoyé",
  VALIDATED: "Validé",
  CANCELED: "Annulé",
};

export const statusBillToColor: Record<DocumentToCheckStatusEnum, string> = {
  NEW: "blue-light",
  REJECTED: "red-light",
  SEND_BACK: "red-light",
  VALIDATED: "green-light",
  CANCELED: "grey-light",
};
