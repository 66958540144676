import { useToast } from "@chakra-ui/react";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import {
  GetDownloadContentVersionDocument,
  type GetDownloadContentVersionQuery,
} from "graphql/__generated__/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClient } from "urql";

export const useDownloadContentVersion = ({
  versionData,
  name,
}: {
  versionData?: string | null;
  name?: string | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const urqlClient = useClient();
  const toast = useToast();
  const { t } = useTranslation();

  const downloadDocument = async () => {
    if (!versionData || !name) {
      return;
    }
    try {
      setIsLoading(true);
      const data = await urqlClient
        .query<GetDownloadContentVersionQuery>(
          GetDownloadContentVersionDocument,
          { versionData },
          {
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      if (!data.data) return;

      const { b64 } = data.data;

      if (!b64) {
        toast({
          title: t(
            "sorry-no-file-associated",
            "Désolé il n'y a pas encore de fichier associé.",
          ),
          duration: 9000,
        });

        return;
      }

      const buffer = Buffer.from(b64, "base64");

      const blob = new Blob([buffer]);
      saveAs(blob, name);
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadDocument, isLoading };
};
