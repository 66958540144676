import {
  ProjectStatusEnum,
  type ProjectTypeEnum,
} from "graphql/__generated__/graphql";

export const WAITING_STATUS: ProjectStatusEnum[] = [
  ProjectStatusEnum.Waiting,
  ProjectStatusEnum.Validated,
  ProjectStatusEnum.Pending,
  ProjectStatusEnum.Canceled,
];

export const hiddenColumns = ["selection", "cost", "actions-adv", "count"];

export const PROJECT_STATUS_DICO: Record<ProjectStatusEnum, string> = {
  PENDING: "En cours d'élaboration",
  VALIDATED: "Validée par le bénéficiaire",
  WAITING: "En attente de commande",
  ACTIVE: "En cours",
  FINISHED: "Terminé",
  CANCELED: "Annulé",
  UNKNOWN: "Inconnu",
  EXECUTED: "Exécuté",
};

export const PROJECT_STATUS_COLOR: Record<ProjectStatusEnum, string> = {
  PENDING: "grey-light",
  ACTIVE: "blue-light",
  FINISHED: "green-light",
  CANCELED: "red-light",
  VALIDATED: "grey",
  WAITING: "grey",
  UNKNOWN: "grey",
  EXECUTED: "green-light",
};

export const PROJECT_STATUS_TRUE_COLOR: Record<ProjectStatusEnum, string> = {
  PENDING: "grey.500",
  ACTIVE: "blue.500",
  FINISHED: "green.500",
  CANCELED: "red.500",
  VALIDATED: "grey.500",
  WAITING: "grey.500",
  UNKNOWN: "grey.500",
  EXECUTED: "green.500",
};

export const PROJECT_TYPE_DICO: Record<ProjectTypeEnum, string> = {
  AT: "AT",
  FORFAIT: "Forfait",
  ATF: "AT",
};
