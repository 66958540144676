import { escapeRegExp } from "lodash-es";

/**
 * Prefix some text to a string, only if it contains something
 * @param string a string thta can be null, undefined or empty
 * @param pre the content to prepend the main string
 */
export const pre = (string: string | null | undefined, pre: string) => {
  if (string) return pre + string;
  return "";
};

export const matchTerm = <T>(arr: T[], searchTerm: string) => {
  const searchTermEscaped = escapeRegExp(searchTerm.trim()).split(" ");

  const regex = searchTermEscaped.map((el) => new RegExp(el, "gim"));

  return arr.filter((obj: any) => {
    const json = JSON.stringify(obj);

    return regex.every((el) => json.match(el));
  });
};

export const toBase64 = async (file: File): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result?.toString() ?? "");
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const truncateFileName = (n: string, len: number) => {
  const ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  let filename = n.replace("." + ext, "");
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
  return filename + "." + ext;
};
