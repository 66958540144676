import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Contact } from "components/Contact";
import { useMe } from "hooks";
import { ASSETS_S3_BASE_URL } from "libs/auth";
import { useTrackEvent } from "libs/tracking";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaFlagCheckered } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import {
  RiGroupFill,
  RiLineChartLine,
  RiSettings2Fill,
  RiShoppingCart2Fill,
} from "react-icons/ri";

const DocumentViewPage: React.FC = () => {
  const { me } = useMe();
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  if (!me?.hasNdaValid) {
    return (
      <Text>
        {t(
          "your_nda_is_not_valid",
          "Votre NDA n'est pas valide. Merci de le mettre à jour pour accéder à cette page.",
        )}
      </Text>
    );
  }

  return (
    <>
      <Tabs borderColor="white" align="start" isLazy={true}>
        <TabList>
          <Tab paddingX={1} mr={8}>
            Démat
          </Tab>
          <Tab paddingX={1} mr={8}>
            Data (marché 2020)
          </Tab>
          <Tab paddingX={1} mr={8}>
            Data (marché 2024)
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Flex direction={"column"}>
              <Heading size={"md"} my={8}>
                Rappel des dates du marché
              </Heading>
              <Flex>
                <Flex align="center" mr={8}>
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={RiShoppingCart2Fill}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de clôture du marché
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2024
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center">
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={FaFlagCheckered}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de fin d'exécution des prestations
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2025
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Heading size={"md"} my={8}>
                Présentation rapide du marché
              </Heading>
              <Flex justify={"space-between"} mt={8} gap={8}>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiShoppingCart2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Commercialisable à partir du 04 mars 2020, durée de 36 mois
                    2* 6 mois
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiGroupFill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    INOP’s titulaire
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiLineChartLine}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Montant estimatif du marché indiqué par l’UGAP : 60M€ (ni
                    min, ni max)
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiSettings2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Plus de 15 Uos déclinées par niveau de complexité nous
                    permettant de répondre à toute les demandes{" "}
                  </Text>
                </Flex>
              </Flex>
              <Text mt={8}>
                Les prestations de ce lot selon l’Ugap concernent la phase de
                définition, de cadrage et d’accompagnement à la mise en place
                d’une solution de dématérialisation. Seule l’intégration de
                solutions éditeurs existantes est autorisée, les développements
                spécifiques ne sont autorisés que s’ils concourent à cette
                intégration (liens entre applicatifs par exemple).
                <br />
                <br />
                La dématérialisation consiste à substituer des documents ou des
                procédures réels par des documents ou des procédures numériques.
                <br />
                <br />A titre d’exemple, les projets peuvent porter sur :
                dossiers agents RH, mise en place d’une solution de gestion
                électronique de courrier (GEC), gestion électronique
                documentaire (GED), d’outil d’échange dématérialisés (EDI), de
                parapheur électronique, de signature électronique, d’archivage
                et les workflows associés, océrisation, SAE (Système Archivage
                Electronique)
              </Text>

              <Heading size={"md"} my={8}>
                Le marché : Son contenu (UOs UGAP)
              </Heading>
              <Image src="/assets/kits/demat.png" alt="" />

              <Heading size={"md"} my={8}>
                Contacts Inop's
              </Heading>
              <Flex gap={8} justify="space-between">
                <Contact
                  contact={{
                    id: "",
                    email: "commercepublic@inops.fr",
                    firstName: "Commerce",
                    lastName: "Public",
                    fonction: "Adresse générique",
                  }}
                  description="Pour l’envoi des demandes de lead"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "jcarena@freelance.com",
                    firstName: "Jérome",
                    lastName: "Carena",
                    fonction: "Responsable de marché",
                  }}
                  description="Pour toute question ayant attrait au marché, de sa contractualisation jusqu’aux processus en place"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "gmunier@freelance.com",
                    firstName: "Guillaume",
                    lastName: "Munier",
                    fonction: " Responsable opération",
                  }}
                  description="Pour toute question opérationnelle liée au marché, ou aux projets réalisés"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "advpublic@freelance.com",
                    firstName: "ADV",
                    lastName: "Public",
                    fonction: "Responsable ADV",
                  }}
                  description="Pour toute question administrative"
                />
              </Flex>

              <Box py={8}>
                <Button
                  as={Link}
                  isExternal
                  onClick={() => trackEvent("download", "kit-demat")}
                  href={`${ASSETS_S3_BASE_URL}/kits/kit-demat.zip`}
                  leftIcon={<HiDownload />}
                >
                  Télécharger le kit du marché
                </Button>
              </Box>
            </Flex>
          </TabPanel>

          <TabPanel px={0}>
            <Flex direction={"column"}>
              <Heading size={"md"} my={8}>
                Rappel des dates du marché
              </Heading>
              <Flex>
                <Flex align="center" mr={8}>
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={RiShoppingCart2Fill}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de clôture du marché
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2024
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center">
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={FaFlagCheckered}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de fin d'exécution des prestations
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2025
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Heading size={"md"} my={8}>
                Présentation rapide du marché
              </Heading>
              <Text>
                Janvier 2020, le groupement solidaire INOP’S-ACCENTURE-CGI a été
                retenu par l’UGAP sur le lot 6 :
              </Text>
              <Flex justify={"space-between"} mt={8} gap={8}>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiShoppingCart2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Notifié le 23.01.2020 Utilisable à partir d’avril 2020 4 ans
                    (3+6m+6m)
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiGroupFill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Co-traitance INOP’S/ACCENTURE/ CGI
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiLineChartLine}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Montant estimatif du marché indiqué par l’UGAP : 80 M€ HT
                    (ni min, ni max)
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiSettings2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    17 familles d’UOS (AMOA / AMOE/MCO)
                  </Text>
                </Flex>
              </Flex>
              <Text mt={8}>
                Ce marché permet d’adresser selon l’UGAP toutes les prestations
                visant à accompagner les bénéficiaires dans la mise en place de
                machines apprenantes (machine learning) ou de prise de décision
                sur la base de l’exploitation informatique des données
                structurées, hiérarchisées et volumineuses.
                <br />
                <br />
                Les thèmes pouvant être traités dans le cadre de ce marché sont
                donc par exemple les projets d’intelligence artificielle, de
                chatbots, d’informatique décisionnelle, langage naturel ou
                encore de blockchain…
              </Text>

              <Heading size={"md"} my={8}>
                Le marché : Son contenu (UOs UGAP)
              </Heading>
              <Image src="/assets/kits/data.png" alt="" />

              <Heading size={"md"} my={8}>
                Contacts Inop's
              </Heading>
              <Flex gap={8} justify="space-between">
                <Contact
                  contact={{
                    id: "",
                    email: "commercepublic@inops.fr",
                    firstName: "Commerce",
                    lastName: "Public",
                    fonction: "Adresse générique",
                  }}
                  description="Pour l’envoi des demandes de lead"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "jcarena@freelance.com",
                    firstName: "Jérome",
                    lastName: "Carena",
                    fonction: "Responsable de marché",
                  }}
                  description="Pour toute question ayant attrait au marché, de sa contractualisation jusqu’aux processus en place"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "gperon@freelance.com",
                    firstName: "Gwennaelle",
                    lastName: "Peron",
                    fonction: " Responsable opération",
                  }}
                  description="Pour toute question opérationnelle liée au marché, ou aux projets réalisés"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "advpublic@freelance.com",
                    firstName: "ADV",
                    lastName: "Public",
                    fonction: "Responsable ADV",
                  }}
                  description="Pour toute question administrative"
                />
              </Flex>

              <Box py={8}>
                <Button
                  as={Link}
                  isExternal
                  onClick={() => trackEvent("download", "kit-data")}
                  href={`${ASSETS_S3_BASE_URL}/kits/kit-data.zip`}
                  leftIcon={<HiDownload />}
                >
                  Télécharger le kit du marché
                </Button>
              </Box>
            </Flex>
          </TabPanel>

          <TabPanel px={0}>
            <Flex direction={"column"}>
              <Heading size={"md"} my={8}>
                Rappel des dates du marché
              </Heading>
              <Flex>
                <Flex align="center" mr={8}>
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={RiShoppingCart2Fill}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de clôture du marché
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2027
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center">
                  <Center
                    bg="brand.blue.background"
                    width="80px"
                    height="80px"
                    borderRadius="50%"
                    alignContent="center"
                    mr={5}
                  >
                    <Icon
                      fontSize="xx-large"
                      as={FaFlagCheckered}
                      color="brand.blue.dark"
                    />
                  </Center>
                  <Flex direction="column">
                    <Text size={"xs"} variant={"small"}>
                      Date de fin d'exécution des prestations
                    </Text>
                    <Text fontSize={14} fontWeight={600}>
                      22/01/2028
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Heading size={"md"} my={8}>
                Présentation rapide du marché
              </Heading>
              <Text>
                Janvier 2024, le groupement solidaire INOP’S-ACCENTURE-CGI a été
                reconduit par l’UGAP sur le lot 6 :
              </Text>
              <Flex justify={"space-between"} mt={8} gap={8}>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiShoppingCart2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Notifié le 15.01.2020 Utilisable à partir de janvier 2024
                    pendant 4 ans (3+6m+6m)
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiGroupFill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Co-traitance INOP’S/ACCENTURE/ CGI
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiLineChartLine}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    Montant estimatif du marché indiqué par l’UGAP : 80 M€ HT
                    (ni min, ni max)
                  </Text>
                </Flex>
                <Flex
                  p={8}
                  shadow="md"
                  borderWidth="1px"
                  align="center"
                  direction={"column"}
                  width={300}
                >
                  <Icon
                    as={RiSettings2Fill}
                    fontSize="4xl"
                    color="brand.blue.dark"
                  />
                  <Text mt={6} fontSize="sm">
                    18 familles d’UO de prestation et 2 familles d’UO sur les
                    carnets de tickets
                  </Text>
                </Flex>
              </Flex>
              <Text mt={8}>
                Ce marché permet d’adresser selon l’UGAP toutes les prestations
                visant à accompagner les bénéficiaires dans la mise en place de
                machines apprenantes (machine learning) ou de prise de décision
                sur la base de l’exploitation informatique des données
                structurées, hiérarchisées et volumineuses.
                <br />
                <br />
                Les thèmes pouvant être traités dans le cadre de ce marché sont
                donc par exemple les projets d’intelligence artificielle, de
                chatbots, d’informatique décisionnelle, langage naturel ou
                encore de blockchain…
              </Text>

              <Heading size={"md"} my={8}>
                Le marché : Son contenu (UOs UGAP)
              </Heading>
              <Image src="/assets/kits/data-2024.png" alt="" />

              <Heading size={"md"} my={8}>
                Contacts Inop's
              </Heading>
              <Flex gap={8} justify="space-between">
                <Contact
                  contact={{
                    id: "",
                    email: "commercepublic@inops.fr",
                    firstName: "Commerce",
                    lastName: "Public",
                    fonction: "Adresse générique",
                  }}
                  description="Pour l’envoi des demandes de lead"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "laskri@freelance.com",
                    firstName: "Lotfi",
                    lastName: "Askri",
                    fonction: "Responsable de marché",
                  }}
                  description="Pour toute question ayant attrait au marché, de sa contractualisation jusqu’aux processus en place"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "fcotentin@freelance.com",
                    firstName: "Florent",
                    lastName: "Cotentin",
                    fonction: " Responsable opération",
                  }}
                  description="Pour toute question opérationnelle liée au marché, ou aux projets réalisés"
                />
                <Contact
                  contact={{
                    id: "",
                    email: "advpublic@freelance.com",
                    firstName: "ADV",
                    lastName: "Public",
                    fonction: "Responsable ADV",
                  }}
                  description="Pour toute question administrative"
                />
              </Flex>

              <Box py={8}>
                <Button
                  as={Link}
                  isExternal
                  onClick={() => trackEvent("download", "kit-data")}
                  href={`${ASSETS_S3_BASE_URL}/kits/kit-data-2024.zip`}
                  leftIcon={<HiDownload />}
                >
                  Télécharger le kit du marché
                </Button>
              </Box>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default DocumentViewPage;
