import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { GetContactDocument } from "graphql/__generated__/graphql";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import { SwitchLeftCompany } from "./SwitchLeftCompany";
import { useTranslation } from "react-i18next";

export const EditContactModal: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  // TODO check: Bitbucket
  const [{ data }] = useQuery({ query: GetContactDocument, variables: { id } });
  const { t } = useTranslation();

  const onClose = () => {
    navigate("..");
  };

  if (!data) return null;

  const contact = data.contact;

  return (
    <Modal isOpen={true} onClose={onClose} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand.blue.dark"
          fontWeight="semibold"
          fontSize="md"
        >
          {t("edit_contact", "Modifier le contact")} - {contact.lastName}{" "}
          {contact.firstName}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody p={5}>
          <SwitchLeftCompany contact={contact} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
