import { TabBadge } from "components";

export const TabBadgeCustom: React.FC<{
  isActive: boolean;
  number: number;
}> = ({ isActive, number }) => {
  if (number <= 0) {
    return null;
  }

  return <TabBadge isActive={isActive}>{number}</TabBadge>;
};
