import {
  Heading,
  Stack,
  Container,
  Textarea,
  Button,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const NdaAgreementPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxW={"7xl"}>
      <Stack spacing={10} py={20} direction={"column"}>
        <Heading fontSize={"2xl"}>Accord de confidentialité</Heading>
        <Textarea h={"md"} />
        <Flex direction={"column"} alignItems={"center"}>
          <Button
            size={"lg"}
            fontSize={"md"}
            px={6}
            onClick={() => {
              navigate("/onboarding/info");
            }}
          >
            Consulter et signer le NDA
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};
