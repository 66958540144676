import { extendTheme } from "@chakra-ui/react";
import { getColorSchemeProps } from "./utils";

export const c = {
  grey: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
    950: "#020617",
  },
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
    950: "#172554",
  },
  cyan: {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0284c7",
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
    950: "#082f49",
  },
  yellow: {
    50: "#fefce8",
    100: "#fef9c3",
    200: "#fef08a",
    300: "#fde047",
    400: "#facc15",
    500: "#eab308",
    600: "#ca8a04",
    700: "#a16207",
    800: "#854d0e",
    900: "#713f12",
    950: "#422006",
  },
  teal: {
    50: "#f0fdfa",
    100: "#ccfbf1",
    200: "#99f6e4",
    300: "#5eead4",
    400: "#2dd4bf",
    500: "#14b8a6",
    600: "#0d9488",
    700: "#0f766e",
    800: "#115e59",
    900: "#134e4a",
    950: "#042f2e",
  },
  purple: {
    50: "#faf5ff",
    100: "#f3e8ff",
    200: "#e9d5ff",
    300: "#d8b4fe",
    400: "#c084fc",
    500: "#a855f7",
    600: "#9333ea",
    700: "#7e22ce",
    800: "#6b21a8",
    900: "#581c87",
    950: "#3b0764",
  },
  pink: {
    50: "#fff6fd",
    100: "#fdebf6",
    200: "#ffdff3",
    300: "#fac5e5",
    400: "#f7a0d5",
    500: "#f47ac4",
    600: "#f046ad",
    700: "#ea1396",
    800: "#b70f75",
    900: "#830b54",
    950: "#690943",
  },
  // camel: {
  //   50: "#fcf7f0",
  //   100: "#f7ecdd",
  //   200: "#efd7b9",
  //   300: "#e4bb8d",
  //   400: "#d8965c",
  //   500: "#d07d3f",
  //   600: "#c26734",
  //   700: "#a1512d",
  //   800: "#81422b",
  //   900: "#693725",
  //   950: "#381b12",
  // },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
    950: "#431407",
  },
  red: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
    950: "#4c0519",
  },
  green: {
    50: "#f0fdf4",
    100: "#dcfce7",
    200: "#bbf7d0",
    300: "#86efac",
    400: "#4ade80",
    500: "#22c55e",
    600: "#16a34a",
    700: "#15803d",
    800: "#166534",
    900: "#14532d",
    950: "#052e16",
  },
};

export const colors = {
  ...c,
  brand: {
    blue: {
      background: c.blue[50],
      light: c.blue[300],
      main: c.blue[600],
      dark: c.blue[800],
      text: c.blue[950],
    },
    green: {
      dark: c.green[800],
      main: c.green[600],
      light: c.green[300],
    },
    red: {
      dark: c.red[800],
      main: c.red[600],
      light: c.red[300],
    },
    grey: {
      background: c.grey[50],
      light: c.grey[300],
      main: c.grey[600],
      dark: c.grey[800],
      text: c.grey[950],
    },
  },
  semantic: {
    error: {
      background: c.red[50],
      light: c.red[300],
      main: c.red[600],
      dark: c.red[800],
      text: c.red[950],
    },
    warning: {
      background: c.orange[50],
      light: c.orange[300],
      main: c.orange[600],
      dark: c.orange[800],
      text: c.orange[950],
    },
    success: {
      background: c.green[50],
      light: c.green[300],
      main: c.green[600],
      dark: c.green[800],
      text: c.green[950],
    },
    information: {
      background: c.cyan[50],
      light: c.cyan[300],
      main: c.cyan[600],
      dark: c.cyan[800],
      text: c.cyan[950],
    },
  },
  neutral: {
    grey: {
      50: c.grey[50],
      100: c.grey[100],
      200: c.grey[200],
      300: c.grey[300],
      400: c.grey[400],
      500: c.grey[500],
      600: c.grey[600],
      700: c.grey[700],
      800: c.grey[800],
      900: c.grey[900],
      950: c.grey[950],
    },
    text: {
      helper: c.grey[600],
      black: c.grey[950],
      white: c.grey[100],
    },
  },
};

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        color: "neutral.grey.950",
      },
      "*": {
        _focus: {
          outlineColor: "brand.blue.main",
        },
      },
    },
  },
  radii: {
    md: "10px",
  },
  colors,
  shadows: {
    xs: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    sm: "0px 2px 2px rgba(0, 0, 0, 0.07)",
    base: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    md: "0px 4px 4px -1px rgba(0, 0, 0, 0.06), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0px 0px 9px 1px rgba(0, 0, 0, 0.13), 0px 0px 9px 2px rgba(0, 0, 0, 0.02)",
    inner: "inset 0px 2px 4px rgba(0, 0, 0, 0.06)",
    xl: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    outline: "0px 0px 0px 3px rgba(230, 230, 230, 1)",
    "dark-lg":
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Montserrat', sans-serif",
    mono: "'Montserrat', sans-serif",
  },

  components: {
    Spinner: {
      baseStyle: {
        color: "blue.600",
      },
    },

    Progress: {
      baseStyle: {
        track: {
          borderRadius: "2px",
        },
        filledTrack: {
          bg: "cyan.600",
        },
      },
    },

    Heading: {
      // style object for base or default style
      baseStyle: {
        color: "brand.blue.main",
        fontWeight: 600,
        lineHeight: "140%",
      },
      // styles for different sizes ("sm", "md", "lg")
      sizes: {
        xs: {
          fontSize: "12px",
        },
        sm: {
          fontSize: "16px",
        },
        md: {
          fontSize: "18px",
        },
        lg: {
          fontSize: "20px",
        },
        xl: {
          fontSize: "24px",
        },
        "2xl": {
          fontSize: "30px",
        },
        "3xl": {
          fontSize: "34px",
        },
        "4xl": {
          fontSize: "36px",
        },
      },
      // default values for 'size', 'variant' and 'colorScheme'
      defaultProps: {
        size: "md",
      },
    },

    Text: {
      // style object for base or default style
      baseStyle: {
        color: "neutral.grey.950",
        fontWeight: 600,
        lineHeight: "140%",
      },
      // styles for different sizes ("sm", "md", "lg")
      sizes: {
        xs: {
          fontSize: "12px",
        },
        sm: {
          fontSize: "14px",
        },
        md: {
          fontSize: "16px",
        },
        lg: {
          fontSize: "18px",
        },
        xl: {
          fontSize: "20px",
        },
      },
      variants: {
        semibold: {
          fontWeight: 600,
        },
        bold: {
          fontWeight: 700,
        },
        small: {
          // color: "neutral.grey.500",
          fontWeight: 600,
          size: "xs",
        },
      },
      defaultProps: {},
    },

    Link: {
      // style object for base or default style
      baseStyle: {
        color: "brand.blue.main",
        fontWeight: 600,
        lineHeight: "140%",
        px: 1,
      },
      // styles for different sizes ("sm", "md", "lg")
      sizes: {
        xs: {
          fontSize: "12px",
        },
        sm: {
          fontSize: "14px",
        },
        md: {
          fontSize: "16px",
        },
        lg: {
          fontSize: "18px",
        },
        xl: { fontSize: "20px" },
      },
      defaultProps: {
        size: "sm",
      },
    },

    Icon: {
      baseStyle: {
        fontSize: "xs",
      },
    },

    Divider: {},

    Modal: {
      // style object for base or default style
      baseStyle: {
        isCentered: true,
      },
    },

    ModalHeader: {
      // Normalise modalheaders
    },

    FormLabel: {
      baseStyle: {
        fontWeight: 600,
        fontSize: "14px",
        mb: 1,
      },
    },

    FormControl: {
      baseStyle: {
        size: "sm",
      },
    },

    DatePicker: {
      baseStyle: {
        inputSize: "sm",
      },
      defaultProps: {
        variant: "filled",
        size: "sm",
      },
    },
    FormErrorMessage: {
      baseStyle: {
        fontSize: "sm",
        mt: 1,
      },
    },

    FormError: {
      baseStyle: {
        fontSize: "sm",
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          border: "none",
        },
        button: {
          _hover: {
            backgroundColor: "grey.50",
          },
        },
      },
    },

    Tag: {
      baseStyle: {
        container: {
          fontWeight: 600,
          borderRadius: "10px",
          padding: "4px 8px",
        },
      },
      variants: {
        solid: ({ colorScheme }: { colorScheme: any }) => ({
          container: {
            bg: getColorSchemeProps(colorScheme).bg,
            color: getColorSchemeProps(colorScheme).color,
          },
        }),
        subtle: ({ colorScheme }: { colorScheme: any }) => {
          const colorSchemeLight = `${colorScheme}-light`;

          return {
            container: {
              bg: getColorSchemeProps(colorSchemeLight).bg,
              color: getColorSchemeProps(colorSchemeLight).color,
            },
          };
        },
      },
      size: {
        sm: {
          label: {
            fontSize: "12px",
          },
        },
        md: {
          label: {
            fontSize: "14px",
          },
        },
        lg: {
          label: {
            fontSize: "14px",
          },
        },
      },
      defaultProps: {
        size: "sm",
        variant: "solid",
      },
    },

    Tabs: {
      variants: {
        underlined: {
          borderColor: "white",
          align: "start",
          tab: {
            fontWeight: 600,
            color: "neutral.grey.500",
            py: 3,
            px: 0,
            mr: 5,
            borderBottomWidth: "3px",
            borderColor: "transparent",
            _selected: {
              color: "neutral.text.black",
              borderColor: "brand.blue.main",
              borderBottomWidth: "3px",
            },
            _hover: {
              color: "brand.blue.main",
            },
          },
        },
      },
      defaultProps: {
        variant: "underlined",
        size: "md",
      },
    },

    Select: {
      defaultProps: {
        size: "lg",
        variant: "filled",
      },
      variants: {
        filled: {
          field: {
            fontSize: "14px",
            color: "brand.blue.text",
            bg: "brand.blue.background",
            fontWeight: 500,
            cursor: "pointer",
            _invalid: {
              bg: "brand.red.light",
              _placeholder: {
                color: "brand.red.main",
              },
            },
            _hover: {
              bg: "brand.blue.background",
              borderColor: "brand.blue.light",
            },
            _focus: {
              bg: "brand.blue.background",
              borderColor: "brand.blue.light",
              _placeholder: {
                color: "brand.blue.dark",
              },
            },
          },
        },
      },
    },

    Textarea: {
      defaultProps: { variant: "filled" },
      variants: {
        filled: {
          bg: "brand.blue.background",
          fontSize: "14px",
          color: "brand.blue.text",
          fontWeight: 500,
          _invalid: {
            bg: "brand.red.light",
            _placeholder: {
              color: "brand.red.main",
            },
          },
          _hover: {
            _hover: {
              borderColor: "brand.blue.light",
              bg: "brand.blue.background",
            },
          },
          _focus: {
            bg: "brand.blue.background",
            borderColor: "brand.blue.light",
            _placeholder: {
              color: "brand.blue.light",
            },
          },
        },
      },
    },

    /**
     * Differents variants of the design system are not implemented yet due to the low amont of variation
     */
    Input: {
      defaultProps: {
        size: "lg",
        variant: "filled",
      },
      variants: {
        filled: {
          field: {
            fontSize: "14px",
            color: "brand.blue.text",
            bg: "brand.blue.background",
            fontWeight: 500,
            _invalid: {
              bg: "brand.red.light",
              _placeholder: {
                color: "brand.red.main",
              },
            },
            _hover: {
              borderColor: "brand.blue.light",
              bg: "brand.blue.background",
            },
            _focus: {
              bg: "brand.blue.background",
              borderColor: "brand.blue.light",
              _placeholder: {
                color: "brand.blue.light",
              },
            },
          },
        },
      },
    },

    Tooltip: {
      baseStyle: {
        bg: "grey.700",
        boxShadow:
          "0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px",
        color: "white",
        fontSize: "14px",
        p: 3,
      },
    },

    Button: {
      baseStyle: {
        fontWeight: 600,
        color: "neutral.text.white",
        alignContent: "baseline",
        borderRadius: "10px",
        gap: "8x",
      },
      sizes: {
        sm: {
          fontSize: "sm",
          p: "6px",
        },
        md: {
          fontSize: "sm",
          p: "12px 8xp 12px 16px",
          height: "40px",
        },
        lg: {
          fontSize: "md",
          p: "12px",
        },
        xl: {
          fontSize: "lg",
          p: "16px",
        },
      },
      variants: {
        solid: ({ colorScheme }: { colorScheme: any }) => ({
          bg: getColorSchemeProps(colorScheme).bg,
          color: getColorSchemeProps(colorScheme).color,
          _focus: {
            // border: "3px solid #D9E5FF",
          },
          _hover: {
            boxShadow: "sm",
            bg: getColorSchemeProps(colorScheme).bg,
          },
          _active: {
            bg: getColorSchemeProps(colorScheme).activeColorBackground,
          },
          _disabled: {
            opacity: 1,
            bg: "brand.blue.background",
            color: "brand.blue.light",
          },
        }),
        link: ({ colorScheme }: { colorScheme: any }) => ({
          textTransform: "inherit",
          color: getColorSchemeProps(colorScheme).bg,
          _focus: {
            // border: "3px solid #D9E5FF",
          },
          _hover: {
            // boxShadow: md",
          },
          _active: {
            // bg: "brand.blue.background"
          },
          _disabled: {
            color: "brand.blue.light",
          },
        }),
        ghost: {
          bg: "grey.100",
          color: "grey.950",
          _hover: { bg: "grey.200" },
          _focus: {},
          _active: {},
          _disabled: {},
        },

        outline: {
          borderColor: "brand.blue.dark",
          border: "2px solid",
          color: "brand.blue.dark",
          _hover: {
            bg: "white",
          },
          _focus: {},
          _active: {},
          _disabled: {},
        },
      },
      defaultProps: {
        size: "md",
      },
    },

    Badge: {
      baseStyle: {
        fontSize: "xs",
        rounded: "2xl",
      },
      variants: {
        solid: ({ colorScheme }: { colorScheme: any }) => ({
          bg: getColorSchemeProps(colorScheme).bg,
          color: getColorSchemeProps(colorScheme).color,
        }),
      },
    },

    Alert: {
      part: ["container", "title", "description", "icon"],
      baseStyle: {
        container: {
          fontSize: "sm",
          borderRadius: "md",
          padding: 5,
          rounded: "md",
          alignItems: "flex-start",
          bg: "semantic.information.background",
        },
        title: { color: "semantic.information.text" },
        description: {
          color: "semantic.information.text",
          fontWeight: 500,
          size: "md",
        },
        icon: { color: "semantic.information.dark" },
      },
      variants: {
        solid: ({ colorScheme }: { colorScheme: any }) => ({
          container: {
            bg: getColorSchemeProps(colorScheme).bg,
          },
          title: {
            color: getColorSchemeProps(colorScheme).color,
          },
          description: { color: getColorSchemeProps(colorScheme).color },
          icon: { color: getColorSchemeProps(colorScheme).color },
        }),
        subtle: ({ colorScheme }: { colorScheme: any }) => {
          const colorSchemeLight = `${colorScheme}-light`;

          return {
            container: {
              bg: getColorSchemeProps(colorSchemeLight).bg,
            },
            title: {
              color: getColorSchemeProps(colorSchemeLight).color,
            },
            description: { color: getColorSchemeProps(colorSchemeLight).color },
            icon: { color: getColorSchemeProps(colorSchemeLight).color },
          };
        },
      },
      defaultProps: {},
    },

    Switch: {
      parts: ["container", "thumb", "track"],
      variants: {
        blue: {
          // thumb: {},
          track: {
            bg: "brand.blue.main",
            _checked: {
              bg: "brand.blue.main",
            },
          },
        },
      },
    },

    Menu: {
      baseStyle: {
        list: {
          shadow: "lg",
          boxShadow: "md",
        },
        item: {
          // py: 4,
          // px: 6,
        },
        MenuDivider: {
          // px: 6,
        },
      },
    },
  },
});

export default theme;
