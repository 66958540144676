import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroupCustom } from "components/RadioGroupCustom";
import { TooltipError } from "components/Tooltip";
import { BOOLEAN_LABEL } from "constants/labels";
import { BooleanEnum, TaskRejectionEnum } from "graphql/__generated__/graphql";
import { find, map, pickBy } from "lodash-es";
import { useForm } from "react-hook-form";
import { type ITask } from "types/task";
import {
  mapInputToRejection,
  mapTaskEnumToLabel,
  type TaskInputType,
} from "./TaskModal.constants";
import { taskInputSchema } from "./utils";
import { useTranslation } from "react-i18next";

export const FormLabelWithWarning: React.FC<{
  label: string;
  warning: boolean;
}> = ({ label, warning }) => (
  <FormLabel>
    <Flex alignItems={"center"}>
      {warning && (
        <TooltipError
          label={
            "C’est ce critère qui a fait l’objet du rejet lors du refus de la PJ"
          }
        />
      )}
      {label}
    </Flex>
  </FormLabel>
);

export const TaskModalForm: React.FC<{
  taskRejection?: ITask["rejection"];
  onSubmit: (commentRejection: string) => Promise<void>;
  isLoadingSubmit: boolean;
}> = ({ taskRejection, onSubmit, isLoadingSubmit }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<TaskInputType>({
    resolver: yupResolver(taskInputSchema),
    mode: "onBlur",
  });

  const rejectionLabel = find(
    mapTaskEnumToLabel,
    (_, key) => key === taskRejection,
  )?.large;

  const validateSchema = async (values: TaskInputType) => {
    await taskInputSchema.validate(values);

    const falseValues = pickBy(values, (val) => val === BooleanEnum.False);

    const commentRejection = map(
      falseValues,
      (_, key: keyof typeof mapInputToRejection) =>
        mapInputToRejection[key] || "",
    ).join("\n");

    await onSubmit(commentRejection);
  };

  const hasFalse = Object.values(watch()).some(
    (val) => val === BooleanEnum.False,
  );
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(validateSchema)}>
      <Flex gap={2} direction={"column"}>
        {taskRejection && (
          <Alert status="error">
            <Stack>
              <Flex>
                <AlertIcon />
                <AlertTitle>
                  {t("rejection_reason", "Motif de rejet")}
                </AlertTitle>
              </Flex>
              <AlertDescription display="block">
                {rejectionLabel}
              </AlertDescription>
            </Stack>
          </Alert>
        )}
        <Flex direction={"column"} alignItems={"start"} gap={3}>
          <FormControl size="sm" isInvalid={!!errors.isFromClient}>
            <FormLabelWithWarning
              label={t(
                "attachment_mentions_new_date_validation",
                "La pièce jointe mentionne la validation de la nouvelle date par le bénéficiaire",
              )}
              warning={taskRejection === TaskRejectionEnum.MissingValidation}
            />
            <RadioGroupCustom
              direction="row"
              control={control}
              name={"isFromClient"}
              options={BOOLEAN_LABEL}
            />
            <FormErrorMessage>{errors.isFromClient?.message}</FormErrorMessage>
          </FormControl>
          <FormControl size="sm" isInvalid={!!errors.isDateIncluded}>
            <FormLabelWithWarning
              label={t(
                "attachment_includes_new_date",
                "La pièce jointe inclut la nouvelle date",
              )}
              warning={taskRejection === TaskRejectionEnum.InvalidDate}
            />
            <RadioGroupCustom
              direction="row"
              control={control}
              name={"isDateIncluded"}
              options={BOOLEAN_LABEL}
            />
            <FormErrorMessage>
              {errors.isDateIncluded?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl size="sm" isInvalid={!!errors.isNewDateEarlier}>
            <FormLabelWithWarning
              label={t(
                "new_date_not_earlier_than_current_date",
                "La nouvelle date mentionnée sur la pièce jointe n'est pas antérieur à la date actuelle",
              )}
              warning={taskRejection === TaskRejectionEnum.BadDate}
            />

            <RadioGroupCustom
              direction="row"
              control={control}
              name={"isNewDateEarlier"}
              options={BOOLEAN_LABEL}
            />
            <FormErrorMessage>
              {errors.isNewDateEarlier?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl size="sm" isInvalid={!!errors.isProjectMentionned}>
            <FormLabelWithWarning
              label={t(
                "attachment_mentions_project",
                "La pièce jointe mentionne le projet concerné",
              )}
              warning={taskRejection === TaskRejectionEnum.ReferenceMissing}
            />
            <RadioGroupCustom
              direction="row"
              control={control}
              name={"isProjectMentionned"}
              options={BOOLEAN_LABEL}
            />
            <FormErrorMessage>
              {errors.isProjectMentionned?.message}
            </FormErrorMessage>
          </FormControl>
          {hasFalse && (
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription display="block">
                {t(
                  "warning_one_condition_is_no",
                  "Attention, une des conditions est à 'Non', votre justificatif sera possiblement rejeté.",
                )}
              </AlertDescription>
            </Alert>
          )}
          <Flex width="100%" justifyContent="center" pt={8}>
            <Button
              size="md"
              type={"submit"}
              isLoading={isLoadingSubmit}
              textAlign="center"
            >
              {t("validate", "Valider")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};
