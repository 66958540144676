import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { InputSearch, Select } from "components";
import { type FilterType } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type IProjectFilters = {
  setFilters: (filters: Partial<FilterType>) => void;
  status?: Array<{ value: string; label: string }>;
  type?: Array<{ value: string; label: string }>;
  filters: FilterType;
  placeholder?: string;
  clearFilters: () => void;
};

export const ProjectFilters = ({
  setFilters,
  status = [],
  filters,
  placeholder,
  clearFilters,
}: IProjectFilters) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      { value: "ALL", label: t("all_status", "Tous les statuts") },
      ...status,
    ],
    [status],
  );

  const { trackEvent } = useTrackEvent();
  const [inputValue, setInputValue] = useState<string>(
    filters.searchTerm ?? "",
  );

  const [statusValue, setStatusValue] = useState<string>(
    filters.status ?? "ALL",
  );

  const debouncedUpdate = useCallback(
    debounce((value) => {
      setFilters({ searchTerm: value });
    }, 300),
    [],
  );

  const clearFiltersAndCleanSearchTerm = () => {
    clearFilters();
    setFilters({ searchTerm: "" });
    setInputValue("");
    setStatusValue("ALL");
  };
  const displayResetFilterButton =
    Object.values(filters).filter((el) => !!el).length > 0;

  // Cleanup the debounce effect
  useEffect(
    () => () => {
      debouncedUpdate.cancel();
    },
    [debouncedUpdate],
  );

  return (
    <Stack alignItems={"start"} mb={10}>
      <Flex alignItems={"center"} gap={5}>
        <Flex alignItems={"center"}>
          <Box mr={4}>
            <Select<string>
              value={statusValue}
              onChange={async (status) => {
                await trackEvent("Filter", "filter-status", { status });
                setStatusValue(status);
                setFilters({ status });
              }}
              defaultValue="ALL"
              options={options}
            />
          </Box>

          <InputSearch
            size="sm"
            value={inputValue}
            placeholder={placeholder}
            onChange={(searchTerm) => {
              setInputValue(searchTerm);
              debouncedUpdate(searchTerm);
            }}
          />
        </Flex>
        {displayResetFilterButton && (
          <Button
            variant={"ghost"}
            color="brand.blue.main"
            fontWeight={600}
            fontSize="sm"
            onClick={clearFiltersAndCleanSearchTerm}
          >
            {t("reset_button", "Effacer les filtres")}
          </Button>
        )}
      </Flex>
    </Stack>
  );
};
