import { toast } from "theme/toast";
import { version } from "../../public/meta.json";

export const refreshCacheAndReload = async () => {
  try {
    if (window?.caches) {
      const { caches } = window;
      const cacheNames = await caches.keys();
      const cacheDeletionPromises = cacheNames.map(
        async (n) => await caches.delete(n),
      );

      await Promise.all(cacheDeletionPromises);

      console.log("The cache has been deleted.");
      window.location.reload();
    }
  } catch (error) {
    console.log("An error occurred while deleting the cache.", true);
    console.log(error, true);
  }
};

export const checkCacheStatus = async () => {
  try {
    const res = await fetch(`/meta.json`);
    const { version: metaVersion } = await res.json();

    const shouldForceRefresh = metaVersion > version;
    if (shouldForceRefresh) {
      console.log(
        `There is a new version (v${metaVersion}). Should force refresh.`,
      );
      toast({
        status: "error",
        title: "Un nouvelle version de l'application est disponible.",
        isClosable: false,
        position: "bottom",
        duration: 3000,
        onCloseComplete: refreshCacheAndReload,
        description: "La page va se recharger ... ⏳",
      });
    }
  } catch (error) {
    console.log("An error occurred while checking cache status.", true);
    console.log(error, true);
  }
};
