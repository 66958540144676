import { Badge, Flex } from "@chakra-ui/react";
import { RolesEnum } from "graphql/__generated__/graphql";

const sfRolesDico: Record<RolesEnum, string> = {
  [RolesEnum.ProviderReadOnly]: "admin",
  [RolesEnum.ProviderExecutive]: "directeur",
  [RolesEnum.ProviderOperational]: "operationnel",
  [RolesEnum.ProviderSales]: "ADV",
  [RolesEnum.AccountManager]: "GestionnaireCompte",
  [RolesEnum.InternalCommercial]: "InterneCommercial",
  [RolesEnum.InternalOperational]: "InterneOperationnel",
  [RolesEnum.ProviderAdmin]: "admin",
};

export const ProviderRoleBadge: React.FC<{ roles: RolesEnum[] }> = ({
  roles,
}) => {
  const nonAdminRoles = roles.filter(
    (role) =>
      ![
        RolesEnum.ProviderReadOnly,
        RolesEnum.ProviderAdmin,
        RolesEnum.AccountManager,
        RolesEnum.InternalCommercial,
        RolesEnum.InternalOperational,
      ].includes(role),
  );

  return (
    <Flex gap={2} wrap={"wrap"} mt={2}>
      {nonAdminRoles.map((role) => (
        <Badge
          key={role}
          variant={"subtle"}
          bg={
            role === RolesEnum.ProviderExecutive
              ? "#eab308"
              : role === RolesEnum.ProviderSales
                ? "#f47ac4"
                : "silver"
          }
        >
          {sfRolesDico[role]}
        </Badge>
      ))}
    </Flex>
  );
};
