import React from "react";

import { Badge } from "@chakra-ui/react";

export const TabBadge: React.FC<{
  isActive: boolean;
  children?: React.ReactNode;
}> = ({ children, isActive }) => (
  <Badge
    variant="solid"
    color={isActive ? "white" : "neutral.grey.800"}
    bg={isActive ? "brand.blue.main" : "brand.blue.background"}
    borderRadius="50%"
    w={5}
    h={5}
    display="flex"
    justifyContent="center"
    alignItems="center"
    pos="absolute"
    top={-2}
    right={-3}
  >
    {children}
  </Badge>
);
