import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CommercialDataTab } from "./CommercialDataTab";
import { IdentityTab } from "./IdentityTab";
import { InterventionsTab } from "./InterventionsTab";
import { LocationAndWorkforceTab } from "./LocationAndWorkForceTab";

const MyCompanySettingsTabsLinks = [
  "fiche-identite",
  "interventions",
  "implantations-et-effectifs",
  "donnees-commerciales",
];

const MyCompanySettings: React.FC<{
  tab: (typeof MyCompanySettingsTabsLinks)[number];
}> = ({ tab }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      borderColor="white"
      variant="line"
      align="start"
      isLazy={true}
      index={MyCompanySettingsTabsLinks.indexOf(tab)}
      onChange={(index) => {
        navigate(`/onboarding/ma-societe/${MyCompanySettingsTabsLinks[index]}`);
      }}
    >
      <TabList>
        <Tab paddingX={1} mr={8} pos="relative">
          <Text fontWeight="600">Fiche d'identité</Text>
        </Tab>
        <Tab paddingX={1} mr={8} pos="relative">
          <Text fontWeight="600">Interventions</Text>
        </Tab>
        <Tab paddingX={1} mr={8} pos="relative">
          <Text fontWeight="600">Implantation et effectif</Text>
        </Tab>
        <Tab paddingX={1} mr={8} pos="relative">
          <Text fontWeight="600">Données commerciales</Text>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <IdentityTab />
        </TabPanel>
        <TabPanel px={0}>
          <InterventionsTab />
        </TabPanel>
        <TabPanel px={0}>
          <LocationAndWorkforceTab />
        </TabPanel>
        <TabPanel px={0}>
          <CommercialDataTab />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default MyCompanySettings;
