import { BooleanEnum } from "graphql/__generated__/graphql";
import yup from "libs/yup";

export const taskInputSchema = yup.object().shape({
  isFromClient: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
  isDateIncluded: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
  isNewDateEarlier: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
  isProjectMentionned: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
});

export const cancelationTaskInputSchema = yup.object().shape({
  isFromClient: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
  isProjectMentionned: yup
    .string()
    .required()
    .oneOf([BooleanEnum.True, BooleanEnum.False]),
});
