import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import React from "react";
import { HiChevronDown } from "react-icons/hi";
import { keyBy } from "lodash-es";

type ISelectProps<T extends string> = {
  onChange: (value: T) => void;
  value?: T;
  defaultValue: T;
  options: Array<{ value: T; label: string }>;
};

export const Select = <T extends string>({
  onChange,
  value,
  defaultValue,
  options,
}: ISelectProps<T>) => {
  const optionsByValue = keyBy(options, (o) => o.value);

  return (
    <Menu size={"sm"}>
      <MenuButton
        bg="brand.blue.background"
        color="brand.blue.dark"
        _hover={{
          bg: "brand.blue.background",
        }}
        _active={{
          bg: "brand.blue.background",
        }}
        borderRadius="md"
        as={Button}
        textTransform="inherit"
        rightIcon={<Icon as={HiChevronDown} />}
      >
        {(value && optionsByValue[value]?.label) ??
          optionsByValue[defaultValue].label}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={defaultValue}
          value={value}
          type="radio"
          onChange={(e) => {
            onChange(e as T);
          }}
        >
          {options.map(({ value, label }) => (
            <MenuItemOption value={value} key={value}>
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
