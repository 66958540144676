export const getColorSchemeProps: (
  colorScheme: string,
) => Record<string, unknown> = (colorScheme) => {
  switch (colorScheme) {
    case "white":
      return {
        bg: "white",
        color: "brand.blue.main",
      };
    case "white-light":
      return {
        bg: "white",
        color: "brand.blue.main",
      };
    case "blue":
      return {
        bg: "brand.blue.main",
        color: "white",
        activeColorBackground: "brand.blue.dark",
      };
    case "blue-light":
      return {
        bg: "semantic.information.background",
        color: "semantic.information.dark",
      };
    case "blue-lighter":
      return {
        bg: "white",
        color: "brand.blue.main",
        activeColorBackground: "brand.blue.light",
      };
    case "blue-dark":
      return {
        color: "brand.blue.dark",
        bg: "white",
      };
    case "red":
      return {
        bg: "brand.red.main",
        color: "white",
        activeColorBackground: "brand.red.dark",
      };
    case "red-light":
      return {
        bg: "semantic.error.background",
        color: "semantic.error.dark",
      };
    case "orange":
      return {
        bg: "semantic.warning.main",
        color: "semantic.warning.background",
        activeColorBackground: "semantic.warning.dark",
      };
    case "orange-light":
      return {
        bg: "semantic.warning.background",
        color: "semantic.warning.dark",
      };
    case "green":
      return {
        bg: "brand.green.main",
        color: "white",
        activeColorBackground: "brand.green.dark",
      };
    case "green-light":
      return {
        bg: "semantic.success.background",
        color: "semantic.success.dark",
      };
    case "grey":
      return {
        bg: "brand.grey.main",
        color: "white",
      };
    case "grey-light":
      return {
        bg: "brand.grey.background",
        color: "brand.grey.text",
      };
    default:
      return {
        bg: "brand.blue.main",
        color: "white",
        activeColorBackground: "brand.blue.dark",
      };
  }
};
