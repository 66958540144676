import { Button, Flex, Icon } from "@chakra-ui/react";
import { Inops } from "assets";
import { loginWithAzure } from "libs/auth";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaWindows } from "react-icons/fa";
import { useNavigate } from "react-router";

const LoginAzurePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("amplify-signin-with-hostedUI")) {
      navigate("/dashboard/main");
    }
  }, []);

  const { t } = useTranslation();

  return (
    <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
      <Flex
        bg="white"
        p={8}
        justify="center"
        align="center"
        flexDirection="column"
        borderRadius={16}
        h="100%"
        gap={3}
      >
        <Inops width="100%" height={74} />
        <Flex justify="center" mt={8}>
          <Button
            data-test="login-azure-button"
            size={"md"}
            onClick={() => loginWithAzure("azure-entra-id-freelancecom")}
            type={"submit"}
            leftIcon={<Icon as={FaWindows} w={"20px"} h={"20px"} />}
          >
            {t("login_with_azure", "Se connecter avec Azure Freelance")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginAzurePage;
