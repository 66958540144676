import { Flex, Text } from "@chakra-ui/react";
import { type IPartnerContactProject } from "types";
import { type TAB_NAME } from "stores";
import TanStackProjectTable from "./ProjectsTable";

type ProjectProps = {
  data?: IPartnerContactProject[];
  hiddenColumns?: string[];
  hiddenColumnsProjects?: string[];
  market: string;
  tab:
    | TAB_NAME.actions
    | TAB_NAME.activeProjects
    | TAB_NAME.finishedProjects
    | TAB_NAME.consolidatedProjects;
};

export const ProjectByMarket: React.FC<ProjectProps> = ({
  market,
  tab,
  hiddenColumns,
  hiddenColumnsProjects,
  data,
}) => {
  if (!data) {
    return null;
  }

  return (
    <Flex mt={5} direction="column">
      <Text fontWeight={600} fontSize="md" align="right">
        {market}
      </Text>
      <TanStackProjectTable
        data={data}
        hiddenColumns={hiddenColumns}
        hiddenColumnsProjects={hiddenColumnsProjects}
        tab={tab}
      />
    </Flex>
  );
};
