import { Button } from "@chakra-ui/react";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { GetOrCreateOrderReportDocument } from "graphql/__generated__/graphql";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { HiDownload } from "react-icons/hi";
import { useMutation } from "urql";

export const DownloadReportButton: FC<{
  orderId?: string;
  disabled?: boolean;
}> = ({ orderId, disabled }) => {
  const [{ fetching }, getReport] = useMutation(GetOrCreateOrderReportDocument);

  const { t } = useTranslation();

  const onGetReport = async () => {
    if (!orderId) return;

    const file = await getReport({ orderId });
    if (!file.data?.getOrCreateOrderReport) return;

    const b64 = file.data?.getOrCreateOrderReport.b64;
    const name = file.data?.getOrCreateOrderReport.name;
    if (!name || !b64) return;

    const buffer = Buffer.from(b64, "base64");

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
    });
    saveAs(blob, name);
  };

  return (
    <Button
      variant={"link"}
      leftIcon={<HiDownload />}
      isLoading={fetching}
      isDisabled={disabled}
      onClick={async (e) => {
        e.stopPropagation();
        await onGetReport();
      }}
    >
      {t("project_details_download_blank_pv", "Télécharger le PV vierge")}
    </Button>
  );
};
