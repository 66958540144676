import {
  Box,
  Flex,
  Icon,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { DownloadButton } from "components/DownloadButton";
import { FileTypeEnum, ProjectTypeEnum } from "graphql/__generated__/graphql";
import { OrderStatus } from "modules/order";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  FiAlertTriangle,
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  FiFlag,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { IPartnerContactProject } from "types";
import { formatDate, toEuro } from "utils/format";
import { DownloadReportButton } from "./DownloadReportButton";

export const Order: React.FC<{
  order: IPartnerContactProject["clientOrders"][number];
  providerOrders: IPartnerContactProject["clientOrders"][number]["providerOrders"];
  onToggle: (id?: string | undefined) => void;
  revealed: string[];
  type?: ProjectTypeEnum;
  isPvNotManagedByPartner?: IPartnerContactProject["isPvNotManagedByPartner"];
}> = ({
  order,
  providerOrders,
  onToggle,
  revealed,
  type,
  isPvNotManagedByPartner,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const from = location.state?.from || null;

  const phases = order.phases;
  const expanded = revealed.includes(order.id);

  return (
    <Flex
      gap="16px"
      border="1px solid #E2E8F0"
      borderRadius={"10px"}
      align="start"
      justify={"center"}
      direction={"column"}
    >
      <Flex
        align="center"
        p="16px 24px"
        w={"100%"}
        borderRadius={8}
        cursor="pointer"
        onClick={() => {
          if (providerOrders.length > 0) {
            onToggle(order.id);
          }
        }}
        gap={"8px"}
      >
        <Text fontSize={"14px"}>
          {t("project_details_orders_client", "BDC Client")} n°{order.reference}
        </Text>
        ∙
        <Text fontSize="sm">
          {formatDate(order.startDate)} → {formatDate(order.endDate)}
        </Text>
        ∙
        <OrderStatus status={order.status} />
        <Spacer />
        {!isPvNotManagedByPartner && (
          <Tooltip
            isDisabled={!!phases?.length}
            label={
              <>
                <Icon as={FiAlertTriangle} />
                {t(
                  "project_details_orders_no_phase",
                  "Cette commande n'a aucune phase associée",
                )}
              </>
            }
            aria-label="A tooltip"
          >
            <Box mr={providerOrders.length > 0 ? 0 : "20px"}>
              <DownloadReportButton
                orderId={order.id}
                disabled={!phases?.length}
              />
            </Box>
          </Tooltip>
        )}
        {providerOrders.length > 0 && (
          <Icon as={expanded ? FiChevronUp : FiChevronDown} fontSize={"20px"} />
        )}
      </Flex>

      {expanded && (
        <Stack gap={"8px"} w={"100%"}>
          <Flex direction={"column"} flex={1} p={4} gap={"16px"}>
            {providerOrders.map((po) => (
              <Flex
                justify="space-between"
                align="center"
                key={po.id}
                height="48px"
                p="12px 16px"
                backgroundColor="blue.50"
                borderRadius={"10px"}
                gap={"8px"}
              >
                <Icon as={FiFileText} fontSize={"24px"} />
                <Text fontSize={"14px"} fontWeight={500}>
                  {t("project_details_orders_partner", "Bdc Partenaire")}
                </Text>
                <Text fontSize={"14px"}>{po.name}</Text>∙
                <Text fontSize="sm">{toEuro(po.orderAmountHT)}</Text>
                ∙
                <OrderStatus status={po.status} />
                <Spacer />
                <DownloadButton
                  linkedEntityId={po.id}
                  typeDocument={FileTypeEnum.PartnerOrder}
                />
                {/* <Text fontSize="sm">émis le {formatDate(po.sendingDate)}</Text> */}
              </Flex>
            ))}
            {phases?.map((p) => (
              <Flex
                align="center"
                justify={"space-between"}
                cursor="pointer"
                key={p.id}
                height="52px"
                p="12px 16px"
                backgroundColor="grey.100"
                borderRadius={"10px"}
                gap={"8px"}
              >
                <Icon as={FiFlag} fontSize={"24px"} />
                <Text fontSize={"14px"} fontWeight={500}>
                  {t("project_details_orders_phase", "Phase associée")} :
                </Text>
                <Link
                  title={p.name || ""}
                  onClick={(e) => {
                    e.stopPropagation();

                    navigate(`/phases/${p.id}`, {
                      state: { from },
                    });
                  }}
                >
                  {p.name} ({p.completion}%)
                </Link>
                <Spacer />
                {(type === ProjectTypeEnum.At ||
                  type === ProjectTypeEnum.Atf) && (
                  <Tooltip
                    label={
                      <Flex direction={"column"} fontSize="small">
                        <Text color="white">
                          {t(
                            "project_details_orders_time_sold",
                            "Jours vendus",
                          )}{" "}
                          : {p.timeSold}
                        </Text>
                        <Text color="white">
                          {t(
                            "project_details_orders_time_billed",
                            "Jours facturés",
                          )}{" "}
                          : {p.timeBilled}
                        </Text>
                      </Flex>
                    }
                  >
                    <span>
                      <Icon
                        as={AiOutlineInfoCircle}
                        fontSize={"16px"}
                        mr={"14px"}
                      />
                    </span>
                  </Tooltip>
                )}{" "}
              </Flex>
            ))}
          </Flex>
        </Stack>
      )}
    </Flex>
  );
};
