export enum ErrorMessages {
  EMAIL_UNAUTHORIZED = "EMAIL_UNAUTHORIZED",
  SUPER_ADMIN_NO_EDIT = "SUPER_ADMIN_NO_EDIT",
  SF_USER_NOT_FOUND = "SF_USER_NOT_FOUND",
  NO_TOKEN = "NO_TOKEN",
  SF_PHASE_NO_WRITE = "SF_PHASE_NO_WRITE",
  DYNAMO_NO_PHASES = "DYNAMO_NO_PHASES",
  MUTATION_NO_PHASES_IDS = "MUTATION_NO_PHASES_IDS",
  SF_NO_ROLES = "SF_NO_ROLES",
  SF_NO_CLIENT_ORDER = "SF_NO_CLIENT_ORDER",
  SF_USER_LEFT = "SF_USER_LEFT",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  SF_CASE_NULL_COST = "SF_CASE_NULL_COST",
  SF_CASE_EXCEED_COST = "SF_CASE_EXCEED_COST",
  SF_PROJECT_NO_READ = "SF_PROJECT_NO_READ",
  SF_PHASE_NO_READ = "SF_PHASE_NO_READ",
  USERNAME_EXISTS_NO_INSIDE = "USERNAME_EXISTS_NO_INSIDE",
  USER_ALREADY_EXISTS_INSIDE = "USER_ALREADY_EXISTS_INSIDE",
  SAGE_IBAN_VALIDATION_NO_EXISTS = "SAGE_IBAN_VALIDATION_NO_EXISTS",
  SAGE_IBAN_VALIDATION_WRONG = "SAGE_IBAN_VALIDATION_WRONG",
  NO_IBAN = "NO_IBAN",
}

export const ERRORS_MESSAGE: Record<ErrorMessages, string> = {
  EMAIL_UNAUTHORIZED:
    "Vous ne pouvez pas utiliser cet email pour créer un compte",
  SUPER_ADMIN_NO_EDIT:
    "L'édition de donnée est interdite quand un superadmin agit à la place d'un utilisateur",
  SF_USER_NOT_FOUND:
    "Vous n’avez pas de projet rattaché à cette adresse mail, donc vous ne pouvez pas créer de compte. Si vous souhaitez un rôle ADV partenaire, ou pour tout autre demande, créez un ticket avec le chatbot en bas à droite, ou contactez l’adresse ope@inops.fr",
  SF_PHASE_NO_WRITE:
    "Désolé, vous n'êtes pas autorisé à réaliser cette opération car vous n'êtes pas le responsable du projet.",
  DYNAMO_NO_PHASES:
    "Il n'y a plus de phases à soumettre. Merci de recharger la page.",
  MUTATION_NO_PHASES_IDS:
    "Il n'y a plus de phases à soumettre. Merci de recharger la page.",
  NO_TOKEN: "Vous n'avez pas de token.",
  SF_NO_ROLES:
    "Votre profil n'a pas de droits suffisants pour vous connecter au Portail. Merci d'ouvrir un ticket via le robot en bas à droite ou contacter l'adresse ope@inops.fr.",
  SF_NO_CLIENT_ORDER:
    "La commande associée n’est pas valide ou annulée. Nous vous suggérons de supprimer le dossier de facturation et de le créer à nouveau car les éléments contractuels du dossier ont été modifiés.",
  SF_USER_LEFT:
    "Selon nos informations, vous ne faites plus partie de la société.",
  NOT_AUTHORIZED: "Vous n'êtes pas autorisé à faire cette opération.",
  SF_CASE_NULL_COST: "Le montant ne peut pas être à zéro ou négatif.",
  SF_CASE_EXCEED_COST:
    "Le montant total ne peut pas être plus grand que le montant facturable.",
  SF_PROJECT_NO_READ:
    "Désolé, vous n'êtes pas autorisé à réaliser cette opération car vous n'êtes pas le responsable du projet.",
  SF_PHASE_NO_READ:
    "Désolé, vous n'êtes pas autorisé à réaliser cette opération car vous n'êtes pas le responsable du projet.",
  USERNAME_EXISTS_NO_INSIDE:
    "Vous possèdez déjà un compte avec cet email sur une application Freelance.com (Connecteed, Talent etc...). \n Veuillez utiliser celui- ci pour vous connecter. \n Si vous avez oublié votre mot de passe vous pouvez le réinitialiser sur la page Mot de passe oublié",
  USER_ALREADY_EXISTS_INSIDE:
    "Vous possèdez déjà un compte avec cet email sur une application Freelance.com (Connecteed, Talent etc...). \n Veuillez utiliser celui- ci pour vous connecter. \n Si vous avez oublié votre mot de passe vous pouvez le réinitialiser sur la page Mot de passe oublié",
  SAGE_IBAN_VALIDATION_NO_EXISTS: `Vous avez spécifié un IBAN sur votre facture, mais nous n'en avons pas configuré par défaut pour votre société. Nous vous recommandons de notifier votre contact AdV`,
  SAGE_IBAN_VALIDATION_WRONG: `L'IBAN spécifié sur cette facture n'est pas celui que nous avons configuré par défaut pour votre société. Nous vous recommandons de notifier votre contact AdV`,
  NO_IBAN: "Votre facture ne contient pas d'IBAN.",
};

export const HANDLED_ERRORS: Array<ErrorMessages> = [
  ErrorMessages.SAGE_IBAN_VALIDATION_NO_EXISTS,
  ErrorMessages.SAGE_IBAN_VALIDATION_WRONG,
  ErrorMessages.NO_IBAN,
  ErrorMessages.NO_TOKEN,
];
