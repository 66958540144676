import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { DeleteInformationPostDocument } from "graphql/__generated__/graphql";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "urql";

export const DeleteInformationPostModal: React.FC = () => {
  const toast = useToast();
  const [{ fetching }, deleteInformationPost] = useMutation(
    DeleteInformationPostDocument,
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!id) {
    return null;
  }

  const onClose = () => {
    navigate("/dashboard/main");
  };

  const deletePost = async () => {
    const { error } = await deleteInformationPost({ id });
    if (!error) {
      toast({
        title: t(
          "delete-informationpost-success",
          "Le post a été supprimé avec succès",
        ),
        status: "success",
        duration: 9000,
      });
      onClose();
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"2xl"} color={"brand.blue.dark"}>
          {t("delete-informationpost-title", "Supprimer le post?")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t(
            "delete-informationpost-content",
            "Attention, vous êtes sur le point de supprimer ce post définitivement.",
          )}
        </ModalBody>
        <ModalFooter gap={4}>
          <Button variant={"link"} onClick={onClose}>
            {t("delete-informationpost-cancel", "Annuler")}
          </Button>
          <Button colorScheme={"red"} onClick={deletePost} isLoading={fetching}>
            {t("delete-informationpost-delete", "Supprimer")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
