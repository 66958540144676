import {
  type InvoiceTypeEnum,
  type ProjectStatusEnum,
} from "graphql/__generated__/graphql";
import { c } from "theme/theme";

export const statusInvoiceToHEXColor: Record<InvoiceTypeEnum, string> = {
  WAITING_VALIDATION: c.blue[400],
  WAITING_PAYMENT: c.green[400],
  LITIGE: c.red[400],
  PAID: c.green[400],
  UNKNOWN: c.grey[400],
  CANCELED: c.grey[400],
  VALIDATED: c.orange[400],
};

export const statusProjectToHEXColor: Record<ProjectStatusEnum, string> = {
  PENDING: c.grey[400],
  ACTIVE: c.blue[400],
  FINISHED: c.green[400],
  CANCELED: c.red[400],
  VALIDATED: c.grey[400],
  WAITING: c.grey[400],
  UNKNOWN: c.grey[400],
  EXECUTED: c.green[400],
};
