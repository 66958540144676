import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "libs/auth";
import { useTrackEvent } from "libs/tracking";
import yup from "libs/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { type ResetInput } from "types";

const schema = yup
  .object()
  .shape({
    email: yup.string().trim().email().required(),
  })
  .required();

export const ResetPasswordForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ResetInput>({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();

  const toast = useToast();
  const { trackEvent } = useTrackEvent();

  const onSubmit = async (values: ResetInput) => {
    await schema.validate(values);
    await trackEvent("Auth", "reset-password", { email: values.email });

    try {
      await resetPassword(values.email);

      toast({
        title: t(
          "auth_reset_password_password_reset",
          "Mot de passe réinitialisé",
        ),
        description: t(
          "auth_reset_password_check_email",
          "Suivez le lien dans l'email que nous venons d'envoyer pour choisir un nouveau mot de passe. Attention, si vous possèdez un compte avec cet email sur une autre application Freelance.com le mot de passe sera changé également.",
        ),
        status: "success",
        duration: 9000,
      });
    } catch (e) {
      toast({
        title: t(
          "auth_reset_password_error",
          "Oups, une erreur est survenue...",
        ),
        status: "error",
        duration: 9000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        size="sm"
        isInvalid={!!errors.email}
        isRequired
        mb={7}
        mt={10}
      >
        <FormLabel htmlFor="email">
          {t("auth_reset_password_email", "Votre email")}
        </FormLabel>
        <Input
          placeholder="john@doe.com"
          {...register("email")}
          data-test="input-email"
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      <Flex justify="center">
        <Button isLoading={isSubmitting} type={"submit"}>
          {t(
            "auth_reset_password_reset_password",
            "Réinitialiser mon mot de passe",
          )}
        </Button>
      </Flex>
    </form>
  );
};
