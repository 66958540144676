import { isBefore } from "date-fns";
import {
  GetPublishingPeriodStartDateDocument,
  PhaseStatusEnum,
} from "graphql/__generated__/graphql";
import { IPartnerContactProject } from "types";
import { useQuery } from "urql";

export const useFilterActivePhases = (
  phases?: IPartnerContactProject["phases"],
) => {
  const [{ data: lastDateQuery }] = useQuery({
    query: GetPublishingPeriodStartDateDocument,
  });
  const publishingPeriodStartDate = lastDateQuery?.startDate;

  const filterPhases =
    phases &&
    phases.filter((phase) => {
      // filter out non active phases or already published phases
      if (
        phase.status !== PhaseStatusEnum.Active ||
        (phase.publishedAt &&
          publishingPeriodStartDate &&
          !isBefore(phase.publishedAt, publishingPeriodStartDate))
      ) {
        return false;
      }
      // add phase to list
      return true;
    });

  return filterPhases;
};
