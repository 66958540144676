import { type GetInformationPostsQuery } from "graphql/__generated__/graphql";
import { type CarouselInjectedProps, WithStore } from "pure-react-carousel";
import { CarouselComponent } from "./Carousel";

export interface UpdateCheckProps extends CarouselInjectedProps {
  readonly posts: GetInformationPostsQuery["posts"];
}

export const CarouselComponentWithStore =
  WithStore<UpdateCheckProps>(CarouselComponent);
