import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Center,
  Flex,
  Heading,
  Icon,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  GenerateDownloadUrlDocument,
  GetAllUsersCampaignDocument,
  GetPublishingPeriodStartDateDocument,
  GetReportsByCognitoUserIdsDocument,
  type Report,
  type UserCampaign,
} from "graphql/__generated__/graphql";
import { compact, groupBy, orderBy, uniq } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdDownload } from "react-icons/md";
import { useMutation, useQuery } from "urql";

export const CompanySubmissionsStatus = () => {
  const [{ data: usersData, fetching: usersDataFetching }] = useQuery({
    query: GetAllUsersCampaignDocument,
  });

  const { t } = useTranslation();

  const [{ data: lastDateQuery, fetching: publishingPeriodFetching }] =
    useQuery({
      query: GetPublishingPeriodStartDateDocument,
    });
  const publishingPeriodStartDate = lastDateQuery?.startDate;

  const users =
    usersData?.users.filter(
      (u) =>
        u.cognitoUserId &&
        publishingPeriodStartDate &&
        u.openingDate === format(publishingPeriodStartDate, "yyyy-MM-dd"),
    ) || [];

  const cognitoUserIds = uniq(users.map((user) => user.cognitoUserId));
  const compactIds = compact(cognitoUserIds);
  const [{ data: reportsData, fetching: reportFetching }] = useQuery({
    query: GetReportsByCognitoUserIdsDocument,
    variables: { ids: compactIds },
  });

  const submissionsByUser = groupBy(
    usersData?.users,
    (user) => user.cognitoUserId,
  );

  const campagnMonth =
    publishingPeriodStartDate &&
    format(publishingPeriodStartDate, "MMMM", {
      locale: fr,
    });

  if (usersDataFetching || publishingPeriodFetching || reportFetching) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Accordion allowToggle defaultIndex={[0]}>
      <AccordionItem borderTop={"0px"}>
        <Stack gap={3}>
          <AccordionButton>
            <Flex gap={5} alignItems={"center"}>
              <Heading>
                {t(
                  "submissions-team-month",
                  "Soumissions de votre équipe au mois de {{month}}",
                  {
                    month: campagnMonth,
                  },
                )}
              </Heading>
              <AccordionIcon fontSize={"xl"} />
            </Flex>
          </AccordionButton>
          <AccordionPanel>
            <Stack gap={1}>
              {usersDataFetching && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {Object.keys(submissionsByUser).length <= 1 && (
                <Flex>
                  {t(
                    "no-submission-registered",
                    "Aucune soumission enregistrée pour votre équipe pour l'instant.",
                  )}
                </Flex>
              )}
              <Flex>
                <UserSubmissionTable
                  users={users}
                  reports={reportsData?.reports}
                />
              </Flex>
            </Stack>
          </AccordionPanel>
        </Stack>
      </AccordionItem>
    </Accordion>
  );
};

export const UserSubmissionTable: React.FC<{
  users?: UserCampaign[];
  reports?: Report[];
}> = ({ users, reports }) => {
  const [, generateDownloadUrl] = useMutation(GenerateDownloadUrlDocument);
  const { t } = useTranslation();
  const submissionsByUser = groupBy(users, (user) => user?.cognitoUserId);
  const reportsByUser = groupBy(reports, (report) => report.cognitoUserId);
  const userIds = Object.keys(submissionsByUser);
  const usersWithSubmissions = userIds.map((id) => {
    const lastEntry = orderBy(
      submissionsByUser[id],
      ["openingDate"],
      ["desc"],
    )[0];
    const lastReport = orderBy(reportsByUser[id], ["createdAt"], ["desc"])[0];
    const firstNameLastName = `${lastEntry.firstName} ${lastEntry.lastName}`;
    return { id, lastEntry, lastReport, firstNameLastName };
  });

  return (
    <TableContainer>
      <Table size={"sm"}>
        <Tbody>
          {usersWithSubmissions.map(
            ({ lastReport, lastEntry, firstNameLastName }) => (
              <Tr key={firstNameLastName}>
                <Td pl={0} borderBottom={"none"}>
                  <Text>{firstNameLastName}</Text>
                </Td>
                <Td borderBottom={"none"}>
                  <Badge
                    colorScheme={lastEntry.hasSubmit ? "green" : "orange"}
                    rounded={"md"}
                  >
                    {lastEntry.hasSubmit ? "a soumis" : "en attente"}
                  </Badge>
                </Td>
                <Td borderBottom={"none"}>
                  {lastReport ? (
                    <Flex alignItems={"center"} gap={1}>
                      <Text>
                        {t(
                          "last-submission",
                          "Dernière soumission le {{date}}",
                          {
                            date: format(lastReport.createdAt, "dd/MM/yyy"),
                          },
                        )}
                      </Text>
                      <Icon
                        as={MdDownload}
                        fontSize={"12px"}
                        cursor={"pointer"}
                        onClick={async () => {
                          const { data } = await generateDownloadUrl({
                            fileName: lastReport.path,
                          });
                          if (!data?.generateDownloadUrl) {
                            return null;
                          }
                          window.open(data.generateDownloadUrl, "_target");
                        }}
                      />
                    </Flex>
                  ) : (
                    <Text>
                      {t(
                        "report-available-next-submission",
                        "Le rapport sera disponible à la prochaine soumission.",
                      )}
                    </Text>
                  )}
                </Td>
              </Tr>
            ),
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
