import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  ModalBody,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Tooltip } from "components/Tooltip";
import {
  DocumentToCheckStatusEnum,
  OrderStatusEnum,
  type GetProviderOrderQuery,
  GetCaseQuery,
} from "graphql/__generated__/graphql";
import { useTrackEvent } from "libs/tracking";
import { DownloadReportButton } from "modules/project/ProjectOrderView/DownloadReportButton";
import React from "react";
import { FaPen } from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { isButtonEnabledFn } from "../utils";
import { useTranslation } from "react-i18next";

export const OrderModal: React.FC<{
  order: GetProviderOrderQuery["order"];
  onClose?: () => void;
  fetching: boolean;
  caseResult?: GetCaseQuery["case"];
}> = ({ order, onClose, fetching, caseResult }) => {
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  const id = order?.id;
  const clientOrderId = order?.clientOrder.id;

  const status = order?.status;

  const singleCase = caseResult;
  const caseId = singleCase?.id;

  const { pv, bill, cra } = singleCase || {};
  const isPvNotManagedByPartner =
    order?.project?.isPvNotManagedByPartner || false;
  const isCRAManaged = order?.project?.isCRAManaged || false;

  const isLitigious = status === OrderStatusEnum.Litigation;
  const advContact = singleCase?.advContact;

  const isButtonEnabled = isButtonEnabledFn(isPvNotManagedByPartner, bill, pv);

  return (
    <ModalBody>
      <Flex direction={"column"} gap={5}>
        {isLitigious && advContact ? (
          <Alert status={"error"}>
            <AlertDescription>
              {t(
                "notif_litigious_cases_description_adv_contact",
                "Votre dossier est en contentieux. Pour plus d'information sur votre dossier, vous pouvez contacter ",
              )}
              <Link href={`mailto:${advContact?.email}`} color="brand.red.main">
                {advContact.firstName} {advContact.lastName}
              </Link>
            </AlertDescription>
          </Alert>
        ) : (
          <Alert status="success">
            <AlertDescription>
              {t(
                "notif_billable_orders_description_start",
                "Vous pouvez dès maintenant démarrer le processus de facturation.",
              )}
            </AlertDescription>
          </Alert>
        )}
        {!isCRAManaged && (
          <Flex gap={2} direction={"column"}>
            <Flex justify="space-between" direction={"row"}>
              <Text size={"sm"} variant="semibold">
                {t("notif_pv_title", "Votre PV")}
              </Text>
              {!order?.project.isPvNotManagedByPartner && (
                <DownloadReportButton orderId={clientOrderId} />
              )}
            </Flex>
            <Flex my={4} justify="center">
              <EditButton
                status={pv?.status}
                isValidated={pv?.isValidated}
                onClick={async () => {
                  await trackEvent("Submit", "open-pv-modal", {
                    orderId: id,
                    caseId,
                  });
                  navigate(`pv`);
                }}
                fetching={fetching}
                isPvNotManagedByPartner={isPvNotManagedByPartner}
                hasPv={!!pv}
              />
            </Flex>
          </Flex>
        )}
        {isCRAManaged && (
          <Flex gap={2} direction={"column"}>
            <Text size={"sm"} variant="semibold">
              {t("notif_cra_title", "Votre CRA")}
            </Text>
            <Flex my={4} justify="center">
              <EditButton
                status={cra?.status}
                isValidated={cra?.isValidated}
                onClick={async () => {
                  await trackEvent("Submit", "open-cra-modal", {
                    orderId: id,
                    caseId,
                  });
                  navigate(`cra`);
                }}
                fetching={fetching}
              />
            </Flex>
          </Flex>
        )}

        <Flex gap={2} direction={"column"}>
          <Text size={"sm"} variant="semibold">
            {t("notif_bill_title", "Votre facture")}
          </Text>
          <Flex my={4} justify="center">
            <EditButton
              status={bill?.status}
              isValidated={bill?.isValidated}
              onClick={async () => {
                await trackEvent("Submit", "open-bill-modal", {
                  orderId: id,
                  caseId,
                });
                navigate(`bill`);
              }}
              fetching={fetching}
            />
          </Flex>
        </Flex>

        <Flex align="center" direction="column" gap={4} p={2}>
          <Flex
            direction={"column"}
            gap={5}
            position="sticky"
            bottom={10}
            textAlign="center"
          >
            {!isButtonEnabled && (
              <Alert status="warning" w={"100%"}>
                <AlertDescription>
                  {t(
                    "notif_billable_orders_description_end",
                    "N'oubliez pas que vous devez déposer et compléter votre justificatif et votre facture avec un montant correct avant de pouvoir soumettre votre dossier de facturation.",
                  )}
                </AlertDescription>
              </Alert>
            )}

            <Box>
              <Button
                as={NavLink}
                to={isButtonEnabled ? `validation` : "#"}
                isDisabled={!isButtonEnabled}
                data-test="check-submit-link"
              >
                {t("notif_cta_check_and_submit", "Vérifier et soumettre")}
              </Button>
            </Box>
          </Flex>

          <Button
            data-test="complete-later-button"
            variant={"link"}
            onClick={onClose}
          >
            {t("notif_cta_complete_later", "Compléter plus tard")}
          </Button>
        </Flex>
      </Flex>
    </ModalBody>
  );
};

const EditButton: React.FC<{
  status?: DocumentToCheckStatusEnum | null;
  isValidated?: boolean | null;
  fetching: boolean;
  onClick: () => void;
  isPvNotManagedByPartner?: boolean;
  hasPv?: boolean;
}> = ({
  status,
  isValidated,
  onClick,
  fetching,
  isPvNotManagedByPartner,
  hasPv,
}) => {
  const { t } = useTranslation();

  if (fetching) {
    return <Spinner />;
  }

  if (isPvNotManagedByPartner) {
    if (hasPv) {
      return (
        <Text>
          {t("notif_pv_recovered_by_ia", "PV récupéré par l'IA Inop's")}
        </Text>
      );
    }

    return (
      <Tooltip
        label={t(
          "notif_pv_recovered_by_ia_tooltip",
          "NB : votre facture ne pourra être réglée qu'à la condition que nous arrivions à récupérer un PV signé auprès du client.",
        )}
      >
        {t(
          "notif_pv_recovered_by_ia",
          "Le PV doit encore être récupéré par l'IA Inop's",
        )}
      </Tooltip>
    );
  }

  if (status === DocumentToCheckStatusEnum.Validated) {
    return (
      <Button
        colorScheme={"green"}
        cursor="auto"
        leftIcon={<Icon as={HiCheckCircle} fontSize={"xs"} />}
      >
        {t("notif_cta_validated", "Validé")}
      </Button>
    );
  }

  if (status === DocumentToCheckStatusEnum.Rejected && !isValidated) {
    return (
      <Button
        colorScheme={"red"}
        onClick={onClick}
        leftIcon={<Icon as={FaPen} fontSize={"xs"} />}
      >
        {t("notif_cta_litigious_cases", "Traiter le contentieux")}
      </Button>
    );
  }

  if (isValidated) {
    return (
      <Button
        colorScheme={"blue-light"}
        onClick={onClick}
        leftIcon={<Icon as={FaPen} fontSize={"xs"} />}
      >
        {t("notif_cta_edit", "Éditer")}
      </Button>
    );
  }

  return (
    <Button
      colorScheme={"blue"}
      data-test="complete-action-button"
      onClick={onClick}
      leftIcon={<Icon as={FaPen} fontSize={"xs"} />}
    >
      {t("notif_cta_complete", "Compléter")}
    </Button>
  );
};
