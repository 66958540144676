import { Box, Flex, Icon } from "@chakra-ui/react";
import { WelcomeInfoThumbnail } from "components/WelcomeInfoPanel";
import { orderBy } from "lodash-es";
import { ButtonPlay, DotGroup, Slide, Slider } from "pure-react-carousel";
import { forwardRef, type FunctionComponent, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { IoMdPlay } from "react-icons/io";
import { MdOutlinePause } from "react-icons/md";
import { type UpdateCheckProps } from "./CarouselComponent";

export const CarouselComponent: FunctionComponent<UpdateCheckProps> =
  forwardRef(({ posts, carouselStore }, _) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const orderedPosts = orderBy(posts, "priority");

    if (!orderedPosts.length) {
      return null;
    }

    return (
      <Box border={"1px"} rounded={"xl"} borderColor={"neutral.grey.100"} p={5}>
        <Slider>
          {orderedPosts.map((post, key) => (
            <Slide index={key} key={post.id} style={{ overflowY: "auto" }}>
              <WelcomeInfoThumbnail post={post} />
            </Slide>
          ))}
        </Slider>
        <Flex direction={"row"} justify={"flex-end"} my={3}>
          <DotGroup
            renderDots={(props) => (
              <Flex>
                {Array.from(Array(props.totalSlides).keys()).map(
                  (val, index) => (
                    <Icon
                      cursor={"pointer"}
                      key={val}
                      onClick={() => {
                        carouselStore.setStoreState({ currentSlide: index });
                      }}
                      as={GoDotFill}
                      display={["none", "none", "inherit"]}
                      color={
                        val === props.currentSlide
                          ? "brand.blue.dark"
                          : "neutral.grey.500"
                      }
                      w={5}
                      h={5}
                    />
                  ),
                )}
              </Flex>
            )}
          />
          {!!posts.length && (
            <ButtonPlay
              onClick={() => {
                setIsPlaying(!isPlaying);
              }}
            >
              {isPlaying ? (
                <Icon
                  as={MdOutlinePause}
                  display={["none", "none", "inherit"]}
                  color="neutral.grey.500"
                  w={5}
                  h={5}
                />
              ) : (
                <Icon
                  as={IoMdPlay}
                  display={["none", "none", "inherit"]}
                  color="neutral.grey.500"
                  w={5}
                  h={5}
                />
              )}
            </ButtonPlay>
          )}
        </Flex>
      </Box>
    );
  });
