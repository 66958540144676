import {
  Center,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Tooltip as TooltipChakra,
} from "@chakra-ui/react";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
} from "chart.js";

import { format, isBefore, parse } from "date-fns";
import {
  PhaseStatusEnum,
  type GetContactProjectsQuery,
} from "graphql/__generated__/graphql";
import { groupBy, sum, sumBy } from "lodash-es";
import { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { TAB_NAME, rootPaths, useUIStore } from "stores";
import { c } from "theme/theme";

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, Legend);

ChartJS.defaults.font.family = "Montserrat";
ChartJS.defaults.font.size = 11;
ChartJS.defaults.font.weight = "bold";

export const MonthlyBarChart: React.FC<{
  yearFilter: string;
  data: GetContactProjectsQuery["projects"];
  isExecutive?: boolean;
}> = ({ data: projects, isExecutive, yearFilter }) => {
  const chartRef = useRef(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [setFilters, setIsExpanded] = useUIStore((s) => [
    s.setProjectFilters,
    s.setIsExpanded,
    s.projectFilters,
  ]);

  const tabName = isExecutive
    ? TAB_NAME.consolidatedProjects
    : TAB_NAME.activeProjects;

  const changeFilter = setFilters(tabName);

  const phases = projects.flatMap((p) => p.phases);

  const filteredPhases = phases.filter(
    (p) => p.status === PhaseStatusEnum.Active,
  );

  const groupedPhasesByYear = groupBy(filteredPhases, (p) => {
    const maxDate =
      p.adjustedDeliveryDate || p.contractualDeliveryDate || new Date();
    return format(maxDate, "yyyy");
  });

  const phasesFilteredByYear =
    yearFilter === "all"
      ? filteredPhases
      : groupedPhasesByYear[yearFilter] ?? [];

  const noData = phasesFilteredByYear.length === 0;

  const projectsEndedByMonth = groupBy(phasesFilteredByYear, (p) => {
    const maxDate =
      p.adjustedDeliveryDate || p.contractualDeliveryDate || new Date();
    return format(maxDate, "MM/yy");
  });

  const labels = Object.keys(projectsEndedByMonth).sort(
    // @ts-expect-error
    (a, b) => parse(a, "MM/yy", new Date()) - parse(b, "MM/yy", new Date()),
  );

  const data = {
    labels,
    datasets: [
      {
        label: t("monthly_chart_label", "Somme du CA (en k€)"),
        data: labels.map((key) =>
          sumBy(projectsEndedByMonth[key], (p) => p.cost / 1000),
        ),
        borderRadius: 8,
        borderSkipped: false,
        backgroundColor: (context: any) => {
          const key = labels[context.dataIndex];

          const month = parse(key, "MM/yy", new Date());
          const now = new Date();
          const monthNow = format(now, "MM/yy");

          if (key === monthNow) {
            return c.orange[400];
          }

          if (isBefore(month, now)) {
            return c.red[400];
          }

          return c.green[400];
        },
      },
    ],
  };

  return (
    <Stack
      width={"100%"}
      border={"2px"}
      rounded={"xl"}
      borderColor={"neutral.grey.100"}
      py={8}
      px={4}
      justify={"space-between"}
    >
      <Flex direction={"column"} gap={3}>
        <Flex justify={"space-between"}>
          <Heading size={"md"} color={"black"}>
            {t(
              "monthly_chart_title",
              "Projection du reste à facturer par mois (en k€)",
            )}
            <TooltipChakra
              label={t(
                "monthly_chart_tooltip",
                "Il s’agit du chiffre d’affaire mensuel de vos affaires en cours projeté avec les dates de fin de chacune des phases. Vous pouvez modifier les dates de fin des phases via le Portail afin de vous assurer que vos phases se terminent bien dans le futur. Avoir des échéances dans le passé vous met en risque de potentielles pénalités selon les termes du marché.",
              )}
              placement="right"
            >
              <span style={{ marginLeft: "5px" }}>
                <Icon as={BsFillInfoCircleFill} color="brand.blue.main" />
              </span>
            </TooltipChakra>
          </Heading>
        </Flex>
        <Heading>
          {(sum(phasesFilteredByYear.map((p) => p.cost)) / 1000).toFixed(2)}k€
        </Heading>
        <div id="legend"></div>
      </Flex>
      {noData ? (
        <Center>
          <Text>{t("monthly_chart_no_data", "Pas de données")}</Text>
        </Center>
      ) : (
        <Flex w={"100%"} justify={"center"}>
          <Bar
            ref={chartRef}
            width={400}
            height={400}
            aria-label="Bar chart representing the data."
            options={{
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  display: false,
                },
              },
              plugins: {
                // @ts-expect-error
                htmlLegend: {
                  // ID of the container to put the legend in
                  containerID: "legend-container",
                },

                legend: {
                  display: false,
                },
              },
            }}
            data={data}
            plugins={[htmlLegendPlugin]}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
            onClick={(e) => {
              // @ts-expect-error
              const element = getElementAtEvent(chartRef.current, e);
              if (element[0]) {
                const date = labels[element[0].index];

                changeFilter({ searchTerm: date });
                setIsExpanded(tabName)(true);
                const destinationPath = isExecutive
                  ? rootPaths["consolidated-projects"]
                  : rootPaths["active-projects"];
                navigate(destinationPath);
              }
            }}
          />
        </Flex>
      )}
    </Stack>
  );
};

const getOrCreateLegendList = (_chart: any, _id: any) => {
  const legendContainer = document.getElementById("legend");

  let listContainer = legendContainer?.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = "0";
    listContainer.style.padding = "0";

    if (legendContainer) {
      legendContainer.appendChild(listContainer);
    }
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    [
      { text: "En retard", fillStyle: c.red[400] },
      { text: "À l'heure", fillStyle: c.orange[400] },
      { text: "À venir", fillStyle: c.green[400] },
    ].forEach((item) => {
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.marginLeft = "10px";

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderWidth = "1px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.borderRadius = "10px";
      boxSpan.style.height = "20px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "20px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = "#333333";
      textContainer.style.margin = "0";
      textContainer.style.padding = "0";
      textContainer.style.fontSize = "12px";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
