import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from "@tanstack/react-table";
import React from "react";

type SimpleTableProps<T extends Record<string, unknown>> = {
  columns: Array<ColumnDef<T>>;
  data: T[];
  hiddenColumns?: string[];
  showHeaders?: boolean;
};
const SimpleTable = <T extends { id: string }>({
  columns,
  data,
  hiddenColumns,
  showHeaders = false,
}: SimpleTableProps<T>) => {
  // Use the state and functions returned from useTable to build your UI
  const { getRowModel, getHeaderGroups } = useReactTable<T>({
    data,
    columns: columns.filter(({ id }) => id && !hiddenColumns?.includes(id)),
    getCoreRowModel: getCoreRowModel(),
    getRowId: (r) => r.id,
  });

  const tableContainerRef = React.useRef<HTMLTableElement>(null);

  const { rows } = getRowModel();

  return (
    <Table
      ref={tableContainerRef}
      border={"2px solid #f1f5f9"}
      borderBottomLeftRadius={"10px"}
      borderBottomRightRadius={"10px"}
      mb={10}
      sx={{ borderCollapse: "separate" }}
    >
      {showHeaders && (
        <Thead>
          {getHeaderGroups().map((headerGroup) => (
            <Tr
              key={headerGroup.id}
              display="grid"
              alignItems={"center"}
              gridTemplateRows={"1fr"}
              gridTemplateColumns={`${headerGroup.headers
                .filter((h) => h.column.getIsVisible())
                .map((val) => `${val.column.columnDef.size ?? 1}fr`)
                .join(" ")}`}
            >
              {headerGroup.headers.map((column) => (
                <Th
                  fontSize="xs"
                  color="neutral.grey.500"
                  textTransform="inherit"
                  border="none"
                  px={2}
                  py={3}
                  key={column.id}
                >
                  {flexRender(
                    column.column.columnDef.header,
                    column.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
      )}
      <Tbody>
        {rows.map((row) => {
          return (
            <Tr
              key={row.id}
              display="grid"
              alignItems={"center"}
              gridTemplateRows={"1fr"}
              _hover={{
                bg: "neutral.grey.50",
              }}
              gridTemplateColumns={`${row
                ._getAllVisibleCells()
                .map((val) => `${val.column.columnDef.size ?? 1}fr`)
                .join(" ")}`}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  color="neutral.grey.700"
                  fontWeight="semibold"
                  fontSize="sm"
                  border="none"
                  px={2}
                  py={4}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default SimpleTable;
