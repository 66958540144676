import { Flex, Heading, Stack } from "@chakra-ui/react";
import { ArcElement, Chart, DoughnutController, Tooltip } from "chart.js";
import { sum } from "lodash-es";
import { useRef } from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { c } from "theme/theme";
import { toEuro } from "utils/format";
import { ChartUnitEnum } from "./constants";

Chart.register(ArcElement, DoughnutController, Tooltip);

const DEFAULT_BACKGROUND_COLORS = [
  c.grey[400],
  c.blue[600],
  c.blue[400],
  c.blue[300],
  c.blue[200],
];

export type DoughnutStatElementProps = {
  title: string;
  label: string;
  labels: string[];
  data: Array<number | undefined>;
  status: string[];
  handleClick?: (status: string) => void;
  unit?: ChartUnitEnum;
  withSum?: boolean;
  backgroundColor?: string[];
};

export const DoughnutStatElement: React.FC<DoughnutStatElementProps> = ({
  title,
  label,
  labels,
  data,
  handleClick,
  unit,
  status,
  backgroundColor,
  withSum = true,
}) => {
  const ref = useRef();

  const dataSets = {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: backgroundColor ?? DEFAULT_BACKGROUND_COLORS,
        borderWidth: 1,
      },
    ],
  };
  const sumData = sum(data);

  return (
    <Stack
      alignItems={"start"}
      py={5}
      px={2}
      border={"2px"}
      rounded={"xl"}
      borderColor={"neutral.grey.100"}
      w={"calc(50% - 8px)"}
    >
      <Flex direction={"column"} gap={2}>
        <Heading size={"md"} color={"black"}>
          {title}
        </Heading>
        {withSum && (
          <Heading size={"md"}>
            {unit === ChartUnitEnum.EURO ? toEuro(sumData) : sumData}
          </Heading>
        )}
      </Flex>

      <Flex justify={"center"} w={"100%"}>
        <Doughnut
          data={dataSets}
          onClick={(event) => {
            if (!ref?.current || !getElementAtEvent(ref.current, event).length)
              return;
            const index = getElementAtEvent(ref.current, event)[0].index;
            const selectedStatus = status[index];

            if (handleClick) {
              handleClick(selectedStatus);
            }
          }}
          width={250}
          aria-label="Doughnut chart representing the data."
          height={250}
          options={{
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
              // tooltip: {
              //   callbacks: {
              //     label: ({ label, parsed }) =>
              //       ` ${label}: ${
              //         unit === ChartUnitEnum.EURO ? toEuro(parsed) : parsed
              //       }`,
              //   },
              // },
              // legend: {
              //   display: false,
              // },
            },

            // radius: 80,
          }}
          style={{
            cursor: "pointer",
          }}
          ref={ref}
        />
      </Flex>
    </Stack>
  );
};
