import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";

import { groupBy, map } from "lodash-es";
import { PROJECT_STATUS_DICO, PROJECT_TYPE_DICO } from "modules/project/utils";

import {
  CounterStatElement,
  DoughnutStatElement,
  MonthlyBarChart,
} from "components";
import { ChartUnitEnum } from "components/DoughnutStatElement/constants";
import { getCost } from "components/utils";
import {
  ProjectStatusEnum,
  type GetContactProjectsQuery,
  type InvoiceTypeEnum,
  type ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { statusInvoiceDico } from "modules/invoice/utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TAB_NAME, rootPaths, useUIStore } from "stores";
import {
  statusInvoiceToHEXColor,
  statusProjectToHEXColor,
} from "../StatsSummary/status.dico";
import { useTranslation } from "react-i18next";

type StatsGridProps = {
  projects: Array<
    GetContactProjectsQuery["projects"][number] & { date: string }
  >;
  yearProjectFilter: string;
  directorScope: boolean;
};

export const StatsGrid: React.FC<StatsGridProps> = ({
  projects,
  yearProjectFilter,
  directorScope,
}) => {
  const [setFilters, clear] = useUIStore((s) => [s.setProjectFilters, s.clear]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const projectsWithInvoices = projects.filter((p) => p.invoices.length) || [];

  const invoices =
    projectsWithInvoices.flatMap((p) =>
      p.invoices.map((i) => ({
        ...i,
        projectId: p.id,
        projectReference: p.reference,
      })),
    ) || [];

  const filteredInvoices =
    yearProjectFilter === "all"
      ? invoices
      : invoices.filter(
          (i) =>
            i.echeanceDate &&
            format(i.echeanceDate, "yyyy") === yearProjectFilter,
        );

  const invoicesByStatus = groupBy(filteredInvoices, (i) => i.status);

  const filteredProjects = projects?.filter((project) => {
    if (yearProjectFilter === "all") return true;

    return project.date === yearProjectFilter;
  });

  const projectsByStatus = groupBy(filteredProjects, (p) => p.status);
  const projectsByType = groupBy(filteredProjects, (p) => p.type);

  const status = Object.keys(projectsByStatus) as ProjectStatusEnum[];
  const invoiceStatus = Object.keys(invoicesByStatus) as InvoiceTypeEnum[];
  const projectTypes = Object.keys(projectsByType) as ProjectTypeEnum[];

  const statusLabels = status.map((s) => PROJECT_STATUS_DICO[s]);
  const invoiceStatusLabels = invoiceStatus.map((s) => statusInvoiceDico[s]);
  const projectTypesLabels = projectTypes.map((p) => PROJECT_TYPE_DICO[p]);

  const handleStatusClick = (status: string) => {
    if (
      [ProjectStatusEnum.Active, ProjectStatusEnum.Finished].includes(
        status as ProjectStatusEnum,
      )
    ) {
      clear();
      const changeFilter = setFilters(
        directorScope ? TAB_NAME.consolidatedProjects : TAB_NAME.activeProjects,
      );
      changeFilter({ status });
      navigate(
        directorScope
          ? rootPaths["consolidated-projects"]
          : rootPaths["active-projects"],
      );
    }
    if (
      [
        ProjectStatusEnum.Validated,
        ProjectStatusEnum.Pending,
        ProjectStatusEnum.Waiting,
      ].includes(status as ProjectStatusEnum)
    ) {
      clear();
      const changeFilter = setFilters(
        directorScope
          ? TAB_NAME.consolidatedProjects
          : TAB_NAME.finishedProjects,
      );
      changeFilter({ status });
      navigate(
        directorScope
          ? rootPaths["consolidated-projects"]
          : rootPaths["finished-projects"],
      );
    }
  };

  const handleInvoiceStatusClick = (status: string) => {
    const changeFilter = setFilters(
      directorScope ? TAB_NAME.consolidatedInvoices : TAB_NAME.invoices,
    );
    clear();
    changeFilter({ status });
    navigate(
      directorScope ? rootPaths["consolidated-invoices"] : rootPaths.invoices,
    );
  };

  return (
    <Flex direction={{ base: "column", xl: "row" }} justify={"space-between"}>
      <Flex width={{ base: "100%", xl: "calc(50% - 8px)" }}>
        <MonthlyBarChart
          data={projects}
          yearFilter={yearProjectFilter}
          isExecutive={directorScope}
        />
      </Flex>

      <Flex
        direction={{ base: "row", xl: "column" }}
        width={{ base: "100%", xl: "calc(50% - 8px)" }}
        justify={"space-between"}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          h="calc(50% - 8px)"
          justify={"space-between"}
        >
          <DoughnutStatElement
            title={t("stats_graph_project", "Projets")}
            label={t("stats_graph_project_number", "# de projets")}
            labels={statusLabels}
            status={status}
            handleClick={handleStatusClick}
            data={map(projectsByStatus, (val) => val.length || 0)}
            backgroundColor={map(status, (val) => statusProjectToHEXColor[val])}
          />

          <DoughnutStatElement
            title={t("stats_graph_revenue", "Chiffre d'affaires")}
            label={t("stats_graph_revenue_eur", "Chiffre d'affaires (en €)")}
            handleClick={handleStatusClick}
            labels={statusLabels}
            status={status}
            data={map(projectsByStatus, (val) => getCost(val))}
            unit={ChartUnitEnum.EURO}
            backgroundColor={map(status, (val) => statusProjectToHEXColor[val])}
          />
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          h="calc(50% - 8px)"
          justify={"space-between"}
        >
          <DoughnutStatElement
            title={t("stats_graph_project_type", "Types de projets")}
            label={t("stats_graph_project_number", "# de projets")}
            labels={projectTypesLabels}
            status={projectTypes}
            data={map(projectsByType, (val) => val.length || 0)}
            withSum={false}
          />

          <CounterStatElement
            title={t("stats_graph_invoice_status", "Statuts facturation")}
            handleClick={handleInvoiceStatusClick}
            labels={invoiceStatusLabels}
            status={invoiceStatus}
            data={map(invoicesByStatus, (val) => val.length || 0)}
            unit={ChartUnitEnum.UNIT}
            backgroundColor={map(
              invoiceStatus,
              (val) => statusInvoiceToHEXColor[val],
            )}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
