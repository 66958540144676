import { ProjectTypeEnum } from "graphql/__generated__/graphql";
import { type FilterType } from "hooks/useProjectFilter";
import { pickBy } from "lodash-es";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

type UIStore = {
  displayOrderInfoPopover: boolean;
  expandedRows: Record<string, boolean>;
  isExpanded: Record<TAB_NAME, boolean>;
  projectFilters: Record<string, FilterType>;
  projectType: ProjectTypeEnum[];
  setProjectType: (projectType: ProjectTypeEnum[]) => void;
  setExpandedRows: (projects: Record<string, boolean>) => void;
  setProjectFilters: (
    filterKey: TAB_NAME,
  ) => (projectFilters: Partial<FilterType>) => void;
  setIsExpanded: (filterKey: TAB_NAME) => (isExpanded: boolean) => void;
  getSelectedProjects: () => string[];
  clear: () => void;
};

export enum TAB_NAME {
  roleProjects = "role-projects",
  actions = "actions",
  invoices = "invoices",
  billableProjects = "billable-projects",
  activeProjects = "active-projects",
  finishedProjects = "finished-projects",
  tasks = "tasks",
  consolidatedProjects = "consolidated-projects",
  consolidatedInvoices = "consolidated-invoices",
  consolidatedBills = "consolidated-bills",
}

export const rootPaths: Record<TAB_NAME, string> = {
  actions: "/dashboard/actions",
  invoices: "/dashboard/invoices",
  "billable-projects": "/dashboard/billable-projects",
  "active-projects": "/dashboard/active-projects",
  "finished-projects": "/dashboard/finished-projects",
  tasks: "/dashboard/tasks",
  "role-projects": "/settings/projects",
  "consolidated-projects": "/consolidated-view/projects",
  "consolidated-bills": "/consolidated-view/bills",
  "consolidated-invoices": "/consolidated-view/invoices",
};

const defaultExpanded: Record<TAB_NAME, boolean> = {
  actions: true,
  invoices: true,
  "billable-projects": true,
  "active-projects": false,
  "finished-projects": false,
  tasks: true,
  "role-projects": false,
  "consolidated-projects": false,
  "consolidated-bills": false,
  "consolidated-invoices": false,
};

export const useUIStore = createWithEqualityFn<UIStore>(
  (set, get) => ({
    displayOrderInfoPopover: false,
    expandedRows: {},
    projectType: [ProjectTypeEnum.Forfait, ProjectTypeEnum.Atf],
    projectFilters: {
      [TAB_NAME.activeProjects]: {
        status: "ACTIVE",
        searchTerm: "",
      },
      [TAB_NAME.consolidatedProjects]: {
        status: "ACTIVE",
        searchTerm: "",
      },
    },
    isExpanded: defaultExpanded,
    setIsExpanded: (filterKey) => (isExpanded) => {
      set((state) => ({
        isExpanded: { ...state.isExpanded, [filterKey]: isExpanded },
      }));
    },

    setExpandedRows: (rowUpdate) => {
      set((state) => ({
        expandedRows: { ...state.expandedRows, ...rowUpdate },
      }));
    },

    setProjectType: (projectType) => {
      set(() => ({
        projectType,
      }));
    },
    setProjectFilters: (filterKey) => (rowUpdate) => {
      set((state) => ({
        projectFilters: {
          ...state.projectFilters,
          [filterKey]: { ...state.projectFilters[filterKey], ...rowUpdate },
        },
      }));
    },

    getSelectedProjects: () =>
      Object.keys(pickBy(get().expandedRows, (p) => p)),

    clear: () => {
      set(() => ({ expandedRows: {}, projectFilters: {} }));
    },
  }),
  shallow,
);
