import { Center, Spinner } from "@chakra-ui/react";
import { type RolesEnum } from "graphql/__generated__/graphql";
import { useLogout, useMe } from "hooks";
import { onUserChange } from "libs/tracking";
import { intersection } from "lodash-es";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { isProd } from "utils/env";
import { stagingEmail } from "utils/staging";

export const Private: React.FC<{
  children?: React.ReactNode;
  roles?: RolesEnum[];
}> = ({ children, roles }) => {
  const { me, isLoading } = useMe();

  const { update, hardShutdown } = useIntercom();
  const { logout } = useLogout();

  // trigger trackers for any user change
  useEffect(() => {
    const isSwitched = !!localStorage.getItem("X-Switch");

    if (isSwitched) {
      hardShutdown();
      mixpanel.disable();
      return;
    }

    if (!me) return;

    update({
      name: me.firstName + " " + me.lastName,
      userId: me.id,
      company: {
        companyId: me.partnerName ?? "",
        name: me.partnerName ?? "",
      },
      phone: me.phone || undefined,
      email: isProd ? me.email : stagingEmail(me.email),
      customAttributes: {
        partner_role: me.roles.join(", "),
        profile_title: me.fonction,
      },
    });

    onUserChange(me);
  }, [me]);

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner color="brand.blue.dark" />
      </Center>
    );
  }

  if (roles) {
    if (!intersection(roles, me?.roles)?.length) {
      logout();
    }
  }

  return <>{children}</>;
};
