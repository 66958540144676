import * as Sentry from "@sentry/react";
import { RolesEnum } from "graphql/__generated__/graphql";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { checkCacheStatus } from "utils/cache";

import AdminPage from "./Admin";
import ConfirmResetPasswordPage from "./ConfirmResetPassword";
import { Dashboard } from "./Dashboard";
import LoginPage from "./Login";
import NotFoundPage from "./NotFound";
import { Private } from "./Private";
import ResendCodePage from "./ResendCode";
import ResetPasswordPage from "./ResetPassword";
import SignUpPage from "./SignUp";
import VerifyEmailPage from "./VerifyEmail";
import LoginAzurePage from "./LoginAzure";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Pages = () => {
  const location = useLocation();

  const { update } = useIntercom();

  // trigger trackers for any page change
  useEffect(() => {
    update({ lastRequestAt: String(new Date().getTime() / 1000) });
    checkCacheStatus();
  }, [location.pathname]);

  return (
    <SentryRoutes>
      {/* Auth related routes */}
      <Route path="login" element={<LoginPage />} />
      <Route path="login-azure" element={<LoginAzurePage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="404" element={<NotFoundPage />} />
      <Route path="verify-email" element={<VerifyEmailPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="resend-code" element={<ResendCodePage />} />
      <Route
        path="confirm-reset-password"
        element={<ConfirmResetPasswordPage />}
      />

      {/* App Private routes */}

      <Route
        path="admin"
        element={
          <Private roles={[RolesEnum.InternalOperational]}>
            <AdminPage />
          </Private>
        }
      />

      <Route
        path="*"
        element={
          <Private>
            <Dashboard />
          </Private>
        }
      />

      {/* Default */}
      <Route
        path="*"
        element={<Navigate to="/login" state={{ from: location.pathname }} />}
      />
    </SentryRoutes>
  );
};
