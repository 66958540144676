import {
  Badge,
  Button,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { WithRoles } from "components/WithRoles";
import { YoutubePlayer } from "components/YoutubePlayer";
import { format } from "date-fns";
import {
  RolesEnum,
  type GetInformationPostsQuery,
} from "graphql/__generated__/graphql";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export const WelcomeInfoThumbnail: React.FC<{
  post: GetInformationPostsQuery["posts"][number];
}> = ({ post }) => {
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { title, description } = post;
  const { t } = useTranslation();
  return (
    <>
      <Stack
        p={5}
        alignItems={"start"}
        justifyContent={"space-between"}
        gap={3}
        minW={"400px"}
        width={isLargerThan1200 ? "500px" : "300px"}
        height={"200px"}
        border={"2px"}
        rounded={"xl"}
        borderColor={"neutral.grey.100"}
      >
        <Heading size={"md"}>{title}</Heading>
        <Text fontSize={"sm"}>{description.substring(0, 100) + "..."}</Text>
        <Button onClick={onOpen}>{t("know-more", "En savoir +")}</Button>
      </Stack>
      <WelcomeInfoModal post={post} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const WelcomeInfoModal: React.FC<{
  post: GetInformationPostsQuery["posts"][number];
  isOpen: boolean;
  onClose: () => void;
}> = ({ post, isOpen, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    id,
    title,
    description,
    startDate,
    endDate,
    buttonLabel,
    link,
    youtubeLink,
    active,
  } = post;

  const splitDescription = description
    .split("\n")
    .map((text, index) => <Text key={index}>{text}</Text>);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent p={10}>
        <ModalCloseButton />
        <Flex direction={"column"} gap={7} alignItems={"start"} flex={1}>
          <Flex alignItems={"center"}>
            <Heading size={"xl"}>{title}</Heading>
            <WithRoles roles={[RolesEnum.ProviderReadOnly]}>
              <Flex gap={2} ml={2}>
                {active && (
                  <Badge variant={"solid"} colorScheme={"green"} rounded={"md"}>
                    {t("active", "Actif")}
                  </Badge>
                )}
                <Text>{startDate && format(startDate, "dd/MM/yyy")}</Text>
                <Text>{endDate && format(endDate, "dd/MM/yyyy")}</Text>
              </Flex>
            </WithRoles>
          </Flex>
          <Flex direction={"column"}>{splitDescription}</Flex>
          {youtubeLink && <YoutubePlayer link={youtubeLink} />}

          {link && buttonLabel && (
            <Button onClick={() => window.open(link, "_blank")}>
              {buttonLabel}
            </Button>
          )}
        </Flex>
        <WithRoles roles={[RolesEnum.ProviderReadOnly]}>
          <Flex
            direction={"column"}
            padding={10}
            gap={4}
            justifyContent={"start"}
            alignItems={"end"}
          >
            <Button
              leftIcon={<FaPen />}
              variant={"link"}
              onClick={() => {
                navigate(`./posts/${id}/edit`);
              }}
            >
              {t("edit", "Éditer")}
            </Button>
            <Button
              leftIcon={<HiOutlineTrash />}
              variant={"link"}
              onClick={() => {
                navigate(`./posts/${id}/delete`);
              }}
              color="red"
            >
              {t("delete", "Supprimer")}
            </Button>
          </Flex>
        </WithRoles>
      </ModalContent>
    </Modal>
  );
};
