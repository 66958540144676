import {
  Flex,
  Heading,
  Icon,
  Spacer,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip as TooltipChakra,
} from "@chakra-ui/react";
import { sum } from "lodash-es";
import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { toEuro } from "utils/format";
import { ChartUnitEnum } from "../DoughnutStatElement/constants";
import { useTranslation } from "react-i18next";

export type ConterStatElementProps = {
  title: string;
  labels: string[];
  data: Array<number | undefined>;
  status: string[];
  handleClick: (status: string) => void;
  unit?: ChartUnitEnum;
  withSum?: boolean;
  backgroundColor?: string[];
};

export const CounterStatElement: React.FC<ConterStatElementProps> = ({
  title,
  labels,
  data,
  handleClick,
  unit,
  status,
  backgroundColor,
  withSum = true,
}) => {
  const sumData = sum(data);
  const { t } = useTranslation();

  return (
    <Stack
      py={5}
      px={5}
      width={"calc(50% - 8px)"}
      border={"2px"}
      rounded={"xl"}
      borderColor={"neutral.grey.100"}
      justifyContent={"space-between"}
    >
      <Flex gap={3}>
        <Heading size={"md"} color={"black"}>
          {title}
        </Heading>
        {withSum && (
          <Heading size={"md"}>
            {unit === ChartUnitEnum.EURO ? toEuro(sumData) : sumData}
          </Heading>
        )}
        <TooltipChakra
          label={t(
            "billing-statistics-summary",
            "Il s'agit d'une synthèse de vos dossiers de facturation traités sur la période sélectionnée.",
          )}
          placement="right"
        >
          <span style={{ marginLeft: "5px" }}>
            <Icon as={BsFillInfoCircleFill} color="brand.blue.main" />
          </span>
        </TooltipChakra>
      </Flex>
      <Spacer />

      <StatGroup display={"grid"} h="100%" py={4}>
        {labels.map((label, index) => (
          <Stat key={index}>
            <Flex
              rounded={"xl"}
              onClick={() => {
                handleClick(status[index]);
              }}
              align={"center"}
              cursor={"pointer"}
            >
              <StatLabel mr={4}>{label}</StatLabel>

              <StatNumber mb={0} mr={2}>
                {data[index]}
              </StatNumber>
              <StatHelpText
                // display={"flex"}
                // flexDirection={"column"}
                fontSize="xs"
                mb={0}
              >
                <StatArrow
                  type="increase"
                  color={
                    backgroundColor ? backgroundColor[index] : "brand.blue.main"
                  }
                />
                {((100 * (data[index] ?? 0)) / sumData).toFixed()}%
              </StatHelpText>
            </Flex>
          </Stat>
        ))}
      </StatGroup>
    </Stack>
  );
};
