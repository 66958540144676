import { Button, Flex, Spinner } from "@chakra-ui/react";
import { Select } from "components";
import { compareDesc, format } from "date-fns";
import {
  GenerateDownloadUrlDocument,
  GetReportsDocument,
} from "graphql/__generated__/graphql";
import { useTrackEvent } from "libs/tracking";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiDownload } from "react-icons/hi";
import { useMutation, useQuery } from "urql";

export const DownloadReport: React.FC = () => {
  const [{ data: reportsData, fetching }] = useQuery({
    query: GetReportsDocument,
  });
  const [{ fetching: isLoadingUrl }, generateDownloadUrl] = useMutation(
    GenerateDownloadUrlDocument,
  );
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  const reportsSort =
    reportsData?.reports.sort((a, b) =>
      compareDesc(a.createdAt, b.createdAt),
    ) || [];

  const lastReport = reportsSort[0];

  const [selectedReport, setSelectedReport] = useState<string>();

  useEffect(() => {
    if (lastReport) {
      setSelectedReport(lastReport.id);
    }
  }, [lastReport, setSelectedReport]);

  if (fetching) return <Spinner mt={9} />;

  const options = reportsSort.map((report) => ({
    value: report.id,
    label: format(report.createdAt, "dd/MM/yyyy"),
  }));

  if (!lastReport)
    return (
      <>
        {t(
          "no_report_available",
          "Aucun rapport n'est disponible au téléchargement.",
        )}
      </>
    );

  return (
    <Flex py={9} gap={4}>
      <Select
        options={options}
        value={selectedReport}
        defaultValue={lastReport.id}
        onChange={(e) => {
          setSelectedReport(e);
        }}
      />
      <Button
        leftIcon={<HiDownload />}
        isLoading={isLoadingUrl}
        onClick={async () => {
          if (!reportsData) return null;

          const { data } = await generateDownloadUrl({
            fileName: selectedReport ?? lastReport.id,
          });

          if (!data?.generateDownloadUrl) {
            return null;
          }

          await trackEvent("Download", "download-report", {
            url: data.generateDownloadUrl,
          });

          window.open(data.generateDownloadUrl, "_target");
        }}
      >
        {t("download_report", "Télécharger le rapport")}
      </Button>
    </Flex>
  );
};
