import {
  BooleanEnum,
  DocumentToCheckStatusEnum,
  GetCaseQuery,
  OrderAfterReceptionEnum,
  type OrderStatusEnum,
} from "graphql/__generated__/graphql";
import yup from "libs/yup";
import { customAlphabet } from "nanoid";

export const statusOrderDico: Record<OrderStatusEnum, string> = {
  ACTIVE: "En cours",
  ACTIVE_BILLING: "En cours de facturation",
  BILLABLE: "Facturable",
  PV_SENT: "PV transmis",
  PV_VALIDATED: "PV validé",
  BILLED: "Facturé",
  BILLED_INOPS: "Facturée par Inop's",
  BILLABLE_YOOZ: "Facturable",
  LITIGATION: "En contentieux",
  NOT_ASSIGNED: "Non assignée",
  BDC_PROVIDER_WAITING: "BDC à venir",
  BDC_MULTI_PROVIDER_WAITING: "BDC à venir ou en cotraitance",
  BILLED_PARTIAL: "Facturé partiellement",
  BILLED_PARTIAL_INOPS: "Partiellement facturée par Inop’s",
  UNKNOWN: "Inconnu",
  CANCELED: "Annulé",
  SIGNING: "En signature",
};

export const statusColorDico: Record<OrderStatusEnum, string> = {
  ACTIVE: "blue-light",
  ACTIVE_BILLING: "blue-light",
  BILLABLE: "orange-light",
  BILLABLE_YOOZ: "orange-light",
  BILLED_PARTIAL_INOPS: "green-light",
  BILLED_PARTIAL: "green-light",
  BILLED_INOPS: "green-light",
  BILLED: "green-light",
  PV_SENT: "orange-light",
  PV_VALIDATED: "green-light",
  LITIGATION: "red-light",
  NOT_ASSIGNED: "grey-light",
  BDC_PROVIDER_WAITING: "grey-light",
  BDC_MULTI_PROVIDER_WAITING: "grey-light",
  UNKNOWN: "grey-light",
  CANCELED: "grey-light",
  SIGNING: "grey-light",
};

export const statusTrueColorDico: Record<OrderStatusEnum, string> = {
  ACTIVE: "blue.700",
  ACTIVE_BILLING: "green.700",
  BILLABLE: "orange.700",
  BILLABLE_YOOZ: "orange.700",
  BILLED_PARTIAL_INOPS: "green.700",
  BILLED_PARTIAL: "green.700",
  BILLED_INOPS: "green.700",
  BILLED: "green.700",
  PV_SENT: "green.700",
  PV_VALIDATED: "green.700",
  LITIGATION: "orange.700",
  NOT_ASSIGNED: "grey.400",
  BDC_PROVIDER_WAITING: "grey.400",
  BDC_MULTI_PROVIDER_WAITING: "grey.400",
  UNKNOWN: "grey.400",
  CANCELED: "grey.400",
  SIGNING: "grey.400",
};

export const craSchema = yup.object().shape({
  deliveryDate: yup.date().required(),
  signature: yup.string().required().oneOf(Object.values(BooleanEnum)),
  partnerComment: yup.string().nullable(),
});

export const pvSchema = yup.object().shape({
  deliveryDate: yup.date().required(),
  deliveryDelay: yup.string().required().oneOf(Object.values(BooleanEnum)),
  reportClient: yup.string().nullable(),
  afterReceptionDecision: yup
    .string()
    .required()
    .oneOf(Object.values(OrderAfterReceptionEnum)),
  cachet: yup.string().required().oneOf(Object.values(BooleanEnum)),
  signature: yup.string().required().oneOf(Object.values(BooleanEnum)),
  nameQuality: yup.string().required().oneOf(Object.values(BooleanEnum)),
  partnerComment: yup.string().nullable(),
});

export const billSchema = yup.object().shape({
  deliveryDate: yup.date().required(),
  name: yup.string().required(),
  partnerComment: yup.string().nullable(),
});

export const nanoidShort = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRST", 15);

export const isButtonEnabledFn = (
  isPvNotManagedByPartner: boolean,
  bill?: GetCaseQuery["case"]["bill"],
  pv?: GetCaseQuery["case"]["pv"],
) => {
  //  The bill is not validated
  if (
    !bill?.isValidated &&
    bill?.status !== DocumentToCheckStatusEnum.Validated
  ) {
    return false;
  }

  // The pv is not validated and it has to be filled by the partner
  if (!isPvNotManagedByPartner) {
    if (
      !pv?.isValidated &&
      pv?.status !== DocumentToCheckStatusEnum.Validated
    ) {
      return false;
    }
  }

  return true;
};
