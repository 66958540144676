import { Checkbox } from "@chakra-ui/react";
import React from "react";
import { useSelectedProjectsStore } from "stores";
import { type IPartnerContactProject } from "types";

export const SelectAllProjects: React.FC<{
  projects: IPartnerContactProject[];
}> = ({ projects }) => {
  const [setSelectedProject, selectedProjects] = useSelectedProjectsStore(
    (s) => [s.setSelectedProjects, s.getSelectedProjects()],
  );

  return (
    <Checkbox
      size="lg"
      ml={4}
      mr={4}
      onChange={(e) => {
        const allProject = projects.reduce(
          (acc: Record<string, boolean>, p) => {
            acc[p.id] = e.target.checked;
            return acc;
          },
          {},
        );

        setSelectedProject(allProject);
      }}
      isChecked={projects.every((p) => selectedProjects.includes(p.id))}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};
