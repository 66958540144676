import { format } from "date-fns";
import { type Phase } from "graphql/__generated__/graphql";

export const generateProjectDeliveryDateUpdateEmail = ({
  partnerName,
  projectRef,
  projectName,
  phases,
  to,
  userFullName,
}: {
  partnerName: string;
  projectRef: string;
  projectName: string;
  phases: Array<
    Pick<Phase, "name" | "adjustedDeliveryDate" | "contractualDeliveryDate"> & {
      orderRefs: string[];
    }
  >;
  to: string | null;
  userFullName: string;
}) => {
  const subject = `${partnerName} ${projectRef} ${projectName} Demande de mise à jour des échéances contractuelles`;
  const head = `Bonjour, 

A ce jour, notre projet « ${projectName} - ${projectRef} » n’est plus en phase sur l’alignement des échéances contractuelles par rapport au déroulé opérationnel.
  
Pour procéder à l’ajustement contractuel des échéances, nous avons besoin d’une validation par un simple « OK » à ce mail pour entériner les nouvelles dates d’échéances.

Voici le tableau récapitulatif à compléter :

`;

  const table = `${phases
    .map(
      (phase) => `${phase.name}:
- Ordre(s) de service n° ${phase.orderRefs.join(", ")}
- Date d’échéance contractuelle: ${
        (phase?.contractualDeliveryDate &&
          format(phase.contractualDeliveryDate, "dd/MM/yy")) ??
        "non définie"
      }
- Date d’échéance ajustée: ${
        (phase?.adjustedDeliveryDate &&
          format(phase.adjustedDeliveryDate, "dd/MM/yy")) ??
        "à définir"
      }

`,
    )
    .join("\n")}`;

  const tail = `Merci par avance pour votre acceptation de ce tableau récapitulatif, ou bien entendu si cela ne vous convient pas je vous propose d’en rediscuter.

Cordialement.
${userFullName}`;

  const body = `${head}${table}${tail}`;

  return {
    mailto: `mailto:${to}?cc=${"ope@inops.fr"}&subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`,
    body,
  };
};

export const generateContactSailsMailTo = ({
  phaseName,
  inopsSaleFirstName,
  inopsSaleEmail,
  partnerName,
  partnerEmail,
  partnerPhone,
  partnerLastName,
}: {
  phaseName?: string;
  inopsSaleFirstName?: string | null;
  inopsSaleEmail?: string;
  partnerEmail?: string;
  partnerName?: string | null;
  partnerPhone?: string | null;
  partnerLastName?: string | null;
}) => {
  const subject = encodeURIComponent(
    `[${partnerName}] Régulation de mes expertises`,
  );

  const body = `
Bonjour ${inopsSaleFirstName},

Le nombre de jours consommés sur la phase ${phaseName} est supérieur au nombre de jours vendus. Merci de revenir vers moi pour que nous régulions la situation.
  
${signatureEmail({
  email: partnerEmail,
  phone: partnerPhone,
  firstName: partnerName,
  lastName: partnerLastName,
})}
  `;

  return {
    mailto: `mailto:${inopsSaleEmail}?cc=${inopsSaleEmail}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

export const generateExpertiseMailTo = ({
  partnerName,
  email,
  phone,
  firstName,
  lastName,
}: {
  email: string;
  partnerName?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}) => {
  const subject = encodeURIComponent(
    `[${partnerName}] Modification de mes expertises`,
  );

  const body = `
Bonjour,

Je souhaite modifier les expertises de ma société enregistrées chez vous, et pour ce faire je souhaite prendre RDV avec vous.

Voici quelques disponibilités : 
jj/mm/aaaa

${signatureEmail({ email, phone, firstName, lastName })}
`;

  return {
    mailto: `mailto:mflevy@freelance.com?cc=${email}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

export const generateBillingMailTo = ({
  partnerName,
  email,
  phone,
  firstName,
  lastName,
  projectRef,
  orderRef,
}: {
  email: string;
  partnerName?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  projectRef?: string | null;
  orderRef?: string | null;
}) => {
  const subject = encodeURIComponent(
    `[${partnerName}] Problème de facturation`,
  );
  const body = `
Bonjour,

Je prends contact avec vous concernant le projet ${projectRef}, car je ne parviens pas à facturer l’OS ${orderRef}.

${signatureEmail({ email, phone, firstName, lastName })}
`;

  return {
    mailto: `mailto:advpublic@freelance.com?cc=${email}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

export const generateSiretMailTo = ({
  partnerName,
  email,
  phone,
  firstName,
  lastName,
}: {
  email: string;
  partnerName?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}) => {
  const subject = encodeURIComponent(`[${partnerName}] Ajout de numéro SIRET`);

  const body = `
Bonjour,

Je souhaite ajouter mon numéro SIRET : <votre SIRET>,

${signatureEmail({ email, phone, firstName, lastName })}
`;

  return {
    mailto: `mailto:ope@inops.fr?cc=${email}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

export const generateAddContactMailTo = ({
  partnerName,
  email,
  phone,
  firstName,
  lastName,
}: {
  email: string;
  partnerName?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}) => {
  const subject = encodeURIComponent(
    `[${partnerName}] Ajout d'un nouveau contact`,
  );

  const body = `
Bonjour,

Je souhaite ajouter un contact qui n'est aujourd'hui pas enregistré :
- Nom : xxxxxx
- Prénom : xxxxxx
- Adresse mail : xxxxxx
- N° de téléphone: xxxxxx
- Fonction : xxxxxx

${signatureEmail({ email, phone, firstName, lastName })}
`;

  return {
    mailto: `mailto:ope@inops.fr?cc=${email}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

export const generateAddADVMailTo = ({
  partnerName,
  email,
  phone,
  firstName,
  lastName,
}: {
  email: string;
  partnerName?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}) => {
  const subject = encodeURIComponent(
    `[${partnerName}] Ajout de nouveau profil ADV`,
  );

  const body = `
Bonjour,

Je souhaite ajouter un profil ADV : 
- Nom : xxxxxx
- Prénom : xxxxxx
- Adresse mail : xxxxxx
- N° de téléphone: xxxxxx
- Fonction : xxxxxx

${signatureEmail({ email, phone, firstName, lastName })}
`;

  return {
    mailto: `mailto:ope@inops.fr?cc=${email}&subject=${subject}&body=${encodeURIComponent(
      body,
    )}`,
    body,
  };
};

const signatureEmail = ({
  email,
  phone,
  firstName,
  lastName,
}: {
  email?: string;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}) => `
Merci par avance.
Cordialement,

${firstName} ${lastName}
${email}
${phone}
`;
