import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { WithRoles } from "components";
import { compareDesc, format } from "date-fns";
import {
  CaseStatusEnum,
  GenerateDownloadUrlDocument,
  GetContactTasksDocument,
  GetReportsDocument,
  OrderStatusEnum,
  ProjectTypeEnum,
  RolesEnum,
} from "graphql/__generated__/graphql";
import {
  useBillableOrders,
  useGetProjects,
  useMe,
  useProjectsWithActivePhases,
} from "hooks";
import { useTrackEvent } from "libs/tracking";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiDownload } from "react-icons/hi";
import { RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { TAB_NAME, useUIStore } from "stores";
import { useMutation, useQuery } from "urql";

export const NotificationSummary = () => {
  const navigate = useNavigate();
  const [setFilters, setProjectType] = useUIStore((s) => [
    s.setProjectFilters,
    s.setProjectType,
  ]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [{ data }] = useQuery({
    query: GetContactTasksDocument,
  });

  const tasks = data?.tasks ?? [];
  const { projects, companyProjects, isLoading, hasOnlyAT } = useGetProjects();

  const { litigiousCases, billableOrders } = useBillableOrders(projects);

  const {
    litigiousCases: companyLitigiousCases,
    billableOrders: companyBillableOrders,
  } = useBillableOrders(companyProjects);

  const { allPhasesChecked, remainingPhasesCount } =
    useProjectsWithActivePhases(
      projects.filter((p) => p.type !== ProjectTypeEnum.At),
    );

  const { me } = useMe();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Flex direction={"column"} width={"100%"} alignItems={"end"}>
        <Button variant="link" onClick={onOpen} rightIcon={<HiDownload />}>
          {t("notif_download", "Télécharger mes soumissions")}
        </Button>
      </Flex>
      <Accordion allowToggle defaultIndex={[0]}>
        <AccordionItem borderTop={"0px"}>
          <Stack gap={3}>
            <AccordionButton>
              <Flex gap={5} alignItems={"center"}>
                <Heading>
                  {t("notif_summary", " Résumé des notifications")}
                </Heading>{" "}
                <AccordionIcon fontSize={"xl"} />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Stack>
                <WithRoles
                  roles={[RolesEnum.ProviderSales, RolesEnum.ProviderExecutive]}
                >
                  <Flex gap={5} direction={{ base: "column", md: "row" }}>
                    {companyLitigiousCases.length > 0 && (
                      <Alert status={"error"}>
                        <Flex>
                          <AlertIcon />
                          <Stack alignItems={"start"}>
                            <AlertTitle>
                              {t(
                                "notif_tasks_to_resolve",
                                "[{{partnerName}}] Tâches à résoudre",
                                { partnerName: me?.partnerName },
                              )}
                            </AlertTitle>
                            <AlertDescription>
                              <Stack
                                height={"100%"}
                                justifyContent={"space-between"}
                              >
                                <Text color={"semantic.error.dark"}>
                                  Vos collègues ont{" "}
                                  {companyLitigiousCases.length} dossier de
                                  facturation en litige.
                                </Text>
                              </Stack>
                            </AlertDescription>
                            <Stack>
                              <Button
                                variant={"link"}
                                colorScheme={"red"}
                                gap={1}
                              >
                                <Text
                                  fontWeight={"bold"}
                                  alignItems={"center"}
                                  color={"semantic.error.dark"}
                                  onClick={() => {
                                    const changeFilter = setFilters(
                                      TAB_NAME.consolidatedBills,
                                    );
                                    changeFilter({
                                      status: CaseStatusEnum.Litigation,
                                    });
                                    navigate("/consolidated-view/bills");
                                  }}
                                >
                                  {t(
                                    "notif_resolve_litiges",
                                    "Résoudre les litiges",
                                  )}
                                </Text>
                                <Icon
                                  as={RiArrowRightLine}
                                  color={"semantic.error.dark"}
                                />
                              </Button>
                            </Stack>
                          </Stack>
                        </Flex>
                      </Alert>
                    )}
                    {companyBillableOrders.length > 0 && (
                      <Alert status={"warning"}>
                        <Flex>
                          <AlertIcon />
                          <Stack alignItems={"start"}>
                            <AlertTitle>
                              {t(
                                "notif_summary_billable_orders_title",
                                "[{{partnerName}}] Facturation en attente",
                                { partnerName: me?.partnerName },
                              )}
                            </AlertTitle>
                            <AlertDescription>
                              Vos collègues ont {companyBillableOrders.length}{" "}
                              dossier de facturation en attente.
                            </AlertDescription>
                            <Button
                              variant={"link"}
                              colorScheme={"red"}
                              gap={1}
                              onClick={() => {
                                const changeFilter = setFilters(
                                  TAB_NAME.consolidatedBills,
                                );
                                changeFilter({
                                  status: OrderStatusEnum.Billable,
                                });
                                navigate("/consolidated-view/bills");
                              }}
                            >
                              <Text
                                fontWeight={"bold"}
                                alignItems={"center"}
                                color={"semantic.warning.dark"}
                              >
                                {t(
                                  "notif_cta_billable_orders",
                                  "Aller à la facturation",
                                )}
                              </Text>
                              <Icon
                                as={RiArrowRightLine}
                                color={"semantic.warning.dark"}
                              />
                            </Button>
                          </Stack>
                        </Flex>
                      </Alert>
                    )}
                  </Flex>
                </WithRoles>
                <Flex gap={5} direction={{ base: "column", md: "row" }}>
                  {(tasks.length > 0 || litigiousCases.length > 0) && (
                    <Alert status={"error"}>
                      <Flex>
                        <AlertIcon />
                        <Stack alignItems={"start"}>
                          <>
                            <AlertTitle>
                              {t("notif_tasks_to_resolve", "Tâches à résoudre")}
                            </AlertTitle>
                            <AlertDescription>
                              <Stack
                                height={"100%"}
                                justifyContent={"space-between"}
                              >
                                {tasks.length > 0 && (
                                  <Text color={"semantic.error.dark"}>
                                    {tasks.length} tâche à résoudre.
                                  </Text>
                                )}
                                {litigiousCases.length > 0 && (
                                  <Text color={"semantic.error.dark"}>
                                    {litigiousCases.length} dossier de
                                    facturation en litige.
                                  </Text>
                                )}
                              </Stack>
                            </AlertDescription>
                            <Stack>
                              {tasks.length > 0 && (
                                <Button
                                  variant={"link"}
                                  colorScheme={"red"}
                                  gap={1}
                                >
                                  <Text
                                    fontWeight={"bold"}
                                    alignItems={"center"}
                                    color={"semantic.error.dark"}
                                    onClick={() => {
                                      navigate("/dashboard/tasks");
                                    }}
                                  >
                                    {t(
                                      "notif_cta_resolve_tasks",
                                      "Résoudre les tâches",
                                    )}
                                  </Text>
                                  <Icon
                                    as={RiArrowRightLine}
                                    color={"semantic.error.dark"}
                                  />
                                </Button>
                              )}
                              {litigiousCases.length > 0 && (
                                <Button
                                  variant={"link"}
                                  colorScheme={"red"}
                                  gap={1}
                                >
                                  <Text
                                    fontWeight={"bold"}
                                    alignItems={"center"}
                                    color={"semantic.error.dark"}
                                    onClick={() => {
                                      const changeFilter = setFilters(
                                        TAB_NAME.billableProjects,
                                      );
                                      changeFilter({
                                        status: CaseStatusEnum.Litigation,
                                      });
                                      navigate("/dashboard/billable-projects");
                                    }}
                                  >
                                    {t(
                                      "notif_cta_resolve_litiges",
                                      "Résoudre les litiges",
                                    )}
                                  </Text>
                                  <Icon
                                    as={RiArrowRightLine}
                                    color={"semantic.error.dark"}
                                  />
                                </Button>
                              )}
                            </Stack>
                          </>
                        </Stack>
                      </Flex>
                    </Alert>
                  )}
                  {billableOrders.length > 0 && (
                    <Alert status={"warning"}>
                      <Flex>
                        <AlertIcon />
                        <Stack alignItems={"start"}>
                          <AlertTitle>
                            {t(
                              "notif_summary_billable_orders_title",
                              "Facturation en attente",
                            )}
                          </AlertTitle>
                          <AlertDescription>
                            Vous avez {billableOrders.length} dossier de
                            facturation en attente.
                          </AlertDescription>
                          <Button
                            variant={"link"}
                            colorScheme={"red"}
                            gap={1}
                            onClick={() => {
                              const changeFilter = setFilters(
                                TAB_NAME.billableProjects,
                              );
                              changeFilter({
                                status: OrderStatusEnum.Billable,
                              });
                              navigate("/dashboard/billable-projects");
                            }}
                          >
                            <Text
                              fontWeight={"bold"}
                              alignItems={"center"}
                              color={"semantic.warning.dark"}
                            >
                              {t(
                                "notif_cta_billable_orders",
                                "Aller à la facturation",
                              )}
                            </Text>
                            <Icon
                              as={RiArrowRightLine}
                              color={"semantic.warning.dark"}
                            />
                          </Button>
                        </Stack>
                      </Flex>
                    </Alert>
                  )}
                  {remainingPhasesCount > 0 && !hasOnlyAT && (
                    <Alert status={"info"}>
                      <Flex>
                        <AlertIcon />
                        <Stack alignItems={"start"}>
                          <AlertTitle>
                            {t("notif_phase_completion", "Complétion de phase")}
                          </AlertTitle>
                          <AlertDescription>
                            Il vous reste {remainingPhasesCount} phase
                            {remainingPhasesCount > 1 ? "s" : ""} à compléter ce
                            mois ci.
                          </AlertDescription>
                          <Button
                            variant={"link"}
                            gap={1}
                            onClick={() => {
                              setProjectType([
                                ProjectTypeEnum.Forfait,
                                ProjectTypeEnum.Atf,
                              ]);

                              navigate("/dashboard/actions");
                            }}
                          >
                            <Text
                              fontWeight={"bold"}
                              alignItems={"center"}
                              color={"semantic.information.dark"}
                            >
                              {t(
                                "notif_completion_cta",
                                "Compléter mon avancement",
                              )}
                            </Text>
                            <Icon as={RiArrowRightLine} />
                          </Button>
                        </Stack>
                      </Flex>
                    </Alert>
                  )}
                  {allPhasesChecked && (
                    <Alert status={"info"}>
                      <Flex>
                        <AlertIcon />
                        <Stack alignItems={"start"}>
                          <AlertTitle>
                            {t(
                              "notif_phase_submission",
                              "Pensez à soumettre vos phases",
                            )}
                          </AlertTitle>
                          <AlertDescription>
                            {t(
                              "notif_completion_submission",
                              "L'avancement de vos phases est complet et il ne vous reste plus qu'à le soumettre.",
                            )}
                          </AlertDescription>
                          <Button
                            gap={1}
                            onClick={() => {
                              navigate("/dashboard/actions");
                            }}
                          >
                            <Text
                              fontWeight={"bold"}
                              alignItems={"center"}
                              color={"white"}
                            >
                              {t(
                                "notif_cta_submission",
                                "Soumettre mon avancement",
                              )}
                            </Text>
                          </Button>
                        </Stack>
                      </Flex>
                    </Alert>
                  )}
                  {remainingPhasesCount === 0 &&
                    billableOrders.length === 0 &&
                    !allPhasesChecked &&
                    litigiousCases.length === 0 &&
                    companyLitigiousCases.length === 0 &&
                    companyBillableOrders.length === 0 &&
                    tasks.length === 0 && (
                      <Text>
                        {t(
                          "notif_phase_no_notification",
                          "Vous n'avez pas de notification.",
                        )}
                      </Text>
                    )}
                </Flex>
              </Stack>
            </AccordionPanel>
          </Stack>
        </AccordionItem>
      </Accordion>
      <DownloadReportModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export const DownloadReportModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [{ data: reportsData }] = useQuery({
    query: GetReportsDocument,
  });
  const [loadingKey, setLoadingKey] = useState<number | null>();
  const [{ fetching: isLoadingUrl }, generateDownloadUrl] = useMutation(
    GenerateDownloadUrlDocument,
  );
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  const reportsSort =
    reportsData?.reports.sort((a, b) =>
      compareDesc(a.createdAt, b.createdAt),
    ) || [];

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent p={10}>
        <ModalCloseButton />

        <Heading>
          {t("notif_download_submission", "Télécharger mes soumissions")}
        </Heading>
        <ModalBody p={5}>
          <Flex direction="column" align={"start"}>
            {reportsSort.map((report, key) => (
              <Button
                py={2}
                key={report.id}
                variant={"link"}
                leftIcon={<HiDownload />}
                isLoading={isLoadingUrl && loadingKey === key}
                onClick={async () => {
                  if (!reportsData) return null;
                  setLoadingKey(key);
                  const { data } = await generateDownloadUrl({
                    fileName: report.id,
                  });

                  if (!data?.generateDownloadUrl) {
                    return null;
                  }
                  setLoadingKey(null);
                  await trackEvent("Download", "download-report", {
                    url: data.generateDownloadUrl,
                  });
                  window.open(data.generateDownloadUrl, "_target");
                }}
              >
                {format(report.createdAt, "dd/MM/yyyy")}
              </Button>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
