import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import { type IPartnerContactProject } from "types";
import { columns } from "./RoleProjectsTable.config";

export const RoleProjectsTableComponent: React.FC<{
  projects: IPartnerContactProject[];
}> = ({ projects }) => {
  const { getRowModel } = useReactTable({
    data: projects,
    columns,
    getCoreRowModel: getCoreRowModel(),
    autoResetAll: false,
    getRowId: (r) => r.id,
  });
  return (
    <Table>
      <Tbody>
        {getRowModel().rows.map((row) => (
          <Tr
            border="1px solid #e2e8f0"
            display="grid"
            cursor="pointer"
            title={row.original.name}
            id={row.id}
            key={row.id}
            width={"100%"}
            alignItems={"center"}
            gridTemplateRows={"1fr"}
            gridTemplateColumns={`${row
              ._getAllVisibleCells()
              .map((val) => `${val.column.columnDef.size ?? 1}fr`)
              .join(" ")}`}
          >
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id} w={"100%"} border={"none !important"}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
