import {
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  ElementTypeEnum,
  GetContactProjectsDocument,
  OrderStatusEnum,
  ProjectTypeEnum,
  SetCachedPhaseDocument,
  SetCachedPhasesDocument,
} from "graphql/__generated__/graphql";
import { useUpload } from "hooks";
import { useFilterActivePhases } from "hooks/useFilterActivePhases";
import { useTrackEvent } from "libs/tracking";
import { nanoidShort } from "modules/order/utils";
import React from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { rootPaths } from "stores";
import { useMutation, useQuery } from "urql";
import { CheckAttachmentModal } from "./check";
import { Form, type FormPhase } from "./form";
import { useTranslation } from "react-i18next";

export const EditPhaseModal: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as {
    id: string;
  };
  const location = useLocation();

  const from = location.state?.from || null;

  const [{ data, fetching, error }] = useQuery({
    query: GetContactProjectsDocument,
  });

  const projects = data?.projects ?? [];
  const phases = projects.flatMap((p) =>
    p.phases.map((ph) => ({ ...ph, project: p })),
  );

  const phase = phases.find((p) => p.id === id);

  const activePhases = useFilterActivePhases(phase?.project?.phases);
  const { trackEvent } = useTrackEvent();
  const { uploadFile } = useUpload();

  const [, savePhase] = useMutation(SetCachedPhaseDocument);
  const [, savePhases] = useMutation(SetCachedPhasesDocument);
  const { t } = useTranslation();

  const onClose = () => {
    navigate("..", { state: { from } });
  };
  const onSubmit = async (
    values: FormPhase,
    file?: File,
    fileTime?: File,
    isDirty?: boolean,
    isSpread?: boolean,
  ) => {
    if (!isDirty) {
      onClose();
      return;
    }

    const proofPath = file && (await uploadFile(file, ElementTypeEnum.Proof));
    const proofTimePath =
      fileTime && (await uploadFile(fileTime, ElementTypeEnum.Proof));
    const phase = {
      ...values,
      id,
      proofPath,
      proofTimePath,
      isValidated: true,
    };

    if (file && phase.commentRejection === null) {
      toast({
        title: t(
          "phase_edit_modal_proof_not_verified_title",
          "Votre PJ n'a pas été vérifiée",
        ),
        description: t(
          "phase_edit_modal_proof_not_verified_description",
          "Veuillez d'abord vérifier votre pj avant de soumettre la phase",
        ),
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      return;
    }

    await trackEvent("Save", "save-phase", phase);

    const { error, data } = await savePhase({ phase });

    if (isSpread && proofPath && activePhases) {
      await trackEvent("Submit", "propagation-pj");
      const proofPhases = activePhases.map(({ id }) => ({ id, proofPath }));
      await savePhases({ phases: proofPhases });
    }

    if (!error) {
      toast({
        title: t(
          "phase_edit_modal_save_success_title",
          "Changement enregistré.",
        ),
        status: "success",
      });
      const project = data?.setCachedPhase?.project;
      const orders = data?.setCachedPhase?.clientOrders || [];

      const billableOrders =
        orders.filter(
          ({ status }) =>
            status === OrderStatusEnum.Billable ||
            status === OrderStatusEnum.BillableYooz,
        ) || [];

      if (
        billableOrders.length &&
        project?.isPartialInvoice === false &&
        project.type === ProjectTypeEnum.Forfait
      ) {
        const providerId = billableOrders[0]?.providerOrders[0]?.id;
        const caseId = nanoidShort();

        navigate(
          `/dashboard/billable-projects/orders/${providerId}/${caseId}`,
          {
            /**
             in case of a cancelation of the billing process
             come back to action tab
            */
            state: { from: rootPaths.actions },
          },
        );
      } else {
        onClose();
      }
    }
  };

  if (error) {
    navigate("..");
  }

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onClose}
        isCentered={true}
        size="xl"
        returnFocusOnClose={false}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent p={2}>
          {fetching && (
            <Center h={500}>
              <Spinner />
            </Center>
          )}

          {phase && <Form phase={phase} onSubmit={onSubmit} />}
        </ModalContent>
      </Modal>
      <Routes>
        <Route path="check" element={<CheckAttachmentModal />} />
      </Routes>
    </>
  );
};
