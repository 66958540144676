import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Center,
  Flex,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { GenerateDownloadUrlDocument } from "graphql/__generated__/graphql";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "urql";

export const NonPdfDisplayAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert status="warning">
      <Flex>
        <AlertIcon />
        <Stack>
          <AlertTitle>
            {t(
              "order_pdf_view_non_pdf_title",
              "Impossible de lire le document",
            )}
          </AlertTitle>
          <AlertDescription display="block">
            {t(
              "order_pdf_view_non_pdf_description",
              "Le document fourni n'est pas au format PDF et ne peut être visualisé.",
            )}
          </AlertDescription>
        </Stack>
      </Flex>
    </Alert>
  );
};

export const PdfView: React.FC<{
  path?: string | null;
  base64?: string | null;
}> = ({ path, base64 }) => {
  const [{ error }, generateDownloadUrl] = useMutation(
    GenerateDownloadUrlDocument,
  );
  const [url, setUrl] = useState<string | null | undefined>(null);

  React.useEffect(() => {
    let isMount = true; // Indicate the mount state

    async function fetchUrl() {
      if (!path) {
        if (base64) {
          setUrl(base64);
        }

        return null;
      }

      const { data } = await generateDownloadUrl({ fileName: path });

      if (!isMount) return; // Exits if comp. is not mounted
      setUrl(data?.generateDownloadUrl);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchUrl();

    return () => {
      // Runs when component will unmount
      isMount = false;
    };
  }, [path, generateDownloadUrl, base64]);

  if (error) {
    return <NonPdfDisplayAlert />;
  }

  if (!url) {
    return (
      <AspectRatio ratio={3 / 4} maxW="100%">
        <Center>
          <Spinner />
        </Center>
      </AspectRatio>
    );
  }

  return (
    <AspectRatio ratio={3 / 4} maxW="100%">
      <iframe src={url} />
    </AspectRatio>
  );
};
