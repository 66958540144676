import { GetMeDocument, type Exact } from "graphql/__generated__/graphql";
import { useQuery, type UseQueryArgs } from "urql";

export const useMe = (
  options?: Omit<UseQueryArgs<Exact<Record<string, never>>, any>, "query">,
) => {
  const [{ data, fetching: isLoading, error }] = useQuery({
    query: GetMeDocument,
    ...options,
  });

  const me = data?.me;
  return { isLoading, error, me };
};
