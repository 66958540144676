import {
  Button,
  Center,
  Flex,
  Link as LinkUI,
  Spinner,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { GetContactProjectsDocument } from "graphql/__generated__/graphql";
import { useProjectFilter, type FilterType } from "hooks";
import { forEach, groupBy, keyBy, map, uniqBy } from "lodash-es";
import { PROJECT_STATUS_DICO } from "modules/project/utils";
import { useCallback } from "react";
import { FaPen } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TAB_NAME, useSelectedProjectsStore } from "stores";
import { type IPartnerContactProject } from "types";
import { useQuery } from "urql";
import { ProjectFilters } from "../../project/ProjectsTableWithFilters/ProjectFilters";
import { RoleProjectsModal } from "./RoleProjectsModal";
import { RoleProjectsTableComponent } from "./RoleProjectsTable.component";
import { SelectAllProjects } from "./SelectAllProjects";
import { useTranslation } from "react-i18next";

export const RoleProjectsTable = () => {
  const [{ data }] = useQuery({ query: GetContactProjectsDocument });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedProjects, clearSelectedProjects] = useSelectedProjectsStore(
    (s) => [s.getSelectedProjects(), s.clear],
  );
  const { t } = useTranslation();

  // Almost functionnal, less stateful : we filter throught the project list (we only keep a copy of
  // the filters in a state, and even that should probably go)

  // Also, getting the list of status should be done here, between the two filterings
  const { filteredProjects, statusOptions, setFilters, filters, clear } =
    useProjectFilter(
      data?.projects || [],
      TAB_NAME.roleProjects,
      PROJECT_STATUS_DICO,
    );
  // clear selection filter change (that could also be done inside the filter component, but I think it's bad practice)
  const onFilterChange = useCallback(
    (f: Partial<FilterType>) => {
      setFilters(f);
      clearSelectedProjects();
    },
    [setFilters, clearSelectedProjects],
  );

  if (!data) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const projectsByUniqContact = filteredProjects.reduce<
    Array<
      IPartnerContactProject & {
        contact: IPartnerContactProject["phases"][number]["partner"];
      }
    >
  >((acc, project) => {
    const contacts = uniqBy(
      map(project.phases, (p) => p.partner),
      (p) => p?.id,
    );

    forEach(contacts, (contact) => acc.push({ ...project, contact }));

    return acc;
  }, []);

  const projectsById = keyBy(filteredProjects, (p) => p.id);

  const projectsByUserUnsorted = groupBy(projectsByUniqContact, (t) =>
    t.contact ? `${t.contact.lastName} ${t.contact.firstName}` : "",
  );

  const projectsByUser = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    Object.entries(projectsByUserUnsorted).sort(),
  );

  return (
    <div>
      {/* This only manages the filters. you also give it the status' list */}
      <ProjectFilters
        setFilters={onFilterChange}
        status={statusOptions}
        filters={filters}
        clearFilters={clear}
      />

      {filteredProjects.length ? (
        <>
          <Flex align="center" mt={14}>
            <SelectAllProjects projects={filteredProjects} />
            <Text fontWeight={500}>
              {t("all_projects", "Tous les projets")}
            </Text>
          </Flex>
          {map(projectsByUser, (projects, key) => (
            <Flex key={key} mt={10} direction="column">
              <Flex align="center" mb={4}>
                <SelectAllProjects projects={projects} />
                <Flex fontWeight={500} align={"baseline"}>
                  {t("all_projects", "Tous les projets")}
                  {key ? (
                    <>
                      {" "}
                      {t("of", "de")}
                      <LinkUI
                        as={Link}
                        color="brand.blue.dark"
                        ml={1}
                        to={`contacts/${projects[0].contact?.id}`}
                      >
                        {key}
                      </LinkUI>
                    </>
                  ) : (
                    t("no_project_manager", "sans responsable projet")
                  )}
                  {projects[0].contact?.left && (
                    <Tag colorScheme={"red"} ml={2}>
                      {t("left_company", "A quitté la société")}
                    </Tag>
                  )}
                </Flex>
              </Flex>
              <RoleProjectsTableComponent projects={projects} />
            </Flex>
          ))}

          <Button
            size={"md"}
            pos="sticky"
            bottom={"20px"}
            left={"calc(50% - 211px)"}
            onClick={onOpen}
            leftIcon={<FaPen />}
            isDisabled={selectedProjects.length === 0}
          >
            {t(
              "change_project_manager",
              "Changer le responsable des projets sélectionnés",
            )}{" "}
            ({selectedProjects.length})
          </Button>
        </>
      ) : (
        <Flex py={10}>
          {t(
            "no_project_match_your_search",
            "Aucun projet ne correspond à la recherche.",
          )}
        </Flex>
      )}

      <RoleProjectsModal
        isOpen={isOpen}
        onClose={onClose}
        projects={projectsById}
        selectedProjects={selectedProjects}
        onSubmitted={() => clearSelectedProjects()}
      />
    </div>
  );
};
