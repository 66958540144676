import { Button, Flex, Icon, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type Row as TSRow,
} from "@tanstack/react-table";
import { TableRow } from "components";
import { mapValues } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useUIStore, type TAB_NAME } from "stores";

type TanStackTableProps<T extends Record<string, unknown>> = {
  columns: Array<ColumnDef<T>>;
  data: T[];
  tab: TAB_NAME;
  hiddenColumns?: string[];

  RowSubComponent: React.FC<{
    row: TSRow<T>;
    hiddenColumns?: string[];
  }>;
};

export const TanStackTable = <
  T extends { id: string; name: string; isValidated?: boolean },
>({
  columns,
  data,
  hiddenColumns,
  tab,
  RowSubComponent,
}: TanStackTableProps<T>) => {
  const [expandedRows, setExpandedRows, isExpanded] = useUIStore((s) => [
    s.expandedRows,
    s.setExpandedRows,
    s.isExpanded,
  ]);

  const expandedState = data.reduce<Record<string, boolean>>((acc, d) => {
    acc[d.id] = expandedRows[d.id] ?? isExpanded[tab];
    return acc;
  }, {});

  const { getRowModel, getIsAllRowsExpanded } = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded: expandedState,
    },
    manualExpanding: true,
    autoResetAll: false,
    getRowId: (r) => r.id,
  });

  const tableContainerRef = React.useRef<HTMLTableElement>(null);
  const { t } = useTranslation();

  const { rows } = getRowModel();

  if (rows.length < 1) {
    return <div>{t("no-element", "Aucun élément présent.")}</div>;
  }

  return (
    <Flex direction={"column"} gap={1} alignItems={"start"}>
      <Button
        variant={"ghost"}
        borderRadius={"md"}
        bg="white"
        _hover={{
          bg: "white",
        }}
        leftIcon={
          <Icon as={getIsAllRowsExpanded() ? HiChevronUp : HiChevronDown} />
        }
        data-test={`expand-button`}
        onClick={() =>
          getIsAllRowsExpanded()
            ? setExpandedRows(mapValues(expandedState, () => false))
            : setExpandedRows(mapValues(expandedState, () => true))
        }
      >
        {getIsAllRowsExpanded()
          ? t("collapse-all", "Tout plier")
          : t("expand-all", "Tout déplier")}
        ({data.length})
      </Button>

      <Table ref={tableContainerRef}>
        <Tbody>
          {rows.map((row) => {
            return (
              // Use a React.Fragment here so the table markup is still valid
              <React.Fragment key={`fragment-${tab}#${row.id}`}>
                <TableRow
                  data-test={`expand-row-${tab}#${row.id}`}
                  cursor="pointer"
                  onClick={() => {
                    setExpandedRows({
                      [row.id]: !row.getIsExpanded(),
                    });
                  }}
                  tabIndex={0}
                  aria-label="Expand row"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setExpandedRows({
                        [row.id]: !row.getIsExpanded(),
                      });
                    }
                  }}
                  // title={row.original.name}
                  id={`${tab}#${row.id}`}
                  width={"100%"}
                  display="grid"
                  alignItems={"center"}
                  gridTemplateRows={"1fr"}
                  gridTemplateColumns={`${row
                    ._getAllVisibleCells()
                    .map((val) => `${val.column.columnDef.size ?? 1}fr`)
                    .join(" ")}`}
                  borderBottomRightRadius={row.getIsExpanded() ? "none" : "xl"}
                  borderBottomLeftRadius={row.getIsExpanded() ? "none" : "xl"}
                  mb={row.getIsExpanded() ? 0 : 10}
                  fontSize={"sm"}
                  bg={
                    row.original.isValidated
                      ? "neutral.grey.200"
                      : "neutral.grey.100"
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td border="none" px={2} key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </TableRow>
                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                {row.getIsExpanded() ? (
                  <Tr key={`tr-${tab}-${row.id}`}>
                    <Td p={0} border="none">
                      {
                        <RowSubComponent
                          row={row}
                          hiddenColumns={hiddenColumns}
                        />
                      }
                    </Td>
                  </Tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default TanStackTable;
