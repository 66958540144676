import { AspectRatio } from "@chakra-ui/react";
import React from "react";

const getVideoId = (link: string) =>
  link
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://www.youtube.com/embed/", "")
    .replace("https://youtu.be/", "");

export const YoutubePlayer: React.FC<{ link: string }> = ({ link }) => (
  <AspectRatio width="40%" ratio={4 / 3}>
    <iframe
      src={`https://www.youtube.com/embed/${getVideoId(link)}`}
      allowFullScreen
    />
  </AspectRatio>
);
