import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroupCustom } from "components/RadioGroupCustom";
import { BOOLEAN_LABEL } from "constants/labels";
import { BooleanEnum } from "graphql/__generated__/graphql";
import { map, pickBy } from "lodash-es";
import React from "react";
import { useForm } from "react-hook-form";
import {
  type CancelationTaskInputType,
  mapCancelationTaskEnumToLabel,
} from "./TaskModal.constants";
import { cancelationTaskInputSchema } from "./utils";
import { useTranslation } from "react-i18next";

export const CancelationTaskModalForm: React.FC<{
  onSubmit: (commentRejection: string) => Promise<void>;
  isLoadingSubmit: boolean;
}> = ({ onSubmit, isLoadingSubmit }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CancelationTaskInputType>({
    resolver: yupResolver(cancelationTaskInputSchema),
    mode: "onBlur",
  });
  const { t } = useTranslation();

  const validateSchema = async (values: CancelationTaskInputType) => {
    await cancelationTaskInputSchema.validate(values);

    const falseValues = pickBy(values, (val) => val === BooleanEnum.False);

    const commentRejection = map(
      falseValues,
      (_, key: keyof typeof mapCancelationTaskEnumToLabel) =>
        mapCancelationTaskEnumToLabel[key] || "",
    ).join("\n");

    await onSubmit(commentRejection);
  };

  const hasFalse = Object.values(watch()).some(
    (val) => val === BooleanEnum.False,
  );

  return (
    <form onSubmit={handleSubmit(validateSchema)}>
      <Flex direction={"column"} alignItems={"start"} gap={3}>
        <FormControl size="sm" isInvalid={!!errors.isFromClient}>
          <FormLabel>
            {t(
              "attachment_mentions_cancelation",
              "La pièce jointe mentionne l'annulation par le bénéficiaire",
            )}
          </FormLabel>
          <RadioGroupCustom
            direction="row"
            control={control}
            name={"isFromClient"}
            options={BOOLEAN_LABEL}
          />
          <FormErrorMessage>{errors.isFromClient?.message}</FormErrorMessage>
        </FormControl>

        <FormControl size="sm" isInvalid={!!errors.isProjectMentionned}>
          <FormLabel>
            {t(
              "attachment_concern_project_or_service_order_canceled",
              "La pièce jointe concerne le projet ou l'ordre de service annulé",
            )}
          </FormLabel>
          <RadioGroupCustom
            direction="row"
            control={control}
            name={"isProjectMentionned"}
            options={BOOLEAN_LABEL}
          />
          <FormErrorMessage>
            {errors.isProjectMentionned?.message}
          </FormErrorMessage>
        </FormControl>
        {hasFalse && (
          <Alert status="warning">
            <AlertIcon />
            <AlertDescription display="block">
              {t(
                "warning_one_condition_is_no",
                "Attention, une des conditions est à 'Non', votre justificatif sera possiblement rejeté.",
              )}
            </AlertDescription>
          </Alert>
        )}
        <Flex width="100%" justifyContent="center" pt={8}>
          <Button
            size="md"
            type={"submit"}
            isLoading={isLoadingSubmit}
            textAlign="center"
          >
            {t("validate", "Valider")}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
