import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput } from "components";
import { signIn } from "libs/auth";
import { useTrackEvent } from "libs/tracking";
import yup from "libs/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "stores/auth";
import { type LogInInput } from "types";

const schema = yup
  .object()
  .shape({
    email: yup.string().trim().email().required(),
    password: yup.string().trim().required(),
  })
  .required();

export const LoginForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LogInInput>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  const { trackEvent } = useTrackEvent();
  const setIsLoggedIn = useAuthStore((s) => s.setIsLoggedIn);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const from = state?.from;

  const onSubmit = async (values: LogInInput) => {
    await schema.validate(values);
    await trackEvent("Auth", "login", { email: values.email });

    try {
      localStorage.clear();

      await signIn(values);

      setIsLoggedIn(true);

      if (from === "login") {
        navigate("/dashboard/main");
      }

      navigate(from || "/dashboard/main");
    } catch (e) {
      const error = new Error(e as string);
      if (
        error.message ===
        "UserAlreadyAuthenticatedException: There is already a signed in user."
      ) {
        setIsLoggedIn(true);

        return navigate("/dashboard/main");
      }
      toast({
        title: t("auth_login_error", "Oups, une erreur est survenue..."),
        description: (
          <>
            {t(
              "auth_login_incorrect_credentials",
              "Email/Mot de passe incorrect. Merci de réessayer ou de vous rapprocher de la cellule projet. (via le bouton Intercom en bas à droite).",
            )}
          </>
        ),
        status: "error",
        duration: 9000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        size="sm"
        isInvalid={!!errors.email}
        isRequired
        mb={7}
        mt={10}
      >
        <FormLabel htmlFor="email">
          {t("auth_login_email", "Votre email")}
        </FormLabel>
        <Input
          placeholder="john@doe.com"
          {...register("email")}
          data-test="input-email"
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.password} isRequired mb={7}>
        <FormLabel htmlFor="password">
          {t("auth_login_password", "Votre mot de passe")}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <PasswordInput
              variant="filled"
              data-test="input-password"
              {...field}
            />
          )}
          name="password"
          control={control}
          defaultValue=""
        />

        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <Flex justify="center">
        <Button
          data-test="login-button"
          size={"md"}
          isLoading={isSubmitting}
          type={"submit"}
        >
          {t("auth_login_sign_in", "Se connecter")}
        </Button>
      </Flex>
    </form>
  );
};
