import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import {
  UpdateContactLeftCompanyDocument,
  type GetContactQuery,
} from "graphql/__generated__/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "urql";

export const SwitchLeftCompany: React.FC<{
  contact: GetContactQuery["contact"];
}> = ({ contact }) => {
  const [{ fetching }, updateContact] = useMutation(
    UpdateContactLeftCompanyDocument,
  );
  const { t } = useTranslation();
  if (!contact) return null;

  return (
    <FormControl display="flex" alignItems="center" width="inherit" mx={4}>
      <FormLabel htmlFor="left-company" m={0}>
        {t(
          "is_person_leave_company",
          "Est-ce que la personne a quitté la société ?",
        )}
      </FormLabel>
      <Switch
        isDisabled={fetching}
        ml={2}
        id="left-company"
        onChange={async (e) => {
          await updateContact({ id: contact.id, left: e.target.checked });
        }}
        isChecked={contact.left}
      />
    </FormControl>
  );
};
