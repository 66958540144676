import { Icon, Tooltip as TooltipChakra } from "@chakra-ui/react";
import React from "react";
import { AiFillWarning } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";

export const Tooltip: React.FC<{
  label: React.ReactNode;
  children?: React.ReactNode;
}> = ({ label, children }) => (
  <TooltipChakra label={label}>
    <span style={{ position: "relative" }}>
      {children}
      <Icon
        as={BsFillInfoCircleFill}
        color="neutral.grey.300"
        pos="absolute"
        top={0}
        right={-8}
      />
    </span>
  </TooltipChakra>
);

export const TooltipError: React.FC<{
  label?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ label = "L'ADV n'a pas validé ce champ", children }) => (
  <TooltipChakra label={label}>
    <span>
      {children}
      <Icon
        mx={2}
        mb={-0.5}
        as={AiFillWarning}
        color="brand.red.main"
        fontSize="lg"
      />
    </span>
  </TooltipChakra>
);
