import { Button, Icon, Link, Flex } from "@chakra-ui/react";
import { useTrackEvent } from "libs/tracking";
import { NavLink, useLocation } from "react-router-dom";

export const NavBarLink: React.FC<{
  to?: string;
  icon: React.FC;
  activePath?: string[];
  children?: React.ReactNode;
  href?: string;
  dataTest?: string;
}> = ({ children, to, icon: IconName, activePath, href, dataTest }) => {
  const { pathname } = useLocation();
  const { trackEvent } = useTrackEvent();

  const active =
    !!activePath &&
    activePath.filter((path) => pathname.includes(path)).length > 0;

  if (href)
    return (
      <Flex gap={2}>
        <Icon
          as={IconName}
          display={["none", "none", "inherit"]}
          color={active ? "brand.blue.main" : "neutral.grey.500"}
          fontSize={"xl"}
          sx={{ ".active &": { color: "brand.blue.main" } }}
        />
        <Link
          variant={"link"}
          target="_blank"
          href={href}
          data-test={dataTest}
          color={active ? "brand.blue.main" : "neutral.grey.800"}
          _activeLink={{ color: "brand.blue.main" }}
        >
          {children}
        </Link>
      </Flex>
    );

  return (
    <Button
      as={NavLink}
      to={to as string}
      onClick={async () => {
        await trackEvent("Navigate", "navigate-to", {
          to,
        });
      }}
      data-test={dataTest}
      variant={"link"}
      leftIcon={
        <Icon
          as={IconName}
          display={["none", "none", "inherit"]}
          color={active ? "brand.blue.main" : "neutral.grey.500"}
          fontSize={"xl"}
          sx={{ ".active &": { color: "brand.blue.main" } }}
        />
      }
      color={active ? "brand.blue.main" : "neutral.grey.800"}
      _activeLink={{ color: "brand.blue.main" }}
    >
      {children}
    </Button>
  );
};
