import { Tag } from "@chakra-ui/react";
import { type Invoice } from "graphql/__generated__/graphql";
import { statusInvoiceDico, statusInvoiceToColor } from "../utils";

export const InvoiceStatus: React.FC<{
  status: Invoice["status"];
}> = ({ status }) => {
  if (!status) {
    return null;
  }

  return (
    <Tag colorScheme={statusInvoiceToColor[status]} isTruncated>
      {statusInvoiceDico[status]}
    </Tag>
  );
};
