import {
  Center,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
} from "@chakra-ui/react";
import { BetaFeature, WithRoles } from "components";

import { MailTo } from "components/MailTo";
import {
  GetPartnerSkillsDocument,
  GetSkillsDocument,
  RolesEnum,
} from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { debounce, groupBy } from "lodash-es";
import React, { useState } from "react";
import { useQuery } from "urql";
import { generateExpertiseMailTo } from "./utils";

export const SkillContainer: React.FC<{
  label: string;
  skills?: Array<{ id: string; skillName: string } | null>;
}> = ({ label, skills }) => (
  <Stack
    boxSizing={"inherit"}
    h={"400px"}
    border={"1px"}
    borderColor={"neutral.grey.200"}
    rounded={"xl"}
    p={5}
  >
    <Heading size={"sm"}>
      {label} ({skills?.length ?? 0})
    </Heading>

    <Flex wrap={"wrap"} py={5} gap={3} overflowY={"auto"}>
      {skills ? (
        skills?.map((skill) => (
          <Tag rounded={"xl"} key={skill?.id}>
            {skill?.skillName}
          </Tag>
        ))
      ) : (
        <Center>Vous n'avez pas d'expertise dans cette section</Center>
      )}
    </Flex>
  </Stack>
);

export const Skills = () => {
  const { me } = useMe();

  const [query, setQuery] = useState("");
  const [{ data: partnerSkills, fetching: isPartnerSkillsFetching }] = useQuery(
    {
      query: GetPartnerSkillsDocument,
    },
  );
  const [{ data: skills, fetching: isSkillsFetching }] = useQuery({
    query: GetSkillsDocument,
  });
  const [filteredSkills, setFilterSkills] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const searchSkills = (query: string) => {
    const cleanedQuery = query
      .trim()
      ?.toLocaleLowerCase()
      .replace(/[^a-zA-Z ]/g, "")
      .split(" ")
      .filter((str) => str !== " ");
    setQuery(query);
    const filteredSkilledByQuery =
      skills?.skills.filter((skill) =>
        cleanedQuery?.some((str) => skill.name.toLowerCase().includes(str)),
      ) || [];

    setFilterSkills(filteredSkilledByQuery);
  };

  if (!me) return null;

  const skillsByLevel = groupBy(
    partnerSkills?.partnerSkills,
    ({ skillLevel }) => {
      if (skillLevel === 1) return "entryLevelSkills";
      if (skillLevel === 2) return "confirmedSkills";
      if (skillLevel === 3) return "expertSkills";
      return "purePlayerSkills";
    },
  );

  const { mailto, body } = generateExpertiseMailTo(me);
  const isLoading = isPartnerSkillsFetching || isSkillsFetching;

  if (isLoading)
    return (
      <Center flex={1}>
        <Spinner />
      </Center>
    );
  return (
    <Stack gap={5} w="100%">
      {/* NOT FACTURATION */}
      <BetaFeature>
        <WithRoles
          roles={[RolesEnum.ProviderReadOnly, RolesEnum.ProviderExecutive]}
        >
          <Input
            placeholder={"Saisissez une expertise à ajouter"}
            onChange={debounce((e) => {
              searchSkills(e.target.value);
            }, 500)}
          />

          {isSkillsFetching ? (
            <Spinner />
          ) : (
            <Flex wrap={"wrap"} gap={2}>
              {query !== "" &&
                filteredSkills.map((skill) => (
                  <Tag rounded={"xl"} key={skill.id}>
                    {skill?.name}
                  </Tag>
                ))}
            </Flex>
          )}
        </WithRoles>
      </BetaFeature>
      <SimpleGrid spacing={10} columns={4}>
        <SkillContainer
          label={"Pure player"}
          skills={skillsByLevel.purePlayerSkills}
        />

        <SkillContainer
          label={"Expertises fortes"}
          skills={skillsByLevel.expertSkills}
        />

        <SkillContainer
          label={"Expertises confirmées"}
          skills={skillsByLevel.confirmedSkills}
        />

        <SkillContainer
          label={"Expertises annexes"}
          skills={skillsByLevel.entryLevelSkills}
        />
      </SimpleGrid>

      <Flex>
        <MailTo
          mailto={mailto}
          body={body}
          label="Vous souhaitez ajouter ou modifier une expertise?"
        />
      </Flex>
    </Stack>
  );
};
