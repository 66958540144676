import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DownloadButton } from "components/DownloadButton";
import { format } from "date-fns";
import {
  FileTypeEnum,
  InvoicePaymentMethodEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useProjectFilter } from "hooks";
import { keyBy } from "lodash-es";
import { ProjectFilters } from "modules/project/ProjectsTableWithFilters/ProjectFilters";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEye } from "react-icons/fi";
import { HiDownload } from "react-icons/hi";
import { IoMdMore } from "react-icons/io";
import { IPartnerContactProject } from "types";
import { type IInvoice } from "types/invoice";
import { toEuro } from "utils/format";
import { InvoiceStatus } from "../InvoiceStatus";
import { statusInvoiceDico } from "../utils";

const InvoicesTable: React.FC<{
  invoices: IInvoice[];
  projectId: IPartnerContactProject["id"];
  projectType: IPartnerContactProject["type"];
  hasFilter?: boolean;
}> = ({ invoices, projectId, projectType, hasFilter = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const {
    filteredProjects: filteredInvoices,
    statusOptions,
    setFilters,
    filters,
    clear,
    // @ts-expect-error
  } = useProjectFilter(invoices, `project-${projectId}`, statusInvoiceDico);
  if (!filteredInvoices) {
    return null;
  }

  const [currentInvoice, setCurrentInvoice] = useState<string | null>(null);

  const filteredInvoicesWithProject = filteredInvoices.map((invoice) => ({
    ...invoice,
    projectType,
  }));

  const filteredInvoicesWithProjectById = keyBy(
    filteredInvoicesWithProject,
    (i) => i.id,
  );

  const invoiceCurrent =
    currentInvoice && filteredInvoicesWithProjectById[currentInvoice];

  if (!invoices.length) return <Text>Aucune facture</Text>;

  return (
    <>
      {hasFilter && (
        <ProjectFilters
          status={statusOptions}
          setFilters={setFilters}
          filters={filters}
          placeholder={t(
            "project_details_search_client_invoice_bdc",
            "Chercher un nom de client, un numéro de facture, de BDC ...",
          )}
          clearFilters={clear}
        />
      )}
      <Box>
        {filteredInvoicesWithProject.length === 0 && (
          <Text>
            {t(
              "project_details_no_invoice",
              "Aucune facture ne correspond à votre recherche",
            )}
          </Text>
        )}
        {filteredInvoicesWithProject.map(
          ({
            id,
            name,
            echeanceDate,
            status,
            montant,
            refInops,
            paymentMethod,
          }) => (
            <Flex
              key={id}
              p={"16px"}
              justify={"space-between"}
              borderBottom="1px solid #E2E8F0"
              h="80px"
              align="center"
              _hover={{
                bg: "grey.50",
              }}
            >
              <Flex
                direction={"column"}
                fontSize={"14px"}
                fontWeight={500}
                flex="4"
              >
                <Text>{name ?? "-"}</Text>
                <Text color="grey.500">
                  {t("project_details_invoice_deadline", "Échéance au ")}
                  {echeanceDate ? format(echeanceDate, "dd/MM/yy") : "-"}
                </Text>
              </Flex>
              <Text
                color="grey.500"
                fontSize={"14px"}
                fontWeight={500}
                flex="2"
              >
                {refInops}
              </Text>

              <Box flex="2">
                <InvoiceStatus status={status} />
              </Box>

              {paymentMethod === InvoicePaymentMethodEnum.Direct && (
                <Box flex="3">
                  <Tag colorScheme={"orange-light"}>
                    Paiement à la charge du client
                  </Tag>
                </Box>
              )}

              <Text flex="2"> {toEuro(montant)}</Text>
              <Menu>
                <MenuButton
                  flex="1"
                  as={IconButton}
                  aria-label="Options"
                  border="none"
                  _hover={{
                    bg: "grey.100",
                  }}
                  icon={
                    <Icon as={IoMdMore} fontSize={"20px"} color="blue.600" />
                  }
                  variant="outline"
                />
                <MenuList>
                  <MenuItem
                    icon={
                      <Icon as={FiEye} fontSize={"20px"} color="blue.600" />
                    }
                    onClick={() => {
                      setCurrentInvoice(id);
                      onOpen();
                    }}
                  >
                    <Link fontSize={"14px"}>
                      {t(
                        "project_details_invoice_view_details",
                        "Voir les détails",
                      )}
                    </Link>
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Icon
                        as={HiDownload}
                        fontSize={"20px"}
                        color="blue.600"
                        mr="6px"
                      />
                    }
                  >
                    <DownloadButton
                      hasIcon={false}
                      linkedEntityId={id}
                      typeDocument={FileTypeEnum.PartnerInvoice}
                    >
                      {t("project_details_invoice_download", "Télécharger")}
                    </DownloadButton>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ),
        )}

        {invoiceCurrent && (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            preserveScrollBarGap
          >
            <ModalOverlay />
            <ModalContent minWidth="fit-content" height="fit-content">
              <ModalHeader fontSize={"20px"} fontWeight={600}>
                {t("project_details_invoice", "Facture")} n°{" "}
                {invoiceCurrent.name ?? "-"}
              </ModalHeader>

              <ModalBody>
                <Flex direction={"column"} gap="24px">
                  <Flex
                    bg="grey.50"
                    borderRadius={"10px"}
                    p="16px"
                    gap="20px"
                    justify={"space-between"}
                  >
                    <Flex direction={"column"} minW="300px" gap="20px">
                      <Flex direction={"column"}>
                        <Text>
                          {t("project_details_invoice_ref", "Référence Inops")}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.refInops}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text>
                          {t(
                            "project_details_invoice_provider_orders",
                            "N° commande partenaire(s)",
                          )}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.providerOrders
                            .map((op) => op?.name)
                            .join("\n")}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text>
                          {t(
                            "project_details_invoice_beneficiary_order",
                            "N° commande bénéficiaire",
                          )}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.ref}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex direction={"column"} minW="300px" gap="20px">
                      <Flex direction={"column"}>
                        <Text>
                          {t(
                            "project_details_invoice_submission_date",
                            "Date de dépôt",
                          )}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.submitDate
                            ? format(invoiceCurrent.submitDate, "dd/MM/yy")
                            : "-"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text>
                          {t(
                            "project_details_invoice_validation_date",
                            "Date de validation",
                          )}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.validatedDate
                            ? format(invoiceCurrent.validatedDate, "dd/MM/yy")
                            : "-"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text>
                          {t(
                            "project_details_invoice_deadline",
                            "Date d’échéance",
                          )}
                        </Text>
                        <Text color="grey.500" fontWeight={500}>
                          {invoiceCurrent.echeanceDate
                            ? format(invoiceCurrent.echeanceDate, "dd/MM/yy")
                            : "-"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    bg="green.50"
                    borderRadius={"10px"}
                    p="16px"
                    gap="20px"
                    justify={"space-between"}
                  >
                    <Flex direction={"column"} minW="300px" gap="20px">
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_status", "Statut")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.status &&
                            statusInvoiceDico[invoiceCurrent.status]}
                        </Text>
                      </Flex>
                      {invoiceCurrent.paymentMethod ===
                        InvoicePaymentMethodEnum.Direct && (
                        <Flex direction={"column"}>
                          <Text color="green.950">
                            Paiement à la charge du client
                          </Text>
                          <Text color="green.800" fontWeight={500}>
                            Oui
                          </Text>
                        </Flex>
                      )}
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_fastcash", "Fastcash")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.fastCash ? "Oui" : "Non"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_payment_date", "Payé le")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.paymentDate
                            ? format(invoiceCurrent.paymentDate, "dd/MM/yy")
                            : "-"}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex direction={"column"} minW="300px" gap="20px">
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_bank", "Banque")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.bankName ?? "-"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_iban", "IBAN")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.iban ?? "-"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text color="green.950">
                          {t("project_details_invoice_bic", "BIC")}
                        </Text>
                        <Text color="green.800" fontWeight={500}>
                          {invoiceCurrent.bic ?? "-"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    bg="blue.100"
                    borderRadius={"10px"}
                    p="16px"
                    gap="20px"
                    justify={"space-between"}
                    color="blue.700"
                    direction={"column"}
                  >
                    <Flex justify={"space-between"} flex="1">
                      <Text color="blue.700">
                        {t("project_details_invoice_amount", "Montant")}
                      </Text>
                      <Text color="blue.700">
                        {toEuro(invoiceCurrent.montant)}
                      </Text>
                    </Flex>
                    {(projectType === ProjectTypeEnum.At ||
                      projectType === ProjectTypeEnum.Atf) && (
                      <Flex justify={"space-between"} flex="1">
                        <Text color="blue.700" fontWeight={500}>
                          {t("project_details_invoice_soit", "Soit")}
                        </Text>
                        <Text color="blue.700" fontWeight={500}>
                          {invoiceCurrent.quantity}j facturés
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button variant="link" mr={"40px"} onClick={onClose}>
                  {t("project_details_close", "Fermer")}
                </Button>
                <DownloadButton
                  variant={"solid"}
                  linkedEntityId={invoiceCurrent.id}
                  typeDocument={FileTypeEnum.PartnerInvoice}
                >
                  {t("project_details_download_invoice", "Télécharger (PDF)")}
                </DownloadButton>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Box>
    </>
  );
};

export default InvoicesTable;
