import { type Costs } from "graphql/__generated__/graphql";
import { sumBy } from "lodash-es";

export const getCost = (projectByStatus: Array<{ costs: Costs }>) => {
  if (!projectByStatus) {
    return 0;
  }
  const costs = projectByStatus.map(({ costs }) => costs);
  return sumBy(costs, (c) => c.total);
};
