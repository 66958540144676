import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip as TooltipChakra,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import {
  FileTypeEnum,
  GetContactProjectsDocument,
  GetProjectDocument,
  InvoiceTypeEnum,
  OrderStatusEnum,
  PhaseStatusEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useMe, useNavigateToBillable } from "hooks";
import { useDownloadProjectDetailsPptx } from "hooks/useDownloadProjectDetailsPptx";
import { findKey, keyBy, map, sumBy, uniqBy } from "lodash-es";
import InvoicesTable from "modules/invoice/InvoicesTable";
import { ProjectOrderView } from "modules/project/ProjectOrderView";
import {
  PROJECT_STATUS_COLOR,
  PROJECT_STATUS_DICO,
} from "modules/project/utils";

import { Calendar, Euro } from "assets";
import { ContactProject } from "components/Contact";
import { NavigationBar } from "components/NavigationBar";
import { PhaseAttachments } from "modules/phase/PhaseAttachment";
import {
  STATUS_COLOR as STATUS_COLOR_PHASE,
  STATUS_DICO,
} from "modules/phase/PhasesTable/PhasesTable.config";
import { RoleProjectsModal } from "modules/role/RoleProjectsTable/RoleProjectsModal";
import { useEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import { IoMdAdd, IoMdPerson } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import { formatDateText, toEuro } from "utils/format";
import { isOpportunity } from "utils/sf";
import { generateProjectDeliveryDateUpdateEmail } from "./MyCompany/utils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const TAB_NAMES = {
  contractuals: 0,
  phases: 1,
  invoices: 2,
  cra: 3,
  contacts: 4,
};

const ProjectDetailsPage = () => {
  const { navigateTobillable } = useNavigateToBillable();
  const { t } = useTranslation();

  const { me } = useMe();
  const navigate = useNavigate();

  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { projectId, tabName } = useParams() as {
    projectId: string;
    tabName: string;
  };

  const [{ data, error, fetching }] = useQuery({
    query: GetContactProjectsDocument,
  });

  const [{ data: dataProject, fetching: fetchingProject }] = useQuery({
    query: GetProjectDocument,
    variables: { id: projectId },
  });

  const { downloadPPT, isLoading } = useDownloadProjectDetailsPptx(projectId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (fetching) {
    return (
      <Center h="calc(100vh - 100px)">
        <Spinner />
      </Center>
    );
  }

  if (error) {
    navigate("..");
    return;
  }

  if (!data) {
    return null;
  }

  const projects = data?.projects || [];

  const project = projects.find((p) => p.id === projectId);
  const singleProject = dataProject?.project;

  if (!project) {
    navigate("..");
    return;
  }

  const billableOrder =
    project.clientOrders.filter((o) => o.status === OrderStatusEnum.Billable) ||
    [];
  const litigiousOrder =
    project.clientOrders.filter(
      (o) => o.status === OrderStatusEnum.Litigation,
    ) || [];

  const isOpport = isOpportunity(projectId);

  const from = location.state?.from || null;

  const { type } = project;
  const isAT = type === ProjectTypeEnum.At || type === ProjectTypeEnum.Atf;

  const providerOrders = uniqBy(
    project.clientOrders.flatMap((p) => p.providerOrders),
    (p) => p.id,
  );

  const filteredProviderOrders = providerOrders.filter(
    (o) => o.status !== OrderStatusEnum.Canceled,
  );

  const ordered = sumBy(filteredProviderOrders, (p) => p.orderAmountHT || 0);

  const projectById = keyBy([project], (p) => p.id);

  const clientOrders = project.clientOrders;

  const invoices = project.invoices;

  const costFactured = sumBy(
    invoices.filter((i) => i.status === InvoiceTypeEnum.Paid),
    (i) => i.montant || 0,
  );

  const costToBeFactured = sumBy(
    invoices.filter(
      (i) =>
        i.status !== InvoiceTypeEnum.Paid &&
        i.status !== InvoiceTypeEnum.Canceled,
    ),
    (i) => i.montant || 0,
  );

  const contacts = uniqBy(
    map(project.phases, (p) => p.partner),
    (p) => p?.id,
  );

  // TODO :
  const totalOrdered = sumBy(
    project.phases.filter(
      (p) =>
        p.status === PhaseStatusEnum.Active ||
        p.status === PhaseStatusEnum.Finished,
    ),
    (p) => p.timeSold || 0,
  );
  const totalBilled = sumBy(project.phases, (p) => p.timeBilled || 0);
  const totalSold = sumBy(project.phases, (p) => p.timeSold || 0);
  const totalDone = sumBy(project.phases, (p) => p.timeDone || 0);

  const cras =
    singleProject?.phases.flatMap(
      (p) =>
        p.attachments
          ?.filter((file) => file?.type === FileTypeEnum.Cra)
          .map((attachment) => ({
            ...attachment,
            phaseName: p.name,
            phaseId: p.id,
          })) ?? [],
    ) || [];

  const { mailto, body } = generateProjectDeliveryDateUpdateEmail({
    partnerName: me?.partnerName ?? "",
    userFullName: `${me?.firstName ?? ""} ${me?.lastName ?? ""}`,
    phases: project.phases
      .filter((p) => p.status === PhaseStatusEnum.Active)
      .map((p) => ({
        ...p,
        orderRefs: project.clientOrders
          .filter((co) => co.phases.map((phase) => phase.id).includes(p.id))
          .map((o) => o.reference ?? ""),
      })),
    to: project.clientContact?.email ?? "",
    projectName: project.name,
    projectRef: project.reference,
  });

  return (
    <Box
      w={"100%"}
      pb={"80px"}
      borderRadius={"10px"}
      minHeight="calc(100vh - 112px)"
    >
      <Container maxWidth="100%">
        <Flex py={"24px"} align="center">
          <NavigationBar projectId={projectId} />
          <Spacer />
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<HiChevronDown />}
              isLoading={isLoading}
            >
              {t("project_details_actions", "Actions")}
            </MenuButton>
            <MenuList>
              {!isOpport && (
                <>
                  <MenuItem
                    icon={<Icon as={IoMdAdd} />}
                    fontSize={"14px"}
                    color="blue.600"
                    fontWeight={500}
                    onClick={downloadPPT}
                  >
                    {t(
                      "project_details_generate_copil",
                      "Générer un template de comité de pilotage",
                    )}
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={IoMdAdd} />}
                    fontSize={"14px"}
                    color="blue.600"
                    fontWeight={500}
                    as={Link}
                    href={mailto}
                  >
                    {t(
                      "project_details_generate_email",
                      "Générer un email de mise à jour de planning",
                    )}
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={MdOutlineContentCopy} />}
                    fontSize={"14px"}
                    color="blue.600"
                    fontWeight={500}
                    onClick={() => navigator.clipboard.writeText(body)}
                  >
                    {t(
                      "project_details_generate_copy_email",
                      "Copier l'email de mise à jour de planning",
                    )}
                  </MenuItem>
                </>
              )}

              <MenuItem
                icon={<Icon as={IoMdPerson} />}
                fontSize={"14px"}
                color="blue.600"
                fontWeight={500}
                onClick={onOpen}
              >
                {t(
                  "project_details_edit_project_manager",
                  "Éditer le responsable du projet",
                )}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Container
          border="1px solid #e2e8f0"
          p={12}
          maxW={"100%"}
          borderRadius={"10px"}
        >
          <Tag colorScheme={PROJECT_STATUS_COLOR[project.status]}>
            {PROJECT_STATUS_DICO[project.status]}
          </Tag>
          {project.isMulti && (
            <Tag ml={4} colorScheme="grey" variant={"subtle"}>
              {t("project_details_multi", "Multi-Partenaires")}
            </Tag>
          )}
          <Flex
            justify={"start"}
            gap={"24px"}
            fontSize={"14px"}
            fontWeight={500}
            my={"32px"}
          >
            <Flex>
              <Euro fontSize={"40px"} />
              <Flex direction={"column"} ml="16px">
                <Text>{toEuro(project.costs.total)}</Text>
                <Text color="grey.500">
                  {t("project_details_cost_total", "Montant total")}
                </Text>
              </Flex>
              <Divider orientation="vertical" ml="24px" />
            </Flex>

            <Flex>
              <Calendar fontSize={"40px"} />
              <Flex direction={"column"} ml="16px">
                <Text>{formatDateText(project.delivery.start)}</Text>
                <Text color="grey.500">
                  {t(
                    "project_details_contractual_start_date",
                    "Date de début contractuelle",
                  )}
                </Text>
              </Flex>
              <Divider orientation="vertical" ml="24px" />
            </Flex>
            <Flex>
              <Calendar fontSize={"40px"} />
              <Flex direction="column" ml="16px">
                <Text>{formatDateText(project.delivery.contractual)}</Text>
                <Text color="grey.500">
                  {t(
                    "project_details_contractual_end_date",
                    "Échéance contractuelle",
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex my={"32px"} direction={"column"} gap="16px">
            {billableOrder?.length > 0 && (
              <Alert status={"warning"}>
                <Flex width={"100%"} justify={"space-between"} gap="24px">
                  <Flex>
                    <AlertIcon />

                    <AlertDescription>
                      {t(
                        "project_details_invoice_available",
                        "La facturation est disponible pour la commande n° ",
                      )}

                      {billableOrder.map((bo) => bo.reference).join(", ")}
                    </AlertDescription>
                  </Flex>
                  <Flex>
                    <Button
                      variant={"link"}
                      color={"semantic.warning.dark"}
                      onClick={() =>
                        navigateTobillable(project.reference, from)
                      }
                    >
                      {t(
                        "project_details_cta_invoice",
                        "Aller à la facturation",
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Alert>
            )}
            {litigiousOrder?.length > 0 && (
              <Alert status={"error"}>
                <Flex width={"100%"} justify={"space-between"} gap="24px">
                  <Flex>
                    <AlertIcon />
                    <AlertDescription>
                      {t(
                        "project_details_invoice_litigious",
                        "Le ou les commandes suivantes sont en litige : ",
                      )}
                      {litigiousOrder.map((bo) => bo.reference).join(", ")}
                    </AlertDescription>
                  </Flex>
                  <Flex>
                    <Button
                      variant={"link"}
                      color={"semantic.error.dark"}
                      onClick={() =>
                        navigateTobillable(project.reference, from)
                      }
                    >
                      {t(
                        "project_details_cta_invoice",
                        "Aller à la facturation",
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Alert>
            )}
          </Flex>
          <Accordion
            allowToggle
            border="1px solid #E2E8F0"
            padding={"16px 24px"}
            borderRadius={"10px"}
            mb="48px"
            defaultIndex={[0]}
          >
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    borderRadius={"10px"}
                    _hover={{ bg: "white" }}
                    p={0}
                  >
                    <Flex
                      borderRadius={"10px"}
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={600}
                      fontSize={"14px"}
                      align="center"
                    >
                      {t("project_details_infos", "Informations du projet")}
                    </Flex>
                    {isExpanded ? (
                      <Icon as={FiChevronUp} fontSize="20px" />
                    ) : (
                      <Icon as={FiChevronDown} fontSize="20px" />
                    )}
                  </AccordionButton>

                  <AccordionPanel p={0} mt={"16px"}>
                    <Flex
                      fontSize={"14px"}
                      direction={"column"}
                      gap="16px"
                      flexWrap={"wrap"}
                    >
                      <Flex justifyContent={"space-between"}>
                        <Text color="grey.500">
                          {t("project_details_infos_type", "Type")}
                        </Text>
                        <Text>{project.type}</Text>
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Text color="grey.500">
                          {t(
                            "project_details_infos_number",
                            "Numéro de projet",
                          )}
                        </Text>
                        <Text>{project.reference}</Text>
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Text color="grey.500">
                          {t("project_details_infos_name", "Nom du projet")}
                        </Text>
                        <Text>{project.name}</Text>
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Text color="grey.500">
                          {t(
                            "project_details_infos_beneficiaire",
                            "Bénéficiaire",
                          )}
                        </Text>
                        <Text>{project.account?.name}</Text>
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Text color="grey.500">
                          {t("project_details_infos_market", "Marché")}
                        </Text>
                        <Text>{project.market}</Text>
                      </Flex>
                    </Flex>
                    <Flex mt="32px" gap="24px">
                      <Flex
                        fontSize={14}
                        flexWrap={"wrap"}
                        direction={"column"}
                        gap="16px"
                        p={"32px"}
                        background={"grey.100"}
                        borderRadius={"10px"}
                        flex="1"
                      >
                        <Flex justify="space-between">
                          <Text color="grey.500">
                            {t(
                              "project_details_infos_cost_total",
                              "Montant total",
                            )}
                          </Text>
                          <Text>{toEuro(project.costs.total)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                          <Text color="grey.500">
                            {t(
                              "project_details_infos_cost_ordered",
                              "Montant commandé",
                            )}
                          </Text>
                          <Text>{toEuro(ordered) || "-"}</Text>
                        </Flex>
                        <Flex justify="space-between" hidden={isAT}>
                          <TooltipChakra
                            label={t(
                              "project_details_infos_cost_engaged_tooltip",
                              "Le montant engagé n’est pas le montant facturé, mais bien le produit des sommes des avancements par rapport au prix des phases.",
                            )}
                          >
                            <Text color="grey.500">
                              {t(
                                "project_details_infos_cost_engaged",
                                "Montant engagé ",
                              )}
                              <b>*</b>
                            </Text>
                          </TooltipChakra>
                          <Text>{toEuro(project.costs.engaged) || "-"}</Text>
                        </Flex>

                        <Flex justify="space-between">
                          <Text color="grey.500">
                            {t(
                              "project_details_infos_cost_billed",
                              "Montant facturé",
                            )}
                          </Text>
                          <Text>{toEuro(costFactured)}</Text>
                        </Flex>
                        <Flex justify="space-between">
                          <Text color="grey.500">
                            {t(
                              "project_details_infos_cost_to_be_factured",
                              "Montant en attente de facturation",
                            )}
                          </Text>
                          <Text>{toEuro(costToBeFactured)}</Text>
                        </Flex>
                      </Flex>
                      {isAT && (
                        <Flex
                          fontSize={14}
                          flexWrap={"wrap"}
                          direction={"column"}
                          gap="16px"
                          p={"32px"}
                          background={"grey.100"}
                          borderRadius={"10px"}
                          flex="1"
                        >
                          <Flex justify="space-between">
                            <Text color="grey.500">
                              {t(
                                "project_details_infos_day_sold",
                                "Jours vendus",
                              )}
                            </Text>
                            <Text>{totalSold}</Text>
                          </Flex>
                          <Flex justify="space-between">
                            <Text color="grey.500">
                              {t(
                                "project_details_infos_day_ordered",
                                "Jours commandés",
                              )}
                            </Text>
                            <Text>{totalOrdered}</Text>
                          </Flex>
                          <Flex justify="space-between" hidden={isAT}>
                            <Text color="grey.500">
                              {t(
                                "project_details_infos_day_done",
                                "Jours consommés",
                              )}
                            </Text>
                            <Text>{totalDone}</Text>
                          </Flex>
                          <Flex justify="space-between">
                            <Text color="grey.500">
                              {t(
                                "project_details_infos_day_billed",
                                "Jours facturés",
                              )}
                            </Text>
                            <Text>{totalBilled}</Text>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
          <Tabs
            width={"100%"}
            isLazy={true}
            // @ts-expect-error
            index={TAB_NAMES[tabName] || 0}
            onChange={(index) => {
              const tabNameKey = findKey(TAB_NAMES, (o) => o === index);

              if (tabNameKey) {
                navigate(`${tabName ? ".." : "."}/${tabNameKey}`, {
                  state: { from },
                });
              }
            }}
          >
            <TabList>
              <Tab>
                {t("project_details_title_contractuel", "Suivi contractuel")}
              </Tab>
              <Tab>{t("project_details_title_phase", "Phases")}</Tab>
              <Tab>{t("project_details_title_invoices", "Factures")}</Tab>
              {isAT && project.isCRAManaged && (
                <Tab>{t("project_details_title_cra", "CRA")}</Tab>
              )}
              <Tab>{t("project_details_title_contacts", "Contacts")}</Tab>
            </TabList>
            <TabPanels minH="300px">
              <TabPanel py={10} px={0}>
                <ProjectOrderView
                  projectOrders={clientOrders}
                  type={project.type}
                  isPvNotManagedByPartner={project.isPvNotManagedByPartner}
                />
              </TabPanel>
              <TabPanel py={10} px={0}>
                <Table>
                  <Thead bg="grey.200">
                    <Tr>
                      <Th>{t("project_details_table_phase", "Phase")}</Th>
                      {isAT && (
                        <>
                          <Th isNumeric>
                            {t("project_details_table_sold", "Vendu")}
                          </Th>
                          <Th isNumeric hidden={isAT}>
                            {t("project_details_table_done", "Consommé")}
                          </Th>
                          <Th isNumeric>
                            {t("project_details_table_billed", "Facturé")}
                          </Th>
                        </>
                      )}
                      <Th>{t("project_details_table_status", "Statut")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize={"14px"} fontWeight={500}>
                    {project.phases.map((phase, index) => (
                      <Tr key={index} py="16px">
                        <Td>
                          <Link
                            color="blue.600"
                            onClick={() => {
                              navigate(`/phases/${phase.id}`, {
                                state: { from },
                              });
                            }}
                          >
                            {phase.name} {!isAT && <>({phase.completion}%)</>}
                          </Link>
                        </Td>
                        {isAT && (
                          <>
                            <Td isNumeric>{phase.timeSold} j</Td>
                            <Td isNumeric hidden={isAT}>
                              {phase.timeDone} j
                            </Td>
                            <Td isNumeric>{phase.timeBilled} j</Td>
                          </>
                        )}
                        <Td>
                          <Tag
                            colorScheme={STATUS_COLOR_PHASE[phase.status]}
                            isTruncated
                          >
                            {STATUS_DICO[phase.status]}
                          </Tag>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TabPanel>
              <TabPanel py={10} px={0}>
                <InvoicesTable
                  invoices={invoices}
                  projectId={project.id}
                  projectType={project.type}
                  hasFilter
                />
              </TabPanel>
              {isAT && project.isCRAManaged && (
                <TabPanel py={10} px={0}>
                  <PhaseAttachments
                    attachments={cras}
                    fetching={fetchingProject}
                  />
                </TabPanel>
              )}
              <TabPanel py={10} px={0}>
                <Flex flexWrap={"wrap"} gap={"16px"}>
                  {contacts.map(
                    (contact) =>
                      contact && (
                        <ContactProject
                          key={contact.id}
                          contact={contact}
                          fetching={fetching}
                          title={t(
                            "project_details_contact_project_manager",
                            "Responsable du projet",
                          )}
                          EditButton={
                            <Button
                              leftIcon={<Icon as={IoMdPerson} />}
                              onClick={onOpen}
                              variant={"link"}
                            >
                              {t("project_details_contact_cta_edit", "Éditer")}
                            </Button>
                          }
                        />
                      ),
                  )}

                  <ContactProject
                    contact={singleProject?.ownerContact}
                    fetching={fetchingProject}
                    title={t(
                      "project_details_contact_owner_title",
                      "Inops Ingénieur d’affaire",
                    )}
                    description={t(
                      "project_details_contact_owner_description",
                      "L’Ingénieur d’Affaires est votre interlocuteur commercial, donc à contacter en priorité si vous avez détecté un foisonnement éventuel chez le bénéficiaire (prolongation de projet ou nouveau projet par exemple), ou pour toute autre question ayant attrait à la contractualisation d’un projet.",
                    )}
                  />
                  <ContactProject
                    contact={singleProject?.deliveryContact}
                    fetching={fetchingProject}
                    title={t(
                      "project_details_contact_delivery_title",
                      "Inops Delivery Manager",
                    )}
                    description={t(
                      "project_details_contact_delivery_description",
                      "Le Delivery Manager effectue le suivi contractuel du projet et est à contacter prioritairement pour tout problème rencontré sur la prestation actuelle ayant attrait à l'engagement pris au forfait sur le projet (prérequis, livrables, délais, qualité …). Si un de ces éléments est en défaut, de votre fait ou à cause du client, n’hésitez pas à le contacter pour définir ensemble un plan d’action permettant de limiter les risques sur le projet.",
                    )}
                  />
                  <ContactProject
                    contact={singleProject?.clientContact}
                    fetching={fetchingProject}
                    title={t("project_details_contact_client_title", "Client")}
                  />
                  <ContactProject
                    contact={{
                      email: "recla-partenaires@freelance.com",
                      firstName: "Équipe",
                      lastName: "ADV",
                      id: "adv",
                      fonction: "Adresse générique",
                    }}
                    title="ADV"
                    description={t(
                      "project_details_contact_adv_description",
                      "Utilisez cette adresse pour toute réclamation en lien avec les problématiques ADV (bons de commandes, factures, échéances de paiement …)",
                    )}
                  />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <RoleProjectsModal
            isOpen={isOpen}
            onClose={onClose}
            projects={projectById}
            selectedProjects={[project.id]}
            onSubmitted={() => {
              navigate("/dashboard/main");
            }}
          />
        </Container>
      </Container>
    </Box>
  );
};

export default ProjectDetailsPage;
