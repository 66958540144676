import { Text, Flex, Input, List, ListItem } from "@chakra-ui/react";
import { useCombobox } from "downshift";
import React, { type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

export const Combobox: React.FC<{
  placeholder?: string;
  onChange: (value?: string) => void;
  items: Array<{ value: string; label: string; subLabel?: string | ReactNode }>;
  inputWidth?: number;
  fallBackLabel?: string;
  fallBackOnClick?: () => void;
}> = ({
  placeholder,
  items,
  onChange,
  inputWidth,
  fallBackLabel,
  fallBackOnClick,
}) => {
  const [inputItems, setInputItems] = useState(items);
  const { t } = useTranslation();
  const { isOpen, getMenuProps, getInputProps, getItemProps, toggleMenu } =
    useCombobox({
      items: inputItems,
      itemToString: (i) => (i ? i.label : ""),
      onSelectedItemChange: (e) => {
        toggleMenu();
        onChange(e.selectedItem?.value);
      },
      onInputValueChange: ({ inputValue = "" }) => {
        setInputItems(
          items.filter((item) =>
            // input should be eather in label, sublabel or value
            `${item.label} ${item.subLabel?.toString()} ${item.value}`
              .toLowerCase()
              .includes(inputValue.toLowerCase()),
          ),
        );
      },
    });

  return (
    <Flex direction="column" position="relative">
      <Input
        onFocus={toggleMenu}
        placeholder={placeholder ?? t("search")}
        width={inputWidth}
        {...getInputProps()}
      />

      <List
        boxShadow="md"
        pt={2}
        borderRadius="md"
        overflowY="scroll"
        maxHeight={250}
        hidden={!isOpen}
        position="absolute"
        top={14}
        zIndex={10}
        width="100%"
        {...getMenuProps()}
      >
        {!!inputItems.length &&
          inputItems.map((item, index) => (
            <ListItem
              _selected={{ bg: "brand.blue.background" }}
              bg="white"
              px={4}
              py={2}
              cursor="pointer"
              fontSize="sm"
              fontWeight={500}
              borderBottom="1px solid"
              borderColor="neutral.grey.100"
              key={`${item.value}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.label}
              {item.subLabel &&
                (typeof item.subLabel === "string" ? (
                  <Text
                    fontSize="x-small"
                    color="neutral.grey.500"
                    title={item.subLabel}
                  >
                    {item.subLabel}
                  </Text>
                ) : (
                  <>{item.subLabel}</>
                ))}
            </ListItem>
          ))}
        {!inputItems.length && (
          <ListItem
            _hover={{ bg: "brand.blue.background" }}
            bg="white"
            px={4}
            py={2}
            cursor="pointer"
            fontSize="sm"
            fontWeight={500}
            borderBottom="1px solid"
            borderColor="neutral.grey.300"
            onClick={() => {
              toggleMenu();
              fallBackOnClick && fallBackOnClick();
            }}
          >
            <Text>
              {fallBackLabel ??
                t("no-matching-element", "Aucun élément correspondant...")}
            </Text>
          </ListItem>
        )}
      </List>
    </Flex>
  );
};
