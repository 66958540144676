import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Combobox, InputSearch, ProviderRoleBadge, Select } from "components";
import { NavBar } from "components/Navbar/NavBar";
import {
  GetAllUsersCampaignAdminDocument,
  type RolesEnum,
} from "graphql/__generated__/graphql";
import { useUserCampaignFilter, type IUserCampaignFilters } from "hooks";
import { compact, groupBy, map, orderBy, reverse, sortBy } from "lodash-es";
import { useQuery } from "urql";
import { toDecimal } from "utils/format";

const AdminFilters = ({
  setFilters,
  roles = [],
  campaign = [],
  partners = [],
  filters,
  placeholder,
}: IUserCampaignFilters) => {
  const partnerOptions = [
    { value: "ALL", label: "Tous les partenaires" },
    ...partners,
  ];
  const roleOptions: Array<{ value: RolesEnum | "ALL"; label: string }> = [
    { value: "ALL", label: "Tous les roles" },
    ...roles,
  ];
  const campaignOptions = [
    { value: "ALL", label: "Toutes les campagnes" },
    ...campaign,
  ];
  return (
    <Stack>
      <InputSearch
        value={filters.searchTerm}
        placeholder={placeholder}
        onChange={(searchTerm) => {
          setFilters({ searchTerm });
        }}
      />
      <Flex gap={4}>
        {roles.length > 1 && (
          <Box>
            <Select<RolesEnum | "ALL">
              value={filters.role}
              onChange={(role) => {
                setFilters({ ...filters, role });
              }}
              defaultValue={"ALL"}
              options={roleOptions}
            />
          </Box>
        )}
        {partners.length > 1 && (
          <Box>
            <Combobox
              onChange={(partner) => {
                setFilters({ ...filters, partner });
              }}
              placeholder="Partenaires ..."
              items={partnerOptions}
            />
          </Box>
        )}
        {campaign.length > 1 && (
          <Box>
            <Combobox
              onChange={(campaign) => {
                setFilters({ ...filters, campaign });
              }}
              placeholder="Campagnes ..."
              items={campaignOptions}
            />
          </Box>
        )}
      </Flex>
    </Stack>
  );
};

const AdminPage = () => {
  const [{ data, fetching }] = useQuery({
    query: GetAllUsersCampaignAdminDocument,
  });

  const {
    filteredUsers,
    partnerOption,
    roleOptions,
    campaignOptions,
    setFilters,
    filters,
  } = useUserCampaignFilter(data?.users ?? []);

  const usersByOpeningDate = groupBy(
    reverse(sortBy(filteredUsers, (u) => u.openingDate)),
    (p) => p.openingDate,
  );

  return (
    <Container maxW={"1280px"} centerContent>
      <NavBar />
      <Stack w="100%">
        <Flex py={12} direction="column" gap={10}>
          <Heading size={"2xl"}>Vue Administrateur</Heading>
          {!fetching && (
            <AdminFilters
              setFilters={setFilters}
              roles={roleOptions}
              partners={partnerOption}
              filters={filters}
              placeholder={"Saisissez un mot clé"}
              campaign={orderBy(campaignOptions, "value", "desc")}
            />
          )}
        </Flex>

        <Flex direction="column">
          {map(usersByOpeningDate, (users, index) => {
            const usersHasSubmit = users.filter((u) => u.hasSubmit);

            const usersCount = users.length;
            const usersSubmitCount = usersHasSubmit.length;
            const usersNoSubmitCount = usersCount - usersSubmitCount;
            return (
              <Flex key={index} direction={"column"} my={8}>
                <Heading size={"xl"} mb={5}>
                  {index}
                </Heading>
                <Text>
                  {usersSubmitCount} soumissions sur un total de {usersCount}{" "}
                  users. ({toDecimal((usersSubmitCount / usersCount) * 100)}%)
                </Text>
                <Text>{usersNoSubmitCount} users vont être relancé.</Text>
                <TableContainer mt={4} overflowY={"auto"} maxHeight={"500px"}>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Email</Th>
                        <Th>Company</Th>
                        <Th>FirstName</Th>
                        <Th>LastName</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortBy(users, (u) => u.companyName).map(
                        ({
                          id,
                          companyName,
                          firstName,
                          lastName,
                          hasSubmit,
                          cache,
                          roles,
                        }) => {
                          const submitColor = cache ? "orange.100" : "red.100";
                          const rolesFinal = compact(roles);

                          return (
                            <Tr
                              key={id}
                              bg={hasSubmit ? "green.100" : submitColor}
                            >
                              <Td>
                                <Flex dir={"row"} align={"baseline"} gap={1}>
                                  <Text>{id}</Text>
                                  <ProviderRoleBadge roles={rolesFinal} />
                                </Flex>
                              </Td>
                              <Td>{companyName}</Td>
                              <Td>{firstName}</Td>
                              <Td>{lastName}</Td>
                            </Tr>
                          );
                        },
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
            );
          })}
        </Flex>
      </Stack>
    </Container>
  );
};

export default AdminPage;
