import { ChakraProvider } from "@chakra-ui/react";
import { DevIndicator } from "components/DevIndicator";
import { initialize as initializeAuth } from "libs/auth";
import { UrqlMetaProvider } from "libs/urqlComponent";

import { boot } from "libs/tracking";
import { Pages } from "pages";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { isProd } from "utils/env";
import "./i18n";
import "./style.css";
import theme from "./theme/theme";

initializeAuth();
boot();

const App: React.FC = () => {
  return (
    <IntercomProvider appId={import.meta.env.VITE_APP_INTERCOM_ID} autoBoot>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <UrqlMetaProvider>
            {!isProd && <DevIndicator />}

            <Pages />
          </UrqlMetaProvider>
        </BrowserRouter>
      </ChakraProvider>
    </IntercomProvider>
  );
};

export default App;
