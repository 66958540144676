import { Box, Checkbox, Tag, Text } from "@chakra-ui/react";
import { type ColumnDef } from "@tanstack/react-table";
import { ProjectStatusEnum } from "graphql/__generated__/graphql";
import { PROJECT_STATUS_DICO } from "modules/project/utils";
import { useSelectedProjectsStore } from "stores";
import { type IPartnerContactProject } from "types";

export const STATUS_COLOR: Record<ProjectStatusEnum, string> = {
  [ProjectStatusEnum.Pending]: "white",
  [ProjectStatusEnum.Validated]: "white",
  [ProjectStatusEnum.Waiting]: "white",
  [ProjectStatusEnum.Active]: "blue",
  [ProjectStatusEnum.Finished]: "green",
  [ProjectStatusEnum.Canceled]: "grey",
  [ProjectStatusEnum.Unknown]: "grey",
  [ProjectStatusEnum.Executed]: "green",
};

export const columns: Array<ColumnDef<IPartnerContactProject>> = [
  {
    id: "selection",
    size: 0.5,
    cell: ({
      row: {
        original: { id },
      },
    }) => {
      const [setSelectedProject, isChecked] = useSelectedProjectsStore((s) => [
        s.setSelectedProjects,
        s.selectedProjects[id] || false,
      ]);

      return (
        <Checkbox
          size="lg"
          bg="white"
          onChange={(e) => {
            setSelectedProject({
              [id]: e.target.checked,
            });
          }}
          isChecked={isChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      );
    },
  },
  {
    header: "Statut",
    id: "status",
    size: 3,
    cell: ({
      row: {
        original: { status },
      },
    }) => (
      <Box as="span" noOfLines={1} isTruncated>
        <Tag colorScheme={STATUS_COLOR[status] || "grey"}>
          {PROJECT_STATUS_DICO[status]}
        </Tag>
      </Box>
    ),
  },
  {
    header: "Partner",
    id: "account",
    size: 3,
    cell: ({
      row: {
        original: { account },
      },
    }) => (
      <Text
        color="brand.blue.dark"
        fontWeight="semibold"
        noOfLines={1}
        fontSize={"sm"}
      >
        {account?.name}
      </Text>
    ),
  },
  {
    header: "Name",
    id: "name",
    size: 8,
    cell: ({
      row: {
        original: { name },
      },
    }) => (
      <Text fontWeight="semibold" noOfLines={1} fontSize={"sm"}>
        {name}
      </Text>
    ),
  },
  {
    header: "Reference",
    id: "reference",
    size: 3,
    cell: ({
      row: {
        original: { reference },
      },
    }) => (
      <Text
        color="neutral.grey.700"
        fontWeight="semibold"
        noOfLines={1}
        fontSize={"sm"}
      >
        {reference}
      </Text>
    ),
  },
];
