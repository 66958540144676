import { Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { MailTo } from "components/MailTo";
import { TanStackTable } from "components/ReactTable";
import { useMe, useProjectFilter } from "hooks";
import { groupBy, keyBy, map } from "lodash-es";
import { ProjectFilters } from "modules/project/ProjectsTableWithFilters/ProjectFilters";
import { PROJECT_COLUMNS } from "modules/project/ProjectsTableWithFilters/ProjectsTable.config";
import { type ProjectDataProps } from "pages/Home/HomePanel";
import { generateAddADVMailTo } from "pages/MyCompany/utils";
import { useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { TAB_NAME } from "stores";
import { type IPartnerContactProject } from "types";
import { statusOrderDico } from "../utils";
import { OrdersRowSubComponent } from "./OrdersRowSubComponent";
import { useTranslation } from "react-i18next";

const OrdersTable: React.FC<ProjectDataProps> = ({
  projectsData,
  isLoading,
  isDirector,
}) => {
  const { me } = useMe();
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const finalTab = isDirector
    ? TAB_NAME.consolidatedBills
    : TAB_NAME.billableProjects;

  const projects = projectsData?.filter((p) => p.clientOrders.length) || [];
  const projectsById = keyBy(projects, (p) => p.id);

  const orders = projects.flatMap((p) =>
    p.clientOrders.map((o) => ({ ...o, project: p, type: p.type })),
  );

  const {
    filteredProjects: filteredOrders,
    statusOptions,
    setFilters,
    filters,
    clear,
  } = useProjectFilter(orders || [], finalTab, statusOrderDico);

  //  We reassign the filtered orders with the projects
  const ordersByProject = groupBy(filteredOrders, (o) => o.project.id);
  const projectsIds = Object.keys(ordersByProject);

  const projectFiltered = projectsIds.map((projectId) => ({
    ...projectsById[projectId],
    orders: ordersByProject[projectId],
  }));

  const projectsByMarket = groupBy(
    projectFiltered,
    (p) => p.market || t("orders_table_no_market"),
  );

  useEffect(() => {
    const status = query.get("status");

    if (status) {
      setFilters({ status });
    }
  }, []);

  if (isLoading)
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );

  const { mailto, body } = generateAddADVMailTo({
    partnerName: me?.partnerName,
    email: me?.email ?? "",
    phone: me?.phone,
    firstName: me?.firstName,
    lastName: me?.lastName,
  });

  const projectColumns = PROJECT_COLUMNS.filter((p) => p.id !== "count");

  return (
    <>
      <Flex justify={"space-between"}>
        <ProjectFilters
          status={statusOptions}
          setFilters={setFilters}
          filters={filters}
          placeholder={"Filtrer par commande, bénéficiaire, projet, phase …"}
          clearFilters={clear}
        />
        {me && (
          <MailTo
            icon={<FaUserPlus />}
            mailto={mailto}
            body={body}
            label={t("orders_table_add_adv")}
          />
        )}
      </Flex>
      {projectFiltered.length ? (
        map(projectsByMarket, (projects, key) => (
          <Flex key={key} mt={5} direction="column">
            <Text fontWeight={600} fontSize="md" align="right" my={8}>
              {key}
            </Text>
            <TanStackTable<
              IPartnerContactProject & {
                clientOrders: IPartnerContactProject["clientOrders"];
              }
            >
              columns={projectColumns}
              data={projects}
              tab={finalTab}
              RowSubComponent={OrdersRowSubComponent}
            />
          </Flex>
        ))
      ) : (
        <Flex py={10}>
          {t(
            "orders_table_no_orders_found",
            "Aucun OS ne correspond à votre recherche.",
          )}
        </Flex>
      )}
    </>
  );
};

export default OrdersTable;
