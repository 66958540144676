import {
  Avatar,
  Center,
  Flex,
  Icon,
  Link,
  Spinner,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { Tooltip } from "components/Tooltip";
import { GetProjectQuery } from "graphql/__generated__/graphql";
import { MdDone, MdOutlineContentCopy } from "react-icons/md";

export const Contact: React.FC<{
  contact?:
    | GetProjectQuery["project"]["ownerContact"]
    | GetProjectQuery["project"]["phases"][number]["partner"]
    | GetProjectQuery["project"]["deliveryContact"]
    | GetProjectQuery["project"]["clientContact"];
  description?: string;
  fetching?: boolean;
}> = ({ contact, description, fetching }) => {
  if (fetching) {
    return (
      <Center h={100}>
        <Spinner color="brand.blue.dark" />
      </Center>
    );
  }

  if (!contact) return null;

  const { firstName, lastName, email, phone, fonction } = contact;
  const name = `${firstName} ${lastName}`;

  return (
    <Flex
      direction="column"
      border="1px solid"
      borderColor="neutral.grey.300"
      borderRadius="8px"
      p={5}
      my={3}
      fontSize="sm"
    >
      <Flex mb={4}>
        <Avatar
          name={name}
          mr={4}
          bg="brand.blue.background"
          color="brand.blue.dark"
          borderRadius="8px"
        />
        <Flex direction="column">
          <Text size={"md"} variant={"semibold"}>
            {name} {description && <Tooltip label={description} />}
          </Text>
          <Text>{fonction}</Text>
        </Flex>
      </Flex>
      <Flex align="center">
        <Text size={"xs"} variant={"small"} mr={3}>
          Email:
        </Text>
        <Text noOfLines={[1]} overflow={"hidden"}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
      </Flex>
      {phone && (
        <Flex align="center">
          <Text size={"xs"} variant={"small"} mr={3}>
            Tél:
          </Text>
          <Text>{phone || "-"}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export const ContactProject: React.FC<{
  contact?:
    | GetProjectQuery["project"]["ownerContact"]
    | GetProjectQuery["project"]["phases"][number]["partner"]
    | GetProjectQuery["project"]["deliveryContact"]
    | GetProjectQuery["project"]["clientContact"];
  description?: string;
  fetching?: boolean;
  title: string;
  EditButton?: React.ReactNode;
}> = ({ contact, description, fetching, title, EditButton }) => {
  const { hasCopied: hasCopiedEmail, onCopy: onCopyEmail } = useClipboard(
    contact?.email ?? "",
  );
  const { hasCopied: hasCopiedPhone, onCopy: onCopyPhone } = useClipboard(
    contact?.phone ?? "",
  );

  if (fetching) {
    return (
      <Center h={100}>
        <Spinner color="brand.blue.dark" />
      </Center>
    );
  }

  if (!contact) return null;

  const { firstName, lastName, email, phone, fonction } = contact;
  const name = `${firstName} ${lastName}`;

  return (
    <Flex
      border="1px solid #E2E8F0"
      bg={EditButton ? "white" : "grey.50"}
      p="24px"
      direction={"column"}
      borderRadius={"10px"}
      minW="465px"
      gap={"16px"}
      fontSize={"14px"}
      fontWeight={500}
      flex="1"
      maxW={"calc(50% - 8px)"}
    >
      <Flex justify={"space-between"}>
        <Text fontWeight={500}>
          {title} {description && <Tooltip label={description} />}
        </Text>
        {EditButton}
      </Flex>
      <Flex justify={"space-between"}>
        <Text fontWeight={600}>{name}</Text>
        <Text color="grey.600" fontWeight={500}>
          {fonction}
        </Text>
      </Flex>

      {email && (
        <Flex justify={"space-between"}>
          <Link fontWeight={500} href={`mailto=${email}`}>
            {email}
          </Link>
          <Icon
            as={hasCopiedEmail ? MdDone : MdOutlineContentCopy}
            fontSize={"20px"}
            color="blue.600"
            cursor={"pointer"}
            onClick={onCopyEmail}
          />
        </Flex>
      )}
      {phone && (
        <Flex justify={"space-between"}>
          <Link fontWeight={500}>{phone}</Link>
          <Icon
            as={hasCopiedPhone ? MdDone : MdOutlineContentCopy}
            fontSize={"20px"}
            color="blue.600"
            cursor={"pointer"}
            onClick={onCopyPhone}
          />
        </Flex>
      )}
    </Flex>
  );
};
