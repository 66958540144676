import { Container, Stack } from "@chakra-ui/react";
import {
  BetaFeature,
  CompanySubmissionsStatus,
  CreateInformationPostModal,
  DeleteInformationPostModal,
  WithRoles,
} from "components";
import { NavBar } from "components/Navbar/NavBar";
import { RolesEnum } from "graphql/__generated__/graphql";
import { InfoPannelRow } from "modules/InfoPannelRow";
import { NotificationSummary } from "modules/NotificationSummary";
import { StatsSummary } from "modules/Stats";
import { OrderBillingModal } from "modules/order";
import { EditPhaseModal } from "modules/phase";
import { EditContactModal } from "modules/role/RoleProjectsTable/EditContactModal";
import { AdjustedDeliveryDateModal } from "modules/task/TaskModal/AdjustedDeliveryDateModal";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { TAB_NAME } from "stores";
import ConsolidatedViewPage from "./ConsolidatedView";
import DocumentViewPage from "./DocumentView";
import { HomePanel } from "./Home/HomePanel";
import MyCompany from "./MyCompany";
import { Alerts } from "./MyCompany/Alerts";
import { Skills } from "./MyCompany/Skills";
import OnboardingPage from "./Onboarding";
import { AlertsSettings } from "./Onboarding/AlertsSettings";
import { ChannelInfoPage } from "./Onboarding/ChannelInfoPage";
import MyCompanySettings from "./Onboarding/MyCompanySettings";
import { NdaAgreementPage } from "./Onboarding/NdaAgreementPage";
import { OnboardingBreadCrumps } from "./Onboarding/OnboardingBreadCrumps";
import { SkillsSettings } from "./Onboarding/SkillsSettings";
import PhaseDetailsPage from "./PhaseDetails";
import ProjectDetailsPage from "./ProjectDetails";
import RoleProjectsPage from "./RoleProjects";

export const Dashboard = () => (
  <>
    <NavBar />

    <Container maxW={"1280px"} height={"100%"} centerContent>
      <Routes>
        <Route path="dashboard" element={<Outlet />}>
          <Route
            path="main/"
            element={
              <Stack gap={5} w="100%" py={12}>
                <NotificationSummary />
                <StatsSummary />
                <InfoPannelRow />
                <WithRoles roles={[RolesEnum.ProviderExecutive]}>
                  <CompanySubmissionsStatus />{" "}
                </WithRoles>
                <Outlet />
              </Stack>
            }
          >
            <Route path="posts" element={<Outlet />}>
              <Route path="create" element={<CreateInformationPostModal />} />

              <Route path=":id" element={<Outlet />}>
                <Route path="edit" element={<CreateInformationPostModal />} />
                <Route path="delete" element={<DeleteInformationPostModal />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="actions/*"
            element={<HomePanel tab={TAB_NAME.actions} />}
          >
            <Route path="phases/:id/edit/*" element={<EditPhaseModal />} />
          </Route>
          <Route
            path="invoices/*"
            element={<HomePanel tab={TAB_NAME.invoices} />}
          />
          <Route
            path="active-projects/*"
            element={<HomePanel tab={TAB_NAME.activeProjects} />}
          >
            <Route path="phases/:id/edit/*" element={<EditPhaseModal />} />
          </Route>
          <Route
            path="billable-projects/*"
            element={<HomePanel tab={TAB_NAME.billableProjects} />}
          >
            <Route
              path="orders/:providerOrderId/:caseId/*"
              element={<OrderBillingModal />}
            />
          </Route>
          <Route
            path="finished-projects/*"
            element={<HomePanel tab={TAB_NAME.finishedProjects} />}
          />
          <Route path="tasks/*" element={<HomePanel tab={TAB_NAME.tasks} />}>
            <Route
              path=":taskId/adjusted-delivery-date"
              element={<AdjustedDeliveryDateModal />}
            />
          </Route>
          <Route path="ma-societe" element={<Outlet />}>
            <Route path="nda" element={<MyCompany tab="nda" />} />
            <Route
              path="informations"
              element={<MyCompany tab="informations" />}
            />
            <Route path="mes-expertises" element={<Skills />} />
            <Route path="mes-alertes" element={<Alerts />} />
          </Route>
          <Route path="documents" element={<DocumentViewPage />} />
          <Route path="*" element={<Navigate to="actions" />} />
        </Route>
        <Route
          path="onboarding"
          element={
            <BetaFeature>
              {/* NOT FACTURATION */}
              <OnboardingBreadCrumps />
              <Outlet />
            </BetaFeature>
          }
        >
          <Route path="accueil" element={<OnboardingPage />} />
          <Route path="nda" element={<NdaAgreementPage />} />
          <Route path="info" element={<ChannelInfoPage />} />
          <Route path="mes-alertes" element={<AlertsSettings />} />
          <Route path="ma-societe">
            <Route
              path={"fiche-identite"}
              element={<MyCompanySettings tab="fiche-identite" />}
            />
            <Route
              path={"interventions"}
              element={<MyCompanySettings tab="interventions" />}
            />
            <Route
              path={"implantations-et-effectifs"}
              element={<MyCompanySettings tab="implantations-et-effectifs" />}
            />
            <Route
              path={"donnees-commerciales"}
              element={<MyCompanySettings tab="donnees-commerciales" />}
            />
          </Route>
          <Route path="mes-expertises" element={<SkillsSettings />} />
        </Route>
        <Route path="consolidated-view" element={<Outlet />}>
          <Route
            path="projects/*"
            element={
              <ConsolidatedViewPage tab={TAB_NAME.consolidatedProjects} />
            }
          />
          <Route
            path="bills/*"
            element={<ConsolidatedViewPage tab={TAB_NAME.consolidatedBills} />}
          >
            <Route
              path="orders/:providerOrderId/:caseId/*"
              element={<OrderBillingModal />}
            />
          </Route>
          <Route
            path="invoices/*"
            element={
              <ConsolidatedViewPage tab={TAB_NAME.consolidatedInvoices} />
            }
          />
          <Route
            path="*"
            element={<Navigate to={"/consolidated-view/projects"} />}
          />
        </Route>

        <Route
          path="settings/projects"
          element={
            <>
              <RoleProjectsPage />
              <Outlet />
            </>
          }
        >
          <Route path="contacts/:id" element={<EditContactModal />} />
        </Route>
        <Route path="projects/:projectId/">
          <Route path=":tabName" element={<ProjectDetailsPage />}></Route>
          <Route path="" element={<ProjectDetailsPage />}></Route>
        </Route>
        <Route path="phases/:id" element={<PhaseDetailsPage />}>
          <Route path="edit/*" element={<EditPhaseModal />} />
        </Route>

        {/* Other goes to the dashboard */}
        <Route path="*" element={<Navigate to={"/dashboard/main"} />} />
      </Routes>
    </Container>
  </>
);
