import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { type ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import {
  OrderStatusEnum,
  PhaseStatusEnum,
  ProjectTypeEnum,
  RiskLevelEnum,
  RolesEnum,
  SetCachedPhaseDocument,
  SetCachedPhaseMutation,
  SetPhaseDocument,
  SetPhaseMutation,
} from "graphql/__generated__/graphql";
import { hasOneOfRoles } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { uniq } from "lodash-es";
import { nanoidShort } from "modules/order/utils";
import { ValidationPhaseButton } from "modules/phase/ValidationPhaseButton";
import { useRef, useState } from "react";
import { BiComment } from "react-icons/bi";
import { FaPen } from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { type IPhase } from "types";
import { useMutation } from "urql";

export const getColor = (riskLevel?: RiskLevelEnum | null) => {
  switch (riskLevel) {
    case RiskLevelEnum.High:
      return "brand.red.main";
    case RiskLevelEnum.Medium:
      return "semantic.warning.main";
    case RiskLevelEnum.Low:
      return "brand.green.main";
    default:
      return "brand.grey.main";
  }
};

export const STATUS_DICO: Record<PhaseStatusEnum, string> = {
  FINISHED: "Terminé",
  FINISHED_OPE: "Terminé opérationnel",
  ACTIVE: "En cours",
  CANCELED: "Annulé",
  PENDING: "En attente OS",
};

export const STATUS_COLOR: Record<PhaseStatusEnum, string> = {
  PENDING: "grey-light",
  ACTIVE: "blue-light",
  FINISHED: "green-light",
  FINISHED_OPE: "green-light",
  CANCELED: "grey-light",
};

export const STATUS_TRUE_COLOR: Record<PhaseStatusEnum, string> = {
  PENDING: "grey.700",
  ACTIVE: "blue.700",
  FINISHED: "green.700",
  FINISHED_OPE: "green.700",
  CANCELED: "grey.700",
};

export const STATUS_TRUE_COLOR_HIGH: Record<PhaseStatusEnum, string> = {
  PENDING: "grey.500",
  ACTIVE: "blue.500",
  FINISHED: "green.500",
  FINISHED_OPE: "green.500",
  CANCELED: "grey.500",
};

const RISK_DICO: Record<RiskLevelEnum, string> = {
  HIGH: "red.800",
  MEDIUM: "yellow.800",
  LOW: "neutral.grey.800",
  CANCEL: "neutral.grey.800",
};

const RISK_DICO_HIGH: Record<RiskLevelEnum, string> = {
  HIGH: "red.500",
  MEDIUM: "orange.500",
  LOW: "neutral.grey.500",
  CANCEL: "neutral.grey.500",
};

const RISK_DICO_LABEL: Record<RiskLevelEnum, string> = {
  CANCEL: "Annulation demandée",
  HIGH: "Risque rouge",
  MEDIUM: "Risque orange",
  LOW: "Pas de risque",
};

export const COLUMNS_PHASE: Array<ColumnDef<IPhase>> = [
  {
    id: "name",
    header: "Intitulé phase",
    size: 4,
    cell: ({
      row: {
        original: { id, name, isValidated, riskLevel, status, clientOrders },
      },
    }) => {
      const navigate = useNavigate();
      const location = useLocation();
      const ref = uniq(clientOrders.map((order) => order.reference)).join("\n");

      return (
        <Flex width={"300px"} align="center">
          <Tooltip
            label={
              <Stack gap={2}>
                <Flex justify={"space-between"} gap={8}>
                  <Text color={STATUS_TRUE_COLOR_HIGH[status]}>
                    {STATUS_DICO[status]}
                  </Text>
                  <Text color={RISK_DICO_HIGH[riskLevel ?? RiskLevelEnum.Low]}>
                    {RISK_DICO_LABEL[riskLevel ?? RiskLevelEnum.Low]}
                  </Text>
                </Flex>

                <Text color="white">{name}</Text>

                <Text color="white">{ref}</Text>
              </Stack>
            }
            placement="auto"
          >
            <Text
              noOfLines={1}
              cursor={"pointer"}
              onClick={(e) => {
                e.stopPropagation();

                navigate(`/phases/${id}`, {
                  state: { from: location.pathname },
                });
              }}
              _hover={{
                textDecoration: "underline",
                color: "blue.600",
              }}
              color={
                isValidated
                  ? "neutral.grey.500"
                  : RISK_DICO[riskLevel ?? RiskLevelEnum.Low]
              }
            >
              <Box as="span" color={STATUS_TRUE_COLOR[status]}>
                {STATUS_DICO[status]}
              </Box>{" "}
              : {name}
            </Text>
          </Tooltip>
        </Flex>
      );
    },
  },

  {
    id: "contractualDeliveryDate",
    header: "Échéance",
    size: 1,
    cell: ({
      row: {
        original: {
          contractualDeliveryDate,
          isValidated,
          adjustedDeliveryDate,
        },
      },
    }) => (
      <Text
        w={"200px"}
        color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
      >
        Éch :{" "}
        {contractualDeliveryDate
          ? format(contractualDeliveryDate, "dd/MM/yy")
          : "-"}{" "}
        {adjustedDeliveryDate && (
          <> → {format(adjustedDeliveryDate, "dd/MM/yy")}</>
        )}
      </Text>
    ),
  },
  {
    id: "originalFields",
    header: "Avcmt",
    size: 4,
    cell: ({
      row: {
        original: { originalFields, isValidated, completion, status, id },
      },
    }) => {
      const navigate = useNavigate();

      return (
        <Flex
          w={"390px"}
          align={"center"}
          title="Éditer l'avancement"
          color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
        >
          <Box w="120px">Avcmt : {originalFields?.completion || 0}%</Box>→{"  "}
          {status === PhaseStatusEnum.Active ? (
            <Button
              ml={2}
              color={isValidated ? "neutral.grey.500" : "brand.blue.main"}
              cursor={"pointer"}
              px={4}
              py={2}
              bg="inherit"
              w={"100px"}
              borderRadius={"md"}
              _hover={{ bg: "blue.100" }}
              data-test="progress-button"
              onClick={() => {
                navigate(`phases/${id}/edit`);
              }}
              rightIcon={<FaPen />}
            >
              {completion}%
            </Button>
          ) : (
            <Text
              ml={4}
              color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
            >
              {completion}%
            </Text>
          )}
        </Flex>
      );
    },
  },

  {
    id: "timeDone",
    header: "Jours",
    size: 4,
    cell: ({
      row: {
        original: {
          originalFields,
          timeSold,
          timeDone,
          isValidated,
          id,
          status,
        },
      },
    }) => {
      const navigate = useNavigate();

      return (
        <Flex
          w={"320px"}
          align={"center"}
          color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
        >
          <Box w="120px">{timeSold}j prévus</Box> →{" "}
          {(originalFields?.timeDone || 0) < timeSold &&
          status === PhaseStatusEnum.Active ? (
            <Button
              w={"100px"}
              mx={2}
              px={4}
              py={2}
              bg="inherit"
              borderRadius={"md"}
              color={isValidated ? "neutral.grey.500" : "brand.blue.main"}
              cursor={"pointer"}
              _hover={{ bg: "blue.100" }}
              onClick={() => {
                navigate(`phases/${id}/edit`);
              }}
              rightIcon={<FaPen />}
              data-test="edit-days-button"
            >
              {timeDone} j
            </Button>
          ) : (
            <Text
              ml={6}
              mr={"45px"}
              color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
            >
              {timeDone} j
            </Text>
          )}
          consommés
        </Flex>
      );
    },
  },

  {
    id: "resource",
    header: "Ressource",
    size: 1,
    cell: ({
      row: {
        original: { ressourceName },
      },
    }) => (
      <Flex w={"32px"} align={"center"} justify={"center"}>
        <Tooltip label={ressourceName} placement="top">
          <Avatar
            bg="purple.100"
            color="purple.900"
            size="sm"
            name={ressourceName || ""}
          />
        </Tooltip>
      </Flex>
    ),
  },
  {
    id: "comment",
    header: "Comm",
    size: 1,
    cell: ({
      row: {
        original: { comment, isValidated },
      },
    }) => (
      <Flex align={"center"} justify={"center"} h="100%">
        {comment ? (
          <Tooltip label={comment} placement="top">
            <span>
              <Icon
                as={BiComment}
                fontSize={22}
                color={isValidated ? "neutral.grey.500" : "neutral.grey.800"}
              />
            </span>
          </Tooltip>
        ) : null}
      </Flex>
    ),
  },
  {
    id: "selection",
    header: "État",
    size: 2,
    cell: ({
      row: {
        original: {
          id,
          completion,
          status,
          isValidated,
          originalFields,
          projectType,
        },
      },
    }) => {
      const enable = hasOneOfRoles([
        RolesEnum.ProviderReadOnly,
        RolesEnum.ProviderExecutive,
        RolesEnum.ProviderOperational,
      ]);
      const timeDone = originalFields?.timeDone ?? 0;
      const timeSold = originalFields?.timeSold ?? 0;

      return (
        <Flex justify={"center"} align="center">
          <ValidationPhaseButton
            isDisabled={!enable}
            completion={completion}
            status={status}
            id={id}
            isValidated={isValidated}
            timeSold={timeSold}
            timeDone={timeDone}
            projectType={projectType}
          />
        </Flex>
      );
    },
  },

  {
    id: "actions-adv",
    header: "Actions-adv",
    size: 1,
    cell: ({
      row: {
        original: { id, completion, status, isPartialInvoice },
      },
    }) => {
      const toast = useToast();
      const navigate = useNavigate();
      const [{ fetching: fetchingPhase }, savePhase] =
        useMutation(SetPhaseDocument);
      const [{ fetching: fetchingCachedPhase }, saveCachedPhase] = useMutation(
        SetCachedPhaseDocument,
      );

      const fetching = fetchingPhase || fetchingCachedPhase;
      const { trackEvent } = useTrackEvent();
      const { isOpen, onOpen, onClose } = useDisclosure();
      const cancelRef = useRef(null);
      const [isChecked, setIsChecked] = useState(true);

      const onSubmitAdv = async () => {
        await trackEvent("Save", "save-phase-adv", { id });

        const { error, data } = isChecked
          ? await savePhase({
              phase: { id, completion: 100 },
            })
          : await saveCachedPhase({
              phase: { id, completion: 100 },
            });

        if (!error) {
          toast({
            title: "Changement enregistré.",
            status: "success",
          });

          const orders = isChecked
            ? (data as SetPhaseMutation)?.setPhase?.clientOrders ?? []
            : (data as SetCachedPhaseMutation)?.setCachedPhase?.clientOrders ??
              [];

          const project = isChecked
            ? (data as SetPhaseMutation)?.setPhase?.project
            : (data as SetCachedPhaseMutation)?.setCachedPhase?.project;

          const billableOrders =
            orders.filter(
              ({ status }) =>
                status === OrderStatusEnum.Billable ||
                status === OrderStatusEnum.BillableYooz,
            ) || [];

          if (
            billableOrders.length &&
            project?.isPartialInvoice === false &&
            project.type === ProjectTypeEnum.Forfait
          ) {
            const providerId = billableOrders[0]?.providerOrders[0]?.id;
            const caseId = nanoidShort();

            navigate(
              `/consolidated-view/bills/orders/${providerId}/${caseId}`,
              {
                state: { from: location.pathname },
              },
            );
          }
          onClose();
        } else {
          onClose();
        }
      };

      if (
        ![PhaseStatusEnum.Active, PhaseStatusEnum.Finished].includes(status) ||
        isPartialInvoice
      )
        return null;

      return (
        <>
          {completion === 100 ? (
            <Button
              w="100px"
              colorScheme={"green-light"}
              rightIcon={<HiCheckCircle />}
            >
              100%
            </Button>
          ) : (
            <Button w="100px" colorScheme={"blue"} onClick={onOpen}>
              Marquer 100%
            </Button>
          )}
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Avancement
                </AlertDialogHeader>

                <AlertDialogBody fontSize={"sm"}>
                  L'avancement va passer à 100% sur cette phase, vous permettant
                  de déposer un dossier de facturation si cela n'a pas déjà été
                  fait.
                  <FormControl display="flex" alignItems="center" mt={4}>
                    <Switch
                      id="save-to-sf"
                      isChecked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      mr={4}
                    />
                    <FormLabel htmlFor="save-to-sf" mb="0">
                      {isChecked
                        ? "Je souhaite transmettre l'avancement et déposer ma facture"
                        : "Je ne veux pas transmettre l'avancement, je ne vais que déposer ma facture"}
                    </FormLabel>
                  </FormControl>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose} variant="link">
                    Annuler
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={onSubmitAdv}
                    ml={3}
                    isLoading={fetching}
                  >
                    Confirmer
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      );
    },
  },
];
