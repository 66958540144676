import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { Select } from "components";
import { format } from "date-fns";
import {
  GetContactProjectsDocument,
  ProjectStatusEnum,
  RolesEnum,
} from "graphql/__generated__/graphql";
import { hasOneOfRoles, useMe } from "hooks";
import { map, orderBy, uniqBy } from "lodash-es";
import { WAITING_STATUS } from "modules/project/utils";
import { useEffect, useState } from "react";
import { useQuery } from "urql";
import { StatsGrid } from "../StatsGrid";
import { useTranslation } from "react-i18next";

export const StatsSummary = () => {
  const { me } = useMe();

  const isDirectorOrAdv = hasOneOfRoles([
    RolesEnum.ProviderExecutive,
    RolesEnum.ProviderSales,
  ]);
  // company data
  const [{ data: contactProjects }] = useQuery({
    query: GetContactProjectsDocument,
  });
  const { t } = useTranslation();

  const [directorScope, setDirectorScope] = useState(false);
  const [yearProjectFilter, setYearProjectFilter] = useState("all");

  useEffect(() => {
    if (isDirectorOrAdv) {
      setDirectorScope(true);
    }
  }, [isDirectorOrAdv]);

  if (!contactProjects) {
    return null;
  }

  const globalProjects = contactProjects.projects.filter(
    (p) => !WAITING_STATUS.includes(p.status),
  ); // we filter to only active projects

  const projects = globalProjects
    .filter((p) => {
      if (directorScope) {
        return p;
      } else {
        return map(p.phases, (p) => p.partner?.email).includes(me?.email ?? "");
      }
    })
    .map(({ delivery, status, createdDate, ...p }) => {
      let date;
      if (WAITING_STATUS.includes(status)) date = createdDate;
      if (status === ProjectStatusEnum.Active) date = delivery.start;
      if (status === ProjectStatusEnum.Finished) date = delivery.contractual;

      return {
        ...p,
        delivery,
        status,
        createdDate,
        date: format(date ?? new Date(), "yyyy"),
      };
    });

  const optionsFilter = orderBy(
    uniqBy(
      projects?.map(({ date }) => ({ label: date, value: date })),
      (p) => p.label,
    ),
    "label",
    "desc",
  );

  const yearOptionsFilter = [
    { label: t("stats_forever", "Depuis toujours"), value: "all" },
    ...optionsFilter,
  ];

  return (
    <>
      <Accordion allowToggle defaultIndex={[0]}>
        <AccordionItem borderTop={"0px"}>
          <Stack gap={3}>
            <AccordionButton>
              <Flex gap={5} alignItems={"center"}>
                <Heading>{t("stats_title", "Statistiques")}</Heading>
                <AccordionIcon fontSize={"xl"} />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Stack width={"100%"}>
                <Flex
                  gap={5}
                  width={"100%"}
                  align={"center"}
                  justify={"end"}
                  mb={4}
                >
                  {isDirectorOrAdv && (
                    <Flex direction={"row"} align={"center"} gap={5}>
                      <Select
                        value={directorScope.toString()}
                        onChange={(e) => {
                          // eslint-disable-next-line no-unneeded-ternary
                          setDirectorScope(e === "true" ? true : false);
                        }}
                        defaultValue={"true"}
                        options={[
                          {
                            label: `${t("stats_projects", "Projets")} ${me?.partnerName}`,
                            value: "true",
                          },
                          {
                            label: t("stats_my_projects", "Mes projets"),
                            value: "false",
                          },
                        ]}
                      />
                    </Flex>
                  )}
                  <Select
                    value={yearProjectFilter}
                    onChange={setYearProjectFilter}
                    defaultValue={"all"}
                    options={yearOptionsFilter}
                  />
                </Flex>

                <StatsGrid
                  projects={projects}
                  directorScope={directorScope}
                  yearProjectFilter={yearProjectFilter}
                />
              </Stack>
            </AccordionPanel>
          </Stack>
        </AccordionItem>
      </Accordion>
    </>
  );
};
