import { Tr } from "@chakra-ui/react";

export const TableRow: typeof Tr = ({ children, ...props }) => (
  <Tr
    display="flex"
    px={5}
    borderTopRightRadius={"xl"}
    borderTopLeftRadius={"xl"}
    bg="neutral.grey.100"
    {...props}
  >
    {children}
  </Tr>
);
