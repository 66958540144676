import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Dropzone } from "components";
import {
  ElementTypeEnum,
  GetContactTaskDocument,
  UpdatePhaseProofDocument,
} from "graphql/__generated__/graphql";
import { useUpload } from "hooks";
import { PdfView } from "modules/order/PdfView";
import { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { toBase64 } from "utils";
import { TaskModalForm } from "./TaskModalForm";
import { useTranslation } from "react-i18next";

export const AdjustedDeliveryDateModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { taskId } = useParams() as {
    taskId: string;
  };

  const [{ fetching: fetchingTask, data: dataTask, error }] = useQuery({
    query: GetContactTaskDocument,
    variables: { id: taskId },
    pause: !taskId,
  });

  const [, updatePhaseProof] = useMutation(UpdatePhaseProofDocument);

  const [b64State, setB64] = useState<string | null>();
  const [file, setFile] = useState<File | null>();

  const [isPdfLoading, setPdfLoading] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const { uploadFile } = useUpload();

  const toast = useToast();
  const onClose = () => {
    navigate("..");
  };

  const onChange = async (file: File) => {
    setPdfLoading(true);
    const b64 = await toBase64(file);

    if (b64 && typeof b64 === "string") {
      setB64(b64);
      setFile(file);
    }

    setPdfLoading(false);
  };

  const onRemove = () => {
    setB64(null);
    setFile(null);
  };

  const task = dataTask?.task;
  const phase = task?.phase;

  const initialB64 = task?.lastAttachment?.b64;

  useEffect(() => {
    if (phase) {
      setB64(`data:application/pdf;base64,${initialB64}`);
    }
  }, [phase, initialB64]);

  const onUpdate = async (commentRejection: string) => {
    setSubmitLoading(true);

    if (!b64State || !phase || !file) {
      toast({
        title: t("upload_new_proof", "Vous devez uploader une nouvelle PJ"),
        description: t(
          "proof_must_be_different",
          "La pièce jointe que vous devez fournir doit être différente de celle que vous avez fournie lors de la première soumission de phase.",
        ),
        status: "error",
      });
      setSubmitLoading(false);
      return;
    }

    if (b64State === initialB64) {
      toast({
        description: t("replace_your_proof", "Vous devez remplacer votre PJ."),
      });
      return;
    }

    const proofPath = await uploadFile(file, ElementTypeEnum.Proof);

    if (!proofPath) {
      toast({
        title: t("upload_proof", "Vous devez uploader une PJ."),
        status: "warning",
      });
      setSubmitLoading(false);
      return;
    }

    const { error } = await updatePhaseProof({
      input: {
        taskId,
        phaseId: phase.id,
        projectId: phase.projectId,
        proofPath,
        commentRejection,
      },
    });

    if (!error) {
      toast({
        title: t("your_proof_sent", "Votre PJ a été envoyée à notre équipe !"),
        status: "success",
      });
    }
    setSubmitLoading(false);

    onClose();
  };

  if (error) {
    navigate("..");
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="6xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent p={8}>
        {fetchingTask ? (
          <Center h="500">
            <Spinner />
          </Center>
        ) : (
          <ModalBody overflow="auto" height="calc(100vh - 300px)">
            <Grid templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
              <GridItem colSpan={6} pr={20}>
                <Heading size={"xl"} mb={8}>
                  {phase?.name}
                </Heading>

                <TaskModalForm
                  taskRejection={task?.rejection}
                  onSubmit={onUpdate}
                  isLoadingSubmit={isSubmitLoading}
                />
              </GridItem>
              <GridItem colSpan={6}>
                {b64State ? (
                  <Stack gap={4}>
                    <PdfView base64={b64State} />
                    <Button onClick={onRemove}>
                      {t("replace_attachment", "Modifier la pièce jointe")}
                    </Button>
                  </Stack>
                ) : (
                  <Center h={"full"} mt={4}>
                    <Dropzone
                      isLoading={isPdfLoading}
                      onChange={onChange}
                      label={
                        <Flex
                          align="center"
                          justifyContent={"center"}
                          width="100%"
                          height="100%"
                        >
                          <Icon as={AiOutlineUpload} mr={6} fontSize={40} />
                          <Box>
                            <Box fontSize={16} fontWeight="semibold">
                              {t(
                                "drag_drop_new_justification",
                                "Glissez-déposez un nouveau justificatif",
                              )}
                            </Box>
                            <Box textDecoration="underline">
                              {t(
                                "or_browse_in_your_computer",
                                "ou parcourir dans votre ordinateur",
                              )}
                            </Box>
                          </Box>
                        </Flex>
                      }
                    />
                  </Center>
                )}
              </GridItem>
            </Grid>
          </ModalBody>
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
