import { signOut } from "libs/auth";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useSelectedProjectsStore, useUIStore } from "stores";
import { useAuthStore } from "stores/auth";

export const useLogout = () => {
  const clearSelectedProjects = useSelectedProjectsStore((s) => s.clear);
  const navigate = useNavigate();
  const setIsLoggedIn = useAuthStore((s) => s.setIsLoggedIn);

  const clearFilters = useUIStore((s) => s.clear);
  const { shutdown, boot } = useIntercom();

  const logout = async () => {
    await signOut();
    shutdown();

    clearSelectedProjects();
    clearFilters();
    localStorage.clear();

    setIsLoggedIn(false);
    navigate("/login");

    boot();
  };

  return { logout };
};
