import {
  Button,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { MyBillsTab } from "components";
import {
  GetContactProjectsDocument,
  RolesEnum,
} from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { useDownloadConsolidatedXlsx } from "hooks/useDownloadConsolidatedXlsx";
import { useTrackEvent } from "libs/tracking";
import OrdersTable from "modules/order/OrdersTable";
import { ConsolidatedViewTable } from "modules/project/ConsolidatedViewTable";
import React from "react";
import { MdFileDownload } from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TAB_NAME } from "stores";
import { useQuery } from "urql";
import { CONSOLIDATED_TAB_NAME_ENUM } from "./contants";
import { useTranslation } from "react-i18next";

const consolidatedTabLinks = [
  TAB_NAME.consolidatedProjects,
  TAB_NAME.consolidatedBills,
  TAB_NAME.consolidatedInvoices,
];

const ConsolidatedViewPage: React.FC<{
  tab: (typeof consolidatedTabLinks)[number];
}> = ({ tab }) => {
  const { me } = useMe();
  const isAdv = me?.roles.includes(RolesEnum.ProviderSales);
  const [{ data, fetching }] = useQuery({
    query: GetContactProjectsDocument,
  });
  const navigate = useNavigate();
  const { downloadXLSX, isLoading: isXlsxLoading } =
    useDownloadConsolidatedXlsx();
  const { trackEvent } = useTrackEvent();
  const location = useLocation();
  const from = location.state?.from || null;
  const projects = data?.projects ?? [];
  const { t } = useTranslation();
  return (
    <>
      <Outlet />
      <Stack w="100%">
        <Flex direction={"column"} width={"100%"} align={"flex-end"}>
          <Heading size={"xl"}>{me?.partnerName}</Heading>{" "}
        </Flex>
        <Tabs
          index={consolidatedTabLinks.indexOf(tab)}
          isLazy={true}
          width={"100%"}
          onChange={(index) => {
            navigate(
              `/consolidated-view/${
                Object.values(CONSOLIDATED_TAB_NAME_ENUM)[index]
              }`,
              {
                state: { from },
              },
            );
          }}
        >
          <TabList
            px={5}
            pos={"sticky"}
            top="0"
            background={"white"}
            zIndex={9}
            py={6}
          >
            <Tab>{t("consolidated_view", "Vue consolidée")}</Tab>
            <Tab>{t("billing", "Déposer mes factures")}</Tab>
            <Tab>{t("bills", "Factures")}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex py={10}>
                <Button
                  variant="ghost"
                  rightIcon={<MdFileDownload />}
                  onClick={async () => {
                    await trackEvent("Download", "download-xlsx");
                    await downloadXLSX();
                  }}
                  isLoading={isXlsxLoading}
                >
                  {t(
                    "download_projects_data",
                    "Télécharger vos données projets au format .xlsx",
                  )}
                </Button>
              </Flex>
              <ConsolidatedViewTable
                projects={projects}
                isLoading={fetching}
                isAdv={isAdv}
              />
            </TabPanel>
            <TabPanel>
              <OrdersTable
                projectsData={projects}
                isLoading={fetching}
                isDirector={true}
              />
            </TabPanel>
            <TabPanel>
              <MyBillsTab
                projectsData={projects}
                isLoading={fetching}
                isDirector={true}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  );
};

export default ConsolidatedViewPage;
