import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { Informations } from "./Informations";
import { Nda } from "./Nda";

const MyCompanyTabsLinks = ["nda", "informations"];

const MyCompany: React.FC<{ tab: (typeof MyCompanyTabsLinks)[number] }> = ({
  tab,
}) => {
  const navigate = useNavigate();
  return (
    <Flex w={"100%"}>
      <Outlet />
      <Tabs
        isLazy={true}
        index={MyCompanyTabsLinks.indexOf(tab)}
        onChange={(index) => {
          navigate(`/ma-societe/${MyCompanyTabsLinks[index]}`);
        }}
      >
        <TabList>
          <Tab pos="relative">
            <Text fontWeight="600">NDA</Text>
          </Tab>
          <Tab pos="relative">Informations</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Nda />
          </TabPanel>
          <TabPanel px={0}>
            <Informations />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default MyCompany;
