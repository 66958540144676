import { Button, Text, Center } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Center
      height="100vh"
      fontWeight={600}
      fontSize="2xl"
      display="flex"
      flexDirection="column"
      gap={10}
    >
      <Text>
        {t("page_not_found", "🚧 La page que vous recherchez n'existe pas. 🚧")}
      </Text>
      <Button as={NavLink} to="/dashboard/main">
        {t("back_to_home", "Retournez vers l'accueil")}
      </Button>
    </Center>
  );
};

export default NotFoundPage;
