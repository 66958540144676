import { Button, Icon, ResponsiveValue } from "@chakra-ui/react";
import { FileTypeEnum } from "graphql/__generated__/graphql";
import { useDownloadContentDocument } from "hooks/useDownloadContentDocument";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { HiDownload } from "react-icons/hi";

export const DownloadButton: FC<{
  linkedEntityId: string;
  typeDocument?: FileTypeEnum;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: ResponsiveValue<string>;
  hasIcon?: boolean;
}> = ({
  linkedEntityId,
  typeDocument,
  disabled,
  children,
  variant = "link",
  hasIcon = true,
}) => {
  const { downloadDocument, isLoading } = useDownloadContentDocument({
    linkedEntityId,
    typeDocument,
  });
  const { t } = useTranslation();

  return (
    <Button
      variant={variant}
      leftIcon={hasIcon ? <Icon as={HiDownload} fontSize="20px" /> : undefined}
      isLoading={isLoading}
      isDisabled={disabled}
      onClick={downloadDocument}
      title={t("download-document", "Télécharger le document")}
      as="div"
    >
      {children}
    </Button>
  );
};
