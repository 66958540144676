/* eslint-disable */
import * as yup from "yup";

yup.setLocale({
  mixed: {
    default: "Ce champ est invalide",
    // typeError: "Doit être {values}",
    required: "Ce champ est obligatoire",
    oneOf: "Doit être parmis : ${values}",
    notOneOf: "Ne doit pas être parmis: ${values}",
  },
  string: {
    length: "Doit faire exactement ${length} charactères",
    min: "Doit faire au minimum ${min} charactères",
    max: "Doit faire au maximum ${max} charactères",
    email: "Format d'email invalide",
    url: "Format d'url invalide",
    trim: "Ne dois pas contenir d'espace au début ou la fin",
    lowercase: "Doit être en minuscule",
    uppercase: "Doit être en majuscule",
  },
  number: {
    min: "Doit être plus que ${min}",
    max: "Doit être moins que ${max}",
    lessThan: "Doit être moins que ${less}",
    moreThan: "Doit être plus que ${more}",
    // notEqual: "Ne doit pas être égale à ${notEqual}",
    positive: "Doit être un nombre positif",
    negative: "Doit être un nombre négatif",
    integer: "Doit être un nombre entier",
  },
  date: {
    min: "Doit être une date supérieur à ${min}",
    max: "Doit être une date inférieur à ${max}",
  },
  array: {
    min: "Doit faire plus de ${min} items",
    max: "Doit faire moins de ${max} items",
  },
});

export default yup;
