import { ActionTaskEnum } from "graphql/__generated__/graphql";

export const dicoButton = {
  [ActionTaskEnum.AdjustedDeliveryDate]: "Changer le justificatif",
  [ActionTaskEnum.BackwardProgression]: "",
  [ActionTaskEnum.OrangeRisk]: "",
  [ActionTaskEnum.PhaseCancelation]: "",
  [ActionTaskEnum.PotentialDerivation]: "",
  [ActionTaskEnum.RedRisk]: "",
  [ActionTaskEnum.SubmitReport]: "",
  [ActionTaskEnum.PrestationProlongation]: "",
  [ActionTaskEnum.CampaingKo]: "",
};

export const dicoLabel = {
  [ActionTaskEnum.AdjustedDeliveryDate]: "Date de fin ajustée",
  [ActionTaskEnum.BackwardProgression]: "",
  [ActionTaskEnum.OrangeRisk]: "",
  [ActionTaskEnum.PhaseCancelation]: "",
  [ActionTaskEnum.PotentialDerivation]: "",
  [ActionTaskEnum.RedRisk]: "",
  [ActionTaskEnum.SubmitReport]: "",
  [ActionTaskEnum.PrestationProlongation]: "",
  [ActionTaskEnum.CampaingKo]: "",
};
