import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const SubmitModalValidation: React.FC<{
  isLoading: boolean;
  label: string;
  isOpen: boolean;
  onClose: () => void;
  onPublish: () => void;
  dataCy?: string;
}> = ({ isOpen, onClose, isLoading, label, onPublish, dataCy }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isLoading
            ? t("processing", "Le processus est en cours de traitement !")
            : label}
        </ModalHeader>
        {/* {!isLoading && <ModalCloseButton />} */}
        {isLoading ? (
          <ModalBody py={4}>
            {t(
              "submit-validation-thank-you",
              "Merci de ne pas recharger la page (le traitement peut durer jusqu'à 30 secondes en fonction du nombre de données et du trafic) ... ⏳",
            )}
          </ModalBody>
        ) : (
          <ModalBody py={4}>
            {t(
              "submit-validation-instructions",
              "Vous avez terminé de renseigner les avancements de l’ensemble des phases de vos projets, mais n’avez pas encore transmis votre avancement. Souhaitez-vous le faire tout de suite ?",
            )}{" "}
            <br /> <br />
            <b>
              {t(
                "submit-validation-warning",
                "Nous vous conseillons de le faire de suite, car sinon vous risquez de perdre vos saisies si vous ne soumettez pas votre avancement avant la fin de la campagne au 15 du mois prochain.",
              )}
            </b>
          </ModalBody>
        )}

        <ModalFooter justifyContent="center" flexDirection={"column"}>
          <Button data-test={dataCy} onClick={onPublish} isLoading={isLoading}>
            {t("submit-now", "Soumettre maintenant")}
          </Button>
          {!isLoading && (
            <Button onClick={onClose} variant={"link"} mt={4}>
              {t("later", "Plus tard")}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
