import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import TanStackTable from "components/ReactTable";
import { groupBy, map } from "lodash-es";
import InvoicesTable from "modules/invoice/InvoicesTable";
import { statusInvoiceDico } from "modules/invoice/utils";
import { ProjectFilters } from "modules/project/ProjectsTableWithFilters/ProjectFilters";
import { PROJECT_COLUMNS } from "modules/project/ProjectsTableWithFilters/ProjectsTable.config";
import { hiddenColumns } from "modules/project/utils";
import { type ProjectDataProps } from "pages/Home/HomePanel";
import { TAB_NAME } from "stores";
import { type IPartnerContactProject } from "types";
import { useProjectFilter } from "../../hooks";
import { useTranslation } from "react-i18next";

export const MyBillsTab: React.FC<ProjectDataProps> = ({
  projectsData,
  isLoading,
  isDirector,
}) => {
  const projects = projectsData.filter((p) => p.invoices.length) || [];

  const { t } = useTranslation();
  const invoices =
    projects.flatMap((p) =>
      p.invoices.map((i) => ({
        ...i,
        projectId: p.id,
        projectReference: p.reference,
        type: p.type,
      })),
    ) || [];

  const finalTab = isDirector
    ? TAB_NAME.consolidatedInvoices
    : TAB_NAME.invoices;

  const {
    filteredProjects: filteredInvoices,
    statusOptions,
    setFilters,
    filters,
    clear,
  } = useProjectFilter(invoices, finalTab, statusInvoiceDico);

  const invoicesByProjectId = groupBy(filteredInvoices, (i) => i.projectId);

  const projectFiltered = projects
    .map((p) => ({
      ...p,
      invoices: invoicesByProjectId[p.id] || [],
    }))
    .filter((p) => p.invoices.length);

  const projectsByMarket = groupBy(
    projectFiltered,
    (p) => p.market || t("my-bills-no-market", "Sans accord cadre"),
  );

  const hiddenColumnsProjects = ["count"];

  if (isLoading)
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );

  return (
    <>
      <ProjectFilters
        status={statusOptions}
        setFilters={setFilters}
        filters={filters}
        placeholder={t(
          "my-bills-placeholder",
          "Filtrer par numéro de facture, commande …",
        )}
        clearFilters={clear}
      />
      {projectFiltered.length ? (
        map(projectsByMarket, (projects, key) => (
          <Flex key={key} mt={5} direction="column">
            <Text fontWeight={600} fontSize="md" align="right" my={8}>
              {key}
            </Text>
            <TanStackTable<IPartnerContactProject>
              columns={PROJECT_COLUMNS.filter(
                (c) => c.id && !hiddenColumnsProjects?.includes(c.id),
              )}
              data={projects}
              tab={finalTab}
              hiddenColumns={hiddenColumns}
              RowSubComponent={({
                row: {
                  original: { invoices, type: projectType, id },
                },
              }) => (
                <Box my={8}>
                  <InvoicesTable
                    invoices={invoices}
                    projectId={id}
                    projectType={projectType}
                  />
                </Box>
              )}
            />
          </Flex>
        ))
      ) : (
        <Flex py={10}>
          {t(
            "my-bills-no-result",
            "Aucune facture ne correspond à votre recherche.",
          )}
        </Flex>
      )}
    </>
  );
};
