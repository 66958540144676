import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Combobox } from "components";
import { MailTo } from "components/MailTo";
import {
  GetCompanyContactsDocument,
  SetProjectsPartnerContactDocument,
  type ProjectSelectorInput,
} from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { useTrackEvent } from "libs/tracking";

import { generateAddContactMailTo } from "pages/MyCompany/utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { type IPartnerContactProject } from "types";
import { useMutation, useQuery } from "urql";

export interface Dictionary<T> {
  [index: string]: T;
}

export const RoleProjectsModal: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  projects: Dictionary<IPartnerContactProject>;
  selectedProjects: string[];
  onSubmitted?: () => void;
}> = ({ onClose, isOpen, selectedProjects, onSubmitted }) => {
  const toast = useToast();
  const { me } = useMe();
  const { trackEvent } = useTrackEvent();

  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null,
  );
  const [{ fetching: fetchingSetProjectsPartner }, setProjectsContact] =
    useMutation(SetProjectsPartnerContactDocument);
  const [{ data, fetching }] = useQuery({
    query: GetCompanyContactsDocument,
    pause: !isOpen,
  });

  const { t } = useTranslation();

  if (fetching) {
    return null;
  }

  const contactOptions =
    data?.contacts
      .filter((c) => !c.left)
      .map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${lastName} ${firstName}`,
      })) || [];

  const onSubmit = async () => {
    if (!selectedContactId) return null;

    const projectsInput: ProjectSelectorInput[] = selectedProjects.map((p) => ({
      id: p,
    }));

    const { error } = await setProjectsContact({
      input: { contactId: selectedContactId, projects: projectsInput },
    });

    if (!error) {
      await trackEvent("Set", "set-projects-contact", {
        selectedContactId,
        projectsInput: JSON.stringify(projectsInput),
      });
      toast({
        status: "success",
        title: t("operation_success", "Opération réalisée avec succès"),
      });
    }
    onClose();
    onSubmitted && onSubmitted();
  };

  const { mailto, body } = generateAddContactMailTo({
    partnerName: me?.partnerName,
    email: me?.email ?? "",
    phone: me?.phone,
    firstName: me?.firstName,
    lastName: me?.lastName,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedContactId(null);
        onClose();
      }}
      size="lg"
      returnFocusOnClose={false}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand.blue.dark"
          fontWeight="semibold"
          fontSize="md"
        >
          Modifier le responsable - {selectedProjects.length} projet selectionné
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl>
            <FormLabel>{t("contact_label", "Responsable de projet")}</FormLabel>
            <Combobox
              items={contactOptions}
              onChange={(value) => {
                value && setSelectedContactId(value);
              }}
              placeholder={t(
                "enter_project_manager",
                "Renseigner un responsable projet",
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <MailTo
            mailto={mailto}
            body={body}
            label={t("add_new_contact", "Ajouter un nouveau contact")}
          />
          <Button
            type={"submit"}
            onClick={onSubmit}
            isLoading={fetchingSetProjectsPartner}
            isDisabled={!selectedContactId}
          >
            {t("cta_validate", "Valider")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
