import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMe } from "hooks";
import React from "react";
import { useNavigate } from "react-router-dom";

const OnboardingPage = () => {
  const { me } = useMe();
  const navigate = useNavigate();
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 10, md: 20 }}>
          <Heading fontSize={"3xl"}>Bonjour {me?.firstName}</Heading>
          <Heading fontSize={"xl"}>Bienvenue dans votre espace Inop's!</Heading>
          <Text color={"neutral.grey.500"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              size={"lg"}
              px={6}
              onClick={() => {
                navigate("/onboarding/nda");
              }}
            >
              Démarrer l'embarquement
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            height={"300px"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              alt={""}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={
                "https://img.freepik.com/free-vector/home-interior-background-concept_52683-44165.jpg?t=st=1650987433~exp=1650988033~hmac=a713fb7f3f9f2f1f6529d3fd374abab781b0fe3b57f9c596d542c7682141bbfc&w=1800"
              }
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
};

export default OnboardingPage;
