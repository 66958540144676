import { FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "libs/yup";
import { useForm } from "react-hook-form";

type IdentityInputType = {
  socialReason: string;
  juridicalForm: string;
  siretNumber: string;
  nafCode: string;
  creationDate: string;
};

export const IdentityInputSchema = yup.object().shape({
  socialReason: yup.string().required(),
  juridicalForm: yup.string().required(),
  siretNumber: yup.string().required(),
  nafCode: yup.string().required(),
  creationDate: yup.string().required(),
});

export const IdentityTab = () => {
  const {
    formState: { errors },
  } = useForm<IdentityInputType>({
    resolver: yupResolver(IdentityInputSchema),
    mode: "onBlur",
  });

  return (
    <Stack my={10} gap={5} w={"500px"}>
      <FormControl size="sm" isInvalid={!!errors.socialReason}>
        <FormLabel>Raison sociale</FormLabel>
        <Input />
      </FormControl>
      <FormControl size="sm" isInvalid={!!errors.juridicalForm}>
        <FormLabel>Forme juridique</FormLabel>
        <Input />
      </FormControl>
      <FormControl size="sm" isInvalid={!!errors.siretNumber}>
        <FormLabel>N° de Siret</FormLabel>
        <Input />
      </FormControl>
      <FormControl size="sm" isInvalid={!!errors.nafCode}>
        <FormLabel>
          N° de code NAF (Nomenclature d'Activités Française)
        </FormLabel>
        <Input />
      </FormControl>
      <FormControl size="sm" isInvalid={!!errors.creationDate}>
        <FormLabel>Date de création</FormLabel>
        <Input />
      </FormControl>
    </Stack>
  );
};
