import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Button,
  Heading,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Stack,
} from "@chakra-ui/react";
import { PopoverTrigger } from "components/PopoverTrigger";
import {
  GetContactTasksDocument,
  OrderStatusEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import {
  useBillableOrders,
  useGetProjects,
  useProjectsWithActivePhases,
} from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiTask } from "react-icons/bi";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { TAB_NAME, useUIStore } from "stores";
import { useQuery } from "urql";

export const NotificationButton: React.FC = () => {
  const navigate = useNavigate();
  const [setFilters] = useUIStore((s) => [s.setProjectFilters]);
  const changeFilter = setFilters(TAB_NAME.billableProjects);
  const { t } = useTranslation();

  const [{ data, fetching: isLoadingTasks }] = useQuery({
    query: GetContactTasksDocument,
  });

  const tasks = data?.tasks ?? [];
  const {
    projects,
    isLoading: isLoadingProjects,
    hasOnlyAT,
  } = useGetProjects();

  const { litigiousCases, billableOrders } = useBillableOrders(projects);

  const { remainingPhasesCount } = useProjectsWithActivePhases(
    projects.filter((p) => p.type !== ProjectTypeEnum.At),
  );

  const isLoading = isLoadingTasks || isLoadingProjects;

  const notificationCount = hasOnlyAT
    ? billableOrders.length + litigiousCases.length
    : remainingPhasesCount +
      tasks.length +
      billableOrders.length +
      litigiousCases.length;

  const isNotification = notificationCount > 0;

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          isDisabled={notificationCount === 0}
          variant={"link"}
          cursor={"pointer"}
          aria-label="Notifications"
          leftIcon={
            <Icon
              as={BiTask}
              fontSize={"xl"}
              display={["none", "none", "inherit"]}
              color={
                isNotification && !isLoading
                  ? "brand.blue.dark"
                  : "neutral.grey.500"
              }
              sx={{ ".active &": { color: "brand.blue.dark" } }}
            />
          }
        >
          {notificationCount > 0 && (
            <Badge
              variant={"solid"}
              colorScheme={"red"}
              rounded={"2xl"}
              pos="absolute"
              top="-3"
              left="4"
              w={6}
              h={5}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              {notificationCount}
            </Badge>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent p={3}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Heading>
            {t("navbar-notifications-title", "Actions à résoudre")}
          </Heading>
        </PopoverHeader>

        <PopoverBody>
          <Stack>
            {litigiousCases.length > 0 && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Vous avez
                  <Link
                    onClick={() => {
                      changeFilter({ status: OrderStatusEnum.Litigation });
                      navigate("/dashboard/billable-projects");
                    }}
                    color={"semantic.alert.dark"}
                  >
                    {litigiousCases.length}
                  </Link>{" "}
                  dossier de facturation en contentieux.
                </AlertDescription>
              </Alert>
            )}
            {remainingPhasesCount > 0 && !hasOnlyAT && (
              <Alert status="info">
                <AlertIcon />
                <AlertDescription>
                  Vous avez
                  <Link
                    as={ReactLink}
                    to={"/dashboard/actions"}
                    color={"semantic.information.dark"}
                  >
                    {remainingPhasesCount}
                  </Link>{" "}
                  phase(s) à saisir.
                </AlertDescription>
              </Alert>
            )}

            {billableOrders.length > 0 && (
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  Vous avez
                  <Link
                    as={ReactLink}
                    to={"/dashboard/billable-projects"}
                    color={"semantic.warning.dark"}
                  >
                    {billableOrders.length} dossier(s) de facturation
                  </Link>
                </AlertDescription>
              </Alert>
            )}

            {tasks.length > 0 && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Vous avez
                  <Link
                    as={ReactLink}
                    to={"/dashboard/tasks"}
                    color={"semantic.error.dark"}
                  >
                    {tasks.length} tâche(s)
                  </Link>
                  en attente.
                </AlertDescription>
              </Alert>
            )}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
