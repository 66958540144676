import { type CaseStatusEnum } from "graphql/__generated__/graphql";

export const STATUS_CASE_DICO: Record<CaseStatusEnum, string> = {
  WAITING: "En attente",
  SENT: "Envoyé",
  LITIGATION: "En contentieux",
  SEND_BACK: "Renvoyé",
  PENDING: "Brouillon",
  CANCELED: "Annulé",
};

export const STATUS_CASE_COLOR: Record<CaseStatusEnum, string> = {
  WAITING: "blue-light",
  PENDING: "blue-light",
  SENT: "blue-light",
  LITIGATION: "red-light",
  SEND_BACK: "grey-light",
  CANCELED: "grey-light",
};
