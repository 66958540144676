import { useToast } from "@chakra-ui/react";
import {
  GenerateUploadUrlDocument,
  type ElementTypeEnum,
} from "graphql/__generated__/graphql";
import { useState } from "react";
import { useMutation } from "urql";

export const useUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, generateUrl] = useMutation(GenerateUploadUrlDocument);
  const toast = useToast();

  const uploadFile = async (file: File, elementType: ElementTypeEnum) => {
    setIsLoading(true);

    try {
      // get presigned url
      const { data } = await generateUrl({
        elementType,
        fileName: file.name,
      });
      const upload = data?.generateUploadUrl;

      // Do upload

      await fetch(upload?.url || "", {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      return upload?.filePath;
    } catch (e) {
      console.error(e);
      toast({ status: "error", title: "Une erreur est survenue." });
    } finally {
      setIsLoading(false);
    }
  };

  return { uploadFile, isLoading };
};
