import { useLogout } from "hooks";
import { FC, useEffect, useMemo } from "react";
import { useAuthStore } from "stores/auth";
import { Provider as UrqlProvider } from "urql";
import { makeUrqlClient } from "./urql";
import { useLocation } from "react-router-dom";

const isDashboardRoute = (pathname: string): boolean => {
  return (
    pathname.startsWith("/dashboard") ||
    pathname.startsWith("/consolidated-view") ||
    pathname.startsWith("/settings")
  );
};

export const UrqlMetaProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { logout } = useLogout();
  const location = useLocation();

  const isLoggedIn = useAuthStore((s) => s.isLoggedIn);

  const client = useMemo(() => {
    if (isLoggedIn === null) {
      return null;
    }

    return makeUrqlClient();
  }, [isLoggedIn]);

  useEffect(() => {
    const handleLogout = async () => {
      if (isLoggedIn === false && isDashboardRoute(location.pathname)) {
        await logout();
      }
    };

    handleLogout().catch((err) => {
      console.error(err);
    });
  }, [isLoggedIn, location.pathname, logout]);

  if (!client) {
    return null;
  }

  return <UrqlProvider value={client}>{children}</UrqlProvider>;
};
