import { type ChakraProps, Link as ChakraLink } from "@chakra-ui/react";
import React from "react";
import { Link, type LinkProps } from "react-router-dom";

export const LinkTo: React.FC<
  {
    label?: string;
    to: string;
  } & ChakraProps &
    LinkProps
> = ({ label, to, ...props }) => (
  <ChakraLink
    as={Link}
    color="brand.blue.dark"
    title={label ?? ""}
    to={to}
    {...props}
  >
    {label}
  </ChakraLink>
);
