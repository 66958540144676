import { Box, Button, Flex, Icon, Tooltip, useToast } from "@chakra-ui/react";
import { useTrackEvent } from "libs/tracking";
import { useTranslation } from "react-i18next";
import { BiCopy } from "react-icons/bi";

export const MailTo: React.FC<{
  mailto: string;
  body: string;
  label: string;
  icon?: React.ReactElement;
}> = ({ mailto, body, label, icon }) => {
  const toast = useToast();
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  return (
    <Flex gap={5} alignItems={"center"}>
      <Box>
        <Button
          variant={"link"}
          as={"a"}
          href={mailto}
          leftIcon={icon}
          onClick={() => trackEvent("Mailto", "mail-to")}
        >
          {label}
        </Button>
      </Box>
      <Tooltip
        label={t("mailto-tooltip", "Copier le template dans le presse papier")}
        placement={"bottom-start"}
      >
        <span>
          <Icon
            as={BiCopy}
            fontSize={"xl"}
            onClick={async (e) => {
              e.stopPropagation();
              await trackEvent("Mailto", "mail-to-clipboard");

              await navigator.clipboard.writeText(body);
              toast({
                title: t("mailto-toast-title", "Copié dans le presse-papier !"),
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            }}
            cursor={"pointer"}
            color={"brand.blue.main"}
          />
        </span>
      </Tooltip>
    </Flex>
  );
};
