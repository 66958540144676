import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  Link,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropzone } from "components";
import { NumberInputCustom } from "components/NumberInputCustom";
import { Tooltip, TooltipError } from "components/Tooltip";
import { addBusinessDays, addDays, format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  ElementTypeEnum,
  GetCaseQuery,
  GetPartnerInvoiceByNameDocument,
  GetProviderOrderQuery,
  OrderStatusEnum,
  ProjectTypeEnum,
  SetCachedBillDocument,
  ValidateIbanDocument,
  type GetPartnerInvoiceByNameQuery,
  type InputBillType,
} from "graphql/__generated__/graphql";
import { useMe, useUpload } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { keyBy, mapValues, sum } from "lodash-es";
import { InvoiceStatus } from "modules/invoice/InvoiceStatus";
import {
  STATUS_COLOR,
  STATUS_DICO,
} from "modules/phase/PhasesTable/PhasesTable.config";
import {
  generateBillingMailTo,
  generateSiretMailTo,
} from "pages/MyCompany/utils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosFlash } from "react-icons/io";
import { RiCloseFill, RiDragDropLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useClient, useMutation } from "urql";
import { toBase64 } from "utils";
import { toDecimal, toDecimalMultiplication, toEuro } from "utils/format";
import { PdfView } from "../PdfView";

import { MailTo } from "components/MailTo";
import { ErrorMessages } from "constants/errorMessages";
import { billSchema, statusColorDico, statusOrderDico } from "../utils";
import { shouldDisplayErrorMessage, shouldGetExistingToast } from "./utils";
import { useTranslation } from "react-i18next";

type InputBill = InputBillType & { file: { b64: string; fullFile: File } };

export const OrderBillModalForm: React.FC<{
  order: GetProviderOrderQuery["order"];
  caseResult: GetCaseQuery["case"];
  onClose: () => void;
}> = ({ order, onClose, caseResult }) => {
  const { me } = useMe();
  const { t } = useTranslation();

  const [{ fetching }, setCachedBill] = useMutation(SetCachedBillDocument);
  const [, validateIban] = useMutation(ValidateIbanDocument);

  const urqlClient = useClient();
  const toast = useToast();
  const { trackEvent } = useTrackEvent();
  const { uploadFile } = useUpload();

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const providerOrderId = order?.id;

  const project = order?.project;
  const type = project?.type;
  const projectReference = project?.reference;
  const isPartialInvoice = project?.isPartialInvoice;
  const paymentDelay = project?.paymentDelay || 30;

  const reference = order?.reference;

  const siret = order?.account?.siret;

  const ordersProvider = order?.providerOrders || [];
  const phases = order?.phases || [];
  const sortedByStartDatePhases = phases.sort((a, b) =>
    a.startDate && b.startDate
      ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      : 0,
  );
  const status = order?.status;
  const invoices = order?.invoices || [];

  const singleCase = caseResult;

  const isAT = type === ProjectTypeEnum.At || type === ProjectTypeEnum.Atf;
  const isATF = type === ProjectTypeEnum.Atf;
  const isForfait = type === ProjectTypeEnum.Forfait;

  const { bill } = singleCase || {};

  const [fastCashValue, setFastCashValue] = useState(!!bill?.fastCash);

  const defaultDeliveryDate = addDays(new Date(), paymentDelay);

  const originalRepartitionByPhaseId = keyBy(
    bill?.originalRepartition,
    (p) => p.phaseId,
  );

  const billableCost = isForfait
    ? sum(
        phases.map(
          (p) =>
            p.costBillable + (originalRepartitionByPhaseId[p.id]?.cost || 0),
        ),
      )
    : sum(
        phases.map((p) =>
          toDecimalMultiplication(
            p.timeBillable + (originalRepartitionByPhaseId[p.id]?.cost || 0),
            p.dailyRate,
          ),
        ),
      );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<InputBill>({
    defaultValues: {
      name: bill?.name,
      deliveryDate: bill?.deliveryDate || defaultDeliveryDate,
      partnerComment: bill?.partnerComment,
      file: undefined,
    },
    // @ts-expect-error
    resolver: yupResolver(billSchema),
  });

  const { deliveryDate: deliveryDateIsDirty } = dirtyFields;

  const costByPhaseDefault = phases.map((p) => {
    const timeToBill = Math.max(0, p.timeDone - p.timeBilled);
    const timeToBillFinal = Math.max(0, Math.min(timeToBill, p.timeBillable)); // we don't want to have negative time to bill
    const costCompletion = Math.max(
      0,
      toDecimalMultiplication(p.completion / 100, p.cost - p.costBilled),
    );

    if (isPartialInvoice) {
      return {
        phaseId: p.id,
        cost: isForfait ? costCompletion : p.dailyRate,
        time: isForfait ? 1 : timeToBillFinal,
      };
    }
    return {
      phaseId: p.id,
      cost: isForfait ? p.costBillable : p.dailyRate,
      time: isForfait ? 1 : p.timeBillable,
    };
  });

  const fileValue = watch("file");

  const [costByPhase, setCostByPhase] = useState(costByPhaseDefault);

  const costByPhaseTotal = sum(costByPhase.map((p) => p.cost || 0));

  const costByPhaseById = keyBy(costByPhase, (p) => p.phaseId);

  const [inputATValue, setInputATValue] = useState(
    mapValues(costByPhaseById, (c) => c.time.toString()),
  );

  const [inputForfaitValue, setInputForfaitValue] = useState(
    mapValues(costByPhaseById, (c) => c.cost.toString()),
  );

  const totalCostAT = sum(
    phases.map((p) =>
      toDecimalMultiplication(p.dailyRate, costByPhaseById[p.id]?.time || 0),
    ),
  );
  const costByPhaseTotalFinal = isForfait ? costByPhaseTotal : totalCostAT;

  useEffect(() => {
    if (bill?.repartition?.length) {
      setCostByPhase(bill.repartition);
      const costByPhaseById = keyBy(bill.repartition, (p) => p.phaseId);

      setInputATValue(mapValues(costByPhaseById, (c) => c.time.toString()));
      setInputForfaitValue(
        mapValues(costByPhaseById, (c) => c.cost.toString()),
      );
    }

    setIsLoading(false);
  }, [setCostByPhase, bill?.repartition]);

  const onSubmit = async (values: InputBill) => {
    await billSchema.validate(values);

    setIsLoading(true);

    const { data } = await urqlClient
      .query<GetPartnerInvoiceByNameQuery>(
        GetPartnerInvoiceByNameDocument,
        {
          name: values.name,
        },
        { requestPolicy: "network-only" },
      )
      .toPromise();

    if (shouldGetExistingToast(data?.invoice, providerOrderId, bill)) {
      toast({
        title: "Numéro de facture existant.",
        description: t(
          "notif_cta_billable_orders_description_existing_bill_number",
          "Un autre dossier de facturation portant ce numéro a déjà été déposé par votre société.",
        ),
        status: "error",
        isClosable: true,
      });

      setIsLoading(false);

      return;
    }

    if (!reference) {
      alert(
        t(
          "notif_cta_billable_orders_description_no_order_reference",
          "Aucune référence de commande n'a été trouvée. Veuillez contacter le support.",
        ),
      );
      setIsLoading(false);

      return;
    }

    let filePath;

    const { file, ...restValues } = values;

    if (file?.fullFile) {
      filePath = await uploadFile(values.file.fullFile, ElementTypeEnum.Bill);
    }

    if (!filePath && !bill?.path && !bill?.attachment) {
      alert(
        t(
          "notif_cta_billable_orders_description_no_attachment",
          "Merci de fournir une pièce jointe.",
        ),
      );
      setIsLoading(false);

      return;
    }

    const setBill = {
      ...restValues,
      id: caseResult.id,
      providerOrderId,
      reference,
      isValidated: true,
      cost: toDecimal(costByPhaseTotalFinal),
      path: filePath,
      siret,
      fastCash: fastCashValue,
      repartition: costByPhase.map(({ phaseId, cost, time }) => ({
        phaseId,
        cost: cost,
        time: time,
      })),
      deliveryDate: fastCashValue
        ? addBusinessDays(new Date(), 10)
        : addDays(new Date(), paymentDelay),
    };

    if (filePath) {
      const { error } = await validateIban({
        fileName: filePath,
        providerOrderId,
      });

      if (error) {
        setIsLoading(false);

        error.graphQLErrors.forEach((e) => {
          if (
            e.extensions.code === ErrorMessages.SAGE_IBAN_VALIDATION_WRONG ||
            e.extensions.code ===
              ErrorMessages.SAGE_IBAN_VALIDATION_NO_EXISTS ||
            e.extensions.code === ErrorMessages.NO_IBAN
          ) {
            toast({
              duration: null,
              render: ({ onClose }) => (
                <Alert status="error">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      {t(
                        "notif_cta_billable_orders_description_iban_error",
                        "Problème avec l'IBAN",
                      )}
                    </AlertTitle>
                    <AlertDescription>
                      <Stack>
                        <Text>{e.message}</Text>

                        <Flex mt={2} align={"center"} justify={"center"}>
                          <Button
                            onClick={onClose}
                            variant={"link"}
                            mr={4}
                            colorScheme="red"
                          >
                            {t("notif_cta_cancel", "Annuler")}
                          </Button>

                          <Button
                            colorScheme="red"
                            data-test="force-save-invoice-button"
                            onClick={async () => {
                              await setCachedBill({
                                bill: setBill,
                              });

                              onClose();
                              toast.closeAll();

                              navigate(`..`);
                            }}
                          >
                            {t(
                              "notif_cta_save_invoice",
                              "Sauvegarder quand même ma facture",
                            )}
                          </Button>
                        </Flex>
                      </Stack>
                    </AlertDescription>
                  </Box>
                </Alert>
              ),
            });
          }
        });
        return;
      }
    }

    await setCachedBill({
      bill: setBill,
    });

    await trackEvent("Submit", "Submit Bill", {
      providerOrderId,
      projectType: project?.type,
    });

    setIsLoading(false);

    navigate(`..`);
  };

  const { mailto, body } = generateBillingMailTo({
    orderRef: reference,
    projectRef: projectReference,
    email: me?.email ?? "",
    firstName: me?.firstName,
    lastName: me?.lastName,
    partnerName: me?.partnerName,
    phone: me?.phone,
  });

  const { mailto: mailtoSiret } = generateSiretMailTo({
    email: me?.email ?? "",
    firstName: me?.firstName,
    lastName: me?.lastName,
    partnerName: me?.partnerName,
    phone: me?.phone,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="bill-modal">
      <Grid templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
        <GridItem
          colSpan={12}
          borderBottom={"1px solid #DCDCDC"}
          mb={20}
          bg={"white"}
          h="80px"
          zIndex={"10"}
          pos={"sticky"}
          top={0}
        >
          <Flex justify={"space-between"} align="center" mb={8} h={"100%"}>
            <Flex>
              <Icon
                as={RiCloseFill}
                color="grey"
                fontSize={30}
                mr={8}
                cursor="pointer"
                onClick={onClose}
              />
              <Heading color="neutral.text.black" size={"xl"}>
                {t("notif_cta_new_bill", "Nouvelle facture")}
              </Heading>
            </Flex>

            <Flex align={"center"} ml={20}>
              <Text fontSize={"lg"} fontWeight={"bold"} mr={16}>
                <Tooltip
                  label={t(
                    "notif_cta_fastcash_service_cost",
                    "Coût du service FastCash : 2% du montant de la commande HT soit {{cost}}. \n Il convient de faire apparaître une ligne FastCash sur votre facture.",
                    { cost: toEuro(costByPhaseTotalFinal * 0.02) },
                  )}
                >
                  {t(
                    "notif_cta_billable_orders_description_amount",
                    "Montant de ma facture",
                  )}
                  :{" "}
                  {toEuro(
                    fastCashValue
                      ? costByPhaseTotalFinal * 0.98
                      : costByPhaseTotalFinal,
                  )}{" "}
                  HT
                </Tooltip>
              </Text>
              <Button
                type={"submit"}
                data-test="save-invoice-button"
                isLoading={fetching || isLoading}
                isDisabled={
                  !siret ||
                  shouldDisplayErrorMessage({
                    billableCost,
                    costByPhaseTotalFinal,
                    orderAmountHT: order?.orderAmountHT || 0,
                    costByPhase,
                    phases,
                    isForfait,
                    originalRepartitionByPhaseId,
                  }).hasError
                }
              >
                {t("notif_cta_save_invoice", "Sauvegarder ma facture")}
              </Button>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={7} overflow={"auto"} pos="relative">
          <Flex direction={"column"} alignItems={"start"} gap={5}>
            {bill?.advComment && (
              <Flex width={"100%"}>
                <Alert status="error">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>Motif de rejet</AlertTitle>
                    <AlertDescription display="block">
                      {bill?.advComment}
                    </AlertDescription>
                  </Box>
                </Alert>
              </Flex>
            )}
            <FormControl size="sm" isRequired isInvalid={!!errors.name}>
              <Flex>
                {!bill?.isBillNumberValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="name">
                  {t(
                    "notif_cta_billable_orders_description_bill_number",
                    "Numéro de la facture",
                  )}
                  <Tooltip
                    label={t(
                      "notif_cta_billable_orders_tooltip_description",
                      "Votre numéro de facture",
                    )}
                  />
                </FormLabel>
              </Flex>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Input
                    data-test="bill-name-input"
                    value={value?.trimStart() || ""}
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired size="sm" isInvalid={!!errors.deliveryDate}>
              <Flex direction={"row"} alignItems={"baseline"}>
                <Flex>
                  {!bill?.isDeliveryDateValid &&
                    status === OrderStatusEnum.Litigation && <TooltipError />}
                  <FormLabel htmlFor="deliveryDate">
                    {t(
                      "notif_cta_billable_orders_description_delivery_date",
                      "Date d'échéance",
                    )}
                    <Tooltip
                      label={t(
                        "notif_cta_billable_orders_tooltip_description_virement",
                        "Date à laquelle le virement sera exécuté si le dossier est valide",
                      )}
                    />
                  </FormLabel>
                </Flex>
              </Flex>

              <Flex justify="space-between">
                <ChakraTooltip
                  label={t(
                    "notif_cta_billable_orders_description_fastcash",
                    "Coût du service FastCash : 2% du montant de la commande HT soit {{cost}}. \n Il convient de faire apparaître une ligne FastCash sur votre facture.",
                    { cost: toEuro(costByPhaseTotalFinal * 0.02) },
                  )}
                >
                  <Flex
                    onClick={() => {
                      setFastCashValue(true);
                    }}
                    border={
                      fastCashValue ? "2px solid #2563eb" : "2px solid #DCDCDC"
                    }
                    data-test="enable-fast-cash"
                    cursor="pointer"
                    p={"12px"}
                    w={"45%"}
                    fontSize="sm"
                    borderRadius="4px"
                    fontWeight={fastCashValue ? "bold" : "normal"}
                    color={fastCashValue ? "#2563eb" : "#333333"}
                    align="center"
                    justify={"space-between"}
                  >
                    <Flex>
                      <Icon
                        as={IoIosFlash}
                        fontSize="18px"
                        color={"#2563eb"}
                        mr={2}
                      />
                      {t(
                        "notif_cta_billable_orders_description_with_fast_cash",
                        "Avec FastCash",
                      )}{" "}
                      (J+10):{" "}
                      {format(addBusinessDays(new Date(), 10), "dd MMMM yyyy", {
                        locale: fr,
                      })}
                    </Flex>
                    <Flex fontWeight={"bold"}>
                      - {toEuro(costByPhaseTotalFinal * 0.02)}
                    </Flex>
                  </Flex>
                </ChakraTooltip>

                <Flex
                  onClick={() => {
                    setFastCashValue(false);
                  }}
                  border={
                    fastCashValue ? "2px solid #DCDCDC" : "2px solid #2563eb"
                  }
                  data-test="disable-fast-cash"
                  cursor="pointer"
                  p={"12px"}
                  fontSize="sm"
                  w={"45%"}
                  fontWeight={fastCashValue ? "normal" : "bold"}
                  borderRadius="4px"
                  color={fastCashValue ? "#333333" : "#2563eb"}
                >
                  {t(
                    "notif_cta_billable_orders_description_no_fast_cash",
                    "Sans FastCash",
                  )}{" "}
                  (J+{paymentDelay}):{" "}
                  {format(addDays(new Date(), paymentDelay), "dd MMMM yyyy", {
                    locale: fr,
                  })}
                </Flex>
              </Flex>

              {!!deliveryDateIsDirty && !fastCashValue && (
                <Text color="semantic.warning.main" fontSize="sm" mt={2}>
                  {t(
                    "notif_cta_billable_orders_description_delivery_date_changed",
                    "Vous avez modifié la date d'échéance de la facture : cela va être transmis à l'ADV en charge du dossier pour instruction.",
                  )}
                </Text>
              )}
              <FormErrorMessage>
                {errors.deliveryDate?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <TableContainer w="100%">
              <Table>
                <Tbody>
                  {sortedByStartDatePhases?.map((phase) => {
                    const timeBillable =
                      phase.timeBillable +
                      (originalRepartitionByPhaseId[phase.id]?.time || 0);

                    const restAT =
                      timeBillable - (costByPhaseById[phase.id]?.time || 0);

                    const costBillable =
                      phase.costBillable +
                      (originalRepartitionByPhaseId[phase.id]?.cost || 0);

                    const restForfait =
                      costBillable - (costByPhaseById[phase.id]?.cost || 0);

                    const costAT = toDecimalMultiplication(
                      costByPhaseById[phase.id]?.time || 0,
                      phase.dailyRate,
                    );

                    return (
                      <Tr key={phase.id} fontSize={"sm"} h={"80px"}>
                        <Td width="35%">
                          <Text
                            isTruncated
                            title={phase.name}
                            whiteSpace={"break-spaces"}
                          >
                            {phase.name}
                          </Text>
                        </Td>
                        {isForfait && (
                          <Td width="10%">
                            <Tag colorScheme={STATUS_COLOR[phase.status]}>
                              {STATUS_DICO[phase.status]}
                            </Tag>
                          </Td>
                        )}

                        {isForfait && (
                          <Td width="20%">
                            <Flex whiteSpace={"nowrap"}>
                              <Tooltip
                                label={
                                  <Flex direction={"column"}>
                                    <Text color="white">
                                      {t(
                                        "notif_cta_billable_orders_description_total",
                                        "Vendu",
                                      )}{" "}
                                      : {toEuro(phase.cost)}
                                    </Text>
                                    <Text color="white">
                                      {t(
                                        "notif_cta_billable_orders_description_product",
                                        "Produit",
                                      )}{" "}
                                      :{" "}
                                      {toEuro(
                                        (phase.cost * phase.completion) / 100,
                                      )}{" "}
                                      ({phase.completion}%)
                                    </Text>
                                    <Text color="white">
                                      {t(
                                        "notif_cta_billable_orders_billed",
                                        "Facturé",
                                      )}{" "}
                                      : {toEuro(phase.costBilled)}
                                    </Text>
                                  </Flex>
                                }
                              >
                                <Flex>
                                  {toEuro(restForfait)} /{toEuro(costBillable)}{" "}
                                  {t(
                                    "notif_cta_billable_orders_available",
                                    "disponibles",
                                  )}
                                </Flex>
                              </Tooltip>
                            </Flex>
                          </Td>
                        )}
                        {isAT && (
                          <Td width="10%">
                            <Flex
                              w={"32px"}
                              align={"center"}
                              justify={"center"}
                            >
                              <ChakraTooltip
                                label={
                                  <Flex direction={"column"}>
                                    <Text color="white">
                                      {phase.ressourceName}
                                    </Text>
                                    <Text color="white">
                                      TJM: {toEuro(phase.dailyRate)}
                                    </Text>
                                  </Flex>
                                }
                              >
                                <Avatar
                                  bg="purple.100"
                                  color="purple.900"
                                  size="sm"
                                  name={phase.ressourceName || ""}
                                />
                              </ChakraTooltip>
                            </Flex>
                          </Td>
                        )}
                        {isATF && (
                          <Td width="15%">
                            <ChakraTooltip
                              label={
                                <Flex direction={"column"}>
                                  <Text color="white">
                                    {t(
                                      "notif_cta_billable_orders_done",
                                      "Consommé",
                                    )}{" "}
                                    : {phase.timeDone}j
                                  </Text>
                                  <Text color="white">
                                    {t(
                                      "notif_cta_billable_orders_billed",
                                      "Facturé",
                                    )}{" "}
                                    : {phase.timeBilled}j
                                  </Text>
                                  <Text color="white">
                                    {t(
                                      "notif_cta_billable_orders_sold",
                                      "Vendu",
                                    )}{" "}
                                    : {phase.timeSold}j
                                  </Text>
                                </Flex>
                              }
                            >
                              <Flex
                                whiteSpace={"nowrap"}
                                justifyContent={"right"}
                              >
                                {timeBillable} prévu
                              </Flex>
                            </ChakraTooltip>
                          </Td>
                        )}

                        {isAT && (
                          <Td isNumeric width="15%">
                            <Flex>{costAT > 0 ? toEuro(costAT) : ""}</Flex>
                          </Td>
                        )}
                        {isForfait && (
                          <Td isNumeric width="25%">
                            <NumberInputCustom
                              max={costBillable}
                              precision={2}
                              min={0}
                              sign="€"
                              isDisabled={!isPartialInvoice}
                              onChange={(stringValue, numberValue) => {
                                setCostByPhase((state) =>
                                  state.map((c) =>
                                    c.phaseId === phase.id
                                      ? {
                                          phaseId: c.phaseId,
                                          cost: toDecimal(numberValue || 0),
                                          time: 1,
                                        }
                                      : c,
                                  ),
                                );
                                setInputForfaitValue({
                                  ...inputForfaitValue,
                                  [phase.id]: stringValue,
                                });
                              }}
                              value={inputForfaitValue[phase.id]}
                            />
                          </Td>
                        )}
                        {isAT && (
                          <Td isNumeric width="25%">
                            <NumberInputCustom
                              min={0}
                              sign="j"
                              step={0.5}
                              isDisabled={!isPartialInvoice}
                              onChange={(stringValue, numberValue) => {
                                setCostByPhase((state) =>
                                  state.map((c) =>
                                    c.phaseId === phase.id
                                      ? {
                                          phaseId: c.phaseId,
                                          time: numberValue || 0,
                                          cost: phase.dailyRate,
                                        }
                                      : c,
                                  ),
                                );
                                setInputATValue({
                                  ...inputATValue,
                                  [phase.id]: stringValue,
                                });
                              }}
                              value={inputATValue[phase.id]}
                            />
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            {costByPhaseTotalFinal == 0 && !isLoading && (
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>
                    {t(
                      "notif_cta_billable_orders_zero_amount",
                      "Montant à zéro",
                    )}
                  </AlertTitle>
                  <AlertDescription display="block">
                    <Text>
                      {t(
                        "notif_cta_billable_orders_zero_amount_description",
                        "Le montant de votre facture est à zéro, vous devez compléter votre facture sur la ou les phases ci-dessus.",
                      )}
                    </Text>
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            {shouldDisplayErrorMessage({
              billableCost,
              costByPhaseTotalFinal,
              orderAmountHT: order?.orderAmountHT || 0,
              costByPhase,
              phases,
              isForfait,
              originalRepartitionByPhaseId,
            }).hasError &&
              !isLoading && (
                <Alert status="error">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>
                      {t(
                        "notif_cta_billable_orders_invalid_amount",
                        "Montant invalide",
                      )}
                    </AlertTitle>
                    <AlertDescription display="block">
                      <Text>
                        {
                          shouldDisplayErrorMessage({
                            billableCost,
                            costByPhaseTotalFinal,
                            orderAmountHT: order?.orderAmountHT || 0,
                            costByPhase,
                            phases,
                            isForfait,
                            originalRepartitionByPhaseId,
                          }).message
                        }
                      </Text>
                      <MailTo
                        mailto={mailto}
                        body={body}
                        label="Contacter un ADV"
                      />
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

            <FormControl
              size="sm"
              display={"flex"}
              justifyContent="space-between"
              mt={8}
            >
              <FormLabel>
                {t("notif_cta_billable_orders_siret", "Numéro de SIRET")}
                <Tooltip
                  label={t(
                    "notif_cta_billable_orders_tooltip_siret",
                    "Numéro de SIRET qui servira à procéder au virement de la facture",
                  )}
                />
              </FormLabel>
              {siret ? (
                <Text fontSize="sm">{siret}</Text>
              ) : (
                <Flex mb={2}>
                  <Alert status="error">
                    <AlertIcon />
                    <Box flex="1">
                      <AlertTitle>
                        {t(
                          "notif_cta_billable_orders_siret_missing",
                          "Données SIRET manquantes",
                        )}
                      </AlertTitle>
                      <AlertDescription display="block">
                        <Text>
                          {t(
                            "notif_cta_billable_orders_siret_missing_description",
                            "Notre service ADV ne dispose pas de votre numéro SIRET ce qui pose problème pour le processus de facturation.",
                          )}
                          <Link color={"brand.red.dark"} href={mailtoSiret}>
                            Renseigner mon SIRET
                          </Link>
                        </Text>
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Flex>
              )}
            </FormControl>

            <Accordion w="100%" defaultIndex={[0]} allowMultiple>
              <AccordionItem border={"none"}>
                <AccordionButton
                  p={0}
                  _hover={{ backgroundColor: "white" }}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <FormLabel>
                    Numéro de l'ordre de service
                    <Tooltip label="Numéro d'ordre de service indiqué sur votre contrat de sous-traitance" />
                  </FormLabel>
                  <Flex>
                    <Text fontSize="sm">Commande n°{reference}</Text>

                    <AccordionIcon fontSize={"25px"} ml={4} />
                  </Flex>
                </AccordionButton>

                <AccordionPanel p={0} my={10}>
                  <Table
                    size={"md"}
                    sx={{
                      Td: { fontSize: "12px", fontWeight: 500 },
                      Thead: { backgroundColor: "#DCDCDC" },
                    }}
                  >
                    <Thead style={{}} py={8}>
                      <Tr>
                        <Th>Statut</Th>
                        <Th>Bon de Commande</Th>
                        <Th isNumeric>Montant HT</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {ordersProvider?.map((op) => (
                        <Tr key={op.name}>
                          <Td w={"250px"}>
                            <Tag colorScheme={statusColorDico[op.status]}>
                              {
                                statusOrderDico[
                                  op.status || OrderStatusEnum.Unknown
                                ]
                              }
                            </Tag>
                          </Td>
                          <Td>{op.name}</Td>

                          <Td isNumeric>{toEuro(op.orderAmountHT)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  {invoices.length > 0 && (
                    <Table
                      size={"md"}
                      mt={8}
                      sx={{
                        Td: { fontSize: "12px", fontWeight: 500 },
                        Thead: { backgroundColor: "#DCDCDC" },
                      }}
                    >
                      <Thead>
                        <Tr>
                          <Th>Statut</Th>
                          <Th>Facture</Th>
                          <Th>Échéance</Th>
                          <Th isNumeric>Montant HT</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {invoices?.map((invoice) => (
                          <Tr key={invoice.id}>
                            <Td w={"250px"}>
                              <InvoiceStatus status={invoice.status} />
                            </Td>
                            <Td>{invoice.name}</Td>
                            <Td>
                              {invoice.echeanceDate
                                ? format(
                                    new Date(invoice.echeanceDate),
                                    "dd/MM/yy",
                                  )
                                : "-"}
                            </Td>
                            <Td isNumeric>{toEuro(invoice.montant)}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <FormControl size="sm">
              <FormLabel htmlFor="comment">Commentaire</FormLabel>
              <Controller
                name={"partnerComment"}
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Textarea
                    p={4}
                    fontSize="sm"
                    height={20}
                    placeholder="Informations complémentaires sur le document"
                    value={value || ""}
                    variant="filled"
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.partnerComment?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </GridItem>
        <GridItem
          colSpan={5}
          alignSelf="start"
          style={{ position: "relative" }}
        >
          <Controller
            render={({ field }) => (
              <Dropzone
                accept={{ "application/pdf": [".pdf"] }}
                onChange={async (e) => {
                  const b64 = await toBase64(e);

                  field.onChange({ b64, fullFile: e });
                }}
                style={{
                  marginTop: 10,
                }}
                label={
                  <Flex
                    align="center"
                    justifyContent="start"
                    width="100%"
                    gap={6}
                  >
                    <Icon
                      as={RiDragDropLine}
                      color={"brand.blue.main"}
                      fontSize={30}
                    />
                    <Box>
                      <Heading color={"neutral.grey.900"}>
                        {fileValue?.fullFile?.name}
                      </Heading>
                      <Text size={"md"} color={"brand.blue.main"}>
                        {bill?.attachment || bill?.path ? (
                          <>Remplacer votre pièce jointe</>
                        ) : (
                          <>Déposer ou parcourir dans votre ordinateur</>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                }
              />
            )}
            control={control}
            name="file"
          />
          {fileValue ? (
            <PdfView base64={fileValue.b64} />
          ) : (
            (bill?.path || bill?.attachment) && (
              <PdfView
                path={bill?.path}
                base64={`data:application/pdf;base64,${
                  bill?.attachment?.b64 ?? ""
                }`}
              />
            )
          )}
        </GridItem>
      </Grid>
    </form>
  );
};
