import { Center, Flex, Spinner } from "@chakra-ui/react";
import { useProjectFilter } from "hooks";
import { groupBy } from "lodash-es";
import React from "react";
import { type IPartnerContactProject } from "types";
import { PROJECT_STATUS_DICO } from "../utils";
import { ProjectFilters } from "./ProjectFilters";
import { ProjectByMarket } from "./ProjectByMarket";
import { type TAB_NAME } from "stores";
import { useTranslation } from "react-i18next";
type ProjectsTableComponentProps = {
  projects?: IPartnerContactProject[];
  isLoading?: boolean;
  hiddenColumns?: string[];
  hiddenColumnsProjects?: string[];
  tab:
    | TAB_NAME.actions
    | TAB_NAME.activeProjects
    | TAB_NAME.finishedProjects
    | TAB_NAME.consolidatedProjects;
};

export const ProjectsTableWithFilters: React.FC<
  ProjectsTableComponentProps
> = ({ projects, isLoading, hiddenColumns, hiddenColumnsProjects, tab }) => {
  const { filteredProjects, statusOptions, setFilters, filters, clear } =
    useProjectFilter(projects ?? [], tab, PROJECT_STATUS_DICO, {
      applyTypeFilter: true,
    });
  const { t } = useTranslation();
  const projectsByMarket = groupBy(
    filteredProjects,
    (p) => p.market || "Sans accord cadre",
  );

  if (isLoading)
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );

  if (!projects) return null;
  return (
    <>
      <ProjectFilters
        status={statusOptions}
        setFilters={setFilters}
        filters={filters}
        clearFilters={clear}
      />
      {filteredProjects.length ? (
        Object.keys(projectsByMarket).map((market) => (
          <ProjectByMarket
            key={tab + market}
            tab={tab}
            hiddenColumns={hiddenColumns}
            hiddenColumnsProjects={hiddenColumnsProjects}
            market={market}
            data={projectsByMarket[market]}
          />
        ))
      ) : (
        <Flex py={10}>
          {t(
            "no_project_match_your_search",
            "Aucun projet ne correspond à votre recherche.",
          )}
        </Flex>
      )}
    </>
  );
};
