import { Box } from "@chakra-ui/react";
import TanStackTable from "components/ReactTable";
import React from "react";
import { type TAB_NAME } from "stores";

import { type IPartnerContactProject } from "types";
import { ProjectsRowSubComponent } from "./ProjectsRowSubComponent";
import { PROJECT_COLUMNS } from "./ProjectsTable.config";

export const TanStackProjectTable: React.FC<{
  data: IPartnerContactProject[];
  hiddenColumns?: string[];
  hiddenColumnsProjects?: string[];
  tab:
    | TAB_NAME.actions
    | TAB_NAME.activeProjects
    | TAB_NAME.finishedProjects
    | TAB_NAME.consolidatedProjects
    | TAB_NAME.tasks;
}> = ({ data, hiddenColumns, hiddenColumnsProjects, tab }) => (
  <Box py={4}>
    <TanStackTable<
      IPartnerContactProject & {
        clientOrders: IPartnerContactProject["clientOrders"];
      }
    >
      columns={PROJECT_COLUMNS.filter(
        (c) => c.id && !hiddenColumnsProjects?.includes(c.id),
      )}
      data={data.map((d) => ({
        ...d,
        orders: [],
        isValidated:
          d.phases.filter((p) => p.isValidated).length === d.phases.length,
      }))}
      tab={tab}
      hiddenColumns={hiddenColumns}
      RowSubComponent={ProjectsRowSubComponent}
    />
  </Box>
);

export default TanStackProjectTable;
