import { Box, Container, Flex } from "@chakra-ui/react";
import { Inops } from "assets";
import {
  InputSearchUsersData,
  NavBarLink,
  SelectUsers,
  WithRoles,
} from "components";
import { GetAllUsersDocument, RolesEnum } from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { truncate } from "lodash-es";
import {
  consolidatedTabsPaths,
  homeTabsPaths,
} from "pages/Home/HomePanel/utils";
import { useTranslation } from "react-i18next";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaBriefcase } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { RiArchiveDrawerLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { rootPaths } from "stores";
import { useQuery } from "urql";
import { AvatarButton } from "./AvatarButton";
import { ConnectedLink } from "./ConnecteedLink";
import { NotificationButton } from "./NotificationsButton";

export const NavBar = () => {
  const { me, isLoading } = useMe();
  const { t } = useTranslation();

  const isAdmin = me?.roles.includes(RolesEnum.ProviderReadOnly);
  const [{ data, fetching }] = useQuery({
    query: GetAllUsersDocument,
    pause: !isAdmin,
  });

  const users = data?.users ?? [];

  return (
    <Container maxW={"1280px"} role="main">
      <Flex
        w="100%"
        h={"80px"}
        align="center"
        borderBottom="1px solid"
        borderColor="neutral.grey.100"
        justify="space-between"
        mb={8}
      >
        <Flex gap={5} align="center">
          <Link to="/dashboard/main" aria-label="Inops home">
            <Inops width={80} height={40} />
          </Link>
          <Box>
            <InputSearchUsersData />
          </Box>
          <WithRoles roles={[RolesEnum.ProviderReadOnly]}>
            <SelectUsers users={users} isLoading={fetching} />
          </WithRoles>
        </Flex>
        <Flex align="center" gap={5} alignItems={"center"}>
          <ConnectedLink webAccesses={me?.webAccesses} />
          <NavBarLink
            icon={MdOutlineDashboard}
            to="/dashboard/main"
            activePath={["/dashboard/main"]}
          >
            {t("navbar_home", "Tableau de bord")}
          </NavBarLink>
          <WithRoles roles={[RolesEnum.ProviderOperational]}>
            <NavBarLink
              icon={RiArchiveDrawerLine}
              to={rootPaths.actions}
              activePath={homeTabsPaths}
              dataTest="my-projects-link"
            >
              {t("navbar_project", "Mes Projets")}
            </NavBarLink>
          </WithRoles>
          <WithRoles
            roles={[RolesEnum.ProviderExecutive, RolesEnum.ProviderSales]}
          >
            <NavBarLink
              icon={FaBriefcase}
              activePath={consolidatedTabsPaths}
              to={rootPaths["consolidated-projects"]}
            >
              {`${t("navbar_view", "Vue")} ${truncate(me?.partnerName || "", { length: 15 })}`}
            </NavBarLink>
          </WithRoles>
          <WithRoles roles={[RolesEnum.ProviderExecutive]}>
            <NavBarLink icon={AiTwotoneSetting} to={rootPaths["role-projects"]}>
              {t("navbar_set_project", "Affectations des projets")}
            </NavBarLink>
          </WithRoles>
          {!isLoading && (
            <>
              <NotificationButton />
              {!!me && <AvatarButton me={me} fetching={fetching} />}
            </>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
