import {
  Center,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MyBillsTab, TabBadgeCustom } from "components";
import {
  GetContactTasksDocument,
  OrderStatusEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import {
  useBillableOrders,
  useGetProjects,
  useProjectsWithActivePhases,
} from "hooks";
import OrdersTable from "modules/order/OrdersTable";
import {
  ActionTab,
  ActiveProjectTab,
  FinishedProjectTab,
} from "modules/project";
import { TaskTab } from "modules/task";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useUIStore } from "stores";
import { type IPartnerContactProject } from "types";
import { useQuery } from "urql";
import { homeTabsLinks, homeTabsLinksAT } from "./utils";

export type ProjectDataProps = {
  projectsData: IPartnerContactProject[];
  isLoading: boolean;
  isDirector?: boolean;
};

const TabTitleWithBadge: React.FC<{
  isActive: boolean;
  nbNotification: number;
  children: React.ReactNode;
  dataTest?: string;
}> = ({ isActive, nbNotification, children, dataTest }) => (
  <>
    <Text
      fontWeight="600"
      data-test={dataTest}
      color={isActive ? "neutral.text.black" : "neutral.grey.500"}
      _hover={{ color: "brand.blue.main" }}
    >
      {children}
    </Text>
    <TabBadgeCustom number={nbNotification} isActive={isActive} />
  </>
);

export const HomePanel: React.FC<{ tab: (typeof homeTabsLinks)[number] }> = ({
  tab,
}) => {
  const navigate = useNavigate();

  const [setProjectType, projectType] = useUIStore((s) => [
    s.setProjectType,
    s.projectType,
  ]);

  const { hasOnlyAT, hasOnlyForfait, isLoading } = useGetProjects();

  const updatedProjectType = useMemo(() => {
    if (hasOnlyAT && projectType.includes(ProjectTypeEnum.Forfait)) {
      return [ProjectTypeEnum.At];
    }
    if (hasOnlyForfait && projectType.includes(ProjectTypeEnum.At)) {
      return [ProjectTypeEnum.Forfait, ProjectTypeEnum.Atf];
    }
    return projectType;
  }, [hasOnlyAT, hasOnlyForfait, projectType]);

  // Update project type if it has changed
  if (updatedProjectType !== projectType) {
    setProjectType(updatedProjectType);
  }

  if (isLoading) {
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );
  }

  if (hasOnlyAT) {
    return (
      <Flex w={"100%"} bg="white" direction={"column"}>
        <Outlet />
        <TabsByProjectType projectType={[ProjectTypeEnum.At]} tab={tab} />
      </Flex>
    );
  }

  if (hasOnlyForfait) {
    return (
      <Flex w={"100%"} bg="white" direction={"column"}>
        <Outlet />
        <TabsByProjectType
          projectType={[ProjectTypeEnum.Forfait, ProjectTypeEnum.Atf]}
          tab={tab}
        />
      </Flex>
    );
  }

  return (
    <Flex w={"100%"} bg="white" direction={"column"}>
      <Outlet />
      <Tabs
        defaultIndex={projectType.includes(ProjectTypeEnum.Forfait) ? 0 : 1}
      >
        <TabList>
          <Tab
            onClick={() => {
              setProjectType([ProjectTypeEnum.Forfait, ProjectTypeEnum.Atf]);
              navigate(`/dashboard/${homeTabsLinks[0]}`);
            }}
          >
            Projet au forfait
          </Tab>
          <Tab
            onClick={() => {
              setProjectType([ProjectTypeEnum.At]);
              navigate(`/dashboard/${homeTabsLinksAT[0]}`);
            }}
          >
            Projet au temps passé
          </Tab>
        </TabList>
      </Tabs>

      <TabsByProjectType projectType={projectType} tab={tab} />
    </Flex>
  );
};

const TabsByProjectType: React.FC<{
  projectType: ProjectTypeEnum[];
  tab: (typeof homeTabsLinks)[number];
}> = ({ projectType, tab }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filters] = useUIStore((s) => [s.projectFilters]);

  const [{ data, fetching: isLoadingTasks }] = useQuery({
    query: GetContactTasksDocument,
  });

  const tasks = data?.tasks ?? [];
  const { projects: allProjects, isLoading: isLoadingProjects } =
    useGetProjects();

  const filteredProjectsByType = allProjects.filter((p) =>
    projectType.includes(p.type),
  );

  const { orders } = useBillableOrders(filteredProjectsByType);

  const status = tab === "billable-projects" ? filters[tab]?.status : undefined;

  const nbNotifications = orders
    .filter((o) => (!status || status === "ALL" ? true : o.status === status))
    .filter(
      (o) =>
        o.status === OrderStatusEnum.Billable ||
        o.status === OrderStatusEnum.Litigation,
    ).length;

  const { phasesCount, remainingPhasesCount, checkedPhases, activeProjects } =
    useProjectsWithActivePhases(filteredProjectsByType);

  useEffect(() => {
    if (
      projectType.includes(ProjectTypeEnum.At) &&
      !homeTabsLinksAT.includes(tab)
    ) {
      navigate(`/dashboard/${homeTabsLinksAT[0]}`);
    }
  }, [projectType]);

  if (projectType.includes(ProjectTypeEnum.At)) {
    return (
      <Tabs
        index={homeTabsLinksAT.indexOf(tab)}
        isLazy={true}
        onChange={(index) => {
          navigate(`/dashboard/${homeTabsLinksAT[index]}`);
        }}
        w={"100%"}
        mt={8}
      >
        <TabList
          pos={"sticky"}
          top="0"
          py={3}
          background={"white"}
          zIndex={9}
          mb={8}
          height={"70px"}
          alignItems={"flex-start"}
        >
          <Tab pos="relative">
            <TabTitleWithBadge
              dataTest="billing-text"
              isActive={tab === "billable-projects"}
              nbNotification={nbNotifications}
            >
              {t("billing", "Facturation")}
            </TabTitleWithBadge>
          </Tab>
          <Tab pos="relative">{t("my_bills", "Mes factures")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <OrdersTable
              isLoading={isLoadingProjects}
              projectsData={filteredProjectsByType}
            />
          </TabPanel>
          <TabPanel px={0}>
            <MyBillsTab
              isLoading={isLoadingProjects}
              projectsData={filteredProjectsByType}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Tabs
      index={homeTabsLinks.indexOf(tab)}
      isLazy={true}
      onChange={(index) => {
        navigate(`/dashboard/${homeTabsLinks[index]}`);
      }}
      w={"100%"}
      mt={8}
    >
      <TabList
        pos={"sticky"}
        top="0"
        py={3}
        background={"white"}
        zIndex={9}
        mb={8}
        height={"70px"}
        alignItems={"flex-start"}
      >
        <Tab pos="relative">
          <TabTitleWithBadge
            isActive={tab === "actions"}
            nbNotification={remainingPhasesCount}
          >
            {t("phase_validation", "Validation de phase")}
          </TabTitleWithBadge>
        </Tab>

        <Tab data-test="all-projects-tab">
          {t("all_my_projects", "Tous mes projets")}
        </Tab>
        <Tab>
          {t("project_under_contract", "Projet en cours de contractualisation")}
        </Tab>

        <Tab pos="relative">
          <TabTitleWithBadge
            dataTest="billing-text"
            isActive={tab === "billable-projects"}
            nbNotification={nbNotifications}
          >
            {t("billing", "Facturation")}
          </TabTitleWithBadge>
        </Tab>
        <Tab pos="relative">{t("my_bills", "Mes factures")}</Tab>
        <Tab pos="relative">
          <TabTitleWithBadge
            isActive={tab === "tasks"}
            nbNotification={tasks.length}
          >
            {t("tasks", "Tâches")}
          </TabTitleWithBadge>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <ActionTab
            isLoading={isLoadingProjects}
            projectsData={activeProjects}
            checkedPhases={checkedPhases}
            phasesCount={phasesCount}
            remainingPhasesCount={remainingPhasesCount}
          />
        </TabPanel>

        <TabPanel px={0}>
          <ActiveProjectTab
            isLoading={isLoadingProjects}
            projectsData={filteredProjectsByType}
          />
        </TabPanel>

        <TabPanel px={0}>
          <FinishedProjectTab
            isLoading={isLoadingProjects}
            projectsData={filteredProjectsByType}
          />
        </TabPanel>

        <TabPanel px={0}>
          <OrdersTable
            isLoading={isLoadingProjects}
            projectsData={filteredProjectsByType}
          />
        </TabPanel>
        <TabPanel px={0}>
          <MyBillsTab
            isLoading={isLoadingProjects}
            projectsData={filteredProjectsByType}
          />
        </TabPanel>

        <TabPanel px={0}>
          <TaskTab tasks={tasks} isLoading={isLoadingTasks} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
