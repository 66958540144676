import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput } from "components";
import { confirmResetPassword } from "libs/auth";
import { useTrackEvent } from "libs/tracking";
import yup from "libs/yup";
import querystring from "query-string";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { type ConfirmInput } from "types";

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .trim()
      .required()
      .min(8)
      .max(99)
      .matches(/[a-z]/, "Au moins un caractère minuscule")
      .matches(/[A-Z]/, "Au moins un caractère majuscule")
      .matches(/[0-9]/, "Au moins un chiffre")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\':;|_~`]/,
        "Au moins un caractère spécial (@,!,#, etc).",
      ),

    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Doit être identique"),
  })
  .required();

export const ConfirmResetPasswordForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm<ConfirmInput>({
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  const { search } = useLocation();
  const { email, code } = querystring.parse(search);

  const onSubmit = async (values: ConfirmInput) => {
    await schema.validate(values);
    await trackEvent("Auth", "confirm-reset-password");

    try {
      await confirmResetPassword(
        email as string,
        code as string,
        values.password,
      );
      toast({
        title: t(
          "auth_reset_password_success",
          "Votre nouveau mot de passe est activé.",
        ),
        status: "success",
      });
      navigate("/login");
    } catch (e) {
      toast({
        title: t(
          "auth_reset_password_error",
          "Oups, une erreur est survenue...",
        ),
        status: "error",
        duration: 9000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        isInvalid={!!errors.password}
        isRequired
        mb={7}
        mt={10}
        onChange={async () => await trigger("confirmPassword")}
      >
        <FormLabel htmlFor="password">
          {t("auth_reset_password_new_password", "Nouveau mot de passe")}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <PasswordInput
              variant="filled"
              data-test="input-password"
              {...field}
            />
          )}
          name="password"
          control={control}
          defaultValue=""
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.confirmPassword} isRequired mb={7}>
        <FormLabel htmlFor="confirmPassword">
          {t(
            "auth_reset_password_confirm_password",
            "Confirmer votre mot de passe",
          )}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <PasswordInput
              variant="filled"
              data-test="input-confirmPassword"
              {...field}
            />
          )}
          name="confirmPassword"
          control={control}
          defaultValue=""
        />
        <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
      </FormControl>
      <Flex justify="center">
        <Button isLoading={isSubmitting} type={"submit"}>
          {t("auth_reset_password_confirm", "Valider")}
        </Button>
      </Flex>
    </form>
  );
};
