import {
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import {
  GetCaseDocument,
  GetProviderOrderDocument,
  OrderStatusEnum,
} from "graphql/__generated__/graphql";
import React from "react";
import { MdCheckCircle } from "react-icons/md";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery } from "urql";
import { OrderBillModal } from "../OrderBillModal";
import { OrderCraModal } from "../OrderCraModal";
import { OrderPvModal } from "../OrderPvModal";
import { OrderValidationModal } from "../OrderValidationModal";
import { OrderModal } from "./OrderModal";
import { OrderModalYooz } from "./OrderModalYooz";
import { useTranslation } from "react-i18next";

export const OrderBillingModal: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const from = location.state?.from || null;

  const { providerOrderId, caseId } = useParams() as {
    providerOrderId: string;
    caseId: string;
  };

  const [{ data: dataOrder, fetching: fetchingOrder, error: errorOrder }] =
    useQuery({
      query: GetProviderOrderDocument,
      variables: { id: providerOrderId },
    });

  const [{ data: dataCase, fetching: fetchingCase, error: errorCase }] =
    useQuery({
      query: GetCaseDocument,
      variables: { id: caseId },
    });

  const onClose = () => {
    if (from) {
      navigate(from);
    } else {
      navigate("../");
    }
  };

  const order = dataOrder?.order;
  const caseResult = dataCase?.case;

  const error = errorOrder || errorCase;
  const fetching = fetchingOrder || fetchingCase;

  if (error) {
    navigate("..");
  }

  if (fetching) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        size="xl"
        returnFocusOnClose={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={8} alignItems={"center"}>
          <Spinner color="blue.600" />
        </ModalContent>
      </Modal>
    );
  }

  if (!caseResult || !order) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onClose}
        size="xl"
        returnFocusOnClose={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={8}>
          <ModalHeader
            color="brand.blue.dark"
            fontWeight="semibold"
            fontSize="xl"
            textAlign="center"
          >
            {order?.project.isPartialInvoice
              ? t(
                  "notif_billable_orders_title_partial_invoice",
                  "Dépôt d’un dossier de facturation pour la commande n°{{reference}}",
                  { reference: order?.name },
                )
              : t(
                  "notif_billable_orders_title_full_invoice",
                  "Toutes les phases de la commande n°{{reference}} sont terminées",
                  { reference: order?.name },
                )}
            <Icon
              as={MdCheckCircle}
              color="semantic.success.main"
              ml={2}
              mb={-0.5}
            />
          </ModalHeader>
          {order?.status === OrderStatusEnum.BillableYooz ? (
            <OrderModalYooz />
          ) : (
            <OrderModal
              order={order}
              onClose={onClose}
              fetching={fetching}
              caseResult={caseResult}
            />
          )}
          <Routes>
            <Route
              path="pv"
              element={<OrderPvModal order={order} caseResult={caseResult} />}
            />
            <Route
              path="cra"
              element={<OrderCraModal order={order} caseResult={caseResult} />}
            />
            <Route
              path="bill"
              element={<OrderBillModal order={order} caseResult={caseResult} />}
            />
            <Route
              path="validation"
              element={
                <OrderValidationModal order={order} caseResult={caseResult} />
              }
            />
          </Routes>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
      <Outlet />
    </>
  );
};
