import {
  Button,
  Center,
  Flex,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { WithRoles } from "components";
import { SubmitModalValidation } from "components/SubmitModalValidation";
import {
  DeleteCachedPhasesDocument,
  PublishCachedPhasesDocument,
  RolesEnum,
  type ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { ProjectsTableWithFilters } from "modules/project/ProjectsTableWithFilters/ProjectsTableWithFilters";
import { DownloadReport } from "modules/report";
import { type ProjectDataProps } from "pages/Home/HomePanel";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TAB_NAME } from "stores";
import { useMutation } from "urql";

const SubmitButton = ({
  onOpen,
  isDisabled,
  isLoading,
  remainingPhasesCount,
}: {
  isDisabled: boolean;
  isLoading: boolean;
  onOpen: () => void;
  remainingPhasesCount: number;
}) => {
  const firstFieldRef = React.useRef(null);
  const [{ fetching: isLoadingDelete }, deleteCachedPhases] = useMutation(
    DeleteCachedPhasesDocument,
  );
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  const onDelete = async () => {
    await deleteCachedPhases({});

    await trackEvent("Reset", "reset-phases");
  };

  return (
    <Flex
      position="sticky"
      bottom={0}
      align="center"
      bg="white"
      py={8}
      justify={"space-between"}
      borderTop={"2px solid #E2E8F0"}
      borderColor="neutral.grey.100"
    >
      <ResetButton onDelete={onDelete} isLoading={isLoadingDelete} />
      <Flex align={"center"}>
        <Text color="brand.blue.main" mr={8}>
          {remainingPhasesCount} phase restante à valider
        </Text>

        <Button
          size={"xl"}
          onClick={onOpen}
          isLoading={isLoading}
          isDisabled={isDisabled}
          ref={firstFieldRef}
          mr={50}
        >
          {t("submit_button", "Soumettre")}
        </Button>
      </Flex>
    </Flex>
  );
};

const ResetButton = ({
  onDelete,
  isLoading,
}: {
  onDelete: () => Promise<void>;
  isLoading: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Button variant="ghost" onClick={onOpen} isLoading={isLoading}>
        {t("reset_button", "Remettre tout à zéro")}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t(
              "reset_modal_title",
              "Souhaitez-vous vraiment remettre à zéro tous vos rapports d’avancement en cours ?",
            )}
          </ModalHeader>
          <ModalCloseButton />

          <ModalFooter justifyContent="center">
            <Button
              data-test="submit-phases"
              onClick={async () => {
                await onDelete();
                onClose();
              }}
              isLoading={isLoading}
            >
              {t("reset_modal_button", "Remettre à zéro")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
const hiddenColumns = ["status", "cost", "actions-adv"];

export const ActionTab: React.FC<
  ProjectDataProps & {
    phasesCount: number;
    checkedPhases: Array<{
      id: string;
      isValidated: boolean | null | undefined;
      projectType: ProjectTypeEnum;
    }>;
    remainingPhasesCount: number;
  }
> = ({
  projectsData,
  isLoading,
  isDirector,
  phasesCount,
  checkedPhases,
  remainingPhasesCount,
}) => {
  const activeProjects = projectsData;
  const { me, isLoading: isLoadingMe } = useMe();
  const { trackEvent } = useTrackEvent();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const [{ fetching: isLoadingPublish }, publishCachedPhase] = useMutation(
    PublishCachedPhasesDocument,
  );

  const onPublish = async () => {
    // get checked phases ids (not necessary as they should be all checked, but let's be sure)
    const ids = checkedPhases?.map((p) => p.id) || [];
    await publishCachedPhase({ ids });

    const idsWithProject =
      checkedPhases?.map((p) => `${p.id} (${p.projectType})`) || [];
    const phaseIds = idsWithProject.join(",");
    await trackEvent("Submit", "submit-phases", { phaseIds });
  };

  useEffect(() => {
    if (remainingPhasesCount === 0 && phasesCount > 0 && !isLoading) {
      onOpen();
    }
  }, [remainingPhasesCount, onOpen]);

  if (isLoading || isLoadingMe)
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );

  if (me?.hasSubmit) {
    return (
      <>
        <Text mt={5} maxWidth={700}>
          {t("submit_success_title", "Félicitations !")}
          <span role="img" aria-label="confetti">
            🎉
          </span>
          <br></br>
          <br></br>
          Merci pour vos actions : vos inputs ont bien été transmis à la cellule
          Opération d’Inop’s, qui va analyser vos données et reviendra vers vous
          en cas de besoin. Pour toute question complémentaire liée à vos
          projets en cours, n’hésitez pas à nous contacter à l’adresse{" "}
          <Link href="mailto:ope@inops.fr">ope@inops.fr</Link>.<br></br>
          Pour mémo, voici une copie du mail contenant votre dernier rapport qui
          vous a été envoyé à votre adresse :
        </Text>
        <DownloadReport />
      </>
    );
  }

  if (!phasesCount) {
    return (
      <Text mt={5}>
        {t(
          "no_report_or_phases_to_validate",
          "Vous n'avez pas encore de rapport ou de phases à valider.",
        )}
      </Text>
    );
  }

  return (
    <>
      <ProjectsTableWithFilters
        projects={activeProjects}
        tab={isDirector ? TAB_NAME.consolidatedProjects : TAB_NAME.actions}
        hiddenColumns={hiddenColumns}
      />
      <WithRoles
        roles={[RolesEnum.ProviderOperational, RolesEnum.ProviderExecutive]}
      >
        <SubmitButton
          remainingPhasesCount={remainingPhasesCount}
          onOpen={onOpen}
          isDisabled={!!remainingPhasesCount}
          isLoading={isLoadingPublish}
        />

        <SubmitModalValidation
          isLoading={isLoadingPublish}
          isOpen={isOpen}
          label={t("submit_modal_label", "Soumission du rapport d’avancement")}
          onClose={onClose}
          dataCy="submit-phases"
          onPublish={async () => {
            await onPublish();
          }}
        />
      </WithRoles>
    </>
  );
};
