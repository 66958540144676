import { Button, Icon } from "@chakra-ui/react";
import {
  PhaseStatusEnum,
  ProjectTypeEnum,
  SetCachedPhaseDocument,
} from "graphql/__generated__/graphql";
import { useHover } from "hooks/useHover";
import { useTrackEvent } from "libs/tracking";
import React from "react";
import { HiCheck } from "react-icons/hi";
import { RiArrowGoBackLine } from "react-icons/ri";
import { type IPhase } from "types";
import { useMutation } from "urql";

type Props = Pick<
  IPhase,
  | "completion"
  | "status"
  | "id"
  | "isValidated"
  | "timeDone"
  | "timeSold"
  | "projectType"
> & {
  size?: string;
  isDisabled?: boolean;
};

export const ValidationPhaseButton: React.FC<Props> = ({
  completion,
  status,
  id,
  isValidated,
  size = "sm",
  isDisabled,
  timeSold,
  timeDone,
  projectType,
}) => {
  if (status !== PhaseStatusEnum.Active) {
    if (
      (projectType === ProjectTypeEnum.At ||
        projectType === ProjectTypeEnum.Atf) &&
      status === PhaseStatusEnum.Finished &&
      timeDone < timeSold
    ) {
      return (
        <ActionButton
          isDisabled={isDisabled}
          size={size}
          completion={completion}
          id={id}
          isValidated={isValidated}
        />
      );
    }

    return null;
  }

  return (
    <ActionButton
      isDisabled={isDisabled}
      size={size}
      completion={completion}
      id={id}
      isValidated={isValidated}
    />
  );
};

const ActionButton: React.FC<
  Pick<IPhase, "completion" | "id" | "isValidated"> & {
    size?: string;
    isDisabled?: boolean;
  }
> = ({ isDisabled, size, completion, id, isValidated }) => {
  const [{ fetching }, savePhase] = useMutation(SetCachedPhaseDocument);
  const { trackEvent } = useTrackEvent();
  const [ref, hovering] = useHover();

  if (isValidated && hovering) {
    return (
      <Button
        isDisabled={isDisabled}
        size={size}
        isLoading={fetching}
        w="100px"
        onClick={async (e) => {
          e.stopPropagation();
          await trackEvent("Check", "check-phase", { id });
          if (completion === null) {
            await savePhase({ phase: { id, completion: 0 } });
          }
          await savePhase({ phase: { id, isValidated: !isValidated } });
        }}
        bg="inherit"
        color="grey.400"
        _hover={{ color: "grey.500", bg: "grey.200" }}
        rightIcon={<Icon as={RiArrowGoBackLine} />}
      >
        Annuler
      </Button>
    );
  }

  return (
    <Button
      // @ts-expect-error
      ref={ref}
      isDisabled={isDisabled}
      size={size}
      isLoading={fetching}
      onClick={async (e) => {
        e.stopPropagation();
        await trackEvent("Check", "check-phase", { id });
        if (completion === null) {
          await savePhase({ phase: { id, completion: 0 } });
        }
        await savePhase({ phase: { id, isValidated: !isValidated } });
      }}
      w="100px"
      bg={isValidated ? "inherit" : "brand.blue.main"}
      color={isValidated ? "neutral.grey.400" : "white"}
      rightIcon={<HiCheck />}
    >
      {isValidated ? "Validée" : "Valider"}
    </Button>
  );
};
