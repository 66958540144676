import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import useMergedRef from "@react-hook/merged-ref";
import { forwardRef, type ReactElement, type ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { type ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { RiDragDropLine } from "react-icons/ri";
import { TbPaperclip } from "react-icons/tb";
import { truncateFileName } from "utils";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  background-color: #edf3ff;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 104px;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9E5FF' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

function nameValidator(file: File) {
  // eslint-disable-next-line no-useless-escape
  if (/\ .[0-9a-z]+$/i.test(file.name)) {
    return {
      code: "name-has-space",
      message: `Le nom du fichier ne doit pas contenir d'espace avant l'extension.`,
    };
  }

  return null;
}

export const Dropzone = forwardRef<
  HTMLInputElement,
  Partial<ControllerRenderProps> & {
    onChange: (...event: any[]) => void;
    label?: ReactNode;
    isLoading?: boolean;
    accept?: any;
    Custom?: ReactElement;
    style?: React.CSSProperties;
  }
>(
  (
    {
      value,
      accept,
      style,
      onChange,
      label = (
        <Flex align="center" justifyContent="start" width="100%" gap={6}>
          <Icon as={RiDragDropLine} color={"brand.blue.main"} fontSize={30} />
          <Box>
            <Heading color={"neutral.grey.900"}>Déposer ou cliquer</Heading>
            <Text size={"md"} color={"brand.blue.main"}>
              ou parcourir dans votre ordinateur
            </Text>
          </Box>
        </Flex>
      ),
      isLoading,
      Custom,
      ...props
    },
    refParent,
  ) => {
    const toast = useToast();
    const { t } = useTranslation();
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      inputRef,
    } = useDropzone({
      multiple: false,
      maxSize: 5000000,
      accept,
      validator: nameValidator,
      onDrop: (files, reject) => {
        if (reject.length && reject[0].errors) {
          const error = reject[0].errors[0];
          const errorCode = error.code;

          if (errorCode === "file-too-large") {
            toast({
              title: t(
                "dropzone-file-too-large",
                "Le fichier doit faire moins de 5Mo.",
              ),
              status: "error",
              duration: 9000,
            });
          } else if (errorCode === "file-invalid-type") {
            toast({
              title: `Le fichier doit être de type : ${Object.keys(accept).join(
                " ",
              )}`,
              status: "error",
              duration: 9000,
            });
          } else if (errorCode === "too-many-files") {
            toast({
              title: t(
                "dropzone-one-file",
                "Il ne peut y avoir qu'un seul fichier.",
              ),
              status: "error",
              duration: 9000,
            });
          } else {
            toast({
              title: reject[0].errors[0].message,
              status: "error",
              duration: 9000,
            });
          }
        } else {
          const file = files[0];

          if (file.size === 0) {
            toast({
              title: t(
                "dropzone-file-empty",
                "Votre PJ ne peut pas être vide.",
              ),
              status: "error",
              duration: 9000,
            });

            return;
          }

          const fileLowercase = new File([file], file.name.toLowerCase(), {
            type: file.type,
            lastModified: file.lastModified,
          });

          onChange(fileLowercase);
        }
      },
    });

    const combinedRef = useMergedRef(refParent, inputRef);

    const containerBody = value ? (
      <Flex
        align="center"
        justifyContent="start"
        width="100%"
        gap={6}
        zIndex={0}
        backgroundColor={"brand.blue.background"}
        rounded={"md"}
        p={5}
        style={style}
      >
        <Icon as={TbPaperclip} fontSize={30} color={"brand.blue.main"} />
        {/* <Icon as={MdCheckCircle} color="semantic.green.dark" /> */}
        <Text variant="semibold" title={value.name}>
          {truncateFileName(value.name, 15)} - {value.size / 1000} ko
        </Text>
        {Custom}
        <Button
          colorScheme={"red"}
          leftIcon={<HiOutlineTrash />}
          isLoading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            onChange(undefined);
          }}
        >
          {t("dropzone-delete-file", "Supprimer")}
        </Button>
      </Flex>
    ) : (
      <>
        {<input {...getInputProps()} {...props} ref={combinedRef} />}
        {isLoading ? <Spinner /> : label}
      </>
    );

    return (
      <div>
        {value ? (
          containerBody
        ) : (
          <Container
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
            style={{ cursor: "pointer", ...style }}
          >
            {<input {...getInputProps()} {...props} ref={combinedRef} />}
            {isLoading ? <Spinner /> : label}
          </Container>
        )}
      </div>
    );
  },
);
