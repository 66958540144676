import {
  forwardRef,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useFormControlContext,
} from "@chakra-ui/react";
import React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";

const getColor = (cx: ReturnType<typeof useFormControlContext>) => {
  if (!cx) {
    return "brand.blue.dark";
  }
  const { isInvalid, isFocused } = cx;

  if (isInvalid) {
    if (isFocused) {
      return "brand.blue.dark";
    }
    return "brand.red.main";
  }
  return "brand.blue.dark";
};

export const PasswordInput = forwardRef(
  (props: React.ComponentPropsWithoutRef<typeof Input>, ref) => {
    const [show, setShow] = React.useState(false);
    const handleClick = () => {
      setShow(!show);
    };
    const context = useFormControlContext();

    return (
      <InputGroup>
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder="********"
          ref={ref}
          {...props}
        />
        <InputRightElement width="4.5rem">
          <Icon
            as={show ? HiEyeOff : HiEye}
            onClick={handleClick}
            color={getColor(context)}
            cursor="pointer"
          />
        </InputRightElement>
      </InputGroup>
    );
  },
);
