import { Flex, Icon, Text } from "@chakra-ui/react";
import { type ColumnDef } from "@tanstack/react-table";
import { FaFolder } from "react-icons/fa";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { type IPartnerContactProject } from "types";
import { PROJECT_STATUS_DICO } from "../utils";

export const PROJECT_TYPE_COLOR: Record<string, string> = {
  MIXED: "blue",
  TIMED_RATE: "blue",
  FIXED_RATE: "blue",
  UNIT: "blue",
};

export const PROJECT_COLUMNS: Array<
  ColumnDef<
    IPartnerContactProject & {
      clientOrders: IPartnerContactProject["clientOrders"];
      isValidated?: boolean;
    }
  >
> = [
  {
    id: "name",
    size: 13,
    cell: ({
      row: {
        original: { name, isValidated, account, id, reference, status },
      },
    }) => {
      const navigate = useNavigate();
      const location = useLocation();

      return (
        <Flex
          title={`${account?.name} ∙ ${reference} ∙ ${name} ∙ ${PROJECT_STATUS_DICO[status]}`}
        >
          <Icon as={FaFolder} fontSize={"40px"} color="grey.400" mr={6} />
          <Flex align={"flex-start"} direction={"column"} gap={1}>
            <Text color={isValidated ? "grey.500" : "neutral.grey.950"}>
              {account?.name}
            </Text>

            <Text
              _hover={{
                textDecoration: "underline",
                color: "blue.600",
              }}
              noOfLines={1}
              color={"grey.500"}
              onClick={() => {
                navigate(`/projects/${id}`, {
                  state: { from: location.pathname },
                });
              }}
            >
              {reference} ∙ {name} ∙ {PROJECT_STATUS_DICO[status]}
            </Text>
          </Flex>
        </Flex>
      );
    },
  },

  {
    id: "count",
    size: 2,
    cell: ({
      row: {
        original: { phases },
      },
    }) => {
      const phasesCount = phases?.length || 0;
      const phasesValidatedCount =
        phases?.filter((phase) => phase.isValidated)?.length || 0;

      if (phasesValidatedCount === phasesCount) {
        return (
          <Flex justify={"right"}>
            <Text noOfLines={1} color={"neutral.grey.500"} align={"right"}>
              {phasesValidatedCount} / {phasesCount} validée
              {phasesCount > 1 && "s"}
            </Text>
          </Flex>
        );
      }
      return (
        <Flex justify={"right"}>
          <Text noOfLines={1} color="brand.blue.main" align={"right"}>
            {phasesCount - phasesValidatedCount} restante
            {phasesCount - phasesValidatedCount > 1 && "s"}
          </Text>
        </Flex>
      );
    },
  },

  {
    id: "details",
    size: 0.5,
    cell: ({ row }) => (
      <Flex justify={"right"}>
        {row.getIsExpanded() ? (
          <Icon
            as={HiChevronUp}
            fontSize={24}
            color={
              row.original.isValidated ? "neutral.grey.500" : "neutral.grey.950"
            }
          />
        ) : (
          <Icon
            as={HiChevronDown}
            fontSize={24}
            color={
              row.original.isValidated ? "neutral.grey.500" : "neutral.grey.950"
            }
          />
        )}
      </Flex>
    ),
  },
];
