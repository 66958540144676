import { Button, Center, Flex, Link, ModalBody, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const OrderModalYooz = () => {
  const { t } = useTranslation();
  return (
    <ModalBody>
      <Flex justify="center" textAlign="center" fontSize={15} mt={8}>
        {t(
          "notif_cta_billable_orders",
          "Vous pouvez dès maintenant procéder à la facturation de votre dossier",
        )}
      </Flex>
      <Flex mt={10} direction="column">
        <Text>
          {t(
            "notif_cta_billable_orders_description_start",
            "A savoir nous retourner tout pièce justificative ainsi que votre facture sur notre plateforme de dépôt et suivi de factures :",
          )}
        </Text>
        <Center my={8}>
          <Button
            as={Link}
            href="https://www.getyooz.com/fr/onlyyooz"
            isExternal
          >
            YOOZ
          </Button>
        </Center>
        <Text>
          Si vous n’avez pas reçu vos identifiants, merci de contacter Romain
          Ossust (<a href="mailto:rossust@inops.fr">rossust@inops.fr</a>) afin
          qu’il vous les transmette.
        </Text>
      </Flex>
    </ModalBody>
  );
};
