import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Icon,
  Progress,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Calendar, Euro } from "assets";
import { MailTo } from "components/MailTo";
import { NavigationBar } from "components/NavigationBar";
import {
  FileTypeEnum,
  GenerateDownloadUrlDocument,
  GetContactProjectsDocument,
  GetPhaseWithAttachmentsDocument,
  OrderStatusEnum,
  PhaseStatusEnum,
  ProjectTypeEnum,
} from "graphql/__generated__/graphql";
import { useMe, useNavigateToBillable } from "hooks";
import { PhaseAttachments } from "modules/phase/PhaseAttachment";
import {
  STATUS_COLOR,
  STATUS_DICO,
} from "modules/phase/PhasesTable/PhasesTable.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaPen, FaRegFileAlt } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { formatDate, formatDateText, toEuro } from "utils/format";
import { generateContactSailsMailTo } from "./MyCompany/utils";

const PhaseDetailsPage = () => {
  const { id } = useParams() as {
    id: string;
  };
  const { me } = useMe();

  const { navigateTobillable } = useNavigateToBillable();

  const location = useLocation();
  const { t } = useTranslation();

  const from = location.state?.from || null;

  const navigate = useNavigate();

  const [{ data }] = useQuery({
    query: GetContactProjectsDocument,
  });

  const projects = data?.projects ?? [];
  const phases = projects.flatMap((p) =>
    p.phases.map((ph) => ({ ...ph, project: p })),
  );

  const phase = phases.find((p) => p.id === id);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [{ fetching: fetchingPJ }, generateDownloadUrl] = useMutation(
    GenerateDownloadUrlDocument,
  );

  const [{ fetching: phaseFetching, data: phaseData, error }] = useQuery({
    query: GetPhaseWithAttachmentsDocument,
    variables: {
      id,
    },
  });

  const phaseWithAttachment = phaseData?.phase;

  const phaseCase = phaseWithAttachment?.clientOrders.flatMap((order) =>
    order.providerOrders.flatMap((op) => op.cases),
  );

  const pvs = phaseCase?.flatMap((p) => p.pv?.attachment) ?? [];
  const bills = phaseCase?.flatMap((c) => c.bill?.attachment) ?? [];
  const crasCases = phaseCase?.flatMap((c) => c.cra?.attachment) ?? [];

  const { type, isPartialInvoice, ownerContact } = phase?.project || {};

  const isAT = type === ProjectTypeEnum.At || type === ProjectTypeEnum.Atf;

  const isForfait = type === ProjectTypeEnum.Forfait;

  const ordersBillable = phase?.clientOrders.filter(
    (o) =>
      o.status === OrderStatusEnum.Billable ||
      o.status === OrderStatusEnum.Litigation,
  );

  const crasPhases =
    phaseWithAttachment?.attachments?.filter(
      (file) => file?.type === FileTypeEnum.Cra,
    ) ?? [];

  const cras = [...crasCases, ...crasPhases];

  const attachmentsWithoutCra =
    phaseWithAttachment?.attachments?.filter(
      (file) => file?.type !== FileTypeEnum.Cra,
    ) ?? [];

  const isPhaseStatusFinishedButImcomplete =
    phase?.status === PhaseStatusEnum.Finished &&
    phase.timeDone < phase.timeSold;

  if (error) {
    navigate("..");
  }

  if (!phase) return null;

  const completionChange =
    phase.completion - (phase.originalFields?.completion || 0);

  const doneTimeWarning = phase.timeDone > phase.timeSold;
  const billedTimeWarning =
    phase.timeBilled < phase.timeDone && isPartialInvoice;

  const { mailto, body } = generateContactSailsMailTo({
    inopsSaleEmail: ownerContact?.email,
    inopsSaleFirstName: ownerContact?.firstName,
    partnerEmail: me?.email,
    partnerLastName: me?.lastName,
    partnerName: me?.firstName,
    partnerPhone: me?.phone,
    phaseName: phaseData?.phase.name,
  });

  return (
    <Box
      w={"100%"}
      pb={"80px"}
      borderRadius={"10px"}
      minHeight="calc(100vh - 112px)"
    >
      <Container maxWidth="100%">
        <Outlet />
        <Flex py={"24px"} align="center">
          <NavigationBar projectId={phase.projectId} phaseId={phase.id} />

          <Spacer />

          {(phase.status === PhaseStatusEnum.Active ||
            isPhaseStatusFinishedButImcomplete) && (
            <Button
              rightIcon={<FaPen />}
              onClick={() => {
                navigate(`/phases/${id}/edit`, {
                  state: { from },
                });
              }}
            >
              {t("edit", "Éditer")}
            </Button>
          )}
        </Flex>
        <Container
          border="1px solid #e2e8f0"
          p={12}
          maxW={"100%"}
          borderRadius={"10px"}
        >
          <Tag colorScheme={STATUS_COLOR[phase.status]}>
            {STATUS_DICO[phase.status] ||
              t("waiting_for_approval", "En attente")}
          </Tag>
          <Flex
            justify={"start"}
            gap={"48px"}
            fontSize={"14px"}
            fontWeight={500}
            my={"32px"}
          >
            <Flex gap="16px">
              <Euro fontSize={"40px"} />
              <Flex direction={"column"}>
                <Text>{toEuro(phase.cost)}</Text>
                <Text color="grey.500">
                  {t("total_amount", "Montant total")}
                </Text>
              </Flex>
              <Divider orientation="vertical" ml="24px" />
            </Flex>

            <Flex gap="16px">
              <Calendar fontSize={"40px"} />
              <Flex direction={"column"}>
                <Text>{formatDateText(phase.startDate)}</Text>
                <Text color="grey.500">
                  {t("contractual_start_date", "Date de début contractuelle")}
                </Text>
              </Flex>
              <Divider orientation="vertical" ml="24px" />
            </Flex>
            <Flex gap="16px">
              <Calendar fontSize={"40px"} />
              <Flex direction="column">
                <Text>{formatDateText(phase.contractualDeliveryDate)}</Text>
                <Text color="grey.500">
                  {t("contractual_deadline", "Échéance contractuelle")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {ordersBillable &&
            ordersBillable.map((order) => {
              if (order.status === OrderStatusEnum.Billable) {
                return (
                  <Alert key={order.id} m={2} status={"warning"}>
                    <AlertIcon />
                    <Flex width={"100%"} justify={"space-between"}>
                      <Flex>
                        <AlertTitle>
                          {t(
                            "billable_order",
                            "La facturation est disponible pour la commande n°",
                          )}
                        </AlertTitle>
                        <AlertDescription>{order.reference}</AlertDescription>
                      </Flex>
                      <Button
                        variant={"link"}
                        color={"semantic.warning.dark"}
                        onClick={() =>
                          navigateTobillable(order.reference, from)
                        }
                      >
                        {t("go_to_billable", "Aller à la facturation")}
                      </Button>
                    </Flex>
                  </Alert>
                );
              }

              if (order.status === OrderStatusEnum.Litigation) {
                return (
                  <Alert key={order.id} m={2} status={"error"}>
                    <AlertIcon />
                    <Flex width={"100%"} justify={"space-between"}>
                      <Flex>
                        <AlertTitle>
                          {t(
                            "litigation_order",
                            "La commande suivante est en litige:",
                          )}
                        </AlertTitle>
                        <AlertDescription>{order.reference}</AlertDescription>
                      </Flex>
                      <Button
                        variant={"link"}
                        color={"semantic.error.dark"}
                        onClick={() =>
                          navigateTobillable(order.reference, from)
                        }
                      >
                        {t("go_to_litigation", "Aller à la litigation")}
                      </Button>
                    </Flex>
                  </Alert>
                );
              }
              return null;
            })}
          {isForfait && (
            <Flex
              my="40px"
              borderRadius={"10px"}
              p="32px"
              direction={"column"}
              gap="24px"
              bg={completionChange >= 0 ? "cyan.50" : "red.50"}
            >
              <Flex justify={"space-between"}>
                <Text>{t("progress", "Avancement")}</Text>
                <Flex align="center" gap="16px">
                  <Text>{phase.completion}%</Text>
                  {phase.updatedAt && (
                    <Text
                      fontSize={"12px"}
                      color={completionChange >= 0 ? "cyan.500" : "red.500"}
                    >
                      {completionChange >= 0 ? "+" : ""}
                      {completionChange}% depuis le{" "}
                      {formatDate(phase.updatedAt)}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Progress value={phase.completion} height="6px" bg="cyan.100" />
            </Flex>
          )}
          {isAT && (
            <Flex gap="24px" my="40px">
              <Flex
                borderRadius={"10px"}
                p="32px"
                direction={"column"}
                gap="16px"
                flex="1"
                bg={"cyan.50"}
              >
                <Text fontSize={"24px"} color="cyan.500">
                  {phase.timeSold}
                </Text>
                <Text>{t("sold_days", "jours vendus")}</Text>
              </Flex>
              <Flex
                borderRadius={"10px"}
                p="32px"
                direction={"column"}
                gap="16px"
                bg={"cyan.50"}
                flex="1"
                hidden={isAT}
              >
                <Text fontSize={"24px"} color="cyan.500">
                  {phase.timeDone}
                </Text>
                <Text>{t("consumed_days", "jours consommés")}</Text>
              </Flex>
              <Flex
                borderRadius={"10px"}
                p="32px"
                direction={"column"}
                gap="16px"
                bg={"cyan.50"}
                flex="1"
              >
                <Text fontSize={"24px"} color="cyan.500">
                  {phase.timeBilled}
                </Text>
                <Text>{t("billed_days", "jours facturés")}</Text>
              </Flex>
            </Flex>
          )}
          <Flex direction={"column"} gap="16px">
            {doneTimeWarning && !isAT && (
              <Alert status="warning" flexDir={"row"}>
                <AlertIcon />
                <Flex direction={"column"} gap={3}>
                  <AlertTitle>
                    {t(
                      "time_consumed_gt_time_sold",
                      "Temps consommé > Temps vendu",
                    )}
                  </AlertTitle>
                  <AlertDescription>
                    {t(
                      "contact_engineer",
                      "Prenez contact avec notre ingénieur d'affaire pour régulariser votre situation.",
                    )}
                  </AlertDescription>

                  <MailTo
                    mailto={mailto}
                    body={body}
                    label={t(
                      "notify_engineer",
                      "Notifier mon ingénieur d'affaire",
                    )}
                  />
                </Flex>
              </Alert>
            )}
            {billedTimeWarning && (
              <Alert status="warning" flexDir={"row"}>
                <AlertIcon />
                <Flex direction={"column"} gap={3}>
                  <AlertTitle>
                    {t(
                      "billed_time_gt_consumed_time",
                      "Temps consommé > facturé",
                    )}
                  </AlertTitle>
                  <AlertDescription>
                    {t(
                      "billed_time_gt_consumed_time_description",
                      "N'oubliez pas de nous facturer les {time} jours manquants",
                      { time: phase.timeDone - phase.timeBilled },
                    )}
                  </AlertDescription>
                </Flex>
              </Alert>
            )}
          </Flex>

          <Flex
            my="40px"
            borderRadius={"10px"}
            bg="grey.100"
            p="32px"
            direction={"column"}
            gap="16px"
          >
            {isAT && (
              <Flex justify="space-between" fontSize={"14px"}>
                <Text color="grey.500">{t("resource", "Ressource")}</Text>
                <Text>{phase.ressourceName}</Text>
              </Flex>
            )}
            <Flex justify="space-between" fontSize={"14px"}>
              <Text color="grey.500">
                {t("client_order_number", "N° BDC Client")}
              </Text>
              <Text>
                {phase.clientOrders.map((el) => el.reference).join(" ")}
              </Text>
            </Flex>
            <Flex justify="space-between" fontSize={"14px"}>
              <Text color="grey.500">{t("total_amount", "Montant total")}</Text>
              <Text>{toEuro(phase.cost)}</Text>
            </Flex>
            <Flex justify="space-between" fontSize={"14px"} hidden={isAT}>
              <Tooltip
                label={t(
                  "engaged_amount_not_billed",
                  "Le montant engagé n’est pas le montant facturé, mais bien le produit des sommes des avancements par rapport au prix des phases.",
                )}
              >
                <Text color="grey.500">
                  {t("engaged_amount", "Montant engagé *")}
                </Text>
              </Tooltip>
              <Text> {toEuro((phase.cost / 100) * phase.completion)}</Text>
            </Flex>
          </Flex>
          {phase.comment && (
            <Flex
              my="40px"
              borderRadius={"10px"}
              bg="grey.100"
              p="32px"
              direction={"column"}
              gap="16px"
            >
              <Text fontWeight={600}>{t("comment", "Commentaire")}</Text>
              <Text fontWeight={500} fontSize={"14px"} color="grey.600">
                {phase.comment || "-"}
              </Text>
            </Flex>
          )}
          <Stack gap="40px">
            <Flex direction={"column"} gap="16px">
              <Text>
                {t("attached_campaign_piece", "Pièce jointe de la campagne")}
              </Text>
              {phase.proofPath ? (
                <Flex
                  key={id}
                  bg="blue.50"
                  p="8px 24px"
                  borderRadius={"10px"}
                  fontSize={"14px"}
                  fontWeight={500}
                  justify={"space-between"}
                >
                  <Flex align="center" gap="8px">
                    <Icon as={FaRegFileAlt} fontSize={"24px"} />
                    <Flex direction="column">
                      <Text isTruncated color="blue.950" fontWeight={500}>
                        {phase.proofPath.replace("proof/", "")}
                      </Text>
                    </Flex>
                  </Flex>

                  <Button
                    isLoading={fetchingPJ}
                    variant={"link"}
                    onClick={async () => {
                      if (!phase.proofPath) return null;

                      const { data } = await generateDownloadUrl({
                        fileName: phase.proofPath,
                      });

                      if (!data?.generateDownloadUrl) return null;
                      window.open(data.generateDownloadUrl, "_target");
                    }}
                    rightIcon={<FiEye />}
                  >
                    {t("see", "Voir")}
                  </Button>
                </Flex>
              ) : (
                <Text fontSize={"14px"} color="grey.600">
                  {t("no_attached_piece", "Aucune pièce jointe")}
                </Text>
              )}
            </Flex>
            <Flex direction={"column"} gap="16px" hidden={isAT}>
              <Text>{t("your_pv", "Vos PV")}</Text>
              <PhaseAttachments attachments={pvs} fetching={phaseFetching} />
            </Flex>
            {isAT && (
              <Flex
                direction={"column"}
                gap="16px"
                hidden={!phase.project.isCRAManaged}
              >
                <Text>{t("your_cra", "Vos CRA")}</Text>
                <PhaseAttachments attachments={cras} fetching={phaseFetching} />
              </Flex>
            )}
            <Flex direction={"column"} gap="16px">
              <Text>{t("your_bills", "Vos factures")}</Text>
              <PhaseAttachments attachments={bills} fetching={phaseFetching} />
            </Flex>
            <Flex direction={"column"} gap="16px">
              <Text>
                {t("previous_piece", "Pièce(s) jointe(s) précédentes")}
              </Text>
              <PhaseAttachments
                attachments={attachmentsWithoutCra}
                fetching={phaseFetching}
              />
            </Flex>
          </Stack>
        </Container>
      </Container>
    </Box>
  );
};

export default PhaseDetailsPage;
