import { TAB_NAME, rootPaths } from "stores";

export const homeTabsLinks = [
  TAB_NAME.actions,
  TAB_NAME.activeProjects,
  TAB_NAME.finishedProjects,
  TAB_NAME.billableProjects,
  TAB_NAME.invoices,
  TAB_NAME.tasks,
];
export const homeTabsLinksAT = [TAB_NAME.billableProjects, TAB_NAME.invoices];

export const homeTabsPaths = [
  rootPaths.actions,
  rootPaths["active-projects"],
  rootPaths["finished-projects"],
  rootPaths["billable-projects"],
  rootPaths.invoices,
  rootPaths.tasks,
];

export const consolidatedTabsPaths = [
  rootPaths["consolidated-bills"],
  rootPaths["consolidated-invoices"],
  rootPaths["consolidated-projects"],
];
