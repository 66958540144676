import { Box, Flex, Icon, Link, Spacer, Tag, Text } from "@chakra-ui/react";
import { ProjectStatusEnum } from "graphql/__generated__/graphql";
import React from "react";
import { HiSearch } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { type NullPartial } from "types/utils";
import { PROJECT_STATUS_COLOR, PROJECT_STATUS_DICO } from "./utils";
import { IPartnerContactProject } from "types";
import { useTranslation } from "react-i18next";

export const ProjectRow: React.FC<NullPartial<IPartnerContactProject>> = ({
  name,
  status,
  account,
  reference,
  id,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Flex
      my={4}
      border="1px solid transparent"
      borderRadius="md"
      bg="brand.blue.background"
      _hover={{ borderColor: "brand.blue.light" }}
      align="center"
      px={4}
    >
      <Box p={4}>
        <Tag
          colorScheme={
            PROJECT_STATUS_COLOR[status || ProjectStatusEnum.Unknown]
          }
        >
          {PROJECT_STATUS_DICO[status || ProjectStatusEnum.Unknown]}
        </Tag>
      </Box>

      <Text
        p={4}
        color="brand.blue.dark"
        fontWeight="semibold"
        title={account?.name || ""}
      >
        {account?.name}
      </Text>
      <Text p={4} fontWeight="semibold" title={name || ""}>
        {name}
      </Text>
      <Spacer />
      <Text p={4} color="neutral.grey.700" fontWeight="semibold">
        {reference}
      </Text>
      <Flex p={4} align="center">
        <Icon color="brand.blue.dark" as={HiSearch} mr={2} />
        <Link
          whiteSpace={"nowrap"}
          onClick={(e) => {
            e.stopPropagation();

            navigate(`/projects/${id}`, {
              state: { from: location.pathname },
            });
          }}
        >
          {t("see_detail", "Voir le détail")}
        </Link>
      </Flex>
    </Flex>
  );
};
