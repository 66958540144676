import {
  Center,
  chakra,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useTrackEvent } from "libs/tracking";
import { debounce, groupBy, keyBy, map, uniqBy } from "lodash-es";
import { STATUS_DICO } from "modules/phase/PhasesTable/PhasesTable.config";
import { PROJECT_STATUS_DICO } from "modules/project/utils";
import React, { useState } from "react";
import { HiSearch } from "react-icons/hi";
import { MdSubdirectoryArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { matchTerm } from "utils";
import { ITEM_TYPE_DICO, ItemTypeEnum } from "./dicos";

import {
  GetContactProjectsDocument,
  GetContactTasksDocument,
} from "graphql/__generated__/graphql";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { type IconType } from "react-icons";
import {
  FaAtom,
  FaBox,
  FaFileInvoiceDollar,
  FaProjectDiagram,
  FaTasks,
} from "react-icons/fa";
import { useQuery } from "urql";

export const InputSearch: React.FC<{
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  size?: "sm" | "md" | "lg" | "xs";
}> = ({ onChange, value, placeholder, size }) => (
  <InputGroup width={480}>
    <Input
      borderRadius="10px"
      height={"40px"}
      size={size}
      width="inherit"
      type="search"
      placeholder={placeholder ?? "Filtrer par bénéficiaire, projet, phase ..."}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  </InputGroup>
);

type ItemPropsType = {
  type: ItemTypeEnum;
  value: string;
  label: string;
  subLabel: string;
};

export const InputSearchUsersData = () => {
  const [{ data: dataProjects, fetching: isProjectsLoading }] = useQuery({
    query: GetContactProjectsDocument,
  });
  const [{ data, fetching: isTaskLoading }] = useQuery({
    query: GetContactTasksDocument,
  });

  const tasks = data?.tasks ?? [];
  const projects = dataProjects?.projects ?? [];

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
    onToggle,
  } = useDisclosure();

  const phases = projects
    .flatMap((p) => p.phases)
    .map((phase) => {
      const date = phase.adjustedDeliveryDate ?? phase.contractualDeliveryDate;
      const displayDate = date ? format(date, "dd-MM-yyyy") : "";
      return {
        type: ItemTypeEnum.Phase,
        value: phase.id,
        label: phase.name,
        subLabel: `${phase.completion}% ${displayDate} ${
          STATUS_DICO[phase.status]
        } ${phase.clientOrders.map((el) => el.reference).join(" ")}`,
      };
    });

  const bills = projects
    .flatMap((p) =>
      p.invoices.map((invoice) => ({ ...invoice, projectId: p.id })),
    )
    .map((bill) => ({
      type: ItemTypeEnum.Invoice,
      value: bill.projectId,
      label: bill.name ?? "",
      subLabel: `${bill.ref || ""} ${bill.montant || 0}`,
    }));

  const clearedProjects = projects.map((project) => ({
    type: ItemTypeEnum.Project,
    value: project.id,
    label: project.name,
    subLabel: `${project.account?.name || ""} ${project.reference} ${
      PROJECT_STATUS_DICO[project.status]
    }`,
  }));

  const clearedTasks = tasks.map((task) => ({
    type: ItemTypeEnum.Task,
    value: task.id,
    label: task.description ?? "",
    subLabel: `${task.phase?.name}`,
  }));

  const ordersObj = uniqBy(
    projects.flatMap((project) =>
      project.clientOrders.flatMap((o) =>
        o.providerOrders.map((op) => ({
          ...op,
          reference: o.reference,
          project,
        })),
      ),
    ),
    (order) => order.id,
  );

  const ordersById = keyBy(ordersObj, (order) => order?.reference ?? "");

  const orders = ordersObj.map((order) => ({
    type: ItemTypeEnum.Order,
    value: order.reference ?? "",
    label: order.name,
    subLabel: `${order.project.name} ${order.reference}`,
  }));

  const items = [
    ...clearedProjects,
    ...clearedTasks,
    ...phases,
    ...bills,
    ...orders,
  ];

  const isLoading = isProjectsLoading || isTaskLoading;
  const initialRef = React.useRef(null);
  const { t } = useTranslation();

  const [inputItems, setInputItems] = useState<ItemPropsType[]>([]);
  const [term, setTerm] = useState("");
  const [recentSearch, setRecentSearch] = useState<string[]>([]);
  const { trackEvent } = useTrackEvent();

  const navigate = useNavigate();
  const itemsByKey = keyBy(
    items,
    (item) => item.value + item.type + item.label,
  );

  const resetAndNavigateTo = (path: string) => {
    setTerm("");
    navigate(path);
  };

  useHotkeys(
    "ctrl+k",
    async () => {
      await trackEvent("Search", "search-modal-open-keyboard");
      onToggle();
    },
    { enableOnFormTags: true, preventDefault: true },
    [isModalOpen],
  );

  const onChange = (e?: ItemPropsType | null) => {
    if (!e) {
      return;
    }
    setRecentSearch((state) => [...state, e.value + e.type + e.label]);
    if (e.type === ItemTypeEnum.Phase) resetAndNavigateTo(`/phases/${e.value}`);
    if (e.type === ItemTypeEnum.Project)
      resetAndNavigateTo(`/projects/${e.value}`);
    if (e.type === ItemTypeEnum.Task) resetAndNavigateTo(`/tasks/${e.value}`);
    if (e.type === ItemTypeEnum.Invoice)
      resetAndNavigateTo(`/projects/${e.value}/invoices`);
    if (e.type === ItemTypeEnum.Order) {
      resetAndNavigateTo(`/projects/${ordersById[e.value].project.id}`);
    }
    onModalClose();
  };

  const inputItemsByType = groupBy(inputItems, (i) => i.type);

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <HiSearch size={"18px"} />
        </InputLeftElement>
        <Input
          value={term}
          readOnly
          rounded={"md"}
          placeholder={t("search", "Rechercher...")}
          onClick={async () => {
            await trackEvent("Search", "search-modal-open");
            onModalOpen();
          }}
          onKeyDown={(e) => {
            // when enter is pressed we open the modal
            if (e.key === "Enter" || e.key === " ") {
              onModalOpen();
            }
          }}
        />
        <InputRightElement mr={4}>
          <Kbd>Ctrl</Kbd>+<Kbd>K</Kbd>
        </InputRightElement>
      </InputGroup>
      <Modal
        isOpen={isModalOpen}
        initialFocusRef={initialRef}
        onClose={onModalClose}
        size={"4xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent py={8}>
          <ModalCloseButton />

          <ModalHeader fontSize={"2xl"} color={"brand.blue.dark"}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <HiSearch size={"18px"} />
              </InputLeftElement>
              <Input
                ref={initialRef}
                placeholder={
                  "Rechercher un projet, une phase ou une facture ..."
                }
                defaultValue={term}
                onChange={debounce(async (e) => {
                  const term = e.target.value;
                  await trackEvent("Search", "search-modal-type", { term });

                  setTerm(term);
                  if (term === "" || term.length <= 2) {
                    setInputItems([]);
                  } else {
                    setInputItems(matchTerm(items, term));
                  }
                }, 200)}
              />
              <InputRightElement pointerEvents="none">
                <Kbd>ESC</Kbd>
              </InputRightElement>
            </InputGroup>
          </ModalHeader>
          <ModalBody>
            {isLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {inputItems.length ? (
              map(inputItemsByType, (inputs, key) => (
                <List key={key}>
                  <Heading py={4}>
                    {ITEM_TYPE_DICO[key as ItemTypeEnum]} ({inputs.length}{" "}
                    résultats)
                  </Heading>
                  {inputs.length &&
                    inputs.map((item) => (
                      <Line
                        key={item.value + item.type + item.label}
                        item={item}
                        term={term}
                        onChange={onChange}
                      />
                    ))}
                </List>
              ))
            ) : term.length >= 3 ? (
              <Text p={4} size="sm">
                Aucun résultat pour "{term}"
              </Text>
            ) : (
              <>
                <Flex
                  direction={"row"}
                  align="center"
                  justify={"space-between"}
                >
                  <Heading size="sm" mb={6} mt={4}>
                    Recherches récentes
                  </Heading>
                  {!!recentSearch.length && (
                    <Link
                      onClick={() => {
                        setRecentSearch(() => []);
                      }}
                    >
                      Supprimer
                    </Link>
                  )}
                </Flex>

                {recentSearch.length ? (
                  <List>
                    {recentSearch.map((el) => {
                      const item = itemsByKey[el];
                      return (
                        item && (
                          <Line
                            key={item.value + item.type + item.label}
                            item={item}
                            term={term}
                            onChange={onChange}
                          />
                        )
                      );
                    })}
                  </List>
                ) : (
                  <Text size="sm">Aucune recherche récente</Text>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconDico: Record<ItemTypeEnum, IconType> = {
  Invoice: FaFileInvoiceDollar,
  Phase: FaProjectDiagram,
  Project: FaAtom,
  Task: FaTasks,
  Order: FaBox,
};

const Line: React.FC<{
  item: ItemPropsType;
  term: string;
  onChange: (e?: ItemPropsType | null) => void;
}> = ({ item, term, onChange }) => (
  <ListItem
    _hover={{ bg: "brand.blue.light" }}
    onClick={() => {
      onChange(item);
    }}
    p={4}
    bg={"brand.blue.background"}
    cursor="pointer"
    mb={4}
    borderRadius="md"
  >
    <Flex align="center" justify={"space-between"}>
      <Icon as={IconDico[item.type]} color="neutral.grey.500" mr={4} />
      <Flex direction={"column"}>
        <Text
          fontSize="small"
          color="neutral.grey.500"
          title={item.label ?? ""}
        >
          {reactStringReplace(item.label ?? "", term, (match) => (
            <chakra.span fontWeight={"bold"} color="black">
              {match}
            </chakra.span>
          ))}
        </Text>{" "}
        <Text
          fontSize="x-small"
          color="neutral.grey.500"
          title={item.subLabel ?? ""}
        >
          {reactStringReplace(item.subLabel ?? "", term, (match) => (
            <chakra.span fontWeight={"bold"} color="black">
              {match}
            </chakra.span>
          ))}
        </Text>
      </Flex>
      <Spacer />

      <Icon as={MdSubdirectoryArrowLeft} color="neutral.grey.500" mr={4} />
    </Flex>
  </ListItem>
);
