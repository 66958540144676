import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const SubmitModal: React.FC<{
  isLoading: boolean;
  label: string;
  isOpen: boolean;
  onClose: () => void;
  onPublish: () => void;
  dataCy?: string;
}> = ({ isOpen, onClose, isLoading, label, onPublish, dataCy }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isLoading ? "Le processus est en cours de traitement !" : label}
        </ModalHeader>
        {!isLoading && <ModalCloseButton />}
        {isLoading && (
          <ModalBody py={4}>
            {t(
              "thank-you",
              "Merci de ne pas recharger la page (le traitement peut durer jusqu'à 30 secondes en fonction du nombre de données et du trafic) ... ⏳",
            )}
          </ModalBody>
        )}

        <ModalFooter justifyContent="center">
          <Button data-test={dataCy} onClick={onPublish} isLoading={isLoading}>
            {t("submit", "Soumettre")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
