import { Buffer } from "buffer";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import {
  GetXlsxFromConsolidatedViewDocument,
  type GetXlsxFromConsolidatedViewQuery,
} from "graphql/__generated__/graphql";
import { useMe } from "hooks";
import { useState } from "react";
import { useClient } from "urql";

export const useDownloadConsolidatedXlsx = () => {
  const [isLoading, setIsLoading] = useState(false);
  const urqlClient = useClient();
  const { me } = useMe();
  const date = new Date();

  const downloadXLSX = async () => {
    try {
      setIsLoading(true);
      const data = await urqlClient
        .query<GetXlsxFromConsolidatedViewQuery>(
          GetXlsxFromConsolidatedViewDocument,
          undefined,
          {
            requestPolicy: "network-only",
          },
        )
        .toPromise();
      const xslx = data.data?.xlsx;

      if (!xslx) return;

      const buffer = Buffer.from(xslx, "base64");

      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      saveAs(
        blob,
        `Extraction_${me?.partnerName}_${format(date, "yyyyMMdd")}.xlsx`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadXLSX, isLoading };
};
