import {
  Tooltip,
  Link as ChakraLink,
  Icon,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useTrackEvent } from "libs/tracking";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";
import { HiChevronDown } from "react-icons/hi";

export const ConnectedLink: React.FC<{ webAccesses?: string[] }> = ({
  webAccesses,
}) => {
  const { trackEvent } = useTrackEvent();
  const { t } = useTranslation();

  if (!webAccesses || webAccesses.length === 0) return null;

  if (webAccesses.length === 1) {
    return (
      <Tooltip
        label={t(
          "navbar-connecteed-tooltip",
          "Accéder à ma plateforme connecteed",
        )}
      >
        <ChakraLink
          href={`https://${webAccesses[0]}`}
          isExternal
          onClick={() => trackEvent("Connecteed", "click-link-connecteed")}
        >
          connecteed <Icon as={FaExternalLinkAlt} mx="2px" />
        </ChakraLink>
      </Tooltip>
    );
  }

  if (webAccesses.length > 1) {
    return (
      <Menu>
        <MenuButton as={Button} rightIcon={<Icon as={HiChevronDown} />}>
          connecteed
        </MenuButton>
        <MenuList>
          {webAccesses.map((url) => (
            <MenuItem
              key={url}
              as={ChakraLink}
              href={`https://${url}`}
              isExternal
              onClick={() =>
                trackEvent("Connecteed", `click-link-connecteed-${url}`)
              }
            >
              {url}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }
};
