import { User } from "graphql/__generated__/graphql";

import * as Sentry from "@sentry/react";

import mixpanel from "mixpanel-browser";
import { useIntercom } from "react-use-intercom";

export const boot = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DNS,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    environment: import.meta.env.VITE_APP_STAGE,
    beforeSend(event, hint) {
      console.log(event, hint);

      return event;
    },
  });

  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_ID, {
    api_host: "https://api-eu.mixpanel.com",
  });
};

export const onUserChange = async (sfUser?: User) => {
  if (!sfUser) return;
  // configure trackers
  mixpanel.identify(sfUser.id);

  mixpanel.people.set({
    $name: `${sfUser.firstName} ${sfUser.lastName}`,
    $email: sfUser.email,
    $first_name: sfUser.firstName,
    $last_name: sfUser.lastName,
    $phone: sfUser.phone,
    partner_role: sfUser?.roles.join(", "),
    company_name: sfUser?.partnerName,
    has_left: sfUser?.left,
    has_nda_valid: sfUser?.hasNdaValid,
    fonction: sfUser?.fonction,
    has_submit: sfUser?.hasSubmit,
  });

  Sentry.setUser({
    email: sfUser.email,
    id: sfUser.id,
    ip_address: "{{auto}}",
  });
};

export const onLogout = () => {
  Sentry.setUser(null);

  mixpanel.reset();
};

export const useTrackEvent = () => {
  const { trackEvent: trackIntercom } = useIntercom();
  const trackEvent = async (category: string, tag: string, obj?: any) => {
    const metadata = {
      ...obj,
    };

    if (obj?.project) {
      metadata.typeProject = obj.project.type;
    }

    trackIntercom(tag, metadata);
    mixpanel.track(tag, metadata);
  };

  return { trackEvent };
};
