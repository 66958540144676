import { Flex } from "@chakra-ui/react";
import { Inops } from "assets";
import { ConfirmResetPasswordForm } from "modules/auth";
import React from "react";

const ConfirmResetPasswordPage = () => (
  <Flex minH="100vh" bg="brand.blue.dark" align="center" justify="center">
    <Flex
      bg="white"
      p={8}
      justify="center"
      align="center"
      flexDirection="column"
      borderRadius={16}
      h="100%"
    >
      <Inops width="100%" height={74} />
      <ConfirmResetPasswordForm />
    </Flex>
  </Flex>
);

export default ConfirmResetPasswordPage;
