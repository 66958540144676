import { Text } from "@chakra-ui/react";
import { ProjectsTableWithFilters } from "modules/project/ProjectsTableWithFilters/ProjectsTableWithFilters";
import { type ProjectDataProps } from "pages/Home/HomePanel";
import { TAB_NAME } from "stores";
import { hiddenColumns, WAITING_STATUS } from "../utils";

export const ActiveProjectTab: React.FC<ProjectDataProps> = ({
  projectsData,
  isLoading,
}) => {
  const activeProjects =
    projectsData.filter((p) => !WAITING_STATUS.includes(p.status)) || [];

  if (!isLoading && !activeProjects.length)
    return <Text mt={5}>Vous n’avez pas de projet actif.</Text>;

  return (
    <ProjectsTableWithFilters
      isLoading={isLoading}
      projects={activeProjects}
      tab={TAB_NAME.activeProjects}
      hiddenColumns={hiddenColumns}
      hiddenColumnsProjects={["count"]}
    />
  );
};
