import { Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { type GetContactProjectsQuery } from "graphql/__generated__/graphql";
import { useProjectFilter } from "hooks";
import { groupBy, map } from "lodash-es";
import { ProjectFilters } from "modules/project/ProjectsTableWithFilters/ProjectFilters";
import { TAB_NAME } from "stores";
import { TanStackProjectTable } from "../ProjectsTableWithFilters/ProjectsTable";
import { PROJECT_STATUS_DICO } from "../utils";
import { useTranslation } from "react-i18next";

const hiddenColumns = ["selection", "actions"];

export const ConsolidatedViewTable: React.FC<
  GetContactProjectsQuery & { isAdv?: boolean; isLoading: boolean }
> = ({ projects, isAdv, isLoading }) => {
  const localHiddenColumns = isAdv
    ? hiddenColumns
    : [...hiddenColumns, "actions-adv"];
  const { filteredProjects, statusOptions, setFilters, filters, clear } =
    useProjectFilter(
      projects || [],
      TAB_NAME.consolidatedProjects,
      PROJECT_STATUS_DICO,
    );
  const { t } = useTranslation();

  if (isLoading)
    return (
      <Center h={500}>
        <Spinner />
      </Center>
    );

  const projectsByMarket = groupBy(
    filteredProjects,
    (p) => p.market || "Sans accord cadre",
  );

  return (
    <>
      <ProjectFilters
        status={statusOptions}
        setFilters={setFilters}
        filters={filters}
        clearFilters={clear}
      />
      {filteredProjects.length ? (
        map(projectsByMarket, (projects, key) => (
          <Flex key={key} mt={5} direction="column">
            <Text fontWeight={600} fontSize="lg" align="right">
              {key}
            </Text>
            <TanStackProjectTable
              tab={TAB_NAME.consolidatedProjects}
              data={projects}
              hiddenColumns={localHiddenColumns}
              hiddenColumnsProjects={["count"]}
            />
          </Flex>
        ))
      ) : (
        <Flex py={10}>
          {t(
            "no_project_match_your_search",
            "Aucun projet ne correspond à votre recherche.",
          )}
        </Flex>
      )}
    </>
  );
};
