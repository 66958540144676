import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

const savedLanguage = localStorage.getItem("i18nextLng") || "fr";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    saveMissing: true,
    lng: savedLanguage, // default language
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
