import { compact, uniq } from "lodash-es";
import { useUIStore, type TAB_NAME } from "stores";
import { type ITask } from "types/task";
import { matchTerm } from "utils";

export const useTaskFilter = (
  unfilteredTasks: ITask[],
  filterKey: TAB_NAME,
  dictionnary?: Record<string, string>,
) => {
  const [allFilters, setFilters, clear] = useUIStore((s) => [
    s.projectFilters,
    s.setProjectFilters,
    s.clear,
  ]);

  const filters = allFilters[filterKey] || {};

  const { searchTerm = "", status = "ALL" } = filters;

  let filteredTasks = matchTerm(unfilteredTasks, searchTerm);
  const statusOptionsArray =
    compact(uniq(filteredTasks.map((t) => t.status))) || [];

  const statusOptions = statusOptionsArray.map((status) => ({
    value: status,
    label: dictionnary ? dictionnary[status] : status,
  }));

  if (status !== "ALL") {
    filteredTasks = filteredTasks.filter((t) => t.status === status);
  }

  return {
    filteredTasks,
    statusOptions,
    setFilters: setFilters(filterKey),
    filters,
    clear,
  };
};
