import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropzone } from "components";
import { DatePicker } from "components/Datepicker";
import { RadioGroupCustom } from "components/RadioGroupCustom";
import { BOOLEAN_LABEL } from "constants/labels";
import {
  BooleanEnum,
  ElementTypeEnum,
  GetProviderOrderQuery,
  SetCachedCraDocument,
  type InputCraType,
  GetCaseQuery,
} from "graphql/__generated__/graphql";
import { useUpload } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RiCloseFill, RiDragDropLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useMutation } from "urql";
import { toBase64 } from "utils";
import { PdfView } from "../PdfView";
import { craSchema } from "../utils";
import { useTranslation } from "react-i18next";

type InputCra = InputCraType & { file: { b64: string; fullFile: File } };

export const OrderCraModalForm: React.FC<{
  order: GetProviderOrderQuery["order"];
  caseResult: GetCaseQuery["case"];
  onClose: () => void;
}> = ({ order, onClose, caseResult }) => {
  const [, setCachedCRA] = useMutation(SetCachedCraDocument);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const { uploadFile } = useUpload();

  const providerOrderId = order?.id;

  const project = order?.project;
  const reference = order?.reference;
  const endDate = order?.endDate;

  const singleCase = caseResult;

  const { cra } = singleCase || {};

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<InputCra>({
    defaultValues: {
      file: undefined,
      deliveryDate: cra?.deliveryDate || endDate,
      signature: cra?.signature,
      partnerComment: cra?.partnerComment,
    },
    // @ts-expect-error
    resolver: yupResolver(craSchema),
  });

  const onSubmit = async (values: InputCra) => {
    await craSchema.validate(values);

    setIsLoading(true);

    if (!reference) {
      alert(
        t(
          "order_cra_modal_no_reference",
          "Aucune référence de commande n'a été trouvée. Veuillez contacter le support.",
        ),
      );
      setIsLoading(false);

      return;
    }

    let filePath;

    const { file, ...restValues } = values;

    if (file?.fullFile) {
      filePath = await uploadFile(values.file.fullFile, ElementTypeEnum.Cra);
    }
    if (!filePath && !cra?.path && !cra?.attachment) {
      alert(t("order_cra_modal_no_file", "Merci de fournir une pièce jointe."));
      setIsLoading(false);

      return;
    }
    await setCachedCRA({
      cra: {
        ...restValues,
        id: caseResult.id,
        providerOrderId,
        isValidated: true,
        path: filePath,
      },
    });
    await trackEvent("Submit", "Submit CRA", {
      providerOrderId,
      projectType: project?.type,
    });

    setIsLoading(false);

    navigate(`..`);
  };

  const fileValue = watch("file");
  const signatureValue = watch("signature");

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="pv-modal">
      <Grid templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
        <GridItem
          colSpan={12}
          borderBottom={"1px solid #DCDCDC"}
          mb={20}
          bg={"white"}
          h="80px"
          zIndex={"10"}
          pos={"sticky"}
          top={0}
        >
          <Flex justify={"space-between"} align="center" mb={8} h={"100%"}>
            <Flex>
              <Icon
                as={RiCloseFill}
                color="grey"
                fontSize={30}
                mr={8}
                cursor="pointer"
                onClick={onClose}
              />
              <Heading color="neutral.text.black" size={"xl"}>
                {t(
                  "order_cra_modal_title",
                  "CRA de la commande n°{{reference}}",
                  {
                    reference: order?.name,
                  },
                )}
              </Heading>
            </Flex>

            <Button type={"submit"} isLoading={isLoading}>
              {t("order_cra_modal_save", "Sauvegarder mon CRA")}
            </Button>
          </Flex>
        </GridItem>
        <GridItem colSpan={6} pos="relative">
          <Flex direction={"column"} alignItems={"start"} gap={4}>
            {cra?.advComment && (
              <Flex>
                <Alert status="error">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>
                      {t("order_pv_modal_reject_reason", "Motif de rejet")}
                    </AlertTitle>
                    <AlertDescription display="block">
                      {cra?.advComment}
                    </AlertDescription>
                  </Box>
                </Alert>
              </Flex>
            )}
            <FormControl size="sm">
              <FormLabel>
                {t("order_cra_modal_chrono_number", "Numéro d’affaire")}
              </FormLabel>
              <Text fontSize="sm">{project?.reference}</Text>
            </FormControl>
            <FormControl isRequired size="sm" isInvalid={!!errors.deliveryDate}>
              <Flex>
                <FormLabel htmlFor="deliveryDate">
                  {t(
                    "order_cra_modal_delivery_date",
                    "Date de fin de prestation",
                  )}
                </FormLabel>
              </Flex>
              <Controller
                name={"deliveryDate"}
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <DatePicker
                    variant="filled"
                    selected={value}
                    size="sm"
                    inputSize="sm"
                    preventOpenOnFocus
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.deliveryDate?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl size="sm" isInvalid={!!errors.signature} isRequired>
              <Flex>
                <FormLabel htmlFor="signature">
                  {t(
                    "order_cra_modal_signature",
                    "Le CRA est-il signé par le client ?",
                  )}
                </FormLabel>
              </Flex>

              <RadioGroupCustom
                direction="row"
                control={control}
                name={"signature"}
                options={BOOLEAN_LABEL}
              />
              {signatureValue === BooleanEnum.False && (
                <Text color="semantic.warning.main" fontSize="sm" my={2}>
                  {t(
                    "order_cra_modal_signature_warning",
                    "Attention, le CRA que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, la signature doit être apparente sur le document.",
                  )}
                </Text>
              )}
              <FormErrorMessage>{errors.signature?.message}</FormErrorMessage>
            </FormControl>
            <FormControl size="sm">
              <FormLabel htmlFor="comment">Commentaire</FormLabel>
              <Controller
                name={"partnerComment"}
                control={control}
                rules={{ required: false }}
                render={({ field: { value, ...rest } }) => (
                  <Textarea
                    p={4}
                    fontSize="sm"
                    height={40}
                    placeholder={t(
                      "order_cra_modal_comment",
                      "Informations complémentaires sur le document",
                    )}
                    variant="filled"
                    value={value || ""}
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.partnerComment?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </GridItem>
        <GridItem colSpan={6} alignSelf="start">
          <Controller
            render={({ field }) => (
              <Dropzone
                accept={{ "application/pdf": [".pdf"] }}
                onChange={async (e) => {
                  const b64 = await toBase64(e);

                  field.onChange({ b64, fullFile: e });
                }}
                label={
                  <Flex
                    align="center"
                    justifyContent="start"
                    width="100%"
                    gap={6}
                  >
                    <Icon
                      as={RiDragDropLine}
                      color={"brand.blue.main"}
                      fontSize={30}
                    />
                    <Box>
                      <Heading color={"neutral.grey.900"}>
                        {fileValue?.fullFile?.name}
                      </Heading>
                      <Text size={"md"} color={"brand.blue.main"}>
                        {cra?.attachment || cra?.path
                          ? t(
                              "order_cra_modal_replace_file",
                              "Remplacer votre pièce jointe",
                            )
                          : t(
                              "order_cra_modal_upload_file",
                              "Déposer ou parcourir dans votre ordinateur",
                            )}
                      </Text>
                    </Box>
                  </Flex>
                }
              />
            )}
            control={control}
            name="file"
          />
          {fileValue ? (
            <PdfView base64={fileValue.b64} />
          ) : (
            (cra?.path || cra?.attachment) && (
              <PdfView
                path={cra?.path}
                base64={`data:application/pdf;base64,${
                  cra?.attachment?.b64 ?? ""
                }`}
              />
            )
          )}
        </GridItem>
      </Grid>
    </form>
  );
};
