import {
  GetContactProjectsDocument,
  ProjectTypeEnum,
  RolesEnum,
} from "graphql/__generated__/graphql";
import { map } from "lodash-es";
import { useQuery } from "urql";
import { useMe } from "./useMe";

export const useGetProjects = () => {
  const [{ data, fetching: isLoadingProjects, stale }] = useQuery({
    query: GetContactProjectsDocument,
  });

  const { me, isLoading: isLoadingMe } = useMe();

  const projects = data?.projects || [];

  const filteredProjects = projects.filter((p) =>
    map(p.phases, (p) => p.partner?.email).includes(me?.email ?? ""),
  );

  const isLoading = isLoadingProjects || isLoadingMe || stale;
  const isADVorDirector =
    me?.roles.includes(RolesEnum.ProviderSales) ||
    me?.roles.includes(RolesEnum.ProviderExecutive);

  const hasOnlyAT =
    projects.length > 0 && projects.every((p) => p.type === ProjectTypeEnum.At);

  const hasOnlyForfait =
    projects.length > 0 &&
    projects.every(
      (p) =>
        p.type === ProjectTypeEnum.Forfait || p.type === ProjectTypeEnum.Atf,
    );

  return {
    projects: isADVorDirector ? filteredProjects : projects,
    companyProjects: projects,
    isLoading,
    hasOnlyAT,
    hasOnlyForfait,
  };
};
