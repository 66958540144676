import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const AlertsSettings = () => {
  const navigate = useNavigate();
  return (
    <Container maxW={"4xl"} alignContent={"center"}>
      <Heading fontSize={"3xl"}>
        Dans quel contexte souhaitez être contacté par INOPS?
      </Heading>
      <CheckboxGroup>
        <Stack gap={2} my={10}>
          <Checkbox>Appel d'offre sur mes expertises</Checkbox>{" "}
          <Checkbox>Sur les marchés</Checkbox>
          <Checkbox>Portage salarial</Checkbox>
          <Checkbox>Sur mes expertises</Checkbox>
          <Checkbox>Les autres services du groupe Freelance</Checkbox>
        </Stack>
        <Box my={10} />
      </CheckboxGroup>
      <Flex direction={"column"} alignItems={"center"}>
        <Button
          mt={20}
          size={"lg"}
          fontSize={"md"}
          px={6}
          onClick={() => {
            navigate("/onboarding/ma-societe/fiche-identite");
          }}
        >
          Étape suivante
        </Button>
      </Flex>
    </Container>
  );
};
