import {
  type BooleanEnum,
  TaskRejectionEnum,
} from "graphql/__generated__/graphql";

export const mapTaskEnumToLabel = {
  [TaskRejectionEnum.MissingValidation]: {
    large:
      "Il manque la validation du bénéficiaire dans la PJ soumise. Ce dernier doit accepter de manière explicite le décalage de la date de livraison de la phase pour que la PJ soit acceptée.",
    small: "PJ sans validation bénéficiaire",
  },
  [TaskRejectionEnum.InvalidDate]: {
    large:
      "La nouvelle date d'échéance doit identifier un jour précis pour être valide, par exemple en utilisant le format de date JJ/MM/AAAA.",
    small: "Date invalide",
  },
  [TaskRejectionEnum.InvalidDocument]: {
    large:
      "Nous ne sommes pas parvenus à ouvrir / exploiter la PJ transmise. Pour maximiser les chances de traitement, merci de joindre un fichier au format PDF, OUTLOOK, PPT ou WORD.",
    small: "PJ invalide",
  },
  [TaskRejectionEnum.BadDate]: {
    large:
      "La date repositionnée est antérieure à la date du jour, ce qui signifie que le projet est toujours en retard contractuellement. Merci de revenir vers le bénéficiaire pour identifier une date cible dans le futur, et revenir vers nous avec son accord.",
    small: "Date incohérente",
  },
  [TaskRejectionEnum.ReferenceMissing]: {
    large:
      "La PJ ne permet pas d’identifier clairement le projet / le numéro d’ordre de service / la phase liée à la demande de décalage. Cela ne nous permet donc pas de procéder au décalage contractuel dans des conditions acceptables. Merci de joindre une pièce justificative très précise quand au périmètre projet impacté par le décalage.",
    small: "Ref projet manquante",
  },
};
export const mapCancelationTaskEnumToLabel = {
  [TaskRejectionEnum.MissingValidation]: {
    large:
      "Il manque la validation du bénéficiaire dans la PJ soumise. Ce dernier doit accepter de manière explicite l'annulation de la phase pour que la PJ soit acceptée.",
    small: "PJ sans validation bénéficiaire",
  },
  [TaskRejectionEnum.ReferenceMissing]: {
    large:
      "La PJ ne permet pas d’identifier clairement le projet / le numéro d’ordre de service / la phase liée à la demande de d'annulation. Cela ne nous permet donc pas de procéder à l'annulation. Merci de joindre une pièce justificative très précise quand au périmètre projet impacté par le décalage.",
    small: "Ref projet manquante",
  },
};

export type TaskInputType = {
  isFromClient: BooleanEnum;
  isDateIncluded: BooleanEnum;
  isNewDateEarlier: BooleanEnum;
  isProjectMentionned: BooleanEnum;
};

export type CancelationTaskInputType = {
  isFromClient: BooleanEnum;
  isProjectMentionned: BooleanEnum;
};

export const mapInputToRejection = {
  isFromClient: mapTaskEnumToLabel.MISSING_VALIDATION.small,
  isDateIncluded: mapTaskEnumToLabel.INVALID_DATE.small,
  isNewDateEarlier: mapTaskEnumToLabel.BAD_DATE.small,
  isProjectMentionned: mapTaskEnumToLabel.REFERENCE_MISSING.small,
};

export const mapInputToCancelationRejection = {
  isFromClient: mapCancelationTaskEnumToLabel.MISSING_VALIDATION.small,
  isProjectMentionned: mapCancelationTaskEnumToLabel.REFERENCE_MISSING.small,
};
