import {
  type ClientOrder,
  type ProviderOrder,
} from "graphql/__generated__/graphql";
import { statusOrderDico } from "../utils";
import { Text } from "@chakra-ui/react";

export const OrderStatus: React.FC<{
  status: ProviderOrder["status"] | ClientOrder["status"];
}> = ({ status }) => {
  if (!status) {
    return null;
  }

  return (
    <Text fontSize={"14px"} fontWeight={600}>
      {statusOrderDico[status]}
    </Text>
  );
};
